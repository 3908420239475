import React from "react";

import { Row, Col, Typography, Divider, Button } from "antd";
import { Link } from "react-router-dom";
import { EnvironmentOutlined } from "@ant-design/icons";

const { Title } = Typography;

const MobileView = ({ data }) => {
  const { company, location, role, level, logoURL, websiteURL } = data.data;
  const { documentID } = data;

  return (
    <>
      <Row>
        <Col offset={1} span={6}>
          <img
            src={logoURL}
            alt="Company logo"
            width="50"
            style={{ paddingTop: "10%", width: "100%" }}
          />
        </Col>
        <Col offset={1} span={16}>
          <Title level={5}>
            <span style={{ fontSize: 14 }}>
              {level} {role}
            </span>
          </Title>
          <Row>
            <Col span={24}>
              <a href={websiteURL}>{company}</a>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <EnvironmentOutlined /> {location}
            </Col>
          </Row>
        </Col>
      </Row>
      <Divider style={{ margin: "18px" }} />
      <Row justify="center" style={{ marginBottom: "10px" }}>
        <Col span={22} offset={1}>
          <Link to={`/opportunities/jobs/${documentID}`}>
            <Button style={{ width: "100%" }}>View Details</Button>
          </Link>
        </Col>
      </Row>
    </>
  );
};

export default MobileView;
