export const opportunityRoleOptions = [
  "Software Engineering",
  "Hardware Engineering",
  "Sales",
  "Business Development",
  "Finance & Operations",
  "Product Management",
  "Marketing",
  "Design",
  "HR",
  "Academic",
  "other",
];

export const opportunityLevelOptions = [
  "Intern",
  "Freelancer",
  "Working student",
  "Junior",
  "Senior",
  "VP",
  "Co-Founder",
];
