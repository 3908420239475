import React from "react";
// Ant design
import { Row, Col, Button, Typography } from "antd";
// CSS
import "./Profile.css";

const { Text } = Typography;

const ProfileField = ({ label, buttons = [], children }) => {
  const MAX_GRID_WITDH = 24;
  const COL_BUTTON_WIDTH = 2;
  const COL_VALUE_WIDTH = MAX_GRID_WITDH - COL_BUTTON_WIDTH * buttons.length;

  return (
    <Row>
      <Col span={24} className="entry-wrapper">
        <Row>
          <Col span={COL_VALUE_WIDTH}>
            <div>
              <Text type="secondary" className="label">
                {label}
              </Text>
              <h3 className="entry-heading">{children}</h3>
            </div>
          </Col>
          {buttons && (
            <>
              {buttons.map(button => (
                <Col span={COL_BUTTON_WIDTH} key={button.icon}>
                  <Button
                    type="primary"
                    shape="circle"
                    icon={button.icon}
                    onClick={() => button.onClick()}
                    className="entry-action-button"
                  />
                </Col>
              ))}
            </>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default ProfileField;
