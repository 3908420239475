import React, { Component } from "react";
import { Form, Button, message } from "antd";
import { CaretLeftOutlined, SaveOutlined } from "@ant-design/icons";
import { compose } from "recompose";
import { UserFormExtended, UserFormExtendedGetInitialValues } from "./Extended";
import { UserFormBasic, UserFormBasicGetInitialValues } from "./Basic";
import { withFirebase } from "../Firebase";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

class UserForm extends Component {
  formRef = React.createRef();

  setupInitialValues() {
    const { authUser, formItems } = this.props;
    const initialValues = {};

    if (formItems.basic) {
      const UserFormBasicInitialValues = UserFormBasicGetInitialValues(
        authUser,
      );
      Object.assign(initialValues, UserFormBasicInitialValues);
    }

    if (formItems.extended) {
      const UserFormExtendedInitialValues = UserFormExtendedGetInitialValues(
        authUser,
      );
      Object.assign(initialValues, UserFormExtendedInitialValues);
      // console.log("-- extended initial values --");
      // console.log(UserFormExtendedInitialValues);
    }
    return initialValues;
  }

  onFinish = values => {
    const { authUser, firebase, onSubmitAdd, onNext } = this.props;
    firebase.doUpdateUserProfile(authUser.email, values, () => {
      message.destroy();
      message.success("Your profile was saved");
      firebase.logEvent("profile_update_profile");
      if (onSubmitAdd) {
        onSubmitAdd();
      } else if (onNext) {
        onNext();
      }
    });
  };

  onFinishFailed = ({ errorFields }) => {
    message.error("Please fill in all fields");
    this.formRef.scrollToField(errorFields[0].name);
  };

  render() {
    const { authUser, formItems, onBack, onNext } = this.props;
    const formProps = {
      authUser,
      formItemLayout,
    };
    const initialValues = this.setupInitialValues();
    // console.log("initial values");

    // console.log(initialValues);

    return (
      <Form
        onFinish={this.onFinish}
        onFinishFailed={this.onFinishFailed}
        initialValues={initialValues}
      >
        {formItems.basic && <UserFormBasic {...formProps} />}

        {formItems.extended && <UserFormExtended {...formProps} />}
        <Button.Group style={{ marginTop: "2.2rem" }}>
          {onBack && (
            <Button icon={<CaretLeftOutlined />} onClick={() => onBack()}>
              Back
            </Button>
          )}
          <Button type="primary" icon={<SaveOutlined />} htmlType="submit">
            {onNext ? "Continue" : "Save"}
          </Button>
        </Button.Group>
      </Form>
    );
  }
}

const WrappedForm = compose(withFirebase)(UserForm);

export default WrappedForm;
