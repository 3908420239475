import React, { useState, useEffect } from "react";
import { Layout, Row, Col, Card, message, PageHeader, Button } from "antd";
import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import { withFirebase } from "../Firebase";
import { withAuthorization } from "../Session";

// Alternative maps:
// * Google Maps (already using Firebase)
// * Simple Maps: https://www.react-simple-maps.io
// * Map from Syncfusion Components: https://ej2.syncfusion.com/home/react.html

import "./index.css";

const viewport = {
  center: [35, 6],
  zoom: 3,
};

const Meetups = ({ firebase }) => {
  const [locations, setLocations] = useState([]);
  const [meetups, setMeetups] = useState([]);

  useEffect(() => {
    const fetchLocations = () => {
      firebase.db
        .collection("locations")
        .get()
        .then(snapshot => {
          const data = snapshot.docs.map(doc => doc.data());
          setLocations(data);
        })
        .catch(() => message.error("Failed to fetch locations"));
    };
    fetchLocations();

    const fetchMeetups = () => {
      firebase.db
        .collection("meetups")
        .get()
        .then(snapshot => {
          const data = snapshot.docs.map(doc => doc.data());
          setMeetups(data);
        })
        .catch(() => message.error("Failed to fetch meetups"));
    };
    fetchMeetups();
  }, [firebase]);

  return (
    <Layout className="module">
      <PageHeader
        title="Locations"
        subTitle="Check out all the CDTM locations around the world!"
        className="page-header"
        extra={[
          <Button
            href="https://forms.gle/1Kb5BKHng4oVwAR29"
            target="_blank"
            rel="noopener noreferrer"
          >
            Submit new Meetup
          </Button>,
        ]}
      />
      <Map
        viewport={viewport}
        maxZoom={10}
        attributionControl
        zoomControl
        doubleClickZoom
        scrollWheelZoom={false}
        dragging
        animate
        easeLinearity={0.35}
        style={{ height: "600px", maxHeight: "80%" }}
      >
        <TileLayer
          url="https://stamen-tiles-{s}.a.ssl.fastly.net/toner-lite/{z}/{x}/{y}{r}.png"
          attribution='Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> | Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />

        {locations &&
          locations.map(location => {
            return (
              <Marker position={location.position}>
                <Popup>
                  <p>
                    <b>{location.name}</b>{" "}
                  </p>
                  <p>
                    <b>Description: </b>
                    {location.description}{" "}
                  </p>
                  <p>
                    <b>Channel: </b>
                    {location.channel}{" "}
                    <a
                      href={location.channelLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Join here
                    </a>{" "}
                  </p>
                  <p>
                    <b>Contact for questions: </b>
                    {location.email}{" "}
                  </p>
                </Popup>
              </Marker>
            );
          })}
      </Map>

      <PageHeader
        title="Meetups"
        subTitle="Check out all the (Munich based) Meetups!"
        className="page-header"
        ghost={false}
      />

      <Row>
        <Col span={20} offset={2}>
          {meetups &&
            meetups.map(meetup => {
              return (
                <Row className="meetup-card-row">
                  <Col span={24} className="meetup-card-col">
                    <Card className="meetup-card">
                      <Row justify="space-between">
                        <Col xs={20} sm={20} md={20} lg={3}>
                          <b>{meetup.name}</b>
                        </Col>
                        <Col xs={12} sm={24} md={12} lg={12}>
                          {meetup.description}
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={2}>
                          <Button
                            className="meetup-card-button-row-button"
                            href={meetup.channelLink}
                            target="_blank"
                          >
                            Join
                          </Button>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              );
            })}
        </Col>
      </Row>
    </Layout>
  );
};

const condition = authUser => authUser != null;

export default withFirebase(withAuthorization(condition)(Meetups));
