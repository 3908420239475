import React from "react";
import { Typography, Row, Col, Divider, Empty } from "antd";

import { withRouter } from "react-router-dom";
import * as ROUTES from "../../../constants/routes";
import "./Profile.css";

import ProfileHeader from "./ProfileHeader";
import ProfileBody from "./ProfileBody";

const { Text } = Typography;

const DirectoryProfile = ({ userData, authUserEmail, history }) => {
  if (userData == null) return <Empty />;

  return (
    <React.Fragment>
      <ProfileHeader
        authUserEmail={authUserEmail}
        userData={userData}
        history={history}
        accountRoute={ROUTES.ACCOUNT}
      />
      <ProfileBody userData={userData} />
      <Divider style={{ marginTop: "1.5rem", marginBottom: "2rem" }} />

      {userData.updatedAt && (
        <Row>
          <Col>
            <Text type="secondary">
              Updated on{" "}
              {userData.updatedAt.toDate().toLocaleDateString("en-EN", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
            </Text>
          </Col>
        </Row>
      )}
      {userData.registeredAt && (
        <Row>
          <Col>
            <Text type="secondary">
              Joined on{" "}
              {userData.registeredAt.toDate().toLocaleDateString("en-EN", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
            </Text>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
};

export default withRouter(DirectoryProfile);
