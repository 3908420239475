import Job from "./Job";

class JobFbDocument {
  constructor(documentID = "", data = new Job({})) {
    this.documentID = documentID;
    this.data = data;
  }
}

export default JobFbDocument;
