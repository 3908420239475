// util functions unique to the opportunities tab
const sortByLatest = (a, b) => {
  return a.createdAt.seconds > b.createdAt.seconds ? -1 : 1;
};

const sortByEarliest = (a, b) => {
  return a.createdAt.seconds > b.createdAt.seconds ? 1 : -1;
};

const sortJobList = (list, sortCriteria) => {
  let sorted;
  switch (sortCriteria) {
    case "latest":
      sorted = list.sort((a, b) => sortByLatest(a.data, b.data)).slice();
      break;
    case "earliest":
      sorted = list.sort((a, b) => sortByEarliest(a.data, b.data)).slice();
      break;
    default:
      break;
  }
  return sorted;
};

const sortTalentList = (list, sortCriteria) => {
  let sorted;
  switch (sortCriteria) {
    case "latest":
      sorted = list.sort(sortByLatest).slice();
      break;
    case "earliest":
      sorted = list.sort(sortByEarliest).slice();
      break;
    default:
      break;
  }
  return sorted;
};

export { sortByEarliest, sortByLatest, sortJobList, sortTalentList };
