export const LANDING = "/";
export const LOGIN = "/login";
export const VERIFY = "/verify";
export const ACCOUNT = "/account";
export const ADMIN = "/admin";
export const SETUP = "/setup";
export const DIRECTORY = "/directory";
export const RESOURCES = "/resources";
export const OPPORTUNITIES = "/opportunities";
export const MEETUPS = "/meetups";
export const VIRTUAL_CENTER = "/virtual-center";
export const EMAIL_PREFERENCES = "/email-preferences";
