import React from "react";
import { Menu } from "antd";
import {
    TeamOutlined,
    FilterOutlined,
    BankOutlined,
    BulbOutlined,
    CoffeeOutlined,
    LaptopOutlined,
  } from "@ant-design/icons"

  class FilterMenu extends React.Component {

    select(filterSelected){
        const {callback} = this.props
        callback(filterSelected)
    }

    render(){
        return(
            <Menu>
                <Menu.Item icon={<TeamOutlined/>} onClick={() => this.select("active")}>
                Active
                </Menu.Item>
                <Menu.Item icon={<CoffeeOutlined/>} onClick={() => this.select("main")}>
                Main Rooms
                </Menu.Item>
                <Menu.Item icon={<BankOutlined/>} onClick={() => this.select("lecture")}>
                Lecture Halls
                </Menu.Item>
                <Menu.Item icon={<BulbOutlined/>} onClick={() => this.select("group")}>
                Group Rooms
                </Menu.Item>
                <Menu.Item icon={<LaptopOutlined/>} onClick={() => this.select("ca")}>
                CA Rooms
                </Menu.Item>
                <Menu.Item icon={<FilterOutlined/>} onClick={() => this.select("")}>
                Reset
                </Menu.Item>
            </Menu>
        )
    }
}

export default FilterMenu; 