import React from "react";
// Ant Design
import { Row, Col, Typography, Button } from "antd";
import { EditOutlined } from "@ant-design/icons";
// Own Components
import AvatarImage from "../../Avatar";

const { Text } = Typography;

const ProfileHeader = React.memo(
  ({ authUserEmail, userData, history, accountRoute }) => {
    return (
      <Row style={{ marginBottom: "4vh" }}>
        <Col
          xs={9}
          sm={9}
          md={9}
          lg={9}
          xl={8}
          xxl={8}
          style={{ paddingRight: "4%" }}
        >
          <AvatarImage
            src={userData.avatar}
            className="directory-card"
            style={{ border: "1px solid #e8e8e8" }}
          />
        </Col>
        <Col xs={15} sm={15} md={15} lg={15} xl={16} xxl={16}>
          <h1 className="name">{userData.firstName}</h1>
          <h1 className="name">{userData.lastName}</h1>
          <div>
            <Text type="secondary" className="header-label">
              {userData.status}
            </Text>
          </div>
          {authUserEmail && userData.email && authUserEmail === userData.email && (
            <Button
              icon={<EditOutlined />}
              onClick={() => history.push(accountRoute)}
              className="edit-button"
            >
              Edit Profile
            </Button>
          )}
        </Col>
      </Row>
    );
  },
);

export default ProfileHeader;
