import React from "react";

import { Col, Row } from "antd";
import { BankOutlined } from "@ant-design/icons";

const DesktopView = ({ data }) => {
  const { company, location, role, level, logoURL, createdAt } = data.data;

  const date = new Date(createdAt.seconds * 1000).toLocaleDateString();

  return (
    <Row justify="space-between">
      <Col className="logo-col" xs={4} sm={4} md={4} lg={1}>
        {logoURL ? (
          <img src={logoURL} alt="company logo" className="company-logo" />
        ) : (
          <BankOutlined />
        )}
      </Col>
      <Col xs={20} sm={20} md={20} lg={5}>
        <b>
          {level} - {role}
        </b>
      </Col>
      <Col xs={12} sm={24} md={12} lg={2}>
        {company}
      </Col>
      <Col xs={12} sm={24} md={12} lg={2}>
        {location}
      </Col>
      <Col xs={12} sm={12} md={12} lg={3}>
        created on {date}
      </Col>
    </Row>
  );
};

export default DesktopView;
