import React from "react";
// Ant Design
import { Col, Tag, Typography } from "antd";
import {
  MailOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  SlackOutlined,
  MessageOutlined,
  PhoneOutlined,
  GitlabOutlined,
  GithubOutlined,
  LinkedinOutlined,
} from "@ant-design/icons";
// Own Components
import ProfileField from "./ProfileField";
// Options & utils
import {
  openMailAdressLink,
  openTelephoneLink,
  openWhatsappLink,
  openSlackLink,
  openGithubLink,
  openGitlabLink,
  openUrl,
} from "./utils";

const { Text } = Typography;

const ProfileBodySection = ({ fields, userData }) => {
  return (
    <>
      {fields.map(field => {
        // Everyone has a CDTM login email, is not a profile attribute
        if (field.type === "cdtm-email")
          return (
            <ProfileField
              key={field.label}
              label={field.label}
              buttons={[
                {
                  icon: <MailOutlined />,
                  onClick: () => openMailAdressLink(userData.email),
                },
              ]}
            >
              <Text className="entry-text" copyable>
                {userData.email}
              </Text>
            </ProfileField>
          );

        // Skip unused fields
        if (!userData[field.value]) return "";

        // Specify behavior based on type
        switch (field.type) {
          case "checkbox":
          case "tags":
            return (
              <ProfileField key={field.label} label={field.label}>
                {userData[field.value].map(tag => (
                  <Tag key={tag} className="tag">
                    {tag}
                  </Tag>
                ))}
              </ProfileField>
            );

          // custom as it does not have a heading styled like the others
          case "switch":
            return (
              <Col key={field.label} className="entry-wrapper">
                <div>
                  <Text style={{ marginRight: "5px" }}>{field.label}</Text>
                  {userData[field.value] ? (
                    <CheckCircleOutlined
                      theme="twoTone"
                      twoToneColor="#bfbfbf"
                    />
                  ) : (
                    <CloseCircleOutlined
                      theme="twoTone"
                      twoToneColor="#bfbfbf"
                    />
                  )}
                </div>
              </Col>
            );

          case "link":
            return (
              <ProfileField
                key={field.label}
                label={field.label}
                buttons={
                  field.icon && [
                    {
                      icon: <LinkedinOutlined/>,
                      onClick: () => openUrl(userData[field.value]),
                    },
                  ]
                }
              >
                <a
                  href={userData[field.value]}
                  rel="noopener noreferrer"
                  target="_blank"
                  className="link"
                >
                  {userData[field.value].replace(
                    /^(?:https?:\/\/)?(?:www\.)?/i,
                    "",
                  )}
                </a>
              </ProfileField>
            );

          case "phone":
            return (
              // Browser throws an error here because of non unique childs
              // Was not able to resolve it, might be because its two buttons
              <ProfileField
                key={field.label}
                label={field.label}
                buttons={[
                  {
                    icon: <MessageOutlined/>,
                    onClick: () => openWhatsappLink(userData[field.value]),
                  },
                  {
                    icon: <PhoneOutlined/>,
                    onClick: () => openTelephoneLink(userData[field.value]),
                  },
                ]}
              >
                {userData[field.value]}
              </ProfileField>
            );

          case "slack":
            return (
              <ProfileField
                key={field.label}
                label={field.label}
                buttons={
                  field.team_id && [
                    {
                      icon: <SlackOutlined />,
                      onClick: () =>
                        openSlackLink(field.team_id, userData[field.value]),
                    },
                  ]
                }
              >
                {userData[field.value]}
              </ProfileField>
            );

          case "google":
            return (
              <ProfileField
                key={field.label}
                label={field.label}
                buttons={[
                  {
                    icon: <MailOutlined />,
                    onClick: () => openMailAdressLink(userData[field.value]),
                  },
                ]}
              >
                {userData[field.value]}
              </ProfileField>
            );

          case "github":
            return (
              <ProfileField
                key={field.label}
                label={field.label}
                buttons={[
                  {
                    icon: <GithubOutlined />,
                    onClick: () => openGithubLink(userData[field.value]),
                  },
                ]}
              >
                {userData[field.value]}
              </ProfileField>
            );

          case "gitlab":
            return (
              <ProfileField
                key={field.label}
                label={field.label}
                buttons={[
                  {
                    icon: <GitlabOutlined />,
                    onClick: () => openGitlabLink(userData[field.value]),
                  },
                ]}
              >
                {userData[field.value]}
              </ProfileField>
            );

          default: {
            return (
              <ProfileField key={field.label} label={field.label}>
                {userData[field.value].charAt(0).toUpperCase() +
                  userData[field.value].slice(1)}
              </ProfileField>
            );
          }
        }
      })}
    </>
  );
};

export default ProfileBodySection;