import React, { useState } from "react";
import { isMobileOnly } from "react-device-detect";
import { Drawer, message } from "antd";
import { Swipeable } from "react-swipeable";

import TalentForm from "../TalentForm";
import { withFirebase } from "../../../Firebase";

function TalentDrawer(props) {
  const {
    firebase,
    windowWidth,
    authUser,
    drawerOpen,
    setDrawerOpen,
    setReloadPosts,
    formData,
  } = props;

  const [fileUploaded, setFileUploaded] = useState("");

  const swipe = (event, isMobile) => {
    if (
      (isMobile &&
        event.dir === "Down" &&
        event.absY >= 140 &&
        event.velocity >= 1.5) ||
      (!isMobile &&
        event.dir === "Right" &&
        event.absX >= 160 &&
        event.velocity >= 1.5)
    ) {
      setDrawerOpen(false);
    }
  };

  const closeDrawer = () => {
    // delete files if necessary
    if (fileUploaded) {
      firebase.removeOpportunityPDF("talent", authUser, fileUploaded);
    }
    setDrawerOpen(false);
    message.info("Talent was not updated.");
  };

  return (
    <Drawer
      placement={isMobileOnly ? "bottom" : "right"}
      visible={drawerOpen}
      onClose={closeDrawer}
      closable
      destroyOnClose
      width={Math.min(windowWidth * 0.85, 750)}
      height="85%"
    >
      <Swipeable onSwiped={event => swipe(event, isMobileOnly)}>
        <TalentForm
          authUser={authUser}
          closeDrawer={() => setDrawerOpen(false)}
          ReloadPosts={() => setReloadPosts(true)}
          setFileUploaded={value => {
            setFileUploaded(value);
          }}
          formData={formData}
        />
      </Swipeable>
    </Drawer>
  );
}

export default withFirebase(TalentDrawer);
