const openMailAdressLink = mailAddress => {
    window.location.href = `mailto:${mailAddress}`;
  };

  const openTelephoneLink = phoneNumber => {
    window.location.href = `tel:${phoneNumber}`;
  };

  const openWhatsappLink = phoneNumber => {
    window.location.href = `https://wa.me/${phoneNumber.replace(/^,/, "")}`;
  };

  const openSlackLink = (team, user) => {
    window.location.href = `slack://user?team=${team}&id=${user}`;
  };

  const openGithubLink = username => {
    window.open(`https://github.com/${username}`);
  };

  const openGitlabLink = username => {
    window.open(`https://gitlab.com/${username}`);
  };

  const openUrl = url => {
    window.open(url);
  };

export {
    openMailAdressLink,
    openTelephoneLink,
    openWhatsappLink,
    openSlackLink,
    openGithubLink,
    openGitlabLink,
    openUrl,
}