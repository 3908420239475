import React, { useState } from "react";
import {
  Form,
  Input,
  Select,
  Button,
  Upload,
  AutoComplete,
  message,
  Checkbox,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { compose } from "recompose";
import { withFirebase } from "../../../Firebase";
import {
  opportunityRoleOptions,
  opportunityLevelOptions,
} from "../../../../constants/opportunities";

// import { resourceTypes } from "../../../../constants/resources";
// import WrapperStatistic from "antd/lib/statistic/Statistic";
import cities from "../../../../constants/worldcities";

const { Option } = Select;
const { TextArea } = Input;

const layout = {
  labelCol: {
    span: 32,
  },
  wrapperCol: {
    span: 32,
  },
};
const tailLayout = {
  wrapperCol: { offset: 12, span: 32 },
};

function TalentForm(props) {
  const [location, setLocation] = useState("");
  const [options, setOptions] = useState([]);
  const [fileUploaded, setFileUploaded] = useState("");
  const [numberUploads, setNumberUploads] = useState(0);
  const [form] = Form.useForm();

  const { firebase, authUser, closeDrawer, ReloadPosts } = props;

  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name);
  };

  const onFinish = values => {
    firebase
      .doCreateTalent(values, authUser)
      .then(() => {
        ReloadPosts();
        closeDrawer();
        message.success("Talent was added successfully!");
      })
      .catch(() => {
        message.error("Error in firebase. Please contact admins.");
      });
  };

  const onSearchLocation = searchText => {
    const potentialOptions = cities.filter(word => word.includes(searchText));
    const newOptions = potentialOptions.map(word => ({
      value: word,
    }));

    setOptions(!searchText ? [] : newOptions);
  };

  const onSelectLocation = data => {
    setLocation(data);
  };

  const onChangeLocation = data => {
    setLocation(data);
  };

  const propsUpload = {
    name: "job_description",
    headers: {
      authorization: "authorization-text",
    },
    accept: ".pdf",
    multiple: false,
    beforeUpload(file, fileList) {
      if (numberUploads === 1) {
        message.error("Only one file upload is allowed.");
        fileList.pop();
        return false;
      }
      return true;
    },
    onChange(info) {
      if (info.file.status === "done") {
        setFileUploaded(info.file.uid);
        props.setFileUploaded(info.file.uid);
        setNumberUploads(1);
        message.success("Document was upload successfully.");
      } else if (info.file.status === "error") {
        setNumberUploads(0);
        message.error("Document was not uploaded.");
      } else if (info.file.status === "removed") {
        setFileUploaded("");
        props.setFileUploaded("");
        setNumberUploads(0);
        firebase
          .removeOpportunityPDF("talent", authUser, fileUploaded)
          .then(() => {
            message.success("Document was removed successfully.");
          })
          .catch(() => {
            message.error("Document was not removed successfully.");
          });
      }
    },
    customRequest({ onError, onSuccess, file, onProgress }) {
      firebase.uploadOpportunityPDF(
        "talent",
        onError,
        onSuccess,
        onProgress,
        file,
        authUser,
      );
    },
  };

  return (
    <React.Fragment>
      <h1>Submit a new talent</h1>

      <Form
        {...layout}
        layout="vertical"
        name="basic"
        initialValues={{
          level: "Working student",
          affiliation: "Member of CDTM",
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label="First name and last name"
          name="name"
          rules={[{ required: true, message: "Name is missing" }]}
        >
          <Input placeholder="Steve Jobs" />
        </Form.Item>

        <Form.Item
          label="Role:"
          name="role"
          rules={[{ required: true, message: "" }]}
        >
          <Checkbox.Group options={opportunityRoleOptions} />
        </Form.Item>

        <Form.Item
          label="Level:"
          name="level"
          rules={[{ required: true, message: "" }]}
        >
          <Select style={{ width: 200 }}>
            {opportunityLevelOptions.map(element => {
              return <Option value={element}>{element}</Option>;
            })}
          </Select>
        </Form.Item>

        <Form.Item
          label="Location (Name of city):"
          name="location"
          rules={[{ required: true, message: "Location is missing" }]}
        >
          <AutoComplete
            value={location}
            options={options}
            style={{
              width: 200,
            }}
            onSelect={onSelectLocation}
            onSearch={onSearchLocation}
            onChange={onChangeLocation}
            placeholder="Munich"
          />
        </Form.Item>

        <Form.Item
          label="CDTM affiliation:"
          name="cdtmAffiliation"
          rules={[{ required: true, message: "" }]}
        >
          <Select style={{ width: 200 }}>
            <Option value="true">Member of CDTM</Option>
            <Option value="false">Extern</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Description:"
          name="description"
          rules={[{ required: true, message: "Description is missing" }]}
        >
          <TextArea
            rows={4}
            placeholder="My name is Timon and I am from the class of Fall 19. I am looking for a working student position ..."
          />
        </Form.Item>

        <Form.Item
          label="CV as PDF:"
          name="pdfFile"
          rules={[{ required: true, message: "CV is missing" }]}
        >
          <Upload {...propsUpload}>
            <Button>
              <UploadOutlined /> Click to Upload
            </Button>
          </Upload>
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </React.Fragment>
  );
}

const WrappedDrawer = compose(withFirebase)(TalentForm);

export default WrappedDrawer;
