import React, { useState } from "react";
import {
  Form,
  Input,
  Select,
  Button,
  Upload,
  AutoComplete,
  message,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { compose } from "recompose";
import { withFirebase } from "../../../Firebase";
import cities from "../../../../constants/worldcities";
import {
  opportunityRoleOptions,
  opportunityLevelOptions,
} from "../../../../constants/opportunities";

const { Option } = Select;
const { TextArea } = Input;

const layout = {
  labelCol: {
    span: 32,
  },
  wrapperCol: {
    span: 32,
  },
};
const tailLayout = {
  wrapperCol: { offset: 12, span: 32 },
};

function JobForm(props) {
  // const [form] = Form.useForm();

  const { firebase, closeDrawer, ReloadPosts, formData, authUser } = props;

  const [location, setLocation] = useState(formData.location);
  const [options, setOptions] = useState([]);
  const [initalUploadedFile] = useState(formData.pdfUid);
  const [fileUploaded, setFileUploaded] = useState(formData.pdfUid);
  const [numberUploads, setNumberUploads] = useState(formData.pdfUid ? 1 : 0);
  const [form] = Form.useForm();
  const [logoURL, setlogoURL] = useState(formData.logoURL);

  const getDownloadURL = () => {
    if (!formData.pdfUid) {
      return;
    }
    const ref = firebase.storage
      .ref()
      .child(`jobs/${authUser.uid}/${formData.pdfUid}`);

    ref
      .getDownloadURL()
      .then(url => {
        formData.url = url;
      })
      .catch(() => {
        // console.error(err);
      });
  };
  getDownloadURL();

  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name);
  };

  const onFinish = values => {
    const data = values;
    if (initalUploadedFile && !data.pdfFile) {
      // case 1: keep initial file
      data.pdfFile = { file: { uid: initalUploadedFile } };
    } else if (initalUploadedFile !== data.pdfFile.file.uid) {
      // case 2: replace initial file with new file
      firebase.removeOpportunityPDF("job", authUser, initalUploadedFile);
    } else if (
      initalUploadedFile === data.pdfFile.file.uid &&
      data.pdfFile.file.status === "removed"
    ) {
      // remove initial file and don't replace it
      firebase.removeOpportunityPDF("job", authUser, initalUploadedFile);
    }
    if (data.pdfFile.file.status === "removed") {
      delete data.pdfFile;
    }

    firebase.updateOpportunityDoc("job", formData.id, data).then(() => {
      ReloadPosts();
      closeDrawer();
      message.success("Job was updated successfully!");
    });
  };

  const onSearchLocation = searchText => {
    const potentialOptions = cities.filter(word => word.includes(searchText));
    const newOptions = potentialOptions.map(word => ({
      value: word,
    }));

    setOptions(!searchText ? [] : newOptions);
  };

  const onSelectLocation = data => {
    setLocation(data);
  };

  const onChangeLocation = data => {
    setLocation(data);
  };

  const onChangelogoURL = event => {
    setlogoURL(event.target.value);
  };

  const propsUpload = {
    name: "job_description",
    headers: {
      authorization: "authorization-text",
    },
    accept: ".pdf",
    multiple: false,
    defaultFileList: formData.pdfUid
      ? [
          {
            uid: formData.pdfUid,
            name: "job_description.pdf",
            status: "done",
            url: formData.url,
          },
        ]
      : undefined,
    beforeUpload(file, fileList) {
      if (numberUploads === 1) {
        message.error("Only one file upload is allowed.");
        fileList.pop();
        return false;
      }
      return true;
    },
    onChange(info) {
      if (info.file.status === "done") {
        setFileUploaded(info.file.uid);
        props.setFileUploaded(info.file.uid);
        setNumberUploads(1);
        message.success("Document was upload successfully.");
      } else if (info.file.status === "error") {
        setNumberUploads(0);
        message.error("Document was not uploaded.");
      } else if (info.file.status === "removed") {
        setFileUploaded("");
        props.setFileUploaded("");
        setNumberUploads(0);
        if (info.file.uid !== initalUploadedFile) {
          firebase
            .removeOpportunityPDF("job", authUser, fileUploaded)
            .then(() => {
              message.success("Document was removed successfully.");
            })
            .catch(() => {
              message.error("Document was not removed successfully.");
            });
        }
      }
    },
    customRequest({ onError, onSuccess, file, onProgress }) {
      firebase.uploadOpportunityPDF(
        "job",
        onError,
        onSuccess,
        onProgress,
        file,
        authUser,
      );
    },
  };

  return (
    <React.Fragment>
      <h1>Update job post</h1>

      <Form
        {...layout}
        layout="vertical"
        name="basic"
        initialValues={{
          role: formData.role,
          level: formData.level,
          company: formData.company,
          location,
          description: formData.description,
          logoURL: formData.logoURL,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label="Company name:"
          name="company"
          rules={[
            { required: true, message: "Name of your company is missing" },
          ]}
          placeholder="Mostly Awesome Company"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Optional company logo (link to image):"
          name="logoURL"
          rules={[{ required: false }]}
        >
          <Input onChange={onChangelogoURL} />
        </Form.Item>

        <span
          style={{
            maxWidth: "250px",
          }}
        >
          {logoURL && (
            <img
              src={logoURL}
              alt="Company Logo could not be fetched. Please use a different URL."
              style={{
                maxWidth: "100%",
                maxHeight: "144px",
                paddingTop: "22px ",
                paddingLeft: "20px",
              }}
            />
          )}
        </span>

        <Form.Item
          label="Role:"
          name="role"
          rules={[{ required: true, message: "" }]}
        >
          <Select style={{ width: 200 }}>
            {opportunityRoleOptions.map(element => {
              return <Option value={element}>{element}</Option>;
            })}
          </Select>
        </Form.Item>

        <Form.Item
          label="Level:"
          name="level"
          rules={[{ required: true, message: "" }]}
        >
          <Select style={{ width: 200 }}>
            {opportunityLevelOptions.map(element => {
              return <Option value={element}>{element}</Option>;
            })}
          </Select>
        </Form.Item>

        <Form.Item
          label="Location (Name of city):"
          name="location"
          rules={[{ required: true, message: "Location is missing" }]}
        >
          <AutoComplete
            value={location}
            options={options}
            style={{
              width: 200,
            }}
            onSelect={onSelectLocation}
            onSearch={onSearchLocation}
            onChange={onChangeLocation}
            placeholder="Munich"
          />
        </Form.Item>

        <Form.Item
          label="Description:"
          name="description"
          rules={[{ required: true, message: "Description is missing" }]}
        >
          <TextArea
            rows={4}
            placeholder="We are looking for a junior front-end developer for our React-Native mobile application."
          />
        </Form.Item>

        <Form.Item label="Optional job description as PDF:" name="pdfFile">
          <Upload {...propsUpload}>
            <Button>
              <UploadOutlined /> Click to Upload
            </Button>
          </Upload>
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </React.Fragment>
  );
}

const WrappedDrawer = compose(withFirebase)(JobForm);

export default WrappedDrawer;
