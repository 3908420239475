import React from "react";
import { Col, Avatar, Tooltip, Button, Badge } from "antd";

class Participant extends React.Component {
    
    showProfileDrawer(e) {
        e.stopPropagation()
        const {participantSelected, participant} = this.props
        participantSelected(participant)
    }

    render() {
        const {size, count, kickParticipant, participant} = this.props
        let pxSize = 32;
        let paddingValue = 2
        if(size === "small") {paddingValue = 2; pxSize= 20};
        if(size === "medium") {paddingValue = 4; pxSize= 35};
        if(size === "large") {paddingValue = 5; pxSize= 60};
        if(size === "huge") {paddingValue = 6; pxSize= 80};

        const participantData = participant.data()

        return(
            <Col style={{padding: paddingValue}}>
                <Badge count={count} offset={[0, 7]} showZero={false} style={{cursor: "pointer"}} onClick={() => kickParticipant(participant.id)}>
                    <Tooltip placement="bottom" title={participantData.firstName}>
                        <Button type="link" onClick={(e) => this.showProfileDrawer(e)} shape="circle" style={{padding: 0, margin: 0, background: "inherit", height: pxSize, width: pxSize}}>
                                <Avatar style={{height: pxSize, width: pxSize}} src={participantData.avatar}/>
                        </Button>
                    </Tooltip>
                </Badge>
            </Col> 
        ) 
    }
}

export default Participant; 