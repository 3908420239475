const cities = [
  "Remote",
  "Tokyo",
  "New York",
  "Mexico City",
  "Mumbai",
  "São Paulo",
  "Shanghai",
  "Kolkata",
  "Los Angeles",
  "Dhaka",
  "Buenos Aires",
  "Karachi",
  "Rio de Janeiro",
  "Ōsaka",
  "Beijing",
  "Manila",
  "Istanbul",
  "Seoul",
  "Lagos",
  "Jakarta",
  "Guangzhou",
  "Chicago",
  "London",
  "Tehran",
  "Kinshasa",
  "Bogotá",
  "Shenzhen",
  "Wuhan",
  "Hong Kong",
  "Tianjin",
  "Chennai",
  "Taipei",
  "Bengalūru",
  "Bangkok",
  "Lahore",
  "Chongqing",
  "Hyderabad",
  "Belo Horizonte",
  "Madrid",
  "Houston",
  "Ahmadābād",
  "Ho Chi Minh City",
  "Toronto",
  "Singapore",
  "Luanda",
  "Baghdad",
  "Hāora",
  "Shenyang",
  "Khartoum",
  "Pune",
  "Saint Petersburg",
  "Chittagong",
  "Dongguan",
  "Riyadh",
  "Hanoi",
  "Chengdu",
  "Rangoon",
  "Phoenix",
  "Xi’an",
  "Porto Alegre",
  "Sūrat",
  "Hechi",
  "Abidjan",
  "Brasília",
  "Ankara",
  "Monterrey",
  "Yokohama",
  "Nanjing",
  "Montréal",
  "Guiyang",
  "Recife",
  "Seattle",
  "Harbin",
  "Fortaleza",
  "Zhangzhou",
  "Detroit",
  "Salvador",
  "Busan",
  "Johannesburg",
  "Berlin",
  "Algiers",
  "Pyongyang",
  "Medellín",
  "Kabul",
  "Nagoya",
  "Cape Town",
  "San Diego",
  "Changchun",
  "Casablanca",
  "Dalian",
  "Cawnpore",
  "Kano",
  "Tel Aviv-Yafo",
  "Addis Ababa",
  "Curitiba",
  "Zibo",
  "Jeddah",
  "Nairobi",
  "Hangzhou",
  "Benoni",
  "Caracas",
  "Kunming",
  "Dar es Salaam",
  "Minneapolis",
  "Jaipur",
  "Taiyuan",
  "Frankfurt",
  "Qingdao",
  "Surabaya",
  "Tampa",
  "Jinan",
  "Fukuoka",
  "Campinas",
  "Denver",
  "Kaohsiung",
  "Quezon City",
  "Katowice",
  "Aleppo",
  "Durban",
  "Kyiv",
  "Lucknow",
  "Giza",
  "Zhengzhou",
  "Taichung",
  "Ibadan",
  "Faisalabad",
  "Changsha",
  "Dakar",
  "İzmir",
  "Xiangtan",
  "Lanzhou",
  "Incheon",
  "Sapporo",
  "Xiamen",
  "Guayaquil",
  "Mashhad",
  "Daegu",
  "Nāgpur",
  "Lianshan",
  "Shijiazhuang",
  "Tunis",
  "Jilin",
  "Omdurman",
  "Bandung",
  "Bekasi",
  "Mannheim",
  "Nanchang",
  "Wenzhou",
  "Queens",
  "Cali",
  "Sendai",
  "Puebla",
  "Tashkent",
  "Havana",
  "Gaoping",
  "Baltimore",
  "Nanning",
  "Belém",
  "Patna",
  "Santo Domingo",
  "Ürümqi",
  "Zaozhuang",
  "Baku",
  "Accra",
  "Yantai",
  "Medan",
  "Tongshan",
  "Linyi",
  "Maracaibo",
  "Kuwait City",
  "Ad Dammām",
  "Haikou",
  "Hiroshima",
  "Baotou",
  "Hefei",
  "Indore",
  "Goiânia",
  "Sanaa",
  "Port-au-Prince",
  "Haiphong",
  "Nanyang",
  "Bucharest",
  "Ningbo",
  "Douala",
  "Tangshan",
  "Tainan",
  "Asunción",
  "Saidu Sharif",
  "Brisbane",
  "Rawalpindi",
  "Sacramento",
  "Beirut",
  "Minsk",
  "Kyōto",
  "Barranquilla",
  "Orlando",
  "Shuyangzha",
  "Vadodara",
  "Manaus",
  "Shangqiu",
  "Wuxi",
  "Palembang",
  "Brussels",
  "Essen",
  "Bhopal",
  "Hohhot",
  "Pittsburgh",
  "Luoyang",
  "Santos",
  "Jingling",
  "Vitória",
  "Quito",
  "Antananarivo",
  "Coimbatore",
  "Daqing",
  "Lu’an",
  "Sanzhou",
  "Budapest",
  "Turin",
  "Suzhou",
  "Ludhiāna",
  "Cincinnati",
  "Kumasi",
  "Qiqihar",
  "Anshan",
  "Zhongli",
  "Handan",
  "Tai’an",
  "Eşfahān",
  "Yaoundé",
  "Shantou",
  "Āgra",
  "Zhanjiang",
  "Kalyān",
  "Abuja",
  "Harare",
  "Indianapolis",
  "Xiantao",
  "Khulna",
  "Weifang",
  "Tijuana",
  "Xinyang",
  "Luzhou",
  "Toluca",
  "Vishākhapatnam",
  "Kōbe",
  "Multan",
  "Kochi",
  "Gujranwala",
  "Niterói",
  "Ganzhou",
  "Liuzhou",
  "Bamako",
  "Conakry",
  "Bursa",
  "León de los Aldama",
  "Virginia Beach",
  "Nāsik",
  "Fushun",
  "Changde",
  "Daejeon",
  "Phnom Penh",
  "Neijiang",
  "Quanzhou",
  "Kharkiv",
  "Hyderabad City",
  "Bronx",
  "Lomé",
  "Huainan",
  "Doha",
  "Kuala Lumpur",
  "Maputo",
  "Kaduna",
  "Gwangju",
  "Kawanakajima",
  "San Salvador",
  "Suining",
  "Lyon",
  "Karaj",
  "Kampala",
  "Tabrīz",
  "The Hague",
  "Davao",
  "Marseille",
  "Meerut",
  "Mianyang",
  "Semarang",
  "Farīdābād",
  "Novosibirsk",
  "Dubai",
  "Milwaukee",
  "Auckland",
  "Ma’anshan",
  "Brazzaville",
  "Yiyang",
  "Vārānasi",
  "Lubumbashi",
  "Juárez",
  "Ghāziābād",
  "Pretoria",
  "Heze",
  "Porto",
  "Āsansol",
  "Lusaka",
  "Changzhou",
  "Mosul",
  "Yekaterinburg",
  "Peshawar",
  "Mandalay",
  "Jamshedpur",
  "Mbuji-Mayi",
  "Madura",
  "Adana",
  "Jabalpur",
  "Nizhniy Novgorod",
  "Chifeng",
  "Duisburg",
  "Munich",
  "Huaiyin",
  "Stockholm",
  "Makassar",
  "Rājkot",
  "Dhanbād",
  "Mudanjiang",
  "Shīrāz",
  "Huzhou",
  "Beidao",
  "Shuangshui",
  "Düsseldorf",
  "Gaozhou",
  "Sevilla",
  "Amritsar",
  "Vila Velha",
  "Almaty",
  "Warangal",
  "Rosario",
  "Allahābād",
  "Benin City",
  "Maceió",
  "Sofia",
  "Abbottabad",
  "Benghazi",
  "Cilacap",
  "Prague",
  "Leshan",
  "Ouagadougou",
  "Adelaide",
  "Shangrao",
  "Torreón",
  "Srīnagar",
  "Bezwāda",
  "Samara",
  "Omsk",
  "Yulin",
  "Namp’o",
  "Xianyang",
  "Cagayan de Oro",
  "Cần Thơ",
  "Barquisimeto",
  "Helsinki",
  "Kazan’",
  "Calgary",
  "Ciudad Nezahualcóyotl",
  "Zürich",
  "Baoding",
  "Zigong",
  "Sharjah",
  "Yerevan",
  "Mogadishu",
  "Tbilisi",
  "Ankang",
  "Huambo",
  "Ikare",
  "Salt Lake City",
  "Bhilai",
  "Jinhua",
  "Chelyabinsk",
  "Dushanbe",
  "Copenhagen",
  "Changwon",
  "Zhuzhou",
  "Suwon",
  "Vereeniging",
  "Xiangyang",
  "Ulsan",
  "Amman",
  "Mizhou",
  "Edmonton",
  "Solāpur",
  "Rostov-na-Donu",
  "Dnipro",
  "Xining",
  "Zhangjiakou",
  "Lille",
  "Ranchi",
  "Gaziantep",
  "São Luís",
  "Jerusalem",
  "New Orleans",
  "Guatemala City",
  "Zhuhai",
  "Florianópolis",
  "Port Elizabeth",
  "Jiamusi",
  "Port Harcourt",
  "Raleigh",
  "Ufa",
  "Hengyang",
  "Benxi",
  "Haifa",
  "Bucaramanga",
  "Maracay",
  "Homs",
  "Cologne",
  "Qinhuangdao",
  "Fès",
  "N’Djamena",
  "Tegucigalpa",
  "Nay Pyi Taw",
  "Djibouti",
  "Managua",
  "Niamey",
  "Tirana",
  "Kathmandu",
  "Ulaanbaatar",
  "Kigali",
  "Bishkek",
  "Oslo",
  "Bangui",
  "Islamabad",
  "Cotonou",
  "Vientiane",
  "Nouakchott",
  "Muscat",
  "Ashgabat",
  "Zagreb",
  "Sarajevo",
  "Chisinau",
  "Lilongwe",
  "Asmara",
  "Abu Dhabi",
  "Port Louis",
  "Libreville",
  "Manama",
  "Vilnius",
  "Skopje",
  "Bloemfontein",
  "Bratislava",
  "Bissau",
  "Tallinn",
  "Valletta",
  "Maseru",
  "Nur-Sultan",
  "Bujumbura",
  "Canberra",
  "New Delhi",
  "Ljubljana",
  "Porto-Novo",
  "Bandar Seri Begawan",
  "Port of Spain",
  "Port Moresby",
  "Bern",
  "Windhoek",
  "Paramaribo",
  "Dili",
  "Nassau",
  "Sucre",
  "Nicosia",
  "Dodoma",
  "Colombo",
  "Gaborone",
  "Yamoussoukro",
  "Suva",
  "Reykjavík",
  "Malabo",
  "Willemstad",
  "Podgorica",
  "Moroni",
  "Sri Jayewardenepura Kotte",
  "Praia",
  "Male",
  "Juba",
  "Luxembourg",
  "Thimphu",
  "Mbabane",
  "São Tomé",
  "Honiara",
  "Oranjestad",
  "Apia",
  "Andorra la Vella",
  "Kingstown",
  "Port-Vila",
  "Banjul",
  "Nuku‘alofa",
  "Castries",
  "Monaco",
  "Vaduz",
  "Saint George’s",
  "Tarawa",
  "Majuro",
  "Roseau",
  "Basseterre",
  "Belmopan",
  "Pago Pago",
  "Lobamba",
  "Funafuti",
  "Palikir",
  "Capitol Hill",
  "Hagåtña",
  "Al Quds",
  "Pristina",
  "Ngerulmud",
  "Aden",
  "Cochabamba",
  "Yongzhou",
  "Baoshan",
  "Quảng Hà",
  "Kitaku",
  "Ahvāz",
  "Jodhpur",
  "San Luis Potosí",
  "Odesa",
  "Yinchuan",
  "Jiaxing",
  "Joinvile",
  "Donets’k",
  "Guilin",
  "Dahūk",
  "Volgograd",
  "Guwāhāti",
  "Yangquan",
  "Natal",
  "Chandigarh",
  "Gwalior",
  "Hamamatsu",
  "Qom",
  "Jixi",
  "Xin’an",
  "Querétaro",
  "João Pessoa",
  "Klang",
  "Jinzhou",
  "Oklahoma City",
  "Salerno",
  "Thiruvananthapuram",
  "Calicut",
  "Trichinopoly",
  "Ogbomoso",
  "General Santos",
  "Huế",
  "Bacolod",
  "Nantong",
  "Foshan",
  "Sŏngnam",
  "Nice",
  "Erbil",
  "Krasnoyarsk",
  "Olinda",
  "Antwerp",
  "Konya",
  "Bogor",
  "Huaibei",
  "Xinyu",
  "Teresina",
  "Naha",
  "Goyang",
  "Nangandao",
  "Yibin",
  "Aba",
  "Maiduguri",
  "Az Zarqā’",
  "Tarsus",
  "Bengbu",
  "Mendoza",
  "Hubli",
  "Zaria",
  "Dengtalu",
  "Mysore",
  "Mexicali",
  "Tongliao",
  "Mombasa",
  "Newcastle upon Tyne",
  "Novo Hamburgo",
  "Callao",
  "Bilbao",
  "Johor Bahru",
  "Raipur",
  "Xiaoxita",
  "Fort Worth",
  "Yangjiang",
  "Marrakech",
  "Kaifeng Chengguanzhen",
  "Dandong",
  "Al Başrah",
  "Aguascalientes",
  "Tucson",
  "Okayama",
  "Bucheon",
  "Xuanzhou",
  "Bandar Lampung",
  "Rizhao",
  "Caerdydd",
  "Tampico",
  "Jiaozuo",
  "Padang",
  "Jalandhar",
  "Zhenjiang",
  "Pingdingshan",
  "Zunyi",
  "Anshun",
  "Toulouse",
  "El Paso",
  "Iguaçu",
  "Bhubaneshwar",
  "Voronezh",
  "Saratov",
  "Yuci",
  "Yancheng",
  "Cuernavaca",
  "Linfen",
  "Honolulu",
  "Warri",
  "Basel",
  "San Miguel de Tucumán",
  "Kermānshāh",
  "Thessaloníki",
  "Omaha",
  "Kota",
  "Braga",
  "Jhānsi",
  "Rongjiawan",
  "Nottingham",
  "Agadir",
  "Butterworth",
  "Bareilly",
  "Jos",
  "Xingyi",
  "Cebu City",
  "Arequipa",
  "Wuhu",
  "Langfang",
  "Rājshāhi",
  "Zhaotong",
  "Culiacán",
  "Valencia",
  "Lingyuan",
  "Cuiabá",
  "Quy Nhơn",
  "Malang",
  "Alīgarh",
  "L’viv",
  "Bordeaux",
  "McAllen",
  "Baojishi",
  "Pekanbaru",
  "Oran",
  "Yingkou",
  "Bhiwandi",
  "Liaoyang",
  "Chihuahua",
  "Jammu",
  "Melaka",
  "Zaporizhzhya",
  "Morādābād",
  "Antalya",
  "Al Ḩudaydah",
  "Campo Grande",
  "Shaoxing",
  "Yichun",
  "Mangalore",
  "Wuppertal",
  "Cheongju",
  "Zamboanga City",
  "Hamhŭng",
  "Ilorin",
  "Saarbrücken",
  "Fuxin",
  "Shiyan",
  "Quetta",
  "Kananga",
  "Trabzon",
  "Albuquerque",
  "Łódź",
  "Kraków",
  "Saltillo",
  "São José dos Campos",
  "Hŭngnam",
  "Ta‘izz",
  "Pietermaritzburg",
  "Tangier",
  "Hsinchu",
  "Changhua",
  "Kolhāpur",
  "Farg‘ona",
  "Namangan",
  "Liège",
  "Ciudad Guayana",
  "Hegang",
  "Naga City",
  "Gdańsk",
  "Ansan",
  "Nuremberg",
  "Oyo",
  "Amrāvati",
  "Denpasar",
  "Sokoto",
  "Beihai",
  "As Sulaymānīyah",
  "Hannover",
  "Cúcuta",
  "Moshi",
  "Shaoguan",
  "Kumamoto",
  "Xinpu",
  "Acapulco de Juárez",
  "Kandahār",
  "Dehra Dūn",
  "Jeonju",
  "Samarkand",
  "Sarasota",
  "Changzhi",
  "Tol’yatti",
  "Jaboatão",
  "Shizuoka",
  "Bulawayo",
  "Meknès",
  "Mālegaon",
  "Enugu",
  "Huangshi",
  "Aracaju",
  "Allentown",
  "Bonn",
  "San Pedro Sula",
  "Nellore",
  "Catania",
  "Gorakhpur",
  "Ipoh",
  "Ch’ŏngjin",
  "Tulsa",
  "Utsunomiya",
  "Puyang Chengguanzhen",
  "An Najaf",
  "São José dos Pinhais",
  "Santo André",
  "Bytom",
  "Pointe-Noire",
  "Aţ Ţā’if",
  "Ismailia",
  "Shimoga",
  "Biên Hòa",
  "Xiping",
  "Kryvyy Rih",
  "Irbid",
  "Tiruppūr",
  "Andijon",
  "Krasnodar",
  "Zaragoza",
  "Diyarbakır",
  "Morelia",
  "Ul’yanovsk",
  "Utrecht",
  "Kikwit",
  "Al Hufūf",
  "Yogyakarta",
  "Wrocław",
  "Winnipeg",
  "Izhevsk",
  "Cape Coral",
  "Zhuozhou",
  "Raurkela",
  "Québec",
  "Poznań",
  "Colorado Springs",
  "Port Said",
  "Nānded",
  "Bannu",
  "Southend-on-Sea",
  "Dresden",
  "Wiesbaden",
  "Changping",
  "Palu",
  "Taizhou",
  "Xingtai",
  "Samsun",
  "Luxor",
  "Belgaum",
  "Pontianak",
  "Yaroslavl’",
  "Constantine",
  "Banjarmasin",
  "Kirkuk",
  "Sāngli",
  "Barcelona",
  "Mission Viejo",
  "Sūhāj",
  "Al Manşūrah",
  "Canoas",
  "Barnaul",
  "Zāhedān",
  "Jalālābād",
  "Chiclayo",
  "Hermosillo",
  "Chānda",
  "Al Ḩillah",
  "Rasht",
  "Nagano",
  "Vinh",
  "Abeokuta",
  "Kayseri",
  "Samarinda",
  "Ajmer",
  "Dortmund",
  "Vladivostok",
  "Irkutsk",
  "Blantyre",
  "Baton Rouge",
  "Anqing",
  "Cuttack",
  "Hachiōji",
  "Khabarovsk",
  "Veracruz",
  "Kisangani",
  "Kermān",
  "Orūmīyeh",
  "Bīkaner",
  "Quetzaltenango",
  "Bakersfield",
  "Shihezi",
  "Kuching",
  "Shuozhou",
  "Niigata",
  "Pereira",
  "Macau",
  "Bouaké",
  "Akron",
  "Binjai",
  "Uberlândia",
  "Sorocaba",
  "Wusong",
  "Weihai",
  "Mar del Plata",
  "Santiago de Cuba",
  "Siping",
  "Kagoshima",
  "Surakarta",
  "Makhachkala",
  "Bhāvnagar",
  "Uyo",
  "Bahawalpur",
  "Kenitra",
  "Ribeirão Prêto",
  "Kanazawa",
  "Orenburg",
  "Málaga",
  "Tabūk",
  "Puerto La Cruz",
  "Jiujiang",
  "Hisar",
  "Kashgar",
  "Matola",
  "Bilāspur",
  "Sargodha",
  "Leipzig",
  "Tinnevelly",
  "Cancún",
  "Yangzhou",
  "Novokuznetsk",
  "Latakia",
  "Heroica Matamoros",
  "Göteborg",
  "Ōtsu",
  "Tomsk",
  "Linxia Chengguanzhen",
  "Matsuyama",
  "Rouen",
  "Jiangmen",
  "Oaxaca",
  "Beira",
  "Guntūr",
  "Tripoli",
  "Hamadān",
  "Cangzhou",
  "Kota Kinabalu",
  "Gold Coast",
  "Ji’an",
  "Londrina",
  "Ryazan’",
  "Shashi",
  "Bello",
  "Tyumen’",
  "Lipetsk",
  "Shiliguri",
  "Eskişehir",
  "Banda Aceh",
  "Ujjain",
  "Salta",
  "Penza",
  "Blida",
  "Mykolayiv",
  "Karbalā’",
  "Suez",
  "Gliwice",
  "Bukittinggi",
  "Liaoyuan",
  "Kota Bharu",
  "Jundiaí",
  "Edinburgh",
  "Tlaxcala",
  "Provo",
  "Arāk",
  "Davangere",
  "Viña del Mar",
  "Pingtung",
  "Annaba",
  "Akola",
  "Astrakhan’",
  "Bari",
  "Damanhūr",
  "Al Minyā",
  "Taoyuan District",
  "Keelung",
  "Pasuruan",
  "Chiayi",
  "Thái Nguyên",
  "Awka",
  "Shuangyashan",
  "Mazatán",
  "Tsu",
  "Mataram",
  "Macapá",
  "Reynosa",
  "Shahrisabz",
  "Mesa",
  "Dūmā",
  "Wuwei",
  "Palm Bay",
  "Port Sudan",
  "Tula",
  "Banī Suwayf",
  "Yanji",
  "Bologna",
  "Sahāranpur",
  "Murrieta",
  "Gulbarga",
  "Bhātpāra",
  "Wichita",
  "Ile-Ife",
  "Feira de Santana",
  "Shah Alam",
  "Mariupol’",
  "Tuxtla",
  "Herāt",
  "Homyel’",
  "Zhaoqing",
  "Americana",
  "Dhūlia",
  "Ostrava",
  "Yazd",
  "Sialkot City",
  "Kemerovo",
  "Nazrēt",
  "Staten Island",
  "Jiaojiangcun",
  "Chaoyang",
  "Juiz de Fora",
  "Udaipur",
  "Kocaeli",
  "Piraeus",
  "Shymkent",
  "Iligan",
  "Qazvīn",
  "Calabar",
  "Malatya",
  "Dadukou",
  "Bandar ‘Abbās",
  "Naberezhnyye Chelny",
  "Ḩamāh",
  "Cranbourne",
  "Iquitos",
  "Mazār-e Sharīf",
  "Jingdezhen",
  "Jambi",
  "Volta Redonda",
  "Hengshui",
  "Sfax",
  "Sunderland",
  "Xalapa",
  "Luhans’k",
  "Manado",
  "Qaraghandy",
  "An Nāşirīyah",
  "Oshawa",
  "Taihecun",
  "Şanlıurfa",
  "Chengde",
  "Jiangguanchi",
  "Chlef",
  "Ōita",
  "Baguio City",
  "Cheboksary",
  "Ado-Ekiti",
  "Balikpapan",
  "Bellary",
  "Bamenda",
  "Gent",
  "Tokushima",
  "Little Rock",
  "Wuzhou",
  "Cabimas",
  "Foz do Iguaçu",
  "Denton",
  "Wakayama",
  "Strasbourg",
  "Mawlamyine",
  "Nantes",
  "Khŭjand",
  "Guangyuan",
  "Khomeynī Shahr",
  "Garoua",
  "Bukavu",
  "Tuticorin",
  "Nagasaki",
  "Pohang",
  "Kaliningrad",
  "Likasi",
  "Reno",
  "Spanish Town",
  "Port St. Lucie",
  "Katsina",
  "Welkom",
  "Santa Marta",
  "Villahermosa",
  "Bryansk",
  "Bournemouth",
  "Bengkulu",
  "Heidelberg",
  "Kurnool",
  "Chaozhou",
  "Batangas",
  "Ibagué",
  "Ivanovo",
  "Erzurum",
  "Akure",
  "Asyūţ",
  "Kolwezi",
  "Sukkur",
  "Luohe",
  "Campina Grande",
  "Kitchener",
  "Winston-Salem",
  "Middlesbrough",
  "Meizhou",
  "Ardabīl",
  "Magnitogorsk",
  "Gifu",
  "Huancayo",
  "Nha Trang",
  "Maturín",
  "Zhangjiakou Shi Xuanhua Qu",
  "Kursk",
  "Oujda-Angad",
  "Metz",
  "Al ‘Ayn",
  "Jeju",
  "Osogbo",
  "Indio",
  "Ipatinga",
  "Szczecin",
  "Chattanooga",
  "Murcia",
  "Ţanţā",
  "Kitwe",
  "Zanzibar",
  "Taubaté",
  "Ghulja",
  "Pasay City",
  "Spokane",
  "Mbale",
  "Palm Coast",
  "Kassala",
  "Sunch’ŏn",
  "Tver’",
  "Sīkar",
  "Jiangjiafan",
  "Surgut",
  "Tumkūr",
  "Gómez Palacio",
  "Buraydah",
  "Khmel’nyts’kyy",
  "Eindhoven",
  "Chiang Mai",
  "Piura",
  "Horlivka",
  "Ndola",
  "Yuxi",
  "Bonita Springs",
  "Poughkeepsie",
  "Kisumu",
  "Stockton",
  "Quilon",
  "El Obeid",
  "Nyala",
  "Sandakan",
  "Ahmadnagar",
  "Osh",
  "Stoke-on-Trent",
  "Bhīlwāra",
  "Oxnard",
  "Comilla",
  "Scranton",
  "Samut Prakan",
  "Grenoble",
  "Nampula",
  "Nizāmābād",
  "Brno",
  "Alajuela",
  "Iloilo",
  "Campos",
  "Resistencia",
  "Qarshi",
  "Mişrātah",
  "Ḩā’il",
  "Boise",
  "Bejaïa",
  "Celaya",
  "Pasto",
  "Modesto",
  "Caxias do Sul",
  "Nizhniy Tagil",
  "Xichang",
  "Dezhou",
  "Vigo",
  "Las Palmas de Gran Canaria",
  "Parbhani",
  "Karlsruhe",
  "Zhoukou",
  "Makiyivka",
  "Putian",
  "Kahramanmaraş",
  "Manizales",
  "Palma",
  "Manukau City",
  "Shillong",
  "Khorramābād",
  "Villavicencio",
  "São José do Rio Prêto",
  "Kaunas",
  "Lātūr",
  "Kissimmee",
  "Youngstown",
  "Shache",
  "Seremban",
  "Denizli",
  "Van",
  "A Coruña",
  "Madan",
  "Quzhou",
  "Rājapālaiyam",
  "Najrān",
  "Mahilyow",
  "Al Qaţīf",
  "Mazatlán",
  "Longyan",
  "Bydgoszcz",
  "Kuantan",
  "Gorontalo",
  "Nakuru",
  "Vladikavkaz",
  "Larkana",
  "Christchurch",
  "Stavropol’",
  "Sanya",
  "Bhāgalpur",
  "Shekhupura",
  "Cusco",
  "Tamale",
  "Ulan-Ude",
  "Bobo-Dioulasso",
  "Lublin",
  "Augsburg",
  "Sungai Petani",
  "Ad Dīwānīyah",
  "Taraz",
  "Toulon",
  "Zanjān",
  "Donostia",
  "Iwaki",
  "Encarnación",
  "Posadas",
  "Fuyu",
  "Cà Mau",
  "Asahikawa",
  "Mirpur Khas",
  "Arkhangel’sk",
  "Wafangdian",
  "Ambon",
  "Orizaba",
  "Hongzhai",
  "Santiago del Estero",
  "Mito",
  "Eldoret",
  "Rahimyar Khan",
  "Neiva",
  "Anaheim",
  "Vinnytsya",
  "Hualien",
  "Kuala Terengganu",
  "Long Xuyên",
  "Việt Trì",
  "Qo‘qon",
  "Yingmen",
  "Haarlem",
  "Buôn Ma Thuột",
  "Chimbote",
  "Muzaffarnagar",
  "Nuevo Laredo",
  "Sanandaj",
  "Linz",
  "Camagüey",
  "Victorville",
  "Charleroi",
  "Belgorod",
  "Kūstī",
  "Al ‘Amārah",
  "Maebashi",
  "Pensacola",
  "Kurgan",
  "Kohat",
  "Vitsyebsk",
  "Piracicaba",
  "Yeosu",
  "Corpus Christi",
  "Jhang City",
  "Pematangsiantar",
  "Arusha",
  "Corrientes",
  "Tamuramachi-moriyama",
  "Plovdiv",
  "Chitungwiza",
  "Aksu",
  "Yucheng",
  "Tieling",
  "Irapuato",
  "Kaluga",
  "East London",
  "Kaesŏng",
  "Ciudad Bolívar",
  "Kawagoe",
  "Jitpur",
  "Phan Thiết",
  "Alor Setar",
  "Santa Cruz de Tenerife",
  "Gijón",
  "Greensboro",
  "Kōchi",
  "Flint",
  "Islip",
  "Bauru",
  "Nakhon Ratchasima",
  "Orël",
  "Takamatsu",
  "Muzaffarpur",
  "Beni",
  "Toyama",
  "Hangu",
  "Wad Medani",
  "Montes Claros",
  "Bielefeld",
  "Heyuan",
  "Mathura",
  "Mymensingh",
  "Baishan",
  "Patiāla",
  "Wŏnsan",
  "Pavlodar",
  "Fort Wayne",
  "Maringá",
  "Saugor",
  "Sochi",
  "Montpellier",
  "Itajaí",
  "Sousse",
  "Ann Arbor",
  "Iaşi",
  "Brahmapur",
  "Bīrjand",
  "Yoshkar-Ola",
  "Miyazaki",
  "Volzhskiy",
  "Chenzhou",
  "Al Kūt",
  "Smolensk",
  "Ciudad del Este",
  "Pelotas",
  "Podol’sk",
  "Kherson",
  "Shāhjānpur",
  "Itu",
  "Legazpi City",
  "Akita",
  "Maroua",
  "Anápolis",
  "Pachuca",
  "Bukhara",
  "Murmansk",
  "Holguín",
  "Öskemen",
  "Tepic",
  "Vladimir",
  "Mobile",
  "Hat Yai",
  "Poltava",
  "Cumaná",
  "Hrodna",
  "Rohtak",
  "Al Fayyūm",
  "Cluj-Napoca",
  "Bauchi",
  "Alicante",
  "Dezfūl",
  "Maiquetía",
  "Armenia",
  "Timişoara",
  "Pescara",
  "Gdynia",
  "Angeles City",
  "Ningde",
  "Aswān",
  "Varna",
  "Koblenz",
  "Cherepovets",
  "Qŭrghonteppa",
  "Semey",
  "Galaţi",
  "Braşov",
  "Sambalpur",
  "Pucallpa",
  "Antofagasta",
  "Santa Clarita",
  "Asheville",
  "Sapele",
  "Dayr az Zawr",
  "Chita",
  "Valledupar",
  "Vitória da Conquista",
  "Antsirabe",
  "Nal’chik",
  "Chernihiv",
  "Ratlām",
  "St. Paul",
  "Ksar El Kebir",
  "Tawau",
  "Fīrozābād",
  "Porto Velho",
  "San Salvador de Jujuy",
  "Hatay",
  "Franca",
  "Rājahmundry",
  "Olongapo",
  "Craiova",
  "Kawm Umbū",
  "Los Teques",
  "Constanţa",
  "Saransk",
  "Wenlan",
  "Gəncə",
  "Fort Collins",
  "Hakodate",
  "Chemnitz",
  "Kingston upon Hull",
  "Batman",
  "Qinā",
  "Barddhamān",
  "Jinja",
  "Gujrat",
  "Tambov",
  "Kumul",
  "Mardan",
  "Bīdar",
  "Rạch Giá",
  "Sóc Trăng",
  "Qyzylorda",
  "Curepipe",
  "Kanggye",
  "Ba‘qūbah",
  "Damietta",
  "Oyster Bay",
  "Jember",
  "Al Mubarraz",
  "Al Kharj",
  "Aomori",
  "Chernivtsi",
  "İskenderun",
  "Cherkasy",
  "Rāmpur",
  "Morioka",
  "Ar Ramādī",
  "Vologda",
  "Sumy",
  "Abertawe",
  "Fukushima",
  "Blumenau",
  "Thiès",
  "Kākināda",
  "Pānīpat",
  "Makurdi",
  "Ponta Grossa",
  "Minna",
  "Białystok",
  "Mbeya",
  "Cagliari",
  "Khammam",
  "Bafoussam",
  "Kasur",
  "Hong’an",
  "Kassel",
  "Limeira",
  "Bhuj",
  "Huizhou",
  "Karīmnagar",
  "Sinŭiju",
  "Talcahuano",
  "Ciudad Obregón",
  "Shreveport",
  "Davenport",
  "Coatzacoalcos",
  "Tirupati",
  "Cuenca",
  "Butembo",
  "Sekondi",
  "Petrópolis",
  "Hospet",
  "Rangpur",
  "Az Zaqāzīq",
  "Mandya",
  "Round Lake Beach",
  "Bago",
  "Barinas",
  "Iksan",
  "Alwar",
  "Cadiz",
  "Cádiz",
  "Aizawl",
  "Kupang",
  "Tongchuanshi",
  "Zhytomyr",
  "Jining",
  "Bahía Blanca",
  "Cap-Haïtien",
  "Ambato",
  "South Bend",
  "Gorgān",
  "Batna",
  "Tacna",
  "Tacloban",
  "Xinzhou",
  "Cotabato",
  "Rize",
  "Ica",
  "Sumqayıt",
  "Taganrog",
  "Kaolack",
  "Kostroma",
  "Owo",
  "Sukabumi",
  "Komsomol’sk-na-Amure",
  "Prokop’yevsk",
  "Montería",
  "Mbandaka",
  "Ciudad Victoria",
  "Sétif",
  "Jinshi",
  "Ubon Ratchathani",
  "Crato",
  "Guantánamo",
  "Blackpool",
  "Majene",
  "Yamagata",
  "Pekalongan",
  "Elazığ",
  "Sar Pol",
  "Tasikmalaya",
  "Bijāpur",
  "Jersey City",
  "Chula Vista",
  "Münster",
  "Thohoyandou",
  "Malmö",
  "Nancy",
  "Mokpo",
  "Tebingtinggi",
  "Yanbu‘",
  "Eugene",
  "Tshikapa",
  "Sterlitamak",
  "Padangsidempuan",
  "Myeik",
  "Temuco",
  "Lausanne",
  "Saint-Étienne",
  "Petrozavodsk",
  "Imphal",
  "Umuahia",
  "Uruapan",
  "Sivas",
  "Tobruk",
  "St. Petersburg",
  "Graz",
  "Kindu",
  "Lubbock",
  "Aqtöbe",
  "Paraná",
  "Az Zubayr",
  "Hotan",
  "Cabo Frio",
  "Balıkesir",
  "Zhuanghe",
  "Sincelejo",
  "Petrolina",
  "Acarigua",
  "Wollongong",
  "Uberaba",
  "Myrtle Beach",
  "Laredo",
  "Sakarya",
  "Konibodom",
  "Kunduz",
  "Tampere",
  "Popayán",
  "Nonthaburi",
  "Al Mukallā",
  "Rio Branco",
  "Etāwah",
  "Mendefera",
  "Cascavel",
  "Ondo",
  "Chimoio",
  "Ensenada",
  "Dzerzhinsk",
  "Ấp Đa Lợi",
  "Damaturu",
  "Rāichūr",
  "Daloa",
  "Rivne",
  "Turpan",
  "Freiburg im Breisgau",
  "Poza Rica de Hidalgo",
  "Chuxiong",
  "Cirebon",
  "Fort-de-France",
  "Pathānkot",
  "Chandler",
  "Anchorage",
  "Tallahassee",
  "Chīrāla",
  "Buenaventura",
  "El Fasher",
  "Dire Dawa",
  "Suihua",
  "Messina",
  "Borūjerd",
  "Linhai",
  "Khon Kaen",
  "Morogoro",
  "Governador Valadares",
  "Sonīpat",
  "Iwo",
  "Zumpango",
  "Laiyang",
  "Jaffna",
  "Arua",
  "Quảng Ngãi",
  "Caserta",
  "Como",
  "Niš",
  "Lucerne",
  "Kāshān",
  "Várzea Grande",
  "Kropyvnyts’kyy",
  "Melun",
  "Mutare",
  "Ḩafar al Bāţin",
  "Los Mochis",
  "Jalal-Abad",
  "Vũng Tàu",
  "Fukui",
  "Singkawang",
  "Miskolc",
  "Killeen",
  "Udon Thani",
  "Kattaqo‘rg‘on",
  "Orsk",
  "San Bernardo",
  "Oruro",
  "Bratsk",
  "Matadi",
  "Mirzāpur",
  "Shinozaki",
  "Rzeszów",
  "Juliaca",
  "Qinzhou",
  "Winter Haven",
  "Nizhnevartovsk",
  "Ternopil’",
  "Braunschweig",
  "Hebi",
  "Thủ Dầu Một",
  "Kunsan",
  "Jessore",
  "Manisa",
  "Wŏnju",
  "Nam Định",
  "Angarsk",
  "North Las Vegas",
  "Hāpur",
  "Gilbert",
  "Le Havre",
  "Caruaru",
  "Neuquén",
  "Ulanhot",
  "Novorossiysk",
  "Tehuacán",
  "Atlantic City",
  "Ivano-Frankivs’k",
  "Barnstable Town",
  "Fuzhou",
  "Chesapeake",
  "Irving",
  "Hialeah",
  "Hachimanchō",
  "Garland",
  "Fürth",
  "As Sīb",
  "Aarhus",
  "Sato",
  "Al Jubayl",
  "Taiping",
  "Pathein",
  "Tegal",
  "Yidu",
  "Sylhet",
  "Qostanay",
  "Košice",
  "Monclova",
  "Tours",
  "Dera Ghazi Khan",
  "Luton",
  "Sahiwal",
  "Yakutsk",
  "Lübeck",
  "Jizzax",
  "Boa Vista",
  "Kediri",
  "McKinney",
  "Kismaayo",
  "Türkmenabat",
  "Punto Fijo",
  "Porbandar",
  "Visalia",
  "Zacatecas",
  "Santa Ana",
  "Santa Ana de Yacuma",
  "Singaraja",
  "Nizhnekamsk",
  "Zābol",
  "Rancagua",
  "Clermont-Ferrand",
  "Kennewick",
  "Kremenchuk",
  "Maradi",
  "Ploieşti",
  "Nakhon Si Thammarat",
  "Heṭauḍā",
  "Saidpur",
  "Al Fallūjah",
  "Xiulin",
  "Ngaoundéré",
  "Debrecen",
  "Osnabrück",
  "Nashua",
  "North Hempstead",
  "Syktyvkar",
  "Zhangye",
  "Ilhéus",
  "Zinder",
  "Nukus",
  "Magdeburg",
  "Tlemcen",
  "Noginsk",
  "Nawabshah",
  "Bharatpur",
  "Uitenhage",
  "Beipiao",
  "Errachidia",
  "Petropavl",
  "Miri",
  "Iquique",
  "Hyesan",
  "Puducherry",
  "Quillacollo",
  "Staryy Oskol",
  "Liaocheng",
  "Gusau",
  "Tanjungpinang",
  "Chon Buri",
  "Chuncheon",
  "Monywa",
  "Sabzevār",
  "Groznyy",
  "Kolpino",
  "Sikasso",
  "Mubi",
  "Novi Sad",
  "Juazeiro do Norte",
  "Nyanza",
  "Skikda",
  "Karnāl",
  "Bạc Liêu",
  "Minamiōzuma",
  "Tanga",
  "Cidade de Nacala",
  "Vitoria-Gasteiz",
  "Nāgercoil",
  "Haeju",
  "Adıyaman",
  "Okara",
  "Nārāyanganj",
  "Colima",
  "Appleton",
  "Hancheng",
  "‘Ar‘ar",
  "Itabuna",
  "Banja Luka",
  "Guéckédou",
  "Neyshābūr",
  "Āmol",
  "Tapachula",
  "Shakhty",
  "Blagoveshchensk",
  "Córdoba",
  "Babruysk",
  "Cabanatuan City",
  "Polokwane",
  "Lhasa",
  "Tanjore",
  "Gabès",
  "Baghlān",
  "Thousand Oaks",
  "Velikiy Novgorod",
  "Wuhai",
  "Bertoua",
  "Imperatriz",
  "San Pedro de Macorís",
  "Mallawī",
  "Sibolga",
  "Orléans",
  "Denov",
  "San Bernardino",
  "Gilgit",
  "Rybinsk",
  "Bremerton",
  "Trieste",
  "Kielce",
  "Mulhouse",
  "Biysk",
  "Hickory",
  "Owerri",
  "Split",
  "Kalamazoo",
  "Oradea",
  "Luts’k",
  "Portoviejo",
  "Bergen",
  "Brăila",
  "Tacoma",
  "Nanping",
  "Machala",
  "Limbe",
  "Marília",
  "Kırıkkale",
  "Abhā",
  "San-Pédro",
  "Toamasina",
  "Pāli",
  "Thái Bình",
  "Oral",
  "Presidente Prudente",
  "Sanming",
  "Rennes",
  "Ijebu-Ode",
  "Tema",
  "Santander",
  "Green Bay",
  "Sidi Bel Abbès",
  "Bojnūrd",
  "La Romana",
  "Ḩajjah",
  "Waitakere",
  "Lobito",
  "College Station",
  "Pingliang",
  "Moreno Valley",
  "Laoag",
  "Bahir Dar",
  "Beersheba",
  "Korla",
  "Salzburg",
  "Kalemie",
  "Amarillo",
  "Northcote",
  "Jiutai",
  "Campeche",
  "Puqi",
  "São Carlos",
  "Fargo",
  "Funchal",
  "Criciúma",
  "Lampang",
  "Santa Barbara",
  "Şalālah",
  "Sibu",
  "Las Tunas",
  "Lianzhou",
  "Guaratinguetá",
  "Pisa",
  "Agartala",
  "Erfurt",
  "Timon",
  "Edéa",
  "Rostock",
  "Ongole",
  "Djougou",
  "Khowy",
  "Mossoró",
  "Barisāl",
  "Biskra",
  "Angren",
  "Taranto",
  "Yonkers",
  "Pskov",
  "Al Khums",
  "Minatitlán",
  "Huntington Beach",
  "Chiniot",
  "Pingzhen",
  "San Miguel",
  "Lashkar Gāh",
  "Daşoguz",
  "Sete Lagoas",
  "Gedaref",
  "Cartagena",
  "Puri",
  "Dindigul",
  "Haldia",
  "Moratuwa",
  "Rivera",
  "Pokhara",
  "Zhubei",
  "Bergamo",
  "Néma",
  "Az Zāwīyah",
  "Kasama",
  "Maīmanah",
  "Bacău",
  "Taza",
  "Batu Pahat",
  "Balakovo",
  "Rosetta",
  "Bila Tserkva",
  "Saskatoon",
  "Aydın",
  "Huangyan",
  "Bulandshahr",
  "Ashino",
  "Purnea",
  "Martapura",
  "Gemena",
  "Thanh Hóa",
  "Mbanza-Ngungu",
  "Kōfu",
  "Talca",
  "Proddatūr",
  "El Tigre",
  "Gurgaon",
  "Burhānpur",
  "Kure",
  "Porlamar",
  "Reims",
  "Olympia",
  "Engel’s",
  "North Port",
  "Burgas",
  "Ghandinagar",
  "Iskandar",
  "Naka",
  "Coro",
  "Brikama",
  "Severodvinsk",
  "Al Jahrā’",
  "Deltona",
  "Bongor",
  "Sunrise Manor",
  "Grand Prairie",
  "Divinópolis",
  "Tarakan",
  "Machilīpatnam",
  "Ciudad Madero",
  "Bayamo",
  "Klaipėda",
  "Dunhua",
  "Ziguinchor",
  "Waterbury",
  "Barrancabermeja",
  "Zlatoust",
  "Muar",
  "Overland Park",
  "Dhamār",
  "Valera",
  "Bhiwāni",
  "Butuan",
  "Caen",
  "Saint-Denis",
  "Saddiqabad",
  "Sơn Tây",
  "Syzran’",
  "Đồng Hới",
  "Mwene-Ditu",
  "Kabwe",
  "Quelimane",
  "Cork",
  "Parakou",
  "San Fernando del Valle de Catamarca",
  "Nandyāl",
  "Waco",
  "Angers",
  "Mostaganem",
  "Paarl",
  "Rio Grande",
  "Szeged",
  "Arapiraca",
  "Ternate",
  "Petropavlovsk-Kamchatskiy",
  "Kimch’aek-si",
  "Puerto Vallarta",
  "Gibraltar",
  "Cachoeiro de Itapemirim",
  "Pinar del Río",
  "Manp’o",
  "Olsztyn",
  "Cienfuegos",
  "Shendi",
  "Pécs",
  "Cedar Rapids",
  "Marbella",
  "Madiun",
  "Ed Damazin",
  "Arica",
  "Hagerstown",
  "Jamaame",
  "Tempe",
  "Kütahya",
  "Yei",
  "Mainz",
  "Ruse",
  "Tiaret",
  "Fianarantsoa",
  "Kramators’k",
  "Kutaisi",
  "Tarlac City",
  "Desē",
  "Çorum",
  "Gweru",
  "Mataró",
  "Spartanburg",
  "Manta",
  "Bhusāval",
  "Shibīn al Kawm",
  "Atyraū",
  "Pinrang",
  "Kamensk-Ural’skiy",
  "Chilpancingo",
  "Birāṭnagar",
  "Bahraigh",
  "Barrie",
  "Chingola",
  "Tonk",
  "Probolinggo",
  "Sirsa",
  "Anda",
  "Gastonia",
  "Hinthada",
  "Gangneung",
  "Castellón de la Plana",
  "Funtua",
  "El Jadid",
  "Reggio di Calabria",
  "Zuwārah",
  "Lorain",
  "Sioux Falls",
  "Tāngāil",
  "Obuase",
  "Fort Lauderdale",
  "Zhaodong",
  "Balkh",
  "Potosí",
  "Phan Rang-Tháp Chàm",
  "Varāmīn",
  "Passo Fundo",
  "Almería",
  "Newport News",
  "Vizianagaram",
  "Sittwe",
  "Klerksdorp",
  "Si Racha",
  "Mary",
  "Al ‘Arīsh",
  "Boma",
  "Sangolquí",
  "Heroica Nogales",
  "High Point",
  "Teziutlan",
  "Korhogo",
  "Termiz",
  "Ar Raqqah",
  "El Oued",
  "Rancho Cucamonga",
  "Treviso",
  "Frisco",
  "Danbury",
  "Vellore",
  "Kadugli",
  "Hemet",
  "Nyíregyháza",
  "Alleppey",
  "Malāyer",
  "Letpandan",
  "Yuzhno-Sakhalinsk",
  "Ouargla",
  "Regina",
  "Salatiga",
  "Turku",
  "Vancouver",
  "Sāveh",
  "Modena",
  "Les Cayes",
  "Baicheng",
  "Entebbe",
  "Yangmei",
  "Taitung",
  "Sīrjān",
  "Harar",
  "As Samāwah",
  "Ōbiraki",
  "Puerto Montt",
  "George",
  "Bo",
  "Garden Grove",
  "Puerto Cabello",
  "Sidon",
  "Beppuchō",
  "Nampa",
  "Bida",
  "Vallejo",
  "Shimla",
  "Stavanger",
  "Esmeraldas",
  "Bata",
  "Ulm",
  "Hạ Long",
  "Lishui",
  "Riobamba",
  "Banyuwangi",
  "Isparta",
  "Weinan",
  "Bade",
  "Nova Friburgo",
  "Elk Grove",
  "Tébessa",
  "Kragujevac",
  "Piteşti",
  "Rijeka",
  "Djelfa",
  "Pembroke Pines",
  "Temirtaū",
  "Uvira",
  "Bayamón",
  "Burgos",
  "Takaoka",
  "Araçatuba",
  "Fuyang",
  "Čačak",
  "Dijon",
  "Kluang",
  "Nîmes",
  "Al Marj",
  "Barra Mansa",
  "Jayapura",
  "Sarh",
  "Medinīpur",
  "Arad",
  "Bandar-e Būshehr",
  "Sonsonate",
  "Baranavichy",
  "Madent Atbara",
  "Würzburg",
  "Hindupur",
  "Peoria",
  "N’Zérékoré",
  "Bāramūla",
  "Jamālpur",
  "Chirchiq",
  "Corona",
  "Berezniki",
  "Volgodonsk",
  "Ormoc",
  "Miass",
  "Oldenburg",
  "Abakan",
  "Murfreesboro",
  "Banhā",
  "Novocherkassk",
  "Gonaïves",
  "Fatehpur",
  "Dibrugarh",
  "Ayacucho",
  "Marabá",
  "Ocumare del Tuy",
  "Rustenburg",
  "Noril’sk",
  "Carpina",
  "Tuluá",
  "Livingstone",
  "Hà Tĩnh",
  "Kendari",
  "Kimberley",
  "Elbasan",
  "Nantou",
  "Serang",
  "Ocala",
  "Lajes",
  "Sitalpur",
  "Zenica",
  "Regensburg",
  "Kigoma",
  "Plzeň",
  "Phitsanulok",
  "Myingyan",
  "Dagupan City",
  "Pátra",
  "Ingolstadt",
  "La Vega",
  "Mostar",
  "Navsāri",
  "El Geneina",
  "Ţarţūs",
  "Simao",
  "La Rioja",
  "Lecce",
  "Mahābād",
  "Dourados",
  "Ash Shaţrah",
  "Settat",
  "Budaun",
  "Tunja",
  "Coquimbo",
  "Rubtsovsk",
  "Bhisho",
  "Geelong",
  "Ra’s al Khaymah",
  "Sullana",
  "Malakal",
  "Bumba",
  "Hayward",
  "Xiaoganzhan",
  "Chapecó",
  "Muskegon",
  "Taunggyi",
  "Ciudad Lázaro Cárdenas",
  "Gonbad-e Kāvūs",
  "Salavat",
  "Valdivia",
  "Pagadian",
  "Nakhodka",
  "Tarija",
  "Zarzis",
  "Punta del Este",
  "Cuddalore",
  "Rio Largo",
  "Sāmarrā’",
  "Maykop",
  "Abéché",
  "Barreiras",
  "Odense",
  "Huánuco",
  "Harīpur",
  "Albacete",
  "Melitopol’",
  "Sobral",
  "Port Arthur",
  "Surat Thani",
  "Palmdale",
  "Al Ghardaqah",
  "Isiro",
  "Puerto Princesa",
  "Kāshmar",
  "Ussuriysk",
  "Rust’avi",
  "Tiraspol",
  "Afyonkarahisar",
  "Zonguldak",
  "Matsue",
  "Champaign",
  "Moundou",
  "Koforidua",
  "Istaravshan",
  "Livorno",
  "Gonder",
  "Ciudad del Carmen",
  "Yulinshi",
  "Shkodër",
  "Muroran",
  "Sibiu",
  "Batumi",
  "Horad Barysaw",
  "Kyŏngju",
  "Innsbruck",
  "Foggia",
  "Ordu",
  "Conjeeveram",
  "Sariwŏn",
  "Beian",
  "Battambang",
  "Guangshui",
  "Mahajanga",
  "La Serena",
  "Chetumal",
  "Gandajika",
  "Pathum Thani",
  "Cairns",
  "Kovrov",
  "Tuscaloosa",
  "Osorno",
  "San Cristóbal",
  "Limassol",
  "Río Cuarto",
  "Masjed Soleymān",
  "Lake Charles",
  "Mufulira",
  "Chincha Alta",
  "Fyzābād",
  "Kansas City",
  "Dingzhou",
  "Rondonópolis",
  "Golmeh",
  "Uşak",
  "Nusaybin",
  "Macon",
  "San Francisco de Macorís",
  "Zabīd",
  "Silchar",
  "Kaihua",
  "Novokuybyshevsk",
  "Mudon",
  "Limoges",
  "Deyang",
  "Samālūţ",
  "Luanshya",
  "Escondido",
  "Al Manāqil",
  "Abbotsford",
  "Guanare",
  "Bintulu",
  "Dundee",
  "Marāgheh",
  "Bălţi",
  "Aş Şuwayḩirah as Sāḩil",
  "Benguela",
  "Guarapuava",
  "Uzhhorod",
  "Binghamton",
  "Kitale",
  "Chillán",
  "Urganch",
  "Vryheid",
  "Yilan",
  "Miaoli",
  "Tororo",
  "Pattani",
  "M’Sila",
  "Keren",
  "Cao Lãnh",
  "Khiwa",
  "Carora",
  "Warner Robins",
  "New Bedford",
  "Târgu-Mureş",
  "Perugia",
  "Xicheng",
  "Topeka",
  "Paterson",
  "Joliet",
  "Gyumri",
  "Palangkaraya",
  "Jiayuguan",
  "Yala",
  "Elkhart",
  "Diourbel",
  "Jijel",
  "Lạng Sơn",
  "Trang",
  "Turbat",
  "Médéa",
  "Kolomna",
  "Naperville",
  "San Juan del Río",
  "Manghyshlaq",
  "Guastatoya",
  "Trondheim",
  "Man",
  "Wamba",
  "Gulu",
  "Aix-en-Provence",
  "Gharyān",
  "Ambāla",
  "Cam Ranh",
  "Torrance",
  "Ibarra",
  "Matanzas",
  "Perpignan",
  "Poços de Caldas",
  "Tabora",
  "Dar‘ā",
  "Bruges",
  "Santo Agostinho",
  "Dawei",
  "Hanzhong",
  "La Ceiba",
  "Krishnanagar",
  "Phra Nakhon Si Ayutthaya",
  "Qūchān",
  "Pointe-à-Pitre",
  "Biarritz",
  "Panama City",
  "Los Ángeles",
  "Machakos",
  "Brest",
  "Metairie",
  "Kolār",
  "Kairouan",
  "Le Mans",
  "Tall ‘Afar",
  "Kumba",
  "Jinchang",
  "Tuzla",
  "Székesfehérvár",
  "Carolina",
  "Huelva",
  "Sarnia",
  "Goma",
  "Merced",
  "Tizi Ouzou",
  "Kafr ash Shaykh",
  "Ajdābiyā",
  "Otaru",
  "Logroño",
  "Stara Zagora",
  "Béchar",
  "Kenema",
  "Amiens",
  "Calama",
  "Macaé",
  "Cape Coast",
  "Houma",
  "Pleiku",
  "Pyatigorsk",
  "Cajamarca",
  "Arzamas",
  "Massawa",
  "Durrës",
  "Nawābganj",
  "Pueblo",
  "Saïda",
  "Ciego de Ávila",
  "Jieshou",
  "Chŏngju",
  "Arnhem",
  "Phuket",
  "Nong’an",
  "Ciudad de Melilla",
  "Tyler",
  "Paranaguá",
  "Ghaznī",
  "Comodoro Rivadavia",
  "As Salţ",
  "Labé",
  "Al’met’yevsk",
  "Miramar",
  "Lhokseumawe",
  "Orekhovo-Zuyevo",
  "Santa Maria",
  "Badajoz",
  "Dunhuang",
  "Bordj Bou Arreridj",
  "Bizerte",
  "Sherbrooke",
  "Piedras Negras",
  "Göttingen",
  "Kumbakonam",
  "Myitkyina",
  "Chinandega",
  "Townsville",
  "Yuma",
  "Tiruvannāmalai",
  "Zalantun",
  "Parnaíba",
  "Pābna",
  "Umtata",
  "Bremerhaven",
  "Tân An",
  "Olathe",
  "Castanhal",
  "Cerro de Pasco",
  "Irákleio",
  "Thornton",
  "Harlingen",
  "Oulu",
  "Grand Junction",
  "Shizuishan",
  "Baia Mare",
  "León",
  "West Valley City",
  "Győr",
  "Serpukhov",
  "Jequié",
  "Cẩm Phả",
  "Lira",
  "Pyay",
  "Orsha",
  "May Pen",
  "Souk Ahras",
  "Zākhū",
  "Cartago",
  "Caxias",
  "’s-Hertogenbosch",
  "Ramapo",
  "Nevinnomyssk",
  "Surprise",
  "Las Cruces",
  "Touggourt",
  "Greeley",
  "Pervoural’sk",
  "Yunxian Chengguanzhen",
  "Šiauliai",
  "Birgañj",
  "Yakima",
  "Ríohacha",
  "Musoma",
  "Āwasa",
  "Coral Springs",
  "Namibe",
  "Kislovodsk",
  "Sterling Heights",
  "Blitar",
  "Mauldin",
  "Pindamonhangaba",
  "Shāhrūd",
  "Papeete",
  "Saint John’s",
  "Racine",
  "Opole",
  "Trà Vinh",
  "Murom",
  "Ciénaga",
  "Lae",
  "Pīlibhīt",
  "Ferrara",
  "Novomoskovsk",
  "Buzău",
  "Stamford",
  "Pinsk",
  "Shuangcheng",
  "Abohar",
  "Ninh Bình",
  "Nikopol’",
  "Florencia",
  "Girardot",
  "Elizabeth",
  "Jaraguá do Sul",
  "Sannār",
  "Masaya",
  "Baidoa",
  "Tokat",
  "Erzincan",
  "Andong",
  "Tete",
  "Copiapó",
  "Batticaloa",
  "Shahr-e Kord",
  "Kökshetaū",
  "Idlib",
  "Xai-Xai",
  "Kamina",
  "Lárisa",
  "Kamyshin",
  "Karabük",
  "Besançon",
  "Sakākā",
  "Jīma",
  "Jirjā",
  "Surt",
  "Al Ḩasakah",
  "Darnah",
  "Ekibastuz",
  "Divo",
  "Simi Valley",
  "San Nicolás de los Arroyos",
  "Bragança Paulista",
  "Tukuyu",
  "Port Blair",
  "Elbląg",
  "Panevėžys",
  "Wau",
  "Alīpur Duār",
  "Lafia",
  "Sancti Spíritus",
  "Pakokku",
  "Hāthras",
  "Neftekamsk",
  "Gafsa",
  "Sogamoso",
  "Edirne",
  "Songea",
  "Tây Ninh",
  "Macheng",
  "Carúpano",
  "Tarragona",
  "Itapetininga",
  "Tengyue",
  "Boulder",
  "Montego Bay",
  "Magway",
  "Pangkalpinang",
  "Malanje",
  "Gashua",
  "Leeuwarden",
  "Sorong",
  "Kumbo",
  "Ghardaïa",
  "Kelowna",
  "Marvdasht",
  "Kenosha",
  "Semnān",
  "Santa Tecla",
  "Zlín",
  "Fasā",
  "Lysychans’k",
  "Drohobych",
  "Mỹ Tho",
  "Laiwu",
  "Alagoinhas",
  "Rudnyy",
  "East Los Angeles",
  "South Lyon",
  "Higüey",
  "Round Rock",
  "Potchefstroom",
  "Siracusa",
  "Guelma",
  "Uruguaiana",
  "Indramayu",
  "Gagnoa",
  "Pôrto Seguro",
  "Minzhu",
  "Ségou",
  "Pati",
  "Brits",
  "Maina",
  "Maastricht",
  "Tekirdağ",
  "Aalborg",
  "Barbacena",
  "Tauranga",
  "Lianhe",
  "Olmaliq",
  "Billings",
  "Sassari",
  "Karaman",
  "Ponce",
  "Yuba City",
  "Manbij",
  "Larache",
  "Huanghua",
  "Pearland",
  "Puerto Plata",
  "Maxixe",
  "Trois-Rivières",
  "Leominster",
  "Jaú",
  "Tetovo",
  "Iowa City",
  "Udine",
  "Arvada",
  "Pleven",
  "Zielona Góra",
  "Anuradhapura",
  "Shahreẕā",
  "Tongren",
  "Berdyans’k",
  "Bandundu",
  "Bama",
  "Ourense",
  "Kingsport",
  "Setúbal",
  "Nakhon Pathom",
  "Smithtown",
  "Sokodé",
  "Agadez",
  "Jalingo",
  "Achinsk",
  "Anaco",
  "Punta Arenas",
  "Langsa",
  "Vyborg",
  "Calabozo",
  "Chiang Rai",
  "Nkongsamba",
  "Kindia",
  "Gao",
  "Lehigh Acres",
  "Port-Gentil",
  "Richardson",
  "Huanren",
  "Guangming",
  "Puno",
  "Nizhyn",
  "Yakeshi",
  "Boké",
  "Gilroy",
  "Cherkessk",
  "El Monte",
  "Navojoa",
  "Rock Hill",
  "Tahoua",
  "Yelets",
  "Binzhou",
  "Inhambane",
  "Clearwater",
  "Toliara",
  "Guelmim",
  "Pouso Alegre",
  "Kŭlob",
  "Nabeul",
  "Tuguegarao",
  "Norman",
  "Ciudad Valles",
  "Botoşani",
  "Las Delicias",
  "Kam”yanets’-Podil’s’kyy",
  "Texas City",
  "Giyon",
  "Shashemenē",
  "Temecula",
  "Utica",
  "Vālpārai",
  "Cuito",
  "Maribor",
  "Siirt",
  "Kankan",
  "Zhijiang",
  "St. George",
  "West Jordan",
  "Kecskemét",
  "Laghouat",
  "Botucatu",
  "Costa Mesa",
  "Tobol’sk",
  "Miami Gardens",
  "Curicó",
  "Sørum",
  "Santa Cruz do Sul",
  "Kipushi",
  "Kōenchō",
  "Downey",
  "Coeur d'Alene",
  "Tokmok",
  "Nefteyugansk",
  "Shaowu",
  "Mojokerto",
  "Satu Mare",
  "Guanajuato",
  "El Centro",
  "Mazyr",
  "Iguala de la Independencia",
  "Nakhon Sawan",
  "Iringa",
  "Zwolle",
  "Kandy",
  "Conselheiro Lafaiete",
  "Daugavpils",
  "Kuqa",
  "Magelang",
  "Jawhar",
  "Gresham",
  "Algeciras",
  "Nsukka",
  "Nguru",
  "North Charleston",
  "Balkanabat",
  "San Buenaventura",
  "Manzanillo",
  "Pemba",
  "Vólos",
  "Inglewood",
  "Noyabr’sk",
  "Yenangyaung",
  "Manzini",
  "Pompano Beach",
  "Centennial",
  "West Palm Beach",
  "Mzuzu",
  "Bouira",
  "Garanhuns",
  "Qingnian",
  "David",
  "Milagro",
  "Bataysk",
  "Hailun",
  "Lichinga",
  "Kandi",
  "Dali",
  "Tieli",
  "Ipiales",
  "Catanduva",
  "The Woodlands",
  "Szombathely",
  "Pottstown",
  "Heihe",
  "Siem Reap",
  "Szolnok",
  "Sergiyev Posad",
  "Tumbes",
  "Matagalpa",
  "Túxpam de Rodríguez Cano",
  "Leninsk-Kuznetskiy",
  "Soubré",
  "Karamay",
  "Kaposvár",
  "Mopti",
  "Trincomalee",
  "Broken Arrow",
  "Nehe",
  "Kyzyl",
  "Mascara",
  "Birnin Kebbi",
  "An Nuhūd",
  "Estelí",
  "Barletta",
  "Trento",
  "Escuintla",
  "West Covina",
  "Kogon",
  "Râmnicu Vâlcea",
  "Koszalin",
  "Obninsk",
  "Shinyanga",
  "Turlock",
  "Västerås",
  "Chapayevsk",
  "Louangphabang",
  "Ilebo",
  "Golmud",
  "Apucarana",
  "Daly City",
  "Eau Claire",
  "Pardubice",
  "Ratchaburi",
  "Elista",
  "Taungoo",
  "Kandalaksha",
  "Sandy Springs",
  "Douliu",
  "Coimbra",
  "Drobeta-Turnu Severin",
  "Longjiang",
  "Namur",
  "Sioux City",
  "Chico",
  "Novotroitsk",
  "Raba",
  "Bagé",
  "Jurupa Valley",
  "Derbent",
  "Suceava",
  "Annecy",
  "Salto",
  "Azare",
  "Lahad Datu",
  "Şa‘dah",
  "Assab",
  "Fresnillo",
  "Lugano",
  "Highlands Ranch",
  "Hòa Bình",
  "Granada",
  "Kongolo",
  "Jāzān",
  "Davie",
  "Focşani",
  "Linhares",
  "Cottbus",
  "Koutiala",
  "League City",
  "Prijedor",
  "San Mateo",
  "Jena",
  "Gera",
  "Torbat-e Jām",
  "Nong Khai",
  "Brindisi",
  "Mmabatho",
  "Zhezqazghan",
  "Tandil",
  "Maumere",
  "Albury",
  "Al Qāmishlī",
  "Ādīgrat",
  "Abengourou",
  "Kiselëvsk",
  "Kroonstad",
  "Ed Damer",
  "El Cajon",
  "Dolisie",
  "Kilinochchi",
  "Ahar",
  "Biak",
  "Gardēz",
  "Rialto",
  "Konotop",
  "Prabumulih",
  "Marsá Maţrūḩ",
  "Heroica Guaymas",
  "Parral",
  "Bend",
  "Vĩnh Long",
  "Velikiye Luki",
  "Palma Soriano",
  "Pedro Juan Caballero",
  "Pakxé",
  "Roxas City",
  "Piatra Neamţ",
  "Grudziądz",
  "Lower Hutt",
  "Houzhuang",
  "Edison",
  "Ukhta",
  "Liberec",
  "Charlottesville",
  "Bethal",
  "La Crosse",
  "Temple",
  "Bontang",
  "Teluk Intan",
  "‘Ibrī",
  "Dera Ismail Khan",
  "Vista",
  "Kansk",
  "Renton",
  "Sarapul",
  "Maladzyechna",
  "Olomouc",
  "Barretos",
  "Teófilo Otoni",
  "Duitama",
  "Vanadzor",
  "Tartu",
  "Novara",
  "Sparks",
  "Oum el Bouaghi",
  "Solikamsk",
  "Vacaville",
  "Allen",
  "Glazov",
  "Ancona",
  "Bukoba",
  "Kpalimé",
  "Sakata",
  "Magangué",
  "Ust’-Ilimsk",
  "Sabhā",
  "Lida",
  "Subotica",
  "Chanthaburi",
  "Tumen",
  "Galle",
  "Ciudad Choluteca",
  "Thika",
  "Giresun",
  "Kericho",
  "České Budějovice",
  "Lahti",
  "Passos",
  "Arden-Arcade",
  "Örebro",
  "Apatzingan de la Constitucion",
  "Jyväskylä",
  "Ciudad de Atlixco",
  "Orem",
  "Ubá",
  "Ozamiz City",
  "Ourinhos",
  "Slavonski Brod",
  "Mangochi",
  "Ariana",
  "Compton",
  "Arapongas",
  "Bismarck",
  "Türkistan",
  "Târgu Jiu",
  "Guasave",
  "Shostka",
  "Yāsūj",
  "Bunia",
  "Linköping",
  "Schwerin",
  "Bolu",
  "Mtwara",
  "Araguari",
  "Yên Bái",
  "Corumbá",
  "Half Way Tree",
  "Sliven",
  "Erechim",
  "Yola",
  "Mekele",
  "Kuznetsk",
  "Guliston",
  "South Gate",
  "Magadan",
  "Catanzaro",
  "Hradec Králové",
  "Juazeiro",
  "Osijek",
  "Al ‘Aqabah",
  "Latacunga",
  "Dobrich",
  "Naxçıvan",
  "Prešov",
  "Kırşehir",
  "Sunrise",
  "Bento Gonçalves",
  "Al Fujayrah",
  "Lagos de Moreno",
  "Yishui",
  "Ústí nad Labem",
  "Lynn",
  "Malindi",
  "Tatuí",
  "Michurinsk",
  "Dalai",
  "Shibirghān",
  "Darwin",
  "Nouméa",
  "Ho",
  "Quibdó",
  "Patos",
  "Tulcea",
  "Santiago de Compostela",
  "Miami Beach",
  "Santa Monica",
  "Tubarão",
  "Calais",
  "Ciudad Guzmán",
  "Kohima",
  "Kuopio",
  "Kilosa",
  "Cabinda",
  "Tecomán",
  "Helsingborg",
  "Muriaé",
  "Niğde",
  "Cuauhtémoc",
  "Drammen",
  "Moncton",
  "Klagenfurt",
  "San Leandro",
  "Phetchaburi",
  "Itanhaém",
  "Barahona",
  "Buea",
  "Limerick",
  "Francistown",
  "Jönköping",
  "Santana do Livramento",
  "Vlorë",
  "Fujin",
  "Tambacounda",
  "Sumbawanga",
  "Wangqing",
  "Brovary",
  "Târgovişte",
  "Nova Lima",
  "Taldyqorghan",
  "State College",
  "Brusque",
  "Coronel Oviedo",
  "Citrus Heights",
  "Yozgat",
  "Ath Thawrah",
  "Ébolowa",
  "Ağrı",
  "Surigao",
  "Çanakkale",
  "San Juan de los Morros",
  "Makeni",
  "Uman’",
  "Žilina",
  "Assis",
  "Koudougou",
  "Shumen",
  "Mishan",
  "Nenjiang",
  "Lokossa",
  "Huaraz",
  "Whittier",
  "Nouadhibou",
  "Ruhengeri",
  "Bitola",
  "Ioánnina",
  "Agua Prieta",
  "Huehuetenango",
  "Chipata",
  "Poitiers",
  "Río Gallegos",
  "Chumphon",
  "Alhambra",
  "Paulo Afonso",
  "Ituiutaba",
  "Cáceres",
  "Liepāja",
  "Louga",
  "Puerto Limón",
  "Lorient",
  "Savannakhet",
  "Mbarara",
  "Gisenyi",
  "Ouidah",
  "Reşiţa",
  "Codó",
  "Kampong Cham",
  "Buena Park",
  "Tulcán",
  "Antsiran̈ana",
  "Amasya",
  "Araxá",
  "Muş",
  "Nan",
  "Pernik",
  "Cukai",
  "Palmerston North",
  "Chimaltenango",
  "Banská Bystrica",
  "Kilis",
  "Fier",
  "Āsela",
  "Bolgatanga",
  "Abomey",
  "Kasserine",
  "Kaspiysk",
  "Sokhumi",
  "Lavras",
  "Mountain View",
  "Bistriţa",
  "Avaré",
  "Formosa",
  "Pisco",
  "Salina Cruz",
  "Zomba",
  "Natitingou",
  "Atakpamé",
  "Deerfield Beach",
  "Bingöl",
  "Tustin",
  "Huacho",
  "Kilifi",
  "Al Karak",
  "Nabatîyé",
  "Ciudad Mante",
  "New Rochelle",
  "Prachin Buri",
  "Haskovo",
  "Erdenet",
  "Itumbiara",
  "Ouahigouya",
  "Silver Spring",
  "Abaetetuba",
  "Alameda",
  "Paysandú",
  "Inowrocław",
  "Slatina",
  "Shulan",
  "Sagaing",
  "Chaniá",
  "Cheyenne",
  "Três Lagoas",
  "Biel/Bienne",
  "Ciudad de Ceuta",
  "São João del Rei",
  "Umeå",
  "Zahlé",
  "Wa",
  "Milpitas",
  "Gangtok",
  "Bellflower",
  "Watsonville",
  "Hakkari",
  "Kars",
  "Itaúna",
  "São Mateus",
  "Caguas",
  "La Rochelle",
  "Thakhèk",
  "Pori",
  "Uttaradit",
  "Jataí",
  "São João da Boa Vista",
  "Davis",
  "Baldwin Park",
  "Tucuruí",
  "Masvingo",
  "Babahoyo",
  "Sakon Nakhon",
  "Birobidzhan",
  "Tamanrasset",
  "Buynaksk",
  "Galway",
  "Nevşehir",
  "Kampong Chhnang",
  "Cachoeira do Sul",
  "Greenock",
  "Toledo",
  "Manokwari",
  "Shancheng",
  "Karlstad",
  "Prey Veng",
  "Cozumel",
  "Kiffa",
  "Farāh",
  "Pine Hills",
  "Mpanda",
  "Skien",
  "Roanne",
  "Nek’emtē",
  "Dabou",
  "New Britain",
  "Tamazunchale",
  "Georgiyevsk",
  "Bacabal",
  "As Suwaydā’",
  "Esbjerg",
  "Nizwá",
  "Pawtucket",
  "Lauderhill",
  "Vratsa",
  "Pôrto União",
  "Goiana",
  "Monastir",
  "Çankırı",
  "Upington",
  "Mardin",
  "Zadar",
  "Passaic",
  "Ijuí",
  "Anlu",
  "Salima",
  "Mamou",
  "Comayagua",
  "Muyinga",
  "Altamira",
  "Guaynabo",
  "Saraburi",
  "Paracatu",
  "Şirvan",
  "Mindelo",
  "Byumba",
  "Sankt Gallen",
  "Tatabánya",
  "Kastamonu",
  "Iguatu",
  "Sunyani",
  "Karakol",
  "Lisala",
  "Lobatse",
  "Trnava",
  "Totonicapán",
  "Ouezzane",
  "Ārba Minch’",
  "Rioverde",
  "Tuy Hòa",
  "Jabuticabal",
  "Kolda",
  "Arauca",
  "Vaslui",
  "Azogues",
  "Giurgiu",
  "Potenza",
  "Timbuktu",
  "Gaithersburg",
  "Gävle",
  "L’Aquila",
  "Matara",
  "Narathiwat",
  "Artemisa",
  "Balsas",
  "Putrajaya",
  "Calbayog City",
  "Redondo Beach",
  "Garissa",
  "Khanty-Mansiysk",
  "Matehuala",
  "Juchitán de Zaragoza",
  "South San Francisco",
  "Santa Inês",
  "El Bayadh",
  "Dimbokro",
  "Kasese",
  "Jelgava",
  "Lào Cai",
  "Bayonne",
  "Puerto Maldonado",
  "Mbaïki",
  "Jáltipan de Morelos",
  "Nālūt",
  "Laḩij",
  "Burdur",
  "Alba Iulia",
  "Tamarac",
  "Schenectady",
  "Panaji",
  "Santo Ângelo",
  "East Orange",
  "Masaka",
  "Debre Birhan",
  "Békéscsaba",
  "Cobán",
  "Solwezi",
  "Şəki",
  "Gurupi",
  "Faīẕābād",
  "Mazatenango",
  "Parintins",
  "Nepālgañj",
  "Tāluqān",
  "Arras",
  "Ciudad Hidalgo",
  "Zrenjanin",
  "Skokie",
  "Curvelo",
  "Kangar",
  "Ramla",
  "Florence-Graham",
  "Kristiansand",
  "Caçador",
  "Kanchanaburi",
  "Irecê",
  "Catalão",
  "Pico Rivera",
  "Samut Sakhon",
  "North Bergen",
  "Kakamega",
  "Cahul",
  "Molepolole",
  "Zalău",
  "Valença",
  "Vilhena",
  "Montebello",
  "Bossangoa",
  "Belize City",
  "Itapeva",
  "Beledweyne",
  "Graaff-Reinet",
  "Bitlis",
  "Guamúchil",
  "Tataouine",
  "Surin",
  "La Habra",
  "Singida",
  "Assen",
  "North Miami",
  "Bambari",
  "Tupã",
  "Veszprém",
  "Madang",
  "Zalaegerszeg",
  "Bāmyān",
  "Berbérati",
  "Chinhoyi",
  "Medenine",
  "Troyes",
  "Cayenne",
  "Ponta Delgada",
  "Jianguang",
  "National City",
  "Malden",
  "Portimão",
  "Gaalkacyo",
  "Monterey Park",
  "Yopal",
  "Coconut Creek",
  "Cherbourg",
  "Canela",
  "Cupertino",
  "Sfântu-Gheorghe",
  "Lokoja",
  "Piraçununga",
  "Banfora",
  "Gardena",
  "Mbanza Congo",
  "Lənkəran",
  "Pirapora",
  "Caratinga",
  "La Mesa",
  "Uíge",
  "South Whittier",
  "West Allis",
  "Gorno-Altaysk",
  "Nkhotakota",
  "Kendale Lakes",
  "Itapetinga",
  "São Borja",
  "Växjö",
  "Leticia",
  "Béja",
  "Drummondville",
  "Bến Tre",
  "Caràzinho",
  "Santa Rosa",
  "Lappeenranta",
  "Fountainebleau",
  "Azua",
  "Viedma",
  "Concepción",
  "Manacapuru",
  "Mount Hagen",
  "White Plains",
  "Kabinda",
  "The Hammocks",
  "Telêmaco Borba",
  "Guanambi",
  "Huntington Park",
  "Kamphaeng Phet",
  "Embu",
  "Margate",
  "Chaiyaphum",
  "Bondoukou",
  "Korçë",
  "San Andrés",
  "Tamiami",
  "Agen",
  "Kırklareli",
  "Rundu",
  "Ariquemes",
  "Hải Dương",
  "Ushuaia",
  "Napier",
  "Suileng",
  "Lop Buri",
  "Timbaúba",
  "Carson City",
  "Arcoverde",
  "Al Mafraq",
  "Puntarenas",
  "New Brunswick",
  "Mafeteng",
  "Vaasa",
  "Ródos",
  "Adrar",
  "Kitgum",
  "Serrinha",
  "Jijiga",
  "Vacaria",
  "Eger",
  "Puerto Barrios",
  "Tikrīt",
  "Janaúba",
  "Kaga Bandoro",
  "Magong",
  "Formiga",
  "Pul-e Khumrī",
  "Zouerate",
  "Fountain Valley",
  "Oak Lawn",
  "Bungoma",
  "Lautoka",
  "Itapipoca",
  "Ełk",
  "Halmstad",
  "Estância",
  "Évora",
  "Berwyn",
  "Melo",
  "Maldonado",
  "Ithaca",
  "São Gabriel",
  "Nakhon Phanom",
  "São José de Ribamar",
  "Aloha",
  "Hoboken",
  "Abancay",
  "Kalasin",
  "Murzuq",
  "Karlovac",
  "Caicó",
  "Paramount",
  "Tarbes",
  "Nuevo Casas Grandes",
  "Juigalpa",
  "Rosemead",
  "Moquegua",
  "Ajaccio",
  "Tacuarembó",
  "West New York",
  "Aveiro",
  "Chornomors’k",
  "Revere",
  "Jihlava",
  "Bắc Giang",
  "Yevlax",
  "Chārīkār",
  "Suphan Buri",
  "Joensuu",
  "Coari",
  "Registro",
  "Saint-Brieuc",
  "Taxco de Alarcón",
  "Camaquã",
  "Veliko Tarnovo",
  "Arrecife",
  "Crateús",
  "Perth Amboy",
  "Slobozia",
  "Mongu",
  "Puerto Ayacucho",
  "Pursat",
  "Tromsø",
  "Mérida",
  "Andradina",
  "Barra do Garças",
  "Aspen Hill",
  "Fredericton",
  "Naryn",
  "Whangarei",
  "Placentia",
  "Batatais",
  "Chilliwack",
  "Usulután",
  "Aliso Viejo",
  "Maha Sarakham",
  "Tucupita",
  "Itacoatiara",
  "Jendouba",
  "Vejle",
  "Séguéla",
  "Nyeri",
  "Jinotega",
  "Kyustendil",
  "Cerritos",
  "Campobasso",
  "Jutiapa",
  "Phetchabun",
  "Gbadolite",
  "Araguaína",
  "Ma‘ān",
  "Sisophon",
  "Kampot",
  "North Bethesda",
  "Hpa-An",
  "Al Khārijah",
  "Chake Chake",
  "Maun",
  "Kanash",
  "Odienné",
  "Zaranj",
  "Dosso",
  "Chachoengsao",
  "Vryburg",
  "Morshansk",
  "Quixadá",
  "Florin",
  "Invercargill",
  "Shawinigan",
  "La Mirada",
  "Cypress",
  "Larnaca",
  "Barra do Corda",
  "Luleå",
  "Songkhla",
  "Floriano",
  "Serowe",
  "Vigan",
  "Valladolid",
  "Senhor do Bonfim",
  "Covina",
  "Cojutepeque",
  "Asadābād",
  "Rio Verde",
  "Ponte Nova",
  "Muğla",
  "Kibuye",
  "El Kef",
  "Guaxupé",
  "Goianésia",
  "Ciudad de Huajuapam de León",
  "Ratnapura",
  "Capanema",
  "Moyobamba",
  "Antelope",
  "Leribe",
  "Autlán de Navarro",
  "Itamaraju",
  "Mtsensk",
  "Badulla",
  "Campo Belo",
  "Āksum",
  "Montana",
  "North Highlands",
  "Itaberaba",
  "Buri Ram",
  "Hämeenlinna",
  "Lamía",
  "Meru",
  "Rosso",
  "Breves",
  "Nacaome",
  "Boosaaso",
  "New Amsterdam",
  "Berat",
  "Kanye",
  "Choma",
  "Leopoldina",
  "Njombe",
  "Kokkola",
  "Aweil",
  "Mikkeli",
  "Everett",
  "Hollister",
  "Östersund",
  "Trujillo Alto",
  "Nevers",
  "Gbarnga",
  "Jalapa",
  "Coyhaique",
  "Wajir",
  "Buta",
  "Puttalam",
  "Komotiní",
  "Liberia",
  "Sololá",
  "Dédougou",
  "Hackensack",
  "Altadena",
  "Cametá",
  "Leiria",
  "Oakland Park",
  "Iganga",
  "University",
  "Itanagar",
  "Piripiri",
  "Si Sa Ket",
  "Kabale",
  "Cleveland Heights",
  "Trollhättan",
  "Annandale",
  "Camocim",
  "Cobija",
  "Umm al Qaywayn",
  "Huancavelica",
  "Bluefields",
  "Aracati",
  "Atar",
  "North Lauderdale",
  "Pärnu",
  "North Miami Beach",
  "Chitré",
  "Inhumas",
  "Whitney",
  "Hāgere Hiywet",
  "Phatthalung",
  "Araranguá",
  "Oyem",
  "San Bruno",
  "West Babylon",
  "Vichy",
  "Bilwi",
  "Franceville",
  "Ventspils",
  "Rohnert Park",
  "Santo Domingo Tehuantepec",
  "Bell Gardens",
  "Canindé",
  "Fort Portal",
  "Montemorelos",
  "Cabo San Lucas",
  "Dieppe",
  "Charlottetown",
  "Hicksville",
  "Mansa",
  "Lovech",
  "Salgueiro",
  "Sidi Bouzid",
  "Penedo",
  "Miercurea-Ciuc",
  "Tizimín",
  "Brumado",
  "Lindi",
  "Artigas",
  "Woonsocket",
  "Waipahu",
  "Chiquimula",
  "Victoriaville",
  "Jaguaquara",
  "Grande Prairie",
  "Lusambo",
  "Faro",
  "Saurimo",
  "Garça",
  "Minas",
  "Mityana",
  "Cao Bằng",
  "Palmeira dos Índios",
  "Bastia",
  "Teaneck",
  "Frutal",
  "Wilkes-Barre",
  "Chapadinha",
  "Bom Jesus da Lapa",
  "Yambio",
  "La Puente",
  "Bilecik",
  "Bozoum",
  "Inongo",
  "Ali Sabieh",
  "Kaya",
  "Salamá",
  "Kendall West",
  "Ciudad Constitución",
  "Hallandale Beach",
  "Daman",
  "Laguna",
  "Mochudi",
  "Goroka",
  "Perth",
  "Salgótarján",
  "Zacatecoluca",
  "Tozeur",
  "Russas",
  "Marumsco",
  "Antigua Guatemala",
  "Roi Et",
  "Annapolis",
  "Keizer",
  "South Miami Heights",
  "Culver City",
  "Upper Hutt",
  "Xam Nua",
  "Mineiros",
  "Northglenn",
  "Pinheiro",
  "Dún Dealgan",
  "Joaçaba",
  "Caloundra",
  "Mission Bend",
  "Phrae",
  "Stanton",
  "Kérkyra",
  "Ruyigi",
  "Hà Giang",
  "Rēzekne",
  "Chur",
  "Razgrad",
  "Aventura",
  "Tenkodogo",
  "Lake Worth",
  "Flores",
  "Salekhard",
  "Hanover Park",
  "Remanso",
  "Valley Stream",
  "Fort Lee",
  "Nanuque",
  "Penticton",
  "Juticalpa",
  "Kasulu",
  "Diamantina",
  "San Vicente",
  "‘Ataq",
  "East Meadow",
  "Kearns",
  "Šibenik",
  "West Hollywood",
  "Rayong",
  "Dubrovnik",
  "Egypt Lake-Leto",
  "Richmond West",
  "Rosário do Sul",
  "Retalhuleu",
  "Nueva Rosita",
  "Elmont",
  "Drogheda",
  "Cristalina",
  "San José",
  "Falun",
  "Tuyên Quang",
  "Temple City",
  "La Presa",
  "São Francisco do Sul",
  "Zacapa",
  "Lauderdale Lakes",
  "Chillum",
  "Kahama",
  "Paphos",
  "Manhattan Beach",
  "Beja",
  "Bell",
  "Ulaangom",
  "Phichit",
  "Watertown Town",
  "Oriximiná",
  "Barreiros",
  "Santa Rosa de Copán",
  "Xique-Xique",
  "Göyçay",
  "La Barca",
  "Pánuco",
  "Loei",
  "Shumerlya",
  "Karlskrona",
  "Luebo",
  "Talas",
  "Jacarèzinho",
  "La Asunción",
  "Samut Songkhram",
  "Kalmar",
  "Taibao",
  "Itapecuru Mirim",
  "Ölgiy",
  "Viborg",
  "Januária",
  "Rovaniemi",
  "Adjumani",
  "Port-de-Paix",
  "Osório",
  "University City",
  "Satun",
  "Norristown",
  "Oildale",
  "Foster City",
  "Bragança",
  "Bouar",
  "Goba",
  "Gisborne",
  "Babati",
  "Sibut",
  "Karonga",
  "Szekszárd",
  "Morrinhos",
  "Coroatá",
  "Kaneohe",
  "Ahuachapán",
  "Bodø",
  "Owando",
  "Golden Glades",
  "Aosta",
  "Glendale Heights",
  "Durazno",
  "Kakata",
  "Dana Point",
  "Ocotal",
  "Fada Ngourma",
  "Fair Lawn",
  "Foothill Farms",
  "Buenaventura Lakes",
  "Montgomery Village",
  "Jacmel",
  "Prachuap Khiri Khan",
  "Castelo Branco",
  "Cananea",
  "Campbell River",
  "Choybalsan",
  "Huntington Station",
  "Sumbe",
  "Kampong Speu",
  "Midvale",
  "Eastchester",
  "Tak",
  "Koh Kong",
  "Lawndale",
  "Kotovsk",
  "Cradock",
  "Bangassou",
  "Fribourg",
  "Courtenay",
  "Capitão Poço",
  "Harper",
  "Orangeville",
  "Maués",
  "Eastpointe",
  "Franklin Square",
  "West Little River",
  "Tenosique",
  "Garfield",
  "North Providence",
  "Artvin",
  "Parras de la Fuente",
  "Gümüşhane",
  "Florida",
  "Paranaíba",
  "Moose Jaw",
  "Bocaiúva",
  "Guarda",
  "Boende",
  "Rumbek",
  "Mamoudzou",
  "Ciudad Sabinas Hidalgo",
  "Rivas",
  "Petatlán",
  "São Luís Gonzaga",
  "Campo Maior",
  "Uniondale",
  "Masindi",
  "Barra do Bugres",
  "Neuchâtel",
  "Krabi",
  "Tucano",
  "San Pablo",
  "Kouvola",
  "Tena",
  "Miami Lakes",
  "Diffa",
  "Jérémie",
  "Arendal",
  "Santa Paula",
  "Pallisa",
  "City of Orange",
  "Oceanside",
  "Burlingame",
  "Ticul",
  "Āsosa",
  "Hovd",
  "Am Timan",
  "Huatabampo",
  "Bay Shore",
  "Nebbi",
  "Presidente Dutra",
  "Blenheim",
  "Ometepec",
  "Novo Horizonte",
  "Rahway",
  "Khorugh",
  "Savanna-la-Mar",
  "Golden Gate",
  "Iturama",
  "East Palo Alto",
  "Port Chester",
  "Bafatá",
  "Stung Treng",
  "Suisun City",
  "Doba",
  "Jinotepe",
  "Swakopmund",
  "Hamar",
  "Santarém",
  "Almenara",
  "Mytilíni",
  "Chalatenango",
  "Bắc Kạn",
  "West Falls Church",
  "Tunceli",
  "Boaco",
  "Bria",
  "Trípoli",
  "Shamokin",
  "Nutley",
  "Burbank",
  "Ladera Ranch",
  "Penonomé",
  "Kisii",
  "Pilar",
  "Ubaitaba",
  "Melrose",
  "Newburgh",
  "Mililani Town",
  "Ouésso",
  "Hillerød",
  "Iporá",
  "Escuinapa",
  "Drexel Hill",
  "Caxito",
  "Popondetta",
  "Arvayheer",
  "Mouila",
  "Bergenfield",
  "Guaranda",
  "Mongo",
  "Mörön",
  "Treinta y Tres",
  "Aţ Ţūr",
  "Óbidos",
  "Nyköping",
  "Niquelândia",
  "Isla Vista",
  "Imperial Beach",
  "Al Ghayz̧ah",
  "Leisure City",
  "Silvassa",
  "Conceição do Araguaia",
  "Lemon Grove",
  "Indiana",
  "West Whittier-Los Nietos",
  "Sensuntepeque",
  "Siliana",
  "Bregenz",
  "Reisterstown",
  "Rocha",
  "Nola",
  "Miracema",
  "Ipu",
  "Champotón",
  "Voinjama",
  "Soledad",
  "Brockville",
  "Wete",
  "Dongola",
  "Tralee",
  "Canavieiras",
  "Rawson",
  "Alenquer",
  "Bayanhongor",
  "Mendi",
  "Sena Madureira",
  "Tuxpan",
  "Chachapoyas",
  "Granja",
  "Pimenta Bueno",
  "Zwedru",
  "Lapa",
  "Cataño",
  "Nueva Gerona",
  "El Cerrito",
  "Aţ Ţafīlah",
  "Ossining",
  "Ati",
  "São Lourenço do Sul",
  "Lambaréné",
  "Melrose Park",
  "Juneau",
  "Colinas",
  "Wewak",
  "Cliffside Park",
  "Valinda",
  "Katima Mulilo",
  "Mohale’s Hoek",
  "South Bradenton",
  "Suitland",
  "Puyo",
  "Rockville Centre",
  "Moyale",
  "Covilhã",
  "Pires do Rio",
  "Otjiwarongo",
  "Felipe Carrillo Puerto",
  "Riverbank",
  "Coral Terrace",
  "Rouyn-Noranda",
  "Watauga",
  "Ranong",
  "Ciudad Altamirano",
  "Herndon",
  "Lamu",
  "Bailey's Crossroads",
  "Rolim de Moura",
  "San Pedro Pochutla",
  "Tbeng Meanchey",
  "Ghāt",
  "Peekskill",
  "Fatick",
  "Sühbaatar",
  "Terrytown",
  "Ewa Gentry",
  "Hialeah Gardens",
  "Kiryas Joel",
  "Quthing",
  "Carteret",
  "Cloverleaf",
  "Aībak",
  "Svay Rieng",
  "Koulikoro",
  "Paraíso",
  "Iguape",
  "Rosemont",
  "Macas",
  "Pijijiapan",
  "Kibaha",
  "Santa Cruz del Quiché",
  "Itambé",
  "West Rancho Dominguez",
  "Santa Maria da Vitória",
  "West Puente Valley",
  "Gjirokastër",
  "Zug",
  "Blue Island",
  "Magdalena de Kino",
  "Fray Bentos",
  "Whitehorse",
  "Nova Cruz",
  "The Crossings",
  "Copiague",
  "Landover",
  "Sibiti",
  "Tecax",
  "Madingou",
  "Bella Unión",
  "Millbrae",
  "Frontera",
  "Visby",
  "Ives Estates",
  "Moyo",
  "Sunny Isles Beach",
  "West Carson",
  "Uthai Thani",
  "Tadjourah",
  "San José del Guaviare",
  "Itaberaí",
  "Ojinaga",
  "Nkhata Bay",
  "Massapequa",
  "North Plainfield",
  "Monsey",
  "Mocoa",
  "Guayama",
  "Heredia",
  "North Bay Shore",
  "Fort McMurray",
  "Nsanje",
  "Hamtramck",
  "Eirunepé",
  "Colonia del Sacramento",
  "Kayunga",
  "Caacupé",
  "Gavarr",
  "Hayesville",
  "Nar’yan-Mar",
  "Yasothon",
  "Barras",
  "Trat",
  "Kilkenny",
  "Lennox",
  "Ngozi",
  "Heroica Ciudad de Tlaxiaco",
  "Mountlake Terrace",
  "Portel",
  "Nakhon Nayok",
  "Itupiranga",
  "Motul",
  "Paracuru",
  "McNair",
  "Luena",
  "Phayao",
  "Omagh",
  "Palisades Park",
  "South El Monte",
  "Rutana",
  "Impfondo",
  "Maralal",
  "Orange Walk",
  "Penzance",
  "Lomita",
  "Merrick",
  "North Lynnwood",
  "Makakilo",
  "Artashat",
  "Atoyac de Álvarez",
  "Jasmine Estates",
  "Elmwood Park",
  "South San Jose Hills",
  "Bururi",
  "Somoto",
  "Viljandi",
  "Columbia Heights",
  "Schofield Barracks",
  "Sligo",
  "Lodwar",
  "Franconia",
  "West Chester",
  "Bayshore Gardens",
  "Sing Buri",
  "Hakha",
  "North Bellmore",
  "Tidjikja",
  "Kratie",
  "Kebili",
  "West Hempstead",
  "Tsetserleg",
  "North Amityville",
  "Kavieng",
  "Lynbrook",
  "Hermosa Beach",
  "Canelones",
  "La Crescenta-Montrose",
  "Makamba",
  "Allende",
  "Langley Park",
  "Evergreen Park",
  "Lillehammer",
  "Puerto Escondido",
  "Faranah",
  "Kayanza",
  "Mobaye",
  "Mamaroneck",
  "Iselin",
  "Laï",
  "Tchibanga",
  "Central Falls",
  "Mzimba",
  "Tillabéri",
  "Yellowknife",
  "North Valley Stream",
  "Hawthorne",
  "Sơn La",
  "Viseu",
  "Mubende",
  "Paraguarí",
  "Acatlán de Osorio",
  "Hūn",
  "Kimbe",
  "Avenel",
  "Bellaire",
  "Ashtarak",
  "Fort Saint John",
  "Ceres",
  "Whakatane",
  "Lezhë",
  "East Massapequa",
  "Idylwood",
  "Mansa Konko",
  "Winthrop Town",
  "Sherrelwood",
  "Molde",
  "North Massapequa",
  "Hinche",
  "Santiago Ixcuintla",
  "Bryn Mawr-Skyway",
  "Hyattsville",
  "Mchinji",
  "Tindouf",
  "Kédougou",
  "Glassmanor",
  "Muramvya",
  "Keetmanshoop",
  "Mao",
  "Dumont",
  "Kirsanov",
  "Burke Centre",
  "Takoma Park",
  "Sudley",
  "Kukës",
  "Obock",
  "Dzuunmod",
  "Ojus",
  "Đông Hà",
  "Miahuatlán de Porfirio Díaz",
  "Peto",
  "Bostonia",
  "Eldama Ravine",
  "Wahiawa",
  "South Houston",
  "Pakwach",
  "Vincent",
  "Bulgan",
  "Mehtar Lām",
  "Loikaw",
  "Massapequa Park",
  "Merrifield",
  "East Cleveland",
  "Yauco",
  "Dutse",
  "Makokou",
  "Härnösand",
  "Vila Real",
  "San Ignacio",
  "Carmelo",
  "Glenmont",
  "Zaghouan",
  "Ma’rib",
  "Hybla Valley",
  "Apodi",
  "Country Walk",
  "Manassas Park",
  "Lansdale",
  "South Orange Village",
  "Hazel Park",
  "Cuilapa",
  "Mulanje",
  "Marsabit",
  "Santa Lucía",
  "Rivière-du-Loup",
  "Ntungamo",
  "South River",
  "Kingstowne",
  "Butha-Buthe",
  "Gobabis",
  "Koulamoutou",
  "Avocado Heights",
  "Xaignabouli",
  "Parkway",
  "Compostela",
  "Dispur",
  "Hillcrest Heights",
  "Floral Park",
  "East Rancho Dominguez",
  "North Arlington",
  "Cherryland",
  "Palmetto Estates",
  "Nieuw Nickerie",
  "Pinewood",
  "Walnut Park",
  "Fords",
  "Adelphi",
  "East San Gabriel",
  "Baruun-Urt",
  "Asbury Park",
  "Bellmore",
  "Pacific Grove",
  "Santiago Tuxtla",
  "East Riverdale",
  "Villa Hayes",
  "West University Place",
  "Dedza",
  "North New Hyde Park",
  "Portalegre",
  "Viana do Castelo",
  "Wilkinsburg",
  "Westbury",
  "Chai Nat",
  "North Fair Oaks",
  "Villa Unión",
  "Cayey",
  "Eggertsville",
  "Cárdenas",
  "White Center",
  "South Farmingdale",
  "Stanford",
  "Zamora",
  "Takeo",
  "Parlier",
  "Bull Run",
  "São Gabriel da Cachoeira",
  "Daru",
  "Manga",
  "West Park",
  "Lüderitz",
  "Ewa Beach",
  "Paro",
  "Fayrōz Kōh",
  "Izamal",
  "Tecuala",
  "Peshkopi",
  "Pierre",
  "Tamuín",
  "Öndörhaan",
  "Técpan de Galeana",
  "Haddon",
  "Falls Church",
  "Robbinsdale",
  "Kiboga",
  "Hawaiian Gardens",
  "Gwanda",
  "Norridge",
  "Gabú",
  "Port Antonio",
  "Basse Santa Su",
  "Olympia Heights",
  "Halawa",
  "New Cassel",
  "Weehawken",
  "Chicago Ridge",
  "Lemon Hill",
  "Schwyz",
  "Juan Aldama",
  "Willowick",
  "Sironko",
  "Saddle Brook",
  "Lamphun",
  "Collingswood",
  "Chinsali",
  "Whitefish Bay",
  "Kinkala",
  "Roselle Park",
  "Bexley",
  "Harper Woods",
  "Ang Thong",
  "Princeton Meadows",
  "Saint Ann’s Bay",
  "Pedreiras",
  "Grover Beach",
  "Carpinteria",
  "Ocean Pointe",
  "Gladeview",
  "Kemp Mill",
  "La Grange Park",
  "Chitipa",
  "Faya",
  "Luganville",
  "Mariental",
  "Key Biscayne",
  "Eisenstadt",
  "Waimalu",
  "Ridgefield Park",
  "Manatí",
  "Coatesville",
  "Guerrero Negro",
  "Menongue",
  "University Heights",
  "New Carrollton",
  "Kumi",
  "Sugarland Run",
  "Kisoro",
  "Obo",
  "Liestal",
  "Vicente Guerrero",
  "Wilton Manors",
  "Kamuli",
  "Federal Heights",
  "Bubanza",
  "Ziniaré",
  "Garbahaarrey",
  "Tovuz",
  "Isla Mujeres",
  "Patchogue",
  "North Merrick",
  "Oshikango",
  "Somerville",
  "Forécariah",
  "Cherry Creek",
  "Aldama",
  "South Miami",
  "Hasbrouck Heights",
  "Westwood Lakes",
  "Ermoúpoli",
  "Haverstraw",
  "Qalāt",
  "Alum Rock",
  "Dikhil",
  "North Wantagh",
  "Home Gardens",
  "Dundo",
  "Robertsport",
  "Clawson",
  "Santa Rosalía",
  "Balancán",
  "Freehold",
  "Wetaskiwin",
  "Haapsalu",
  "Wallington",
  "Minnehaha",
  "Ndélé",
  "Emeryville",
  "River Edge",
  "Guttenberg",
  "Alotau",
  "Signal Hill",
  "Waipio",
  "Bartica",
  "Yeadon",
  "Citrus",
  "Coamo",
  "Fort Liberté",
  "Sanniquellie",
  "Ḩadībū",
  "Mwanza",
  "Las Tablas",
  "View Park-Windsor Hills",
  "Steinkjer",
  "Loreto",
  "Vanimo",
  "El Fuerte",
  "Villanueva",
  "Mpigi",
  "Little Ferry",
  "Biltine",
  "Suffern",
  "Rossmoor",
  "North Lindenhurst",
  "Naranja",
  "Bolama",
  "Dangriga",
  "Coalcomán de Vázquez Pallares",
  "Karuzi",
  "Darby",
  "La Riviera",
  "Kavaratti",
  "Jacinto City",
  "Timberlane",
  "Ewo",
  "West Perrine",
  "Del Aire",
  "Valparaíso",
  "East Whittier",
  "Coral Hills",
  "Cacheu",
  "Filadelfia",
  "Bound Brook",
  "Ntcheu",
  "West Haverstraw",
  "Canatlán",
  "Orange Cove",
  "Anadyr’",
  "Great Neck",
  "Sukhothai",
  "Ventnor City",
  "Puerto Carreño",
  "Maili",
  "Capitola",
  "Birao",
  "Ondjiva",
  "Watervliet",
  "Greymouth",
  "Tarīn Kōṯ",
  "New Hyde Park",
  "Lincoln Village",
  "Catió",
  "Ayutla de los Libres",
  "Bocas del Toro",
  "East Rockaway",
  "Maywood",
  "Pájaros",
  "North Kensington",
  "Keansburg",
  "Marina del Rey",
  "Tshabong",
  "Phangnga",
  "Lake Stickney",
  "Fort William",
  "Djambala",
  "Trujillo",
  "Sabana Grande",
  "Brookdale",
  "Charter Oak",
  "Bladensburg",
  "Woodlyn",
  "Sarnen",
  "Kundiawa",
  "Weyburn",
  "San Pedro de Ycuamandiyú",
  "Aiea",
  "Ahumada",
  "Leisure World",
  "Westgate",
  "Leonia",
  "Kasane",
  "Plainedge",
  "Halachó",
  "Porto Nacional",
  "Mae Hong Son",
  "Farmingdale",
  "Inírida",
  "Stony Brook University",
  "Ciudad Tula",
  "Novo Airão",
  "Wood-Ridge",
  "Baabda",
  "Boulder Hill",
  "South San Gabriel",
  "Sinop",
  "Cuencamé de Ceniceros",
  "South Amboy",
  "Midway City",
  "Mokhotlong",
  "Collingdale",
  "Nueva Ocotepeque",
  "Saynshand",
  "Tocantinópolis",
  "Swissvale",
  "West Athens",
  "Aibonito",
  "Altdorf",
  "Gədəbəy",
  "Luba",
  "Malverne",
  "Bogota",
  "Harwood Heights",
  "Kirkwall",
  "Seven Corners",
  "Greenbriar",
  "Haledon",
  "Evinayong",
  "New Square",
  "Katakwi",
  "Aleg",
  "Dormont",
  "Four Corners",
  "Brokopondo",
  "Garden City Park",
  "North Bay Village",
  "Cheviot",
  "Temple Hills",
  "Yeghegnadzor",
  "Mount Rainier",
  "N’dalatando",
  "West Miami",
  "Ewa Villages",
  "Sabana Seca",
  "Uliastay",
  "Conshohocken",
  "Rio Bravo",
  "Broadview Park",
  "Illizi",
  "Sen Monorom",
  "August",
  "Gracias",
  "Port Maria",
  "San Juan Bautista",
  "Fanwood",
  "Grandview Heights",
  "Calumet Park",
  "Luquillo",
  "Gurabo",
  "Glen Ridge",
  "Hillcrest",
  "Castillos",
  "Glenside",
  "Bentiu",
  "Holtville",
  "Roatán",
  "Montpelier",
  "Stans",
  "Waikele",
  "Alondra Park",
  "Hatboro",
  "Dunellen",
  "Maḩmūd-e Rāqī",
  "Williston Park",
  "Juncos",
  "Baldwin Harbor",
  "Goranboy",
  "Boyes Hot Springs",
  "Twin Rivers",
  "East Franklin",
  "Sorø",
  "Glenolden",
  "Orland Hills",
  "Nyack",
  "Ridley Park",
  "West Samoset",
  "Great Neck Plaza",
  "Souderton",
  "South Monrovia Island",
  "Las Piedras",
  "Lascano",
  "San Isidro",
  "South Valley Stream",
  "Nakasongola",
  "Yabucoa",
  "Oğuz",
  "Forest Glen",
  "Rosário",
  "Farim",
  "Cedarhurst",
  "Magugpo Poblacion",
  "Notre Dame",
  "Manorhaven",
  "Clifton Heights",
  "Castroville",
  "Folcroft",
  "West View",
  "Lerwick",
  "Cankuzo",
  "Mongomo",
  "Bronxville",
  "Lauderdale-by-the-Sea",
  "Ambler",
  "Contra Costa Centre",
  "Pleasure Point",
  "Ban Houayxay",
  "Ghanzi",
  "Kingston Estates",
  "Lucea",
  "Hewlett",
  "Diekirch",
  "Binghamton University",
  "Saranap",
  "Glenarden",
  "Kennett Square",
  "Larchmont",
  "Gizo",
  "Siteki",
  "Iqaluit",
  "Naples Manor",
  "Kirundo",
  "Fruitridge Pocket",
  "Jamesburg",
  "Puerto Real",
  "Santa Isabel",
  "Puerto Baquerizo Moreno",
  "District Heights",
  "Bay Harbor Islands",
  "Phôngsali",
  "Prospect Park",
  "Campanilla",
  "McKees Rocks",
  "Arroyo",
  "Monaghan",
  "Mitú",
  "Meadow Lake",
  "Cutler",
  "Surfside",
  "Mayflower Village",
  "Lorengau",
  "Ceiba",
  "Penn Wynne",
  "Sharon Hill",
  "Naval Academy",
  "Belmar",
  "Glarus",
  "Rochelle Park",
  "Appenzell",
  "Kerema",
  "Añasco",
  "South Tucson",
  "Palm Springs North",
  "Paradise Hills",
  "Shaw Heights",
  "Salavan",
  "Caazapá",
  "Waipio Acres",
  "Bret Harte",
  "Media",
  "Kaser",
  "Peñuelas",
  "Highwood",
  "La Esperanza",
  "South Highpoint",
  "Albertson",
  "Shillington",
  "Sisimiut",
  "Turtle Creek",
  "Kibale",
  "Kalangala",
  "Tara Hills",
  "Vadsø",
  "Mechanicville",
  "Mattawa",
  "South Pasadena",
  "Teyateyaneng",
  "Hartsdale",
  "Broadmoor",
  "Kenneth City",
  "Friendship Heights Village",
  "Wangdue Phodrang",
  "Punakha",
  "Nieuw Amsterdam",
  "Ros Comáin",
  "Opuwo",
  "Puerto Lempira",
  "Klaksvík",
  "Aqtaū",
  "Ilulissat",
  "Al Qunayţirah",
  "Attapu",
  "Black River",
  "Bensonville",
  "Xinguara",
  "Grevenmacher",
  "Wabag",
  "Esperanza",
  "Janjanbureh",
  "Kaberamaido",
  "Bol",
  "Louang Namtha",
  "Qaqortoq",
  "Groningen",
  "Qal‘ah-ye Now",
  "Mandalgovĭ",
  "Mabaruma",
  "Kerewan",
  "Barclayville",
  "Cestos City",
  "Garoowe",
  "Fuerte Olimpo",
  "Yuscarán",
  "Onverwacht",
  "Marka",
  "La Palma",
  "Al Bayḑā’",
  "Totness",
  "Xuddur",
  "Chiradzulu",
  "Geita",
  "Bu’aale",
  "Aioun",
  "Machinga",
  "Kaabong",
  "Fulin",
  "Soroti",
  "Lata",
  "Sélibaby",
  "Fada",
  "Dhuusamarreeb",
  "Moroto",
  "Akjoujt",
  "Lethem",
  "Waitangi",
  "Kenge",
  "Gingoog",
  "Gaigirgordub",
  "Antipolo",
  "Navotas",
  "Mandaluyong City",
  "Mandaue City",
  "Pasig City",
  "Un’goofaaru",
  "Banqiao",
  "Caloocan City",
  "Valenzuela",
  "City of Parañaque",
  "Lucena",
  "Masbate",
  "Hackney",
  "Tandag",
  "Camden Town",
  "Islington",
  "Malabon",
  "Naifaru",
  "Hammersmith",
  "East Ham",
  "Oroquieta",
  "Camberwell",
  "Walthamstow",
  "Đà Nẵng",
  "Catarman",
  "City of Westminster",
  "Lambeth",
  "Catford",
  "Hove",
  "La Trinidad",
  "Marawi City",
  "Taguig City",
  "Sliema",
  "Lapu-Lapu City",
  "Ealing",
  "Ikeja",
  "Poplar",
  "Balanga",
  "Dumaguete City",
  "Marikina City",
  "Santo Domingo Este",
  "Sorsogon",
  "Kalibo",
  "Ilford",
  "Wandsworth",
  "Kidapawan",
  "Fgura",
  "Birkirkara",
  "Wood Green",
  "Ħamrun",
  "Koboko",
  "Arafat",
  "Daet",
  "Virac",
  "Senglea",
  "Mahibadhoo",
  "South Shields",
  "Eydhafushi",
  "Bắc Ninh",
  "Maidstone",
  "Bayombong",
  "Tarxien",
  "Jolo",
  "Bongao",
  "Piran",
  "Morden",
  "Barnsley",
  "Ben Arous",
  "Floriana",
  "Grays",
  "Imsida",
  "Wembley",
  "Weston-super-Mare",
  "Tevragh Zeina",
  "Santa Venera",
  "Saitama",
  "Ashton-under-Lyne",
  "Pietà",
  "Ta’ Xbiex",
  "Barri",
  "Stretford",
  "Ibb",
  "Kingston upon Thames",
  "Hendon",
  "Doncaster",
  "Romford",
  "Gżira",
  "Calapan",
  "Manouba",
  "Kanifing",
  "South Bank",
  "Tagum",
  "Muntinlupa City",
  "Kiambu",
  "Worcester",
  "Catbalogan",
  "Stockport",
  "Paisley",
  "Uppsala",
  "Digos",
  "Béni Mellal",
  "Hưng Yên",
  "Wallasey",
  "Oldham",
  "Twickenham",
  "Preston",
  "Harrow",
  "Rotherham",
  "Dorchester",
  "San Ġwann",
  "Port Talbot",
  "Rumphi",
  "Makati City",
  "Isulan",
  "Hounslow",
  "Pont-y-pŵl",
  "Forfar",
  "Trece Martires City",
  "Boac",
  "Janakpur",
  "San Ġiljan",
  "Tubod",
  "Mamburao",
  "Khenchela",
  "Widnes",
  "Dunleary",
  "Nabunturan",
  "Bugiri",
  "Wigan",
  "Malita",
  "Gambēla",
  "Maasin",
  "Glenrothes",
  "Rochdale",
  "Vittoriosa",
  "Xırdalan",
  "Chiba",
  "Aylesbury",
  "Balzan",
  "Wokingham",
  "Thinadhoo",
  "Truro",
  "Funadhoo",
  "Bangued",
  "Simferopol’",
  "Thornbury",
  "Bracknell",
  "Busia",
  "Trowbridge",
  "Morpeth",
  "Milton Keynes",
  "La Chorrera",
  "Caerfyrddin",
  "Mambajao",
  "Kitui",
  "Qormi",
  "Dalkeith",
  "Kulhudhuffushi",
  "Naval",
  "Scunthorpe",
  "Kolonia",
  "Motherwell",
  "Dipolog",
  "Kampong Thom",
  "Dhihdhoo",
  "Balaka",
  "Mosta",
  "Hartlepool",
  "Kudahuvadhoo",
  "Kapchorwa",
  "Arima",
  "Merthyr Tudful",
  "Rasdhoo",
  "Salford",
  "Hertford",
  "Koper",
  "Żabbar",
  "Caernarfon",
  "Walsall",
  "Al Wakrah",
  "Tallaght",
  "Swieqi",
  "Ballymena",
  "Škofja Loka",
  "Hwlffordd",
  "Iba",
  "Wrecsam",
  "Magherafelt",
  "Bundibugyo",
  "Ta Khmau",
  "Yoro",
  "Malolos",
  "Boumerdes",
  "Domžale",
  "Belfast",
  "Kraljevo",
  "Lingayen",
  "Lisburn",
  "Banlung",
  "Pen-y-Bont ar Ogwr",
  "Phủ Lý",
  "Wicklow",
  "Kalkara",
  "Cospicua",
  "Thyolo",
  "Newry",
  "Strabane",
  "Romblon",
  "Dagenham",
  "Ravne na Koroškem",
  "Thulusdhoo",
  "Northallerton",
  "Clonmel",
  "Leskovac",
  "Baalbek",
  "Wexford",
  "Bromley",
  "Marsaskala",
  "Isiolo",
  "Viligili",
  "Downpatrick",
  "Užice",
  "Alabel",
  "Antrim",
  "Esch-sur-Alzette",
  "Lija",
  "Izola",
  "Bariadi",
  "Hoima",
  "Baler",
  "Huddersfield",
  "Żejtun",
  "Velenje",
  "Poole",
  "Homa Bay",
  "Kabarnet",
  "Jagodina",
  "Mukono",
  "Bindura",
  "Oshakati",
  "Mandera",
  "Bexleyheath",
  "Gateshead",
  "Migori",
  "Naas",
  "Kerugoya",
  "Siquijor",
  "Yumbe",
  "Fontana",
  "Butwāl",
  "Sihanoukville",
  "Banbridge",
  "Buchanan",
  "Xgħajra",
  "Toruń",
  "Hithadhoo",
  "Newtownabbey",
  "Iklin",
  "Alloa",
  "Slovenske Konjice",
  "Vranje",
  "Solothurn",
  "Chichester",
  "Vĩnh Yên",
  "Dar Naim",
  "Borongan",
  "Iten",
  "Stirling",
  "Veymandoo",
  "Al Maḩwīt",
  "Mežica",
  "Phalombe",
  "Usk",
  "Nyamira",
  "Blackburn",
  "Valjevo",
  "Wallsend",
  "Mold",
  "Swords",
  "Auki",
  "Celje",
  "Cookstown",
  "Kirkop",
  "Felidhoo",
  "Trim",
  "Hugh Town",
  "Kapsabet",
  "Gorzów Wielkopolski",
  "Šabac",
  "Nilandhoo",
  "Kirakira",
  "Kamnik",
  "Baucau",
  "Radlje ob Dravi",
  "Carrick on Shannon",
  "Attard",
  "Solihull",
  "Newtown Saint Boswells",
  "Clydach Vale",
  "Chaguanas",
  "Rapla",
  "Marsa",
  "Foammulah",
  "Armagh",
  "Tullamore",
  "City of Isabela",
  "Valmiera",
  "Kranj",
  "Dungannon",
  "Fomboni",
  "Coleraine",
  "Conwy",
  "Grosuplje",
  "Kurunegala",
  "Murang’a",
  "Bududa",
  "Lagawe",
  "Craigavon",
  "‘Ajmān",
  "Għargħur",
  "Rakvere",
  "Alytus",
  "Zagorje ob Savi",
  "Carlow",
  "Slovenj Gradec",
  "Mersin",
  "Sotik Post",
  "Gudja",
  "Dravograd",
  "Vreed-en-Hoop",
  "Escaldes-Engordany",
  "Jõgeva",
  "Gornji Milanovac",
  "Relizane",
  "Žalec",
  "Rhuthun",
  "Malaybalay",
  "Litija",
  "Bajina Bašta",
  "Imqabba",
  "Wolverhampton",
  "Tam Kỳ",
  "Saint Paul’s Bay",
  "Mullingar",
  "Krško",
  "Bà Rịa",
  "Jesenice",
  "Radovljica",
  "Santa Cruz de la Sierra",
  "Marsaxlokk",
  "Prevalje",
  "Outapi",
  "Kuressaare",
  "Taro",
  "Kozáni",
  "Għaxaq",
  "Birżebbuġa",
  "Żurrieq",
  "Naxxar",
  "Sopište",
  "Trbovlje",
  "Mahikeng",
  "Jēkabpils",
  "Longford",
  "Muli",
  "Ar Rayyān",
  "Wakiso",
  "Ivančna Gorica",
  "Bury",
  "Rogaška Slatina",
  "Lyantonde",
  "Giffnock",
  "Ipil",
  "Aïn Temouchent",
  "Areguá",
  "Serravalle",
  "Võru",
  "‘Amrān",
  "Halba",
  "Marondera",
  "Castlebar",
  "Põlva",
  "Nenagh",
  "Laško",
  "Palayan City",
  "Bender",
  "Chikwawa",
  "Oakham",
  "Ilagan",
  "Dunaújváros",
  "Kasungu",
  "Safi",
  "Labuan",
  "Yalova",
  "Oldbury",
  "Ruše",
  "Imtarfa",
  "Picos",
  "Kerċem",
  "An Cabhán",
  "Mariehamn",
  "Nova Gorica",
  "Fonadhoo",
  "Žirovnica",
  "Manadhoo",
  "Ponta do Sol",
  "Ribeira Grande",
  "Krupanj",
  "Lebane",
  "Štore",
  "Siaya",
  "Aleksinac",
  "Cerklje na Gorenjskem",
  "Isale",
  "Šoštanj",
  "Điện Biên Phủ",
  "P’yŏngsŏng",
  "Luqa",
  "Basse-Terre",
  "Birendranagar",
  "Diego Martin",
  "Vladičin Han",
  "Knjaževac",
  "Basco",
  "Murska Sobota",
  "Ljubovija",
  "Slovenska Bistrica",
  "Smederevska Palanka",
  "Qrendi",
  "Gornja Radgona",
  "Karlovy Vary",
  "Mahonda",
  "Mali Zvornik",
  "Point Fortin",
  "Novo Mesto",
  "Paide",
  "Surdulica",
  "Požarevac",
  "Mayuge",
  "Varaždin",
  "Ptuj",
  "Llandrindod Wells",
  "Shariff Aguak",
  "Nitra",
  "Gleno",
  "Zaječar",
  "Llangefni",
  "Šentjur",
  "Al Khawr",
  "Stornoway",
  "Dowa",
  "Port Laoise",
  "Maliana",
  "Aïn Defla",
  "Enniskillen",
  "Mādabā",
  "Rwamagana",
  "Cabarroguis",
  "Asaba",
  "Prebold",
  "Tulagi",
  "Miklavž na Dravskem Polju",
  "Aranguez",
  "Šempeter pri Gorici",
  "Mozirje",
  "Šmartno ob Paki",
  "Rumonge",
  "Trenčín",
  "Trebnje",
  "Bor",
  "Yambol",
  "Loznica",
  "Paraćin",
  "Tržič",
  "Jūrmala",
  "Bulambuli",
  "Borgo Maggiore",
  "Maracha",
  "Luwero",
  "Kapenguria",
  "Jarash",
  "Polzela",
  "Yenagoa",
  "Nangan",
  "Kärdla",
  "Namayingo",
  "Xewkija",
  "Ystrad Mynach",
  "Selibe Phikwe",
  "Kičevo",
  "Lučani",
  "Knowsley",
  "Valga",
  "Brežice",
  "Suong",
  "Għasri",
  "Kočevje",
  "Smederevo",
  "Đồng Xoài",
  "Kruševac",
  "Hrastnik",
  "Mila",
  "Al Buraymī",
  "Hunedoara",
  "Bontoc",
  "Đà Lạt",
  "Luuka Town",
  "Dingli",
  "Kabugao",
  "Ankaran",
  "Lendava",
  "Vị Thanh",
  "Spodnje Hoče",
  "Selnica ob Dravi",
  "Despotovac",
  "Weno",
  "Mauren",
  "Jincheng",
  "Šmartno pri Litiji",
  "Saga",
  "Mamushë",
  "Liquiçá",
  "Magas",
  "Osmaniye",
  "Cibitoke",
  "Kuala Belait",
  "Kibuku",
  "Bupoto",
  "Xagħra",
  "Lenart v Slovenskih Goricah",
  "Lai Châu",
  "Manatuto",
  "Neno",
  "Moravče",
  "Apac",
  "Imdina",
  "Sevastopol’",
  "Kladovo",
  "Düzce",
  "Kanal",
  "Sankt Pölten",
  "Mwaro",
  "Nadur",
  "Nazarje",
  "Mengeš",
  "Pančevo",
  "Brezovica",
  "Zreče",
  "Preddvor",
  "Rubirizi",
  "Vuzenica",
  "Vwawa",
  "Munxar",
  "Vrhnika",
  "Pirot",
  "Domagnano",
  "Sevnica",
  "Poljčane",
  "Vlasotince",
  "Prosperidad",
  "Koani",
  "Žiri",
  "Šmarje pri Jelšah",
  "Komenda",
  "Metlika",
  "Tipasa",
  "Sannat",
  "Vojnik",
  "Medvode",
  "Puerto Francisco de Orellana",
  "Škofljica",
  "Remich",
  "Buala",
  "Cabadbaran",
  "Buikwe",
  "Kagadi",
  "Muta",
  "Radenci",
  "Eschen",
  "Novobërdë",
  "Graçanicë",
  "Bled",
  "Spodnji Duplek",
  "Trstenik",
  "Bushenyi",
  "Schellenberg",
  "Acquaviva",
  "Siġġiewi",
  "Kon Tum",
  "Rabat",
  "Ash Shīḩānīyah",
  "Borovnica",
  "Kaliro",
  "Tunapuna",
  "Ljutomer",
  "Aračinovo",
  "Idrija",
  "Log pri Brezovici",
  "Naklo",
  "Prokuplje",
  "Tissemsilt",
  "Batočina",
  "Zinjibār",
  "Afega",
  "Mellieħa",
  "Trindade",
  "Sopron",
  "Umm Şalāl ‘Alī",
  "Al Bāḩah",
  "La Guaira",
  "Vrnjačka Banja",
  "Aileu",
  "Oplotnica",
  "Fiorentino",
  "Butebo",
  "Namutumba",
  "Debe",
  "Saint-Louis",
  "Miragoâne",
  "Ormož",
  "Dogbo",
  "Abakaliki",
  "Manafwa",
  "Dimitrovgrad",
  "Čakovec",
  "Šenčur",
  "Ibanda",
  "Aasiaat",
  "Mirna",
  "Rače",
  "Lelystad",
  "Črnomelj",
  "Wote",
  "Ferizaj",
  "Mwatate",
  "Vitanje",
  "Sremski Karlovci",
  "Doljevac",
  "Rukungiri",
  "Għajnsielem",
  "Lifford",
  "Isangel",
  "Koprivnica",
  "Toftir",
  "Maidenhead",
  "Gombe",
  "Kanjiža",
  "Šentjernej",
  "Palé",
  "Santo Domingo de los Colorados",
  "Khōst",
  "Kyenjojo",
  "Chicksands",
  "‘Ajlūn",
  "Velika Plana",
  "Az̧ Z̧a‘āyin",
  "Qala",
  "Prizren",
  "Al Jabīn",
  "Montegiardino",
  "Mitoma",
  "Ebbw Vale",
  "Nagykanizsa",
  "Kalungu",
  "Brvenica",
  "Svilajnac",
  "Ainaro",
  "Vukovar",
  "Aḑ Ḑāli‘",
  "Santa Luċija",
  "Slough",
  "Majšperk",
  "Bukomansimbi",
  "Svrljig",
  "Horjul",
  "Postojna",
  "Luče",
  "Spodnja Hajdina",
  "Nelspruit",
  "Šid",
  "Negotin",
  "Grimsby",
  "Kajiado",
  "Telford",
  "Hola",
  "Rubanda",
  "Kasaali",
  "Zgornje Gorje",
  "Dapaong",
  "Pivka",
  "Braslovče",
  "Masallı",
  "Butaleja",
  "Zugdidi",
  "Sakété",
  "Tivat",
  "Lovrenc na Pohorju",
  "Ruggell",
  "Pesnica pri Mariboru",
  "Serere",
  "Cerknica",
  "Amuria",
  "Gamprin",
  "Bojnik",
  "Kibiito",
  "Kibingo",
  "Capellen",
  "Vodice",
  "Bukedea",
  "Dobrna",
  "Nakaseke",
  "El Tarf",
  "Mitrovicë",
  "Samraong",
  "Wiltz",
  "Binyin",
  "Šentilj",
  "Kaédi",
  "Sārī",
  "Vransko",
  "Vršac",
  "Lubango",
  "Šentrupert",
  "Mparo",
  "Odranci",
  "Mati",
  "Tottori",
  "Ol Kalou",
  "Logatec",
  "Kole",
  "Couva",
  "Partesh",
  "Żebbuġ",
  "Bohinjska Bistrica",
  "Zombo",
  "Narok",
  "Rakai",
  "Schaan",
  "Encamp",
  "Ajdovščina",
  "Rajin",
  "Lwengo",
  "Trzin",
  "Heydərabad",
  "Gorišnica",
  "Imġarr",
  "Bukwa",
  "Omuthiya",
  "Volčja Draga",
  "Salaspils",
  "Gllogovc",
  "Kikinda",
  "Dokolo",
  "Dornava",
  "Astara",
  "Rahovec",
  "Leulumoega",
  "Koronadal",
  "Beltinci",
  "Topola",
  "Sokobanja",
  "Bogovinje",
  "Balzers",
  "Fushë Kosovë",
  "Obiliq",
  "Kamwenge",
  "Shtime",
  "Swindon",
  "Bela Crkva",
  "Železniki",
  "Ngora",
  "Echternach",
  "Kara",
  "Dol pri Ljubljani",
  "Kumanovo",
  "Ig",
  "Vushtrri",
  "Falkirk",
  "Sežana",
  "Techiman",
  "Guadalupe",
  "Alebtong",
  "Visaginas",
  "Brčko",
  "Gorenja Vas",
  "Pante Macassar",
  "Bjelovar",
  "Għarb",
  "Cerkno",
  "Suai",
  "Sandbach",
  "Lospalos",
  "Armavir",
  "Stari Trg pri Ložu",
  "Chiesanuova",
  "Sveti Jurij ob Ščavnici",
  "Rečica ob Savinji",
  "Malishevë",
  "Pombas",
  "Videm",
  "Kanungu",
  "Ljubno",
  "Ulbroka",
  "Straža",
  "Kanoni",
  "Kokopo",
  "Senta",
  "Sejong",
  "Tišina",
  "Ramotswa",
  "Mulifanua",
  "Šmarješke Toplice",
  "Al ‘Azīzīyah",
  "Vrapčište",
  "Kidričevo",
  "Semič",
  "Oyam",
  "Požega",
  "Ilinden",
  "Starše",
  "Bačka Palanka",
  "Dobrovo",
  "Turnišče",
  "Yamaguchi",
  "Gia Nghĩa",
  "Blace",
  "Lipjan",
  "Gostivar",
  "La Massana",
  "Tabor",
  "Saltangará",
  "Suharekë",
  "Nueva Loja",
  "Križevci pri Ljutomeru",
  "Strumica",
  "Siparia",
  "Tutong",
  "Viti",
  "Kllokot",
  "Gjakovë",
  "Sembabule",
  "Vianden",
  "Tsirang",
  "Kakumiro",
  "Maīdān Shahr",
  "Pailin",
  "Petrovec",
  "Sveta Trojica v Slovenskih Goricah",
  "Pejë",
  "Sveta Ana v Slovenskih Goricah",
  "Tolmin",
  "Pazardzhik",
  "Rabak",
  "Princes Town",
  "Tarrafal",
  "Triesen",
  "Benedikt v Slovenskih Goricah",
  "Studeničani",
  "Krong Kep",
  "Sant Julià de Lòria",
  "Palanga",
  "Faetano",
  "Buyende",
  "João Teves",
  "Santana",
  "Kwale",
  "Mirna Peč",
  "Isingiro",
  "Apatin",
  "Mārupe",
  "Novi Pazar",
  "Kyegegwa",
  "San Lawrenz",
  "Radeče",
  "Buhweju",
  "Kaçanik",
  "Debar",
  "Sefwi Wiawso",
  "Hani i Elezit",
  "Matlock",
  "Mersch",
  "Bərdə",
  "Budva",
  "Ucar",
  "Sombor",
  "Odžaci",
  "Hongseong",
  "Destrnik",
  "Strendur",
  "Tearce",
  "Tuzi",
  "Sarpsborg",
  "Veržej",
  "Zgornja Kungota",
  "Ćuprija",
  "Muan",
  "Jegunovce",
  "Črenšovci",
  "Kobarid",
  "Želino",
  "Ohrid",
  "Skenderaj",
  "Saratamata",
  "Deçan",
  "Markovci",
  "Rumuruti",
  "Kathwana",
  "Arilje",
  "Kovin",
  "Boljevac",
  "Nkurenkuru",
  "Podujevë",
  "Klinë",
  "Cəlilabad",
  "San José de las Lajas",
  "Struga",
  "Vipava",
  "Ilirska Bistrica",
  "Saulkrasti",
  "Žužemberk",
  "Ialoveni",
  "Phôn-Hông",
  "Batken",
  "Kosjerić",
  "Veles",
  "Tvøroyri",
  "Dobrova",
  "Perm’",
  "Amolatar",
  "Čaška",
  "Lelydorp",
  "Velike Lašče",
  "Puconci",
  "Mutsamudu",
  "Morant Bay",
  "Škocjan",
  "Şəmkir",
  "Naftalan",
  "Kardzhali",
  "Mukdahan",
  "Ozurgeti",
  "Bačka Topola",
  "Nong Bua Lamphu",
  "Dolenjske Toplice",
  "Drochia",
  "Ćićevac",
  "Mokronog",
  "Herceg-Novi",
  "Plandište",
  "Rogašovci",
  "Virovitica",
  "Kozina",
  "Cankova",
  "Velika Polana",
  "Središče ob Dravi",
  "Lamwo",
  "Eenhana",
  "Hrazdan",
  "Xaçmaz",
  "Bangolo",
  "Straşeni",
  "Jõhvi",
  "Quinhámel",
  "Bogatić",
  "Lipkovo",
  "Balakən",
  "Qazax",
  "Varvarin",
  "Vidin",
  "Kočani",
  "Obleševo",
  "Santa Elena",
  "Saint Helens",
  "Ražanj",
  "Cēsis",
  "San Jose",
  "Mongar",
  "Godāwari̇̄",
  "Anenii Noi",
  "Blagoevgrad",
  "Bartın",
  "Sabirabad",
  "Mionica",
  "Kurumul",
  "Junik",
  "Soroca",
  "Sisak",
  "Aranđelovac",
  "Gabrovo",
  "Nova Vas",
  "Apače",
  "Cerkvenjak",
  "Lukovica pri Domžalah",
  "Haa",
  "Krapina",
  "Palenga",
  "Singa",
  "Novi Bečej",
  "Bečej",
  "Şərur",
  "Juršinci",
  "Aizkraukle",
  "Port Loko",
  "Hînceşti",
  "Famagusta",
  "Kyrenia",
  "Orhei",
  "Gornji Grad",
  "Žitorađa",
  "Mingəçevir",
  "Amnat Charoen",
  "Videm pri Ptuju",
  "Kotido",
  "Titel",
  "Pul-e ‘Alam",
  "Laçın",
  "Cirkulane",
  "Ungheni",
  "Bar",
  "Ribnica",
  "Miren",
  "Sharan",
  "Tari",
  "Iğdır",
  "Istog",
  "Planken",
  "Cocieri",
  "Pader",
  "Merošina",
  "Fuglafjørður",
  "Triesenberg",
  "Călăraşi",
  "Zaqatala",
  "Lufilufi",
  "Floreşti",
  "Podlehnik",
  "Bovec",
  "Kotor",
  "Stara Pazova",
  "Calheta de São Miguel",
  "Carnikava",
  "Viqueque",
  "Clervaux",
  "Redange-sur-Attert",
  "São Domingos",
  "Bosilovo",
  "Goaso",
  "Vevčani",
  "Hódmezővásárhely",
  "Ağdaş",
  "Sîngerei",
  "Bimbo",
  "Štip",
  "Dambai",
  "Zavrč",
  "Yardımlı",
  "Igreja",
  "Saatlı",
  "Kaffrine",
  "Ribnica na Pohorju",
  "Beyləqan",
  "Criuleni",
  "Briceni",
  "Ķekava",
  "Tomaž pri Ormožu",
  "Sangre Grande",
  "Edineţ",
  "Beočin",
  "Nhlangano",
  "Ordino",
  "Targovishte",
  "Bosilegrad",
  "Knić",
  "Kamenicë",
  "Kostanjevica na Krki",
  "Canillo",
  "Agago",
  "Fort Wellington",
  "Vitomarci",
  "Nova Sintra",
  "Sa Kaeo",
  "Şuḩār",
  "Komen",
  "Tərtər",
  "Sečanj",
  "Bački Petrovac",
  "Füzuli",
  "Teleneşti",
  "Cimişlia",
  "Marijampolė",
  "Făleşti",
  "Stockton-on-Tees",
  "Nalerigu",
  "Kuzma",
  "Divača",
  "Inčukalns",
  "Sar-e Pul",
  "Assomada",
  "Ocniţa",
  "Ağcabədi",
  "Kozje",
  "Nīlī",
  "Bogdanci",
  "Bulisa",
  "Phônsavan",
  "Plasnica",
  "Amudat",
  "Jurovski Dol",
  "Lerik",
  "Glodeni",
  "Bueng Kan",
  "Kovačica",
  "Pakxan",
  "Čoka",
  "Ikšķile",
  "Aksaray",
  "Dobje pri Planini",
  "Şoldăneşti",
  "Gornji Petrovci",
  "Samdrup Jongkhar",
  "Dragash",
  "Ağsu",
  "Moravske-Toplice",
  "Pedra Badejo",
  "Podvelka",
  "Olaine",
  "Sarpang",
  "Biləsuvar",
  "Nisporeni",
  "Makole",
  "Basarabeasca",
  "Mtskheta",
  "Trashigang",
  "Ādaži",
  "Leova",
  "Zərdab",
  "Qəbələ",
  "Kürdəmir",
  "Comrat",
  "Žabalj",
  "Ozolnieki",
  "Neves",
  "Aberaeron",
  "Medveđa",
  "Căuşeni",
  "Napak",
  "Vágur",
  "Prilep",
  "Ulcinj",
  "Vrbas",
  "Ağstafa",
  "Pemagatshel",
  "Cova Figueira",
  "Žitište",
  "Qusar",
  "Quba",
  "İmişli",
  "Zəngilan",
  "Sofifi",
  "Taraclia",
  "Razkrižje",
  "Rustavi",
  "Gori",
  "Neftçala",
  "Rožaje",
  "Čajetina",
  "Rezina",
  "Krivogaštani",
  "Rogatec",
  "Qacha’s Nek",
  "Sremska Mitrovica",
  "Ştefan Vodă",
  "Safotulafai",
  "Ivanjica",
  "Kuacjok",
  "Sédhiou",
  "Göygöl",
  "Črna na Koroškem",
  "Tubmanburg",
  "Inđija",
  "Rîşcani",
  "Vasilevo",
  "Žabari",
  "Zveçan",
  "Ed Daein",
  "Telavi",
  "Radoviš",
  "Tabuk",
  "Cantemir",
  "Osilnica",
  "Petrovac na Mlavi",
  "Pazin",
  "Savalou",
  "Elektrėnai",
  "Mali Iđoš",
  "Centar Župa",
  "Ub",
  "Rača",
  "Srbobran",
  "Ljig",
  "Porto Inglês",
  "Ranillug",
  "Kiryandongo",
  "Rekovac",
  "Skopun",
  "Mamuju",
  "Hargeysa",
  "Kiruhura",
  "Leava",
  "Garkalne",
  "Qubadlı",
  "Zrnovci",
  "Novo Selo",
  "Gevgelija",
  "Novi Kneževac",
  "Torit",
  "Lielvārde",
  "Cəbrayıl",
  "Sigulda",
  "Akhaltsikhe",
  "Tsimasham",
  "Lajkovac",
  "Kruševo",
  "Priboj",
  "Bijelo Polje",
  "Madīnat ash Shamāl",
  "Bāzārak",
  "Grad",
  "Probištip",
  "Aleksandrovac",
  "Berane",
  "Petnjica",
  "Sodražica",
  "Siyəzən",
  "Samux",
  "Negotino",
  "Jonava",
  "Dobrovnik",
  "Pala",
  "Matam",
  "Abim",
  "Dalandzadgad",
  "Rio Claro",
  "Malo Crniće",
  "Piņķi",
  "Kuršumlija",
  "Ntoroko",
  "Dəvəçi",
  "Vinica",
  "Čučer-Sandevo",
  "Raška",
  "Mislinja",
  "Golubac",
  "Trnovska Vas",
  "Norðragøta",
  "Pećinci",
  "Koumra",
  "Qıvraq",
  "Ordubad",
  "Nova Crnja",
  "Druskininkai",
  "Mažeikiai",
  "Seinäjoki",
  "Bač",
  "Smolyan",
  "Podčetrtek",
  "Samamea",
  "Donduşeni",
  "Kavadarci",
  "İsmayıllı",
  "Xai",
  "Irig",
  "Kretinga",
  "Ar Rustāq",
  "Majdanpek",
  "Kriva Palanka",
  "Danilovgrad",
  "Delčevo",
  "Ogre",
  "Buka",
  "Makedonska Kamenica",
  "Satupa‘itea",
  "Otuke",
  "Ruma",
  "Samtse",
  "Skrīveri",
  "Sveti Nikole",
  "Qax",
  "Cidade Velha",
  "Asau",
  "Hacıqabul",
  "Thaba-Tseka",
  "Trakai",
  "Hrib-Loški Potok",
  "Gjilan",
  "Birštonas",
  "Nikšić",
  "Valandovo",
  "Beverley",
  "Lakatoro",
  "Irvine",
  "Bauska",
  "Utena",
  "Şūr",
  "Tauragė",
  "Daşkəsən",
  "Plungė",
  "Silistra",
  "Alibunar",
  "Dolneni",
  "Safotu",
  "Kəlbəcər",
  "Šilutė",
  "Kobilje",
  "Vladimirci",
  "Vailoa",
  "Baldone",
  "Ardahan",
  "Espargos",
  "Preiļi",
  "Vilkaviškis",
  "Kranjska Gora",
  "Amuru",
  "Iecava",
  "Şırnak",
  "Kilmarnock",
  "Rosoman",
  "Priekuļi",
  "Buabidi",
  "Plav",
  "Gusinje",
  "Kapan",
  "Shtërpcë",
  "Telšiai",
  "Kėdainiai",
  "Ribeira Brava",
  "Qobustan",
  "Ibrā’",
  "Tofol",
  "Žetale",
  "Xocalı",
  "Şuşa",
  "Tutin",
  "Zelenikovo",
  "Resen",
  "Babušnica",
  "Xocavənd",
  "Sørvágur",
  "Kaišiadorys",
  "Brus",
  "Tukums",
  "Şahbuz",
  "Dobele",
  "Hvannasund",
  "Nida",
  "Prienai",
  "Kratovo",
  "Eiði",
  "Ukmergė",
  "Akmenė",
  "Massakory",
  "Kalvarija",
  "Šalovci",
  "Osečina",
  "Ebebiyín",
  "Buba",
  "Al Ḩazm",
  "Mogila",
  "Nakapiripirit",
  "Pehčevo",
  "Khaşab",
  "Barentu",
  "Radviliškis",
  "Leposaviq",
  "Veliko Gradište",
  "Bangar",
  "Viļāni",
  "Pili",
  "Burco",
  "Kučevo",
  "Līgatne",
  "Koceljeva",
  "Oyrarbakki",
  "Arta",
  "Temerin",
  "Vestmanna",
  "Berovo",
  "Porkeri",
  "Kazlų Rūda",
  "Raseiniai",
  "Mojkovac",
  "Gadžin Han",
  "Roja",
  "Līvāni",
  "Lapovo",
  "Opovo",
  "Kyankwanzi",
  "Ropaži",
  "São João dos Angolares",
  "Hvalba",
  "Šilalė",
  "Šakiai",
  "Bayburt",
  "Šalčininkai",
  "Pljevlja",
  "Sal Rei",
  "Grobiņa",
  "Smārde",
  "Joniškis",
  "Pasvalys",
  "Kajaani",
  "Zubin Potok",
  "Skuodas",
  "Ntchisi",
  "Talsi",
  "Xékong",
  "Trashi Yangtse",
  "Širvintos",
  "Bistrica ob Sotli",
  "Goz Beïda",
  "Krāslava",
  "Pilsrundāle",
  "Sumba",
  "Mērsrags",
  "Rokiškis",
  "Jurbarkas",
  "Kvívík",
  "Tērvete",
  "Karbinci",
  "Kelmė",
  "Mālpils",
  "Demir Hisar",
  "Kupiškis",
  "Biržai",
  "Solčava",
  "Moussoro",
  "Pagėgiai",
  "Rūjiena",
  "Cetinje",
  "Koknese",
  "Saldus",
  "Pļaviņas",
  "Ragana",
  "Auce",
  "Limbaži",
  "Pakruojis",
  "Ludza",
  "Cesvaine",
  "Koné",
  "Aizpute",
  "Lazdijai",
  "Zalingei",
  "Sandavágur",
  "Hodoš",
  "Konče",
  "Rankovce",
  "Kuldīga",
  "Lozovo",
  "Švenčionys",
  "Kandava",
  "Andrijevica",
  "Smiltene",
  "Anykščiai",
  "Zarasai",
  "Molėtai",
  "Balvi",
  "Hov",
  "Salacgrīva",
  "Rietavas",
  "Ilūkste",
  "Sala",
  "Nwoya",
  "Brocēni",
  "Varakļāni",
  "Salto del Guairá",
  "Saleaula",
  "Žagubica",
  "Kocēni",
  "Trgovište",
  "Sola",
  "Jaunpils",
  "Gulbene",
  "Rostuša",
  "Ķegums",
  "Madona",
  "Gospić",
  "Rauna",
  "Semera",
  "Gradsko",
  "Ambrolauri",
  "Ignalina",
  "Priekule",
  "Demir Kapija",
  "Viðareiði",
  "Burtnieki",
  "Änew",
  "Masunga",
  "Haddington",
  "Kostel",
  "Xızı",
  "Mūrmuiža",
  "Hermansverk",
  "Prijepolje",
  "Trongsa",
  "Aglona",
  "Sandur",
  "Kirkintilloch",
  "Nīca",
  "Valka",
  "Zilupe",
  "Alūksne",
  "Aknīste",
  "Vecumnieki",
  "El Fula",
  "Zhemgang",
  "Porto Novo",
  "Damongo",
  "Strenči",
  "Loja",
  "Tanjung Selor",
  "Skrunda",
  "Lieģi",
  "Kārsava",
  "Varėna",
  "Jaunpiebalga",
  "Belčišta",
  "Tigoa",
  "Naama",
  "Lupane",
  "Riebiņi",
  "Pārūn",
  "Jaunjelgava",
  "Dagda",
  "Viļaka",
  "Aloja",
  "Sjenica",
  "Vaiņode",
  "Staro Nagoričane",
  "Ērgļi",
  "Mazsalaca",
  "Stalbe",
  "Kolašin",
  "Baki",
  "Fish Town",
  "Massenya",
  "Bopolu",
  "Vecpiebalga",
  "Anouvông",
  "Loango",
  "Alsunga",
  "Drabeši",
  "Laascaanood",
  "Vecvārkava",
  "Lubāna",
  "Navoiy",
  "Naukšēni",
  "Lochgilphead",
  "Fámjin",
  "Ape",
  "Húsavík",
  "Baltinava",
  "Žabljak",
  "Makedonski Brod",
  "Dundaga",
  "Viesīte",
  "Altay",
  "Nereta",
  "Cibla",
  "Am Djarass",
  "Pāvilosta",
  "Lhuentse",
  "Jakar",
  "Nova Varoš",
  "Ceerigaabo",
  "Skálavík",
  "Rugāji",
  "Kunoy",
  "Daga",
  "Bela Palanka",
  "Novaci",
  "Rucava",
  "Kirkja",
  "Sowa Town",
  "Zgornje Jezersko",
  "Šavnik",
  "Kidal",
  "Crna Trava",
  "Skúvoy",
  "Plužine",
  "Choyr",
  "Edinburgh of the Seven Seas",
  "Awbārī",
  "Gasa",
  "Idrī",
  "Jwaneng",
  "Mahdia",
  "Haymā’",
  "Unión Chocó",
  "Bardaï",
  "Al Kufrah",
  "Star Dojran",
  "Wé",
  "Bujanovac",
  "Preševo",
  "Albina",
  "Kitamilo",
  "São Filipe",
  "Torquay",
  "Isnā",
  "Qamdo",
  "Arlit",
  "San Angelo",
  "Tsuruoka",
  "Urgut",
  "Wichita Falls",
  "Zelënodol’sk",
  "Novoshakhtinsk",
  "Longmont",
  "Thunder Bay",
  "Bam",
  "Beichengqu",
  "Kwekwe",
  "Tuapse",
  "Talara",
  "Kontagora",
  "Manteca",
  "Votkinsk",
  "Serov",
  "Boca Raton",
  "Balashov",
  "Iseyin",
  "Flensburg",
  "Zheleznogorsk",
  "Spokane Valley",
  "Idaho Falls",
  "Beaverton",
  "Bafra",
  "Dhahran",
  "San Carlos del Zulia",
  "Prescott Valley",
  "Shangzhi",
  "Greece",
  "Federal Way",
  "Kelo",
  "Rio Rancho",
  "Tracy",
  "Aurangābād",
  "Sungai Penuh",
  "Bolzano",
  "Brockton",
  "San Carlos de Bariloche",
  "Boli",
  "Biu",
  "Hesperia",
  "Nowra",
  "Mandeville",
  "Vineland",
  "Dearborn",
  "Ponta Porã",
  "Novyy Urengoy",
  "Livonia",
  "Plantation",
  "Slidell",
  "Samandağ",
  "Manzhouli",
  "Trelew",
  "Nazran’",
  "Polatlı",
  "Bafang",
  "Kineshma",
  "Coronel",
  "Wukari",
  "Rosenheim",
  "Toowoomba",
  "Carson",
  "Terre Haute",
  "Sampit",
  "Foumban",
  "Blacksburg",
  "Fu’an",
  "Maizuru",
  "Villa María",
  "Itaituba",
  "Dhangaḍhi̇̄",
  "Ereğli",
  "Hanford",
  "Danjiangkou",
  "Edmond",
  "Bugul’ma",
  "Fishers",
  "Greenburgh",
  "Arezzo",
  "Grahamstown",
  "Toms River",
  "Novoaltaysk",
  "Lawton",
  "Mons",
  "Kendu Bay",
  "Pingdu",
  "Livermore",
  "Lüleburgaz",
  "Chauk",
  "San Tan Valley",
  "Lee's Summit",
  "Menifee",
  "Gatchina",
  "Edinburg",
  "Kirovo-Chepetsk",
  "Suffolk",
  "Missoula",
  "Reconquista",
  "Chino",
  "Yegor’yevsk",
  "Fall River",
  "Hosa’ina",
  "Valle de La Pascua",
  "Rapid City",
  "Shwebo",
  "Zárate",
  "Rafaela",
  "Muncie",
  "Norrköping",
  "Montero",
  "Chosica",
  "Chaman",
  "Sugar Land",
  "Zhob",
  "Qal‘at Bīshah",
  "Alafaya",
  "Saint John",
  "Yeysk",
  "Parepare",
  "Waukegan",
  "Buzuluk",
  "Pergamino",
  "Gitarama",
  "Koidu-Bulma",
  "Cheektowaga",
  "Changxing",
  "Clarkstown",
  "Ad Diwem",
  "Napa",
  "Tumaco",
  "Redwood City",
  "Olavarría",
  "Joplin",
  "Panshi",
  "Newport Beach",
  "Lorca",
  "Shchëkino",
  "Potiskum",
  "Keffi",
  "Usol’ye-Sibirskoye",
  "Hedong",
  "Behbahān",
  "Sokcho",
  "Ballarat",
  "Hoover",
  "Nanaimo",
  "Sumenep",
  "Guider",
  "Conroe",
  "Johns Creek",
  "Linjiang",
  "Yurga",
  "Town 'n' Country",
  "Port Huron",
  "Anzhero-Sudzhensk",
  "Madera",
  "Ocaña",
  "Izmayil",
  "Wiener Neustadt",
  "Bagamoyo",
  "Nangong",
  "Gießen",
  "Troitsk",
  "Kaneyama",
  "Polatsk",
  "Chililabombwe",
  "Melbourne",
  "Presidencia Roque Sáenz Peña",
  "Agboville",
  "Luján",
  "Westland",
  "Bendigo",
  "Watampone",
  "Campana",
  "Béziers",
  "Balkhash",
  "Asbest",
  "Cranston",
  "Farmington Hills",
  "Hattiesburg",
  "Ālbū Kamāl",
  "Klin",
  "Necochea",
  "Chino Hills",
  "Uvinza",
  "Mbalmayo",
  "Namsan",
  "Meïganga",
  "Vorkuta",
  "Xigazê",
  "Koktokay",
  "Capenda Camulemba",
  "Fort Myers",
  "Goodyear",
  "Quillota",
  "Kropotkin",
  "Banī Mazār",
  "Pharr",
  "Shadrinsk",
  "Valdosta",
  "Kadoma",
  "New Braunfels",
  "Kokomo",
  "Kup”yans’k",
  "Gualeguaychú",
  "Flagstaff",
  "Saint-Jérôme",
  "Pingyi",
  "Folsom",
  "Kankakee",
  "Perris",
  "Marsala",
  "Linkou",
  "Boynton Beach",
  "Numan",
  "Söke",
  "Ende",
  "Jiexiu",
  "Ovalle",
  "Butare",
  "Upland",
  "Sarqan",
  "Karūr",
  "Baytown",
  "Battle Creek",
  "Porterville",
  "Oudtshoorn",
  "Layton",
  "Flower Mound",
  "Lake Jackson",
  "Al Musayyib",
  "Zephyrhills",
  "Tuban",
  "Gary",
  "Tailai",
  "St. Augustine",
  "San Ramon",
  "Mackay",
  "Kamensk-Shakhtinskiy",
  "Cedar Park",
  "Campo Mourão",
  "Agrínio",
  "Bolingbrook",
  "Anniston",
  "Oshkosh",
  "Kendall",
  "Brick",
  "Atascocita",
  "Idah",
  "Bombo",
  "Godē",
  "Red Deer",
  "Darhan",
  "Camarillo",
  "The Villages",
  "Hilton Head Island",
  "Missouri City",
  "San Marcos",
  "Rochester Hills",
  "Schaumburg",
  "Kara-Balta",
  "San Ramón de la Nueva Orán",
  "Harrisonburg",
  "Standerton",
  "Riberalta",
  "Wausau",
  "Grand-Bassam",
  "Munch’ŏn",
  "Bawku",
  "Rancho Cordova",
  "Sundsvall",
  "Onitsha",
  "Mandurah",
  "Dale City",
  "Bonao",
  "Cuamba",
  "Waldorf",
  "Tatvan",
  "General Roca",
  "Southfield",
  "Tokār",
  "Owensboro",
  "Pasco",
  "Korosten’",
  "Lodi",
  "Guadalajara",
  "Quảng Trị",
  "Lanxi",
  "Waukesha",
  "Launceston",
  "Morgantown",
  "Keshan",
  "Bourges",
  "Venado Tuerto",
  "Goya",
  "Montepuez",
  "Sumter",
  "Chusovoy",
  "Chalkída",
  "Kalamáta",
  "Ellicott City",
  "Andkhōy",
  "Dothan",
  "Chernogorsk",
  "Wenatchee",
  "Kovel’",
  "Sotik",
  "Asti",
  "Framingham",
  "Maple Grove",
  "Pocatello",
  "South Jordan",
  "Debre Mark’os",
  "Lethbridge",
  "Janesville",
  "Vol’sk",
  "Baiquan",
  "North Richland Hills",
  "Châu Đốc",
  "Belogorsk",
  "Zhanggu",
  "Sheboygan",
  "Homestead",
  "Lima",
  "Walnut Creek",
  "El Paso de Robles",
  "Paragominas",
  "Guajará-Mirim",
  "West Bend",
  "Villa Carlos Paz",
  "Bafia",
  "Hasselt",
  "Ongjin",
  "Hammond",
  "Daphne",
  "Jorhāt",
  "Mocuba",
  "Ragusa",
  "Liuhe",
  "Güines",
  "Casas Adobes",
  "Wum",
  "Chulucanas",
  "Tuymazy",
  "Glen Burnie",
  "Al Aḩmadī",
  "Delray Beach",
  "Say’ūn",
  "Kamloops",
  "Palatine",
  "Borisoglebsk",
  "Dubuque",
  "Bossier City",
  "Sabanalarga",
  "Buckeye",
  "Broomfield",
  "Türkmenbaşy",
  "Saldanha",
  "Lodja",
  "Yorba Linda",
  "Otukpo",
  "Ames",
  "Daytona Beach",
  "Concepción del Uruguay",
  "Chengjiao Chengguanzhen",
  "Deva",
  "Ishim",
  "DeKalb",
  "Soio",
  "Casper",
  "Kenner",
  "Oktyabr’skiy",
  "Sherman",
  "Petaluma",
  "Tejen",
  "Tzaneen",
  "Palo Alto",
  "Weirton",
  "Narva",
  "Apatity",
  "Kati",
  "Aleksin",
  "Old Bridge",
  "Kissidougou",
  "Baní",
  "Las Heras",
  "Eagan",
  "Los Lunas",
  "Mansehra",
  "Poinciana",
  "Corvallis",
  "Lake Elsinore",
  "Kungur",
  "Klintsy",
  "Laguna Niguel",
  "Neryungri",
  "Huinan",
  "Leninogorsk",
  "P’yŏngsan",
  "Grand Forks",
  "Morón",
  "Lesosibirsk",
  "North Little Rock",
  "Lahat",
  "Rockhampton",
  "Chābahār",
  "Alpharetta",
  "Polevskoy",
  "Sodo",
  "Longview",
  "West Des Moines",
  "Pyapon",
  "Rio Negro",
  "Prince George",
  "Banī Walīd",
  "Kumertau",
  "San Clemente",
  "Birnin Konni",
  "Michigan City",
  "Carmichael",
  "Ji-Paraná",
  "Borås",
  "Jupiter",
  "Krasnotur’insk",
  "Tan-Tan",
  "Rocklin",
  "Rafḩā",
  "Fairbanks",
  "Elmira",
  "Binga",
  "Mayagüez",
  "Puerto Madryn",
  "Sammamish",
  "Eden Prairie",
  "Tikhoretsk",
  "Glens Falls",
  "Parkersburg",
  "Qinggang",
  "Mazabuka",
  "Colón",
  "Tulare",
  "Haverhill",
  "Castro Valley",
  "Svobodnyy",
  "Zarafshon Shahri",
  "Beloit",
  "Aïn Sefra",
  "Rzhev",
  "Bhairāhawā",
  "West Hartford",
  "Pflugerville",
  "Eastvale",
  "Port Orange",
  "Encinitas",
  "Bethesda",
  "Medicine Hat",
  "Knysna",
  "Novaya Balakhna",
  "Sopur",
  "Kasongo",
  "Coffs Harbour",
  "Machiques",
  "Balboa",
  "Rowlett",
  "Monessen",
  "Sebring",
  "Lehi",
  "Nkawkaw",
  "Coon Rapids",
  "Belebey",
  "Kayes",
  "Dundalk",
  "Waltham",
  "Ankeny",
  "San Luis Obispo",
  "Tanjungpandan",
  "Council Bluffs",
  "Castle Rock",
  "Chistopol’",
  "Tikhvin",
  "Sagua la Grande",
  "Ferkessédougou",
  "Labinsk",
  "Fernandópolis",
  "Mankato",
  "Noblesville",
  "Bassar",
  "Moca",
  "Iskitim",
  "Benevento",
  "Vyksa",
  "Gadsden",
  "Pula",
  "Sibay",
  "Palm Harbor",
  "Moore",
  "Hamden",
  "Burnsville",
  "Stralsund",
  "Civitavecchia",
  "Doral",
  "Coburg",
  "Sal’sk",
  "El Carmen de Bolívar",
  "Bouaflé",
  "Sŏnbong",
  "Tartagal",
  "San Fernando",
  "Shuya",
  "Soasio",
  "Langzhong",
  "Xo‘jayli Shahri",
  "Reston",
  "Lakewood",
  "Zima",
  "Millcreek",
  "Crotone",
  "Benton Harbor",
  "Meriden",
  "Kotlas",
  "Chapel Hill",
  "Linares",
  "Novi",
  "Port Charlotte",
  "St. Clair Shores",
  "Beckley",
  "Huazhou",
  "Kavála",
  "Gannan",
  "Royal Oak",
  "San Francisco",
  "Goldsboro",
  "Mikhaylovka",
  "Solnechnogorsk",
  "Tezpur",
  "Towson",
  "Orland Park",
  "Arsen’yev",
  "Casa Grande",
  "Aketi",
  "San Pedro",
  "Des Plaines",
  "Santee",
  "Spring",
  "Allanmyo",
  "Borovichi",
  "António Enes",
  "Hot Springs",
  "Walla Walla",
  "Qŭnghirot",
  "Tiznit",
  "Piscataway",
  "Xankəndi",
  "Kentaū",
  "Cheremkhovo",
  "Heroica Caborca",
  "Midwest City",
  "Punta Alta",
  "St. Peters",
  "Taunton",
  "General Pico",
  "Roslavl’",
  "Cruzeiro do Sul",
  "Los Andes",
  "East Stroudsburg",
  "Ridder",
  "Tinley Park",
  "Diamond Bar",
  "Weymouth Town",
  "Garzón",
  "Bradenton",
  "Bayan Hot",
  "Pittsfield",
  "Huntersville",
  "Rotorua",
  "Shoreline",
  "Hof",
  "Paita",
  "Lesnoy",
  "Nova Viçosa",
  "Novato",
  "Commerce City",
  "Sérres",
  "Changling",
  "Cartersville",
  "Dearborn Heights",
  "Umm Ruwaba",
  "Parachinar",
  "Sayanogorsk",
  "Arqalyq",
  "Chicopee",
  "Gelendzhik",
  "Vyaz’ma",
  "Brive-la-Gaillarde",
  "Hazleton",
  "Xinqing",
  "Placetas",
  "Wagga Wagga",
  "Concórdia",
  "Lake Havasu City",
  "Kribi",
  "Euless",
  "Tadmur",
  "Palm Beach Gardens",
  "Grants Pass",
  "Blue Springs",
  "Colton",
  "Ning’an",
  "Fond du Lac",
  "Arroyo Grande",
  "Cathedral City",
  "Cape Girardeau",
  "El Banco",
  "Chivilcoy",
  "Penápolis",
  "Stonecrest",
  "Twin Falls",
  "Williamsport",
  "Krasnokamensk",
  "Ilha de Moçambique",
  "Eagle Pass",
  "Normal",
  "Ash Shiḩr",
  "Leesburg",
  "Beaufort",
  "Hacienda Heights",
  "Southaven",
  "Nuevitas",
  "Grapevine",
  "Ruteng",
  "Chiquinquirá",
  "Bozeman",
  "Azul",
  "Chicoutimi",
  "Mount Prospect",
  "Severomorsk",
  "Elyria",
  "Bījār",
  "Vyshniy Volochëk",
  "Markala",
  "Upata",
  "Yucaipa",
  "Pamplona",
  "DeSoto",
  "Lenexa",
  "West Sacramento",
  "Buguruslan",
  "Ilo",
  "Parsippany-Troy Hills",
  "Lewiston",
  "Río Tercero",
  "Wheaton",
  "Kateríni",
  "Qing’an",
  "Tingo María",
  "Tigard",
  "Banes",
  "Minnetonka",
  "Peabody",
  "Alexandroúpoli",
  "Milford city ",
  "Palm Desert",
  "Livny",
  "Lompoc",
  "Pinellas Park",
  "Port Shepstone",
  "Lorica",
  "Krasnokamsk",
  "Siena",
  "New Plymouth",
  "Porirua",
  "Thongwa",
  "Jaén",
  "Bundaberg",
  "Glendora",
  "Chambersburg",
  "Basankusu",
  "Tayshet",
  "Nyagan’",
  "Kimry",
  "Walvisbaai",
  "Edina",
  "Levittown",
  "Gwadar",
  "Kentwood",
  "Burien",
  "Sierra Vista",
  "Hoffman Estates",
  "Apopka",
  "Emden",
  "Florissant",
  "Tefé",
  "Bloomsburg",
  "Tarma",
  "Tulun",
  "St. Cloud",
  "Coral Gables",
  "Hinesville",
  "Kyshtym",
  "Irondequoit",
  "East Hartford",
  "Ye",
  "Mahalapye",
  "Mweka",
  "New Bern",
  "Rowland Heights",
  "Xánthi",
  "Passau",
  "Turbo",
  "Hua Hin",
  "Apex",
  "Arjona",
  "Orangetown",
  "Sawahlunto",
  "Collierville",
  "Methuen Town",
  "Catalina Foothills",
  "Ferreñafe",
  "An Nabk",
  "North Bay",
  "Siguiri",
  "Otradnyy",
  "Gogrial",
  "Poway",
  "Pozi",
  "Korogwe",
  "Lindong",
  "Kahemba",
  "Ashburn",
  "Minot",
  "Monchegorsk",
  "Azusa",
  "Wylie",
  "Traverse City",
  "Tacheng",
  "Lota",
  "Zyryanovsk",
  "Dunwoody",
  "Berber",
  "Downers Grove",
  "Bullhead City",
  "Ifakara",
  "Wilson",
  "Villa Mercedes",
  "Ruzayevka",
  "Bentonville",
  "Libertador General San Martín",
  "Cuyahoga Falls",
  "Timimoun",
  "Leander",
  "I-n-Salah",
  "Mishawaka",
  "Yong’an",
  "Roswell",
  "Severn",
  "Mollendo",
  "St. Louis Park",
  "Verkhnyaya Salda",
  "Changyŏn",
  "Reedley",
  "Slobodskoy",
  "Pascagoula",
  "East Lansing",
  "Waingapu",
  "Kannapolis",
  "Rancho Santa Margarita",
  "Cedar Hill",
  "Tuckahoe",
  "Megion",
  "East Brunswick",
  "Uniontown",
  "Rio Grande City",
  "Paducah",
  "Namtu",
  "Cookeville",
  "Findlay",
  "Port Macquarie",
  "Torzhok",
  "Niagara Falls",
  "Serdar",
  "Wakema",
  "Bulungu",
  "Enid",
  "Upper Buchanan",
  "Wauwatosa",
  "San Jacinto",
  "Palm Springs",
  "Morehead City",
  "Belaya Kalitva",
  "Bel Air South",
  "East Honolulu",
  "Mildura",
  "Cutral-Có",
  "Luwuk",
  "Ålesund",
  "Al Madrah Samā’il",
  "Draper",
  "Glenview",
  "Amahai",
  "West Orange",
  "East Providence",
  "Kafue",
  "Gryazi",
  "Jeffersonville",
  "Ladysmith",
  "McLean",
  "Mozhga",
  "Keller",
  "Sahuarita",
  "Antalaha",
  "Euclid",
  "Sandusky",
  "P’ot’i",
  "Tres Arroyos",
  "Mentor",
  "Poso",
  "Qingyuan",
  "Dīla",
  "Amursk",
  "Beaumont",
  "Beavercreek",
  "Sandnes",
  "Pine Bluff",
  "Maricopa",
  "Zhitiqara",
  "New Philadelphia",
  "Elmhurst",
  "Perinton",
  "Safonovo",
  "Galveston",
  "Little Elm",
  "Leavenworth",
  "Kita",
  "Alatyr’",
  "Ocoee",
  "Nadym",
  "Berekum",
  "West Lafayette",
  "Pearl City",
  "Kibungo",
  "Burleson",
  "Pechora",
  "Manono",
  "Kohtla-Järve",
  "Lufkin",
  "Biloxi",
  "Spassk-Dal’niy",
  "Apsheronsk",
  "Ayagöz",
  "Irati",
  "Mae Sot",
  "Potomac",
  "Hilo",
  "Mozdok",
  "Volkhov",
  "Oroville",
  "Tangdukou",
  "South Brunswick",
  "Fort Pierce",
  "Bothell",
  "Yefremov",
  "Evesham",
  "Coachella",
  "Birāk",
  "Olbia",
  "Joliette",
  "West Seneca",
  "Sayreville",
  "Salcedo",
  "Radā‘",
  "Tupelo",
  "Shebekino",
  "Chernyakhovsk",
  "El Dorado Hills",
  "Cutler Bay",
  "Morgan Hill",
  "Shaḩḩāt",
  "Wallingford",
  "Manitowoc",
  "Vallenar",
  "Angol",
  "Marana",
  "Strezhevoy",
  "Chone",
  "Strongsville",
  "Beaufort West",
  "Artëmovskiy",
  "Enfield",
  "Attleboro",
  "Kamen’-na-Obi",
  "Lake Ridge",
  "Akhtubinsk",
  "Zvolen",
  "Aliwal North",
  "Del Rio",
  "Haltom City",
  "Oro Valley",
  "Roskilde",
  "Altamonte Springs",
  "Villa Constitución",
  "Winneba",
  "Kingman",
  "Rockwall",
  "Taree",
  "Bountiful",
  "Padangpanjang",
  "Timashevsk",
  "Alapayevsk",
  "Voznesens’k",
  "Naivasha",
  "Roseburg",
  "Nabire",
  "Comendador",
  "Adrian",
  "Salinas",
  "Batouri",
  "Southington",
  "Lombard",
  "Desert Hot Springs",
  "Basoko",
  "Egg Harbor",
  "Henrietta",
  "Urbandale",
  "Winder",
  "Winter Garden",
  "Novozybkov",
  "Stevens Point",
  "Kyle",
  "Sambava",
  "Imbituba",
  "Volksrust",
  "Nizhneudinsk",
  "Peachtree Corners",
  "Al Wajh",
  "Whanganui",
  "Ambatondrazaka",
  "Moorhead",
  "Mariinsk",
  "Krasnoufimsk",
  "Macenta",
  "Lubao",
  "Linden",
  "Yendi",
  "Clovis",
  "Genhe",
  "Ormond Beach",
  "Cortlandt",
  "Billerica",
  "Brea",
  "Prescott",
  "San Antonio de los Baños",
  "The Colony",
  "Burke",
  "Kearny",
  "Arys",
  "Sansanné-Mango",
  "Goose Creek",
  "North Brunswick",
  "Hulin",
  "Partizansk",
  "Klamath Falls",
  "Shū",
  "Kotabumi",
  "Rancho Palos Verdes",
  "Qəzyan",
  "Nadi",
  "Wake Forest",
  "Moline",
  "Edmonds",
  "Sokol",
  "Qapshaghay",
  "Yemanzhelinsk",
  "Moatize",
  "Zanesville",
  "Coppell",
  "Beverly",
  "Mount Laurel",
  "Lesozavodsk",
  "Oakley",
  "Bluefield",
  "Belleville",
  "Uryupinsk",
  "Cotuí",
  "Gay",
  "Cedar Falls",
  "Arecibo",
  "Auxerre",
  "Manassas",
  "Canoinhas",
  "Zaraza",
  "Lushnjë",
  "Catonsville",
  "South Valley",
  "San",
  "Zelenokumsk",
  "La Quinta",
  "Buffalo Grove",
  "Bartlett",
  "Pottsville",
  "Itá",
  "Birsk",
  "Puyallup",
  "Salinópolis",
  "Channelview",
  "North Fort Myers",
  "Shakopee",
  "Frolovo",
  "Linton Hall",
  "Abadan",
  "Aquidauana",
  "Oviedo",
  "Coram",
  "Hobbs",
  "Muskogee",
  "Apache Junction",
  "Balykchy",
  "Odenton",
  "Alta Floresta",
  "Marlboro",
  "Huaura",
  "Pyu",
  "Alta Gracia",
  "Weslaco",
  "Calexico",
  "Holyoke",
  "Haugesund",
  "Mirnyy",
  "Bình Long",
  "Arawa",
  "Oroqen Zizhiqi",
  "Luga",
  "Manalapan",
  "Schertz",
  "Statesboro",
  "Altagracia de Orituco",
  "Raub",
  "Pogradec",
  "Tômbua",
  "Carol Stream",
  "Streamwood",
  "Ob’",
  "Yi’an",
  "Uray",
  "Alekseyevka",
  "Friendswood",
  "Mableton",
  "New Berlin",
  "Westerville",
  "Woburn",
  "Romeoville",
  "Bartlesville",
  "Bogoroditsk",
  "Huntsville",
  "Duncanville",
  "Tavda",
  "Spanish Fork",
  "Borlänge",
  "Wentzville",
  "Kirov",
  "Woodlawn",
  "Pacasmayo",
  "Delaware",
  "Herriman",
  "Lake Oswego",
  "Queen Creek",
  "Palmas",
  "Caibarién",
  "Guiglo",
  "Los Banos",
  "Pacifica",
  "Asheboro",
  "Vichuga",
  "Hurst",
  "Toumodi",
  "Sun City",
  "Indian Trail",
  "Tønsberg",
  "Newnan",
  "Asha",
  "The Acreage",
  "Tshela",
  "Bukama",
  "Gur’yevsk",
  "Enterprise",
  "Severna Park",
  "Plant City",
  "Roy",
  "Royal Palm Beach",
  "Wheeling",
  "Tamworth",
  "Millerovo",
  "Ts’khinvali",
  "Sassandra",
  "Kaka",
  "Meridian",
  "Lynnwood",
  "Carpentersville",
  "Rock Island",
  "Kailua",
  "Carnot",
  "Maroantsetra",
  "Huber Heights",
  "Kapiri Mposhi",
  "Constitución",
  "Qarataū",
  "Reynoldsburg",
  "Dori",
  "Moses Lake",
  "Uglich",
  "Juanjuí",
  "Mechanicsville",
  "Rosenberg",
  "Aboisso",
  "Valrico",
  "Sydney",
  "Réo",
  "Forney",
  "Issaquah",
  "Orillia",
  "Menomonee Falls",
  "Olive Branch",
  "Gallatin",
  "Tynda",
  "Security-Widefield",
  "Penfield",
  "Villazón",
  "Mangai",
  "Oakton",
  "Braintree Town",
  "Commack",
  "Qulsary",
  "Farmers Branch",
  "Monrovia",
  "Kampene",
  "Galloway",
  "Kalispell",
  "San Juan",
  "Wildomar",
  "Chimboy Shahri",
  "Moss",
  "Clifton Park",
  "Crestview",
  "Kalgoorlie",
  "Morondava",
  "Moorpark",
  "Kambove",
  "Kasimov",
  "Serdobsk",
  "Winter Springs",
  "Calumet City",
  "Cedar City",
  "Tunduma",
  "Dunedin",
  "Bermejo",
  "Zharkent",
  "Voi",
  "Kaniama",
  "Trumbull",
  "Ewing",
  "Guaíra",
  "Merritt Island",
  "Oregon City",
  "Oak Creek",
  "Roseville",
  "Yirga ‘Alem",
  "Phenix City",
  "Owasso",
  "Slantsy",
  "Cleburne",
  "Baikonur",
  "Nyaungdon",
  "Nanyuki",
  "Severoural’sk",
  "Tucker",
  "Açu",
  "San Juan Capistrano",
  "Masasi",
  "Guayaramerín",
  "Hato Mayor",
  "Land O' Lakes",
  "Blagodarnyy",
  "Boyarka",
  "Natick",
  "Hilliard",
  "LaGrange",
  "Jipijapa",
  "Zvishavane",
  "Yalutorovsk",
  "Valuyki",
  "Pennsauken",
  "Shawnee",
  "Atbasar",
  "Bettendorf",
  "Pahrump",
  "Martinez",
  "Victoria Falls",
  "Guilderland",
  "Cooper City",
  "La Vergne",
  "Kumo",
  "West Fargo",
  "Kuala Kapuas",
  "Tibati",
  "Rimouski",
  "Shar’ya",
  "Yangambi",
  "Prattville",
  "Honda",
  "Pala Oua",
  "Bougouni",
  "Randallstown",
  "Kundian",
  "Inver Grove Heights",
  "Waxahachie",
  "Upper Arlington",
  "Paradise",
  "Esik",
  "Yarumal",
  "Saint-Benoît",
  "Gahanna",
  "Vác",
  "East Point",
  "Peachtree City",
  "El Mirage",
  "Clermont",
  "Nacogdoches",
  "Praya",
  "Granbury",
  "Bell Ville",
  "Korsakov",
  "Chelmsford",
  "Kōṯah-ye ‘As̲h̲rō",
  "Timmins",
  "Kamsar",
  "Bethlehem",
  "Ikot Abasi",
  "Merrillville",
  "Oswego",
  "Aksay",
  "Viacha",
  "Carrollwood",
  "Bezhetsk",
  "Mount Juliet",
  "Nueve de Julio",
  "Boardman",
  "Glastonbury",
  "Riviera Beach",
  "Oak Harbor",
  "Leawood",
  "Grass Valley",
  "Prince Albert",
  "Chacabuco",
  "Orangevale",
  "Merauke",
  "Galesburg",
  "Gillette",
  "Kondopoga",
  "Menlo Park",
  "Kennesaw",
  "Dartmouth",
  "New City",
  "Carmel",
  "San Dimas",
  "Staraya Russa",
  "Vestavia Hills",
  "Businga",
  "Owings Mills",
  "Slavgorod",
  "Sonoma",
  "Navarre",
  "Adelanto",
  "Biharamulo",
  "Tooele",
  "Cottonwood Heights",
  "Hwange",
  "Puerto Berrío",
  "Túquerres",
  "Vibo Valentia",
  "Dakota Ridge",
  "Verkhniy Ufaley",
  "Zaozërnyy",
  "Schaffhausen",
  "Nagua",
  "Riverhead",
  "Luuq",
  "Arcata",
  "Rostov",
  "Meadow Woods",
  "Douglasville",
  "Woodridge",
  "Sicuani",
  "Shepparton",
  "Fairborn",
  "Ferry Pass",
  "Dyat’kovo",
  "Laramie",
  "North Ridgeville",
  "Pikesville",
  "University Place",
  "Northbrook",
  "Valparaiso",
  "Segezha",
  "Forbe Oroya",
  "Derry",
  "Lebowakgomo",
  "Salina",
  "Springville",
  "Post Falls",
  "Pullman",
  "Central Islip",
  "Atamyrat",
  "Velikiy Ustyug",
  "Alabaster",
  "Mount Isa",
  "Gualeguay",
  "Foley",
  "Ken Caryl",
  "Tweed Heads",
  "Mushie",
  "Hanumānnagar",
  "Gaya",
  "Estero",
  "Calhoun",
  "Aral",
  "Franklin Town",
  "Reggane",
  "Djenné",
  "Safājā",
  "Kholmsk",
  "Sun Prairie",
  "Carlsbad",
  "Key West",
  "Warsaw",
  "Omutninsk",
  "Lake Stevens",
  "Elk Grove Village",
  "Alamogordo",
  "Pekin",
  "St. Charles",
  "Copperas Cove",
  "Queanbeyan",
  "Ridgecrest",
  "DeLand",
  "Qarqan",
  "La Verne",
  "Beyneu",
  "Kudymkar",
  "Bethel Park",
  "Melton",
  "Massillon",
  "De Aar",
  "Westlake",
  "East Lake",
  "Eagle Mountain",
  "Parkland",
  "Kristianstad",
  "Rome",
  "Starkville",
  "Matthews",
  "Eldersburg",
  "Koupéla",
  "El Golea",
  "Dania Beach",
  "Coos Bay",
  "Clarence",
  "Centereach",
  "Pativilca",
  "Fruit Cove",
  "Southlake",
  "Santa Rita",
  "Gīmbī",
  "Kaysville",
  "Petersburg",
  "North Olmsted",
  "Redmond",
  "Hopkinsville",
  "Jacareacanga",
  "Balyqshy",
  "Villarrica",
  "Dubbo",
  "Willingboro",
  "I‘zāz",
  "Caboolture",
  "Barabinsk",
  "Naugatuck",
  "Lismore",
  "Marrero",
  "Gubakha",
  "Köneürgench",
  "Mundelein",
  "Cauquenes",
  "French Valley",
  "Cicero",
  "Sasovo",
  "Laguna Hills",
  "Skellefteå",
  "Wheat Ridge",
  "Buurhakaba",
  "Bel Air North",
  "Placerville",
  "Milledgeville",
  "Marovoay",
  "Des Moines",
  "Banning",
  "Fallbrook",
  "Goleta",
  "Dracut",
  "Redan",
  "Pingxiang",
  "Quesada",
  "Río Grande",
  "Orangeburg",
  "Harker Heights",
  "Lucapa",
  "Algonquin",
  "Wooster",
  "Brooklyn Center",
  "Mossendjo",
  "Pueblo West",
  "Greer",
  "Winter Park",
  "Nogales",
  "Guasdualito",
  "Huamachuco",
  "Karpinsk",
  "Gurnee",
  "Los Altos",
  "Los Gatos",
  "Aiken",
  "Granger",
  "South Kingstown",
  "Holladay",
  "Kahului",
  "Mednogorsk",
  "Palapye",
  "San Luis",
  "Butte",
  "Ambanja",
  "Curanilahue",
  "Barstow",
  "Nicholasville",
  "Banamba",
  "Deptford",
  "Prairieville",
  "Spanaway",
  "North Tonawanda",
  "Westfield",
  "Needham",
  "South Riding",
  "Borzya",
  "Atascadero",
  "Télimélé",
  "North Royalton",
  "Texarkana",
  "Opelika",
  "Grajaú",
  "Walnut",
  "Ballwin",
  "Carney",
  "Gloucester",
  "North Andover",
  "Kibiti",
  "Moanda",
  "Ypacarai",
  "Kerrville",
  "East Liverpool",
  "Kombissiri",
  "Svetogorsk",
  "Milford Mill",
  "Wuyuan",
  "Villa Ángela",
  "Kulat",
  "Orcutt",
  "Casma",
  "Bourèm Guindou",
  "Mavinga",
  "Winona",
  "Warrnambool",
  "Castro",
  "Chicago Heights",
  "South Lake Tahoe",
  "Rexburg",
  "North Chicago",
  "Kabalo",
  "Shīeli",
  "Fountain",
  "Mandalī",
  "Sovetskaya Gavan’",
  "Ukiah",
  "Crown Point",
  "New Iberia",
  "Jaguarão",
  "Saratoga Springs",
  "Kirovsk",
  "American Fork",
  "Glenville",
  "Fitchburg",
  "Duluth",
  "Chamblee",
  "Rotterdam",
  "Atwater",
  "Voorhees",
  "Pontes e Lacerda",
  "Sosnogorsk",
  "East Fishkill",
  "Cheshire",
  "O'Fallon",
  "Sanford",
  "Firestone",
  "Cibolo",
  "Raytown",
  "Cottica",
  "Cornelius",
  "Tauá",
  "Lake Magdalene",
  "Svendborg",
  "Fleming Island",
  "SeaTac",
  "Stockbridge",
  "Tame",
  "Nouna",
  "North Attleborough",
  "Tocache Nuevo",
  "Plattsburgh",
  "East Haven",
  "Wellesley",
  "Morgan City",
  "Lake in the Hills",
  "Hermiston",
  "Newberg",
  "Garner",
  "Agawam Town",
  "Tupiza",
  "Granite City",
  "Chili",
  "West Warwick",
  "West Springfield Town",
  "Perry Hall",
  "Gates",
  "Monterey",
  "Schererville",
  "Big Spring",
  "Ak’ordat",
  "Karasuk",
  "Wallkill",
  "Kartaly",
  "Mehlville",
  "Zheleznogorsk-Ilimskiy",
  "Mpika",
  "Lacey",
  "Austintown",
  "Kotel’nich",
  "Casselberry",
  "Acaraú",
  "Benicia",
  "Icó",
  "Dal’nerechensk",
  "West Windsor",
  "Crofton",
  "Wisconsin Rapids",
  "Alga",
  "Magna",
  "Vestal",
  "Thung Song",
  "Laplace",
  "East Chicago",
  "Chiredzi",
  "Fortuna Foothills",
  "Ruston",
  "Oktyabr’sk",
  "Drexel Heights",
  "Nev’yansk",
  "Branford",
  "Llallagua",
  "Glen Ellyn",
  "Sion",
  "Saugus",
  "Gaoua",
  "Ancud",
  "Aleysk",
  "Belle Glade",
  "Sonora",
  "Kasangulu",
  "Camiri",
  "Millville",
  "Mocímboa",
  "Fuquay-Varina",
  "Gloversville",
  "Cayambe",
  "Kolpashevo",
  "Bolobo",
  "Fridley",
  "Ashtabula",
  "Garfield Heights",
  "Dembī Dolo",
  "Santa Vitória do Palmar",
  "Seguin",
  "Örnsköldsvik",
  "Neptune",
  "Monroeville",
  "Alice Springs",
  "Dodge City",
  "Mananjary",
  "Oak Forest",
  "Deer Park",
  "Dix Hills",
  "Galt",
  "Queensbury",
  "Atkarsk",
  "Danvers",
  "Kinston",
  "Touba",
  "Glen Cove",
  "Pasadena",
  "Tualatin",
  "Rockledge",
  "Tongyangdao",
  "Shaker Heights",
  "Sol’-Iletsk",
  "Wilmette",
  "Jeffersontown",
  "Santiago de Tolú",
  "New Milford",
  "Cañon City",
  "Vicksburg",
  "Savonlinna",
  "St. Helens",
  "Plum",
  "Shalqar",
  "Kuaidamao",
  "Huntley",
  "Timaru",
  "West Chicago",
  "Searcy",
  "Shemonaīkha",
  "Allen Park",
  "Nikolayevsk-na-Amure",
  "Puerto Armuelles",
  "Colíder",
  "West Islip",
  "Sikonge",
  "Horn Lake",
  "South Laurel",
  "Lewes",
  "Asino",
  "Libenge",
  "Paramus",
  "Bernards",
  "Ocean",
  "Fernandina Beach",
  "Coronel Suárez",
  "Zeya",
  "Bunbury",
  "Maryland Heights",
  "East Lake-Orient Park",
  "Tara",
  "Aripuanã",
  "Chancay",
  "Wasco",
  "McHenry",
  "Wappinger",
  "Chíos",
  "Léo",
  "Paragould",
  "Yukon",
  "Bafoulabé",
  "Seneca",
  "Ilchester",
  "New Windsor",
  "Shoreview",
  "Norco",
  "West Milford",
  "Mint Hill",
  "Bixby",
  "Rock Springs",
  "Zionsville",
  "West Linn",
  "Pugachev",
  "Viana",
  "Colleyville",
  "La Ligua",
  "East St. Louis",
  "Statesville",
  "Short Pump",
  "Marshalltown",
  "Nzega",
  "Mahwah",
  "Natchez",
  "New Lenox",
  "Xenia",
  "Chaska",
  "Okha",
  "Bakal",
  "Pearl",
  "Temple Terrace",
  "Buy",
  "Alvin",
  "Brawley",
  "Fort Hood",
  "New Smyrna Beach",
  "Beitbridge",
  "Mason City",
  "Albany",
  "Prior Lake",
  "Sedalia",
  "Wethersfield",
  "Bessemer",
  "Lemoore",
  "Illapel",
  "Muscatine",
  "Punata",
  "Oakleaf Plantation",
  "Pukekohe East",
  "Vel’sk",
  "La Unión",
  "Ayorou",
  "Vernon Hills",
  "Timberwood Park",
  "Lochearn",
  "Arlon",
  "North Kingstown",
  "Cabot",
  "Bayonet Point",
  "Toktogul",
  "Mizdah",
  "Fajardo",
  "Summerlin South",
  "Snoqualmie",
  "Marquette",
  "Tinipuka",
  "Wall",
  "Diapaga",
  "Citrus Park",
  "Neenah",
  "Sachse",
  "Randolph",
  "Española",
  "Brownsburg",
  "White Bear Lake",
  "Okeechobee",
  "Krasnoarmeysk",
  "South Windsor",
  "Severobaykal’sk",
  "Koko",
  "West Odessa",
  "Maple Valley",
  "Neiba",
  "Ridgewood",
  "Immokalee",
  "Sept-Îles",
  "Scottsbluff",
  "Middle River",
  "De Witt",
  "Lensk",
  "Owatonna",
  "Moscow",
  "Merrimack",
  "Chanhassen",
  "Hercules",
  "Cave Spring",
  "Kariba",
  "Tougué",
  "South Portland",
  "Lockport",
  "Temescal Valley",
  "San Ignacio de Velasco",
  "Ust’-Kut",
  "Freeport City",
  "Dedham",
  "Hutto",
  "Claremore",
  "Horizon West",
  "North Tustin",
  "Balch Springs",
  "Nelidovo",
  "Tarpon Springs",
  "Austin",
  "Hazelwood",
  "Kingsville",
  "Paraparaumu",
  "Mercer Island",
  "Midlothian",
  "Chiromo",
  "Dolores",
  "Belvidere",
  "Sebastian",
  "Hermanus",
  "Tatarsk",
  "Bologoye",
  "Ereymentaū",
  "Hervey Bay",
  "Londonderry",
  "Sun City West",
  "Columbine",
  "Opelousas",
  "De Pere",
  "Ongwediva",
  "Karoi",
  "Champlin",
  "Yucca Valley",
  "Caledonia",
  "Muskego",
  "Wyandotte",
  "Edwardsville",
  "Walpole",
  "Sevierville",
  "Puerto Varas",
  "South Salt Lake",
  "Holt",
  "Hanahan",
  "West Memphis",
  "Ponca City",
  "Madaoua",
  "Chantilly",
  "Farafangana",
  "Westmont",
  "McDonough",
  "Palmetto Bay",
  "Kakonko",
  "Branson",
  "Topki",
  "Homer Glen",
  "Fountain Hills",
  "Chesapeake Beach",
  "Palm City",
  "San Benito",
  "Bainbridge Island",
  "Watsa",
  "North Laurel",
  "Elk River",
  "Norton Shores",
  "Tocopilla",
  "Inkster",
  "South Plainfield",
  "Fresno",
  "Aldan",
  "Coronado",
  "Baubau",
  "Kernersville",
  "Trotwood",
  "Waycross",
  "North Platte",
  "Lincolnia",
  "Rosemount",
  "Scotch Plains",
  "Seal Beach",
  "Xinxing",
  "Tifton",
  "Simsbury",
  "Haines City",
  "Saint-Laurent-du-Maroni",
  "Thomasville",
  "North Potomac",
  "Keystone",
  "Erie",
  "Fairland",
  "Fort Dodge",
  "Middleborough",
  "Ottumwa",
  "Candler-McAfee",
  "Calabasas",
  "Loma Linda",
  "Labasa",
  "Avon Lake",
  "Mequon",
  "Plainview",
  "Forest Grove",
  "Al Jawf",
  "Frederikshavn",
  "Faribault",
  "Grootfontein",
  "As Sulayyil",
  "Westford",
  "Gusinoozërsk",
  "Dunkirk",
  "Kalachinsk",
  "Wilsonville",
  "Dinuba",
  "Vero Beach South",
  "Kourou",
  "Rolling Meadows",
  "Ilion",
  "Cranford",
  "Zion",
  "Cottage Lake",
  "Heber",
  "Zorgo",
  "North Haven",
  "Port Angeles",
  "Pooler",
  "Charles Town",
  "Jardim",
  "Diu",
  "Hoh Ereg",
  "Fria",
  "Guanhães",
  "Khromtaū",
  "Bay Point",
  "Nchelenge",
  "Greeneville",
  "Halfmoon",
  "Ayapel",
  "Bogotol",
  "Dudinka",
  "Forest Lake",
  "Tash-Kömür",
  "Benbrook",
  "Socastee",
  "Nazca",
  "El Seibo",
  "Mount Pocono",
  "Half Moon Bay",
  "Jacksonville Beach",
  "Bondo",
  "L’gov",
  "Greenbelt",
  "Plainsboro",
  "Palm River-Clair Mel",
  "Wadsworth",
  "Ellensburg",
  "Roxbury",
  "Romulus",
  "Gallup",
  "Loves Park",
  "Converse",
  "Wekiwa Springs",
  "Medford",
  "Wright",
  "Camas",
  "Lincolnton",
  "Auburn Hills",
  "Bāglung",
  "Westchase",
  "Dubăsari",
  "Quaraí",
  "Mount Gambier",
  "Sugar Hill",
  "Gitega",
  "Crystal",
  "Macia",
  "Meadville",
  "Laguna Beach",
  "Morton Grove",
  "West Springfield",
  "Sīwah",
  "Lengir",
  "Hingham",
  "Venice",
  "Onondaga",
  "Makīnsk",
  "Sechura",
  "Saginaw",
  "Gyêgu",
  "Hunters Creek",
  "Okemos",
  "Anthem",
  "Vega Baja",
  "Tumwater",
  "Solon",
  "Roanoke Rapids",
  "Greenfield Town",
  "Silver Firs",
  "North Augusta",
  "Inta",
  "Taylors",
  "Lisle",
  "Chalmette",
  "Yako",
  "Pittsburg",
  "Webster Groves",
  "Kenmore",
  "Willoughby",
  "Tunuyán",
  "Harriman",
  "East Patchogue",
  "Roselle",
  "Dolton",
  "Braidwood",
  "New Brighton",
  "Lathrop",
  "Tashtagol",
  "Griffin",
  "San Miguel de Papasquiaro",
  "East Peoria",
  "Machesney Park",
  "Farragut",
  "Gjøvik",
  "Munster",
  "Elizabeth City",
  "Acworth",
  "Onega",
  "Maracaju",
  "Andoany",
  "Armidale",
  "Oytal",
  "Lysander",
  "Kemi",
  "Westerly",
  "Owen Sound",
  "Hopewell",
  "Taupo",
  "Dawmat al Jandal",
  "Jenks",
  "Fremont",
  "Radcliff",
  "Campoalegre",
  "Whyalla",
  "Northdale",
  "Bélabo",
  "South Elgin",
  "Wareham",
  "Corsicana",
  "Sale",
  "Granite Bay",
  "Le Ray",
  "Aranyaprathet",
  "Baturité",
  "Maple Heights",
  "Pýrgos",
  "Bloomington",
  "Guilford",
  "Ar Ruţbah",
  "Prairie Village",
  "Lebu",
  "Port Hueneme",
  "Barakī Barak",
  "Demba",
  "Christiansburg",
  "Niskayuna",
  "Southold",
  "Winter Gardens",
  "Pace",
  "Mandan",
  "Djibo",
  "Eséka",
  "Kihei",
  "Brownwood",
  "Qarazhal",
  "Ruskin",
  "Marina",
  "Barnegat",
  "Warrensburg",
  "Kizel",
  "Union Hill-Novelty Hill",
  "Clarksburg",
  "West Melbourne",
  "Katoomba",
  "Simpsonville",
  "Lower",
  "Allison Park",
  "Araçuaí",
  "Puerto Suárez",
  "Sun City Center",
  "Frauenfeld",
  "St. Andrews",
  "Stoneham",
  "Eastmont",
  "Naples",
  "Ensley",
  "Green Valley",
  "Fort Walton Beach",
  "Toguchin",
  "Mukwonago",
  "Ramona",
  "Rose Hill",
  "Watertown",
  "Vänersborg",
  "Trussville",
  "Del City",
  "Marietta",
  "Duarte",
  "Darien",
  "Frederickson",
  "South Holland",
  "Prichard",
  "Affton",
  "Park Forest",
  "La Porte",
  "Kobilo",
  "Bellview",
  "Rancho San Diego",
  "Siloam Springs",
  "South Euclid",
  "Piqua",
  "Arvin",
  "Johnston",
  "Manicoré",
  "Carrboro",
  "Hays",
  "Biddeford",
  "Perrysburg",
  "Mukilteo",
  "Shimanovsk",
  "Gaffney",
  "Owosso",
  "Palatka",
  "Hazel Dell",
  "Lutz",
  "Lino Lakes",
  "West Deptford",
  "Amsterdam",
  "Ludlow",
  "Nixa",
  "Ardmore",
  "Orchards",
  "Fort Atkinson",
  "Antsohihy",
  "Krujë",
  "Mustang",
  "Hyde Park",
  "Olean",
  "Elko",
  "Crest Hill",
  "Bayou Cane",
  "Arbutus",
  "Raymore",
  "Birmingham",
  "Isil’kul’",
  "East Ridge",
  "Arnold",
  "East Moline",
  "Sun Valley",
  "Kapolei",
  "Ypsilanti",
  "Salmon Creek",
  "New Hope",
  "Nzeto",
  "Camp Springs",
  "Jauja",
  "Sotouboua",
  "Chascomús",
  "Easley",
  "Bardstown",
  "Dyersburg",
  "Oneida",
  "Boralday",
  "South Milwaukee",
  "Grayslake",
  "Camaná",
  "Goulburn",
  "Fairhope",
  "Prunedale",
  "Lynn Haven",
  "Rolla",
  "Shafter",
  "Petrovsk-Zabaykal’skiy",
  "Coralville",
  "Okahandja",
  "Gamboma",
  "Grand Island",
  "Sapulpa",
  "Havelock",
  "Mill Creek",
  "Eustis",
  "Logansport",
  "Alice",
  "Milwaukie",
  "Corner Brook",
  "Cockeysville",
  "DeBary",
  "Pleasant Prairie",
  "Lealman",
  "Ulundi",
  "San Felipe",
  "Sequim",
  "Holiday",
  "Ferguson",
  "Atoka",
  "Little Egg Harbor",
  "Agoura Hills",
  "Bonney Lake",
  "Palm Valley",
  "Brushy Creek",
  "Silverdale",
  "Shelbyville",
  "Waukee",
  "Gardner",
  "Murray",
  "Miandrivazo",
  "Val-d’Or",
  "Kawambwa",
  "Battle Ground",
  "Golden",
  "Krasnoural’sk",
  "Mbala",
  "Moorestown",
  "Universal City",
  "Poplar Bluff",
  "Mill Creek East",
  "Komatipoort",
  "North Salt Lake",
  "Vincennes",
  "Dublin",
  "Üshtöbe",
  "Libertyville",
  "Bothell West",
  "Duncan",
  "Clemmons",
  "La Cañada Flintridge",
  "Mo i Rana",
  "Pickerington",
  "Mead Valley",
  "Bear",
  "Mililani Mauka",
  "Marblehead",
  "Lake Ronkonkoma",
  "Millburn",
  "Mountain Brook",
  "New Canaan",
  "Chilecito",
  "Kill Devil Hills",
  "New Glasgow",
  "West Pensacola",
  "Prosper",
  "Harvey",
  "Cañas",
  "The Dalles",
  "Mokena",
  "Sulphur",
  "Lorton",
  "Willmar",
  "American Canyon",
  "South St. Paul",
  "Montclair",
  "Rocky River",
  "Secaucus",
  "Wade Hampton",
  "San Lorenzo de Esmeraldas",
  "Holtsville",
  "Masterton",
  "Escanaba",
  "Casa de Oro-Mount Helix",
  "Bryant",
  "Musina",
  "Valley",
  "Sesheke",
  "Tukwila",
  "Cherepanovo",
  "Brockport",
  "Laurinburg",
  "Kwinana",
  "Amherst Center",
  "Hazlet",
  "Zhosaly",
  "Kasongo-Lunda",
  "Rayevskiy",
  "Parma Heights",
  "Pita",
  "Esquel",
  "King of Prussia",
  "Rosamond",
  "Rocky Hill",
  "Glassboro",
  "Haya",
  "Narvik",
  "Zemio",
  "Puerto Natales",
  "Al Mijlad",
  "Culpeper",
  "Miamisburg",
  "Leland",
  "Burnie",
  "East Hemet",
  "Kampong Thum",
  "Selden",
  "Yeniseysk",
  "Sand Springs",
  "Lake Zurich",
  "Cyangugu",
  "Brambleton",
  "Old Jamestown",
  "Hauppauge",
  "Ben Gardane",
  "West Mifflin",
  "General Martín Miguel de Güemes",
  "Camacupa",
  "Bourne",
  "Painesville",
  "Mpwapwa",
  "Haslett",
  "Murrysville",
  "West St. Paul",
  "Punta Gorda",
  "Snellville",
  "Orinda",
  "Itigi",
  "Cameron Park",
  "Babanūsah",
  "Swansboro",
  "Tahlequah",
  "Southbury",
  "Syosset",
  "Front Royal",
  "Stevenson Ranch",
  "Durango",
  "Bikin",
  "Pinecrest",
  "San Borja",
  "Liberal",
  "Howard",
  "Scarborough",
  "Atasū",
  "Vernal",
  "Lake Forest",
  "Bartow",
  "Franklin Farm",
  "Brainerd",
  "Horizon City",
  "Levin",
  "Suwanee",
  "Santa Cruz Cabrália",
  "Angleton",
  "Papillion",
  "Bethel",
  "Upper St. Clair",
  "Saco",
  "Sherwood",
  "Natchitoches",
  "East Northport",
  "North Ogden",
  "Fernley",
  "Sil-li",
  "Echuca",
  "Terrace",
  "North Battleford",
  "Harstad",
  "Matteson",
  "Ballenger Creek",
  "Yorkville",
  "Montville",
  "Ivdel’",
  "Stillwater",
  "Elkridge",
  "Pinole",
  "Florida Ridge",
  "Devonport",
  "Ayaviri",
  "Safford",
  "Castaic",
  "Shelton",
  "Oum Hadjer",
  "Batī",
  "Roebourne",
  "McKeesport",
  "Kos",
  "Laconia",
  "Kuna",
  "Broadview Heights",
  "La Follette",
  "Uzhur",
  "Canby",
  "Lumphat",
  "Polyarnyy",
  "Brigham City",
  "Somerton",
  "Eloy",
  "Zapala",
  "South Burlington",
  "McAlester",
  "Cullman",
  "Tiffin",
  "Homewood",
  "Mumbwa",
  "Alsip",
  "Linda",
  "Helena",
  "Cornelia",
  "Badger",
  "Dadeldhurā",
  "Ennis",
  "Altamont",
  "Balcarce",
  "Twinsburg",
  "Sylvania",
  "Maple Shade",
  "Altus",
  "Contamana",
  "Eyl",
  "Rio Rico",
  "Macomb",
  "East Lyme",
  "Breaux Bridge",
  "Grandview",
  "Erlangen",
  "Westborough",
  "Palestine",
  "Qitai",
  "Dzüünharaa",
  "Sonsón",
  "Ansonia",
  "North Liberty",
  "Slyudyanka",
  "Cherry Hill",
  "Marinette",
  "Nueva Imperial",
  "Villamontes",
  "Emba",
  "Uvalde",
  "Red Bluff",
  "Sudbury",
  "Forest Park",
  "Mayfield Heights",
  "Hopkins",
  "Kavalerovo",
  "Puerto San José",
  "Bay City",
  "Orodara",
  "Ronkonkoma",
  "Huanta",
  "Brook Park",
  "Middle",
  "Vanino",
  "Cranbrook",
  "Springboro",
  "Cholpon-Ata",
  "Iron Mountain",
  "Martha Lake",
  "Holden",
  "Moultrie",
  "Cocoa",
  "Wantagh",
  "Defiance",
  "Gautier",
  "Spanish Lake",
  "Luau",
  "Nara",
  "Dayan",
  "Round Lake",
  "Kyakhta",
  "Bourbonnais",
  "Five Corners",
  "Silverthorne",
  "North Wilkesboro",
  "Durant",
  "Bensenville",
  "Nanuet",
  "Rancho Mirage",
  "Crawfordsville",
  "Charata",
  "Mattoon",
  "North Druid Hills",
  "Southeast",
  "Cudahy",
  "Katy",
  "Lobos",
  "Gibsonton",
  "Beeville",
  "Bua Yai",
  "North Aurora",
  "Wheatfield",
  "Central Point",
  "Traralgon",
  "Maumelle",
  "Cinco Ranch",
  "Murray Bridge",
  "Kilgore",
  "Eastlake",
  "Middelburg",
  "Kiruna",
  "Tillmans Corner",
  "St. Matthews",
  "Diego de Almagro",
  "Hannibal",
  "Ingleside",
  "Hassi Messaoud",
  "Abaza",
  "Scarsdale",
  "Oxon Hill",
  "Newburyport",
  "Shenandoah",
  "Menomonie",
  "Sycamore",
  "Humacao",
  "Steubenville",
  "Requena",
  "Santa Fe Springs",
  "Port Alfred",
  "Anacortes",
  "Lenoir",
  "Kurtamysh",
  "Lackawanna",
  "Apollo Beach",
  "Sunnyside",
  "Pô",
  "Marco Island",
  "Goffstown",
  "Nguigmi",
  "Edmundston",
  "Beltsville",
  "Huntington",
  "Yuanquan",
  "Cary",
  "Sovetsk",
  "Safety Harbor",
  "Colonial Heights",
  "White Settlement",
  "Tinton Falls",
  "Tullahoma",
  "Lithia Springs",
  "Jenison",
  "Wallingford Center",
  "Sartell",
  "Menasha",
  "Cimarron Hills",
  "South Hadley",
  "Blooming Grove",
  "Hinsdale",
  "Ashton",
  "Fish Hawk",
  "Maryland City",
  "Ocean Springs",
  "Zongo",
  "Radford",
  "Godfrey",
  "Pampa",
  "Bitam",
  "Moraga",
  "Rosedale",
  "Zaysan",
  "San Carlos Park",
  "Tuncurry",
  "Tougan",
  "St. Michael",
  "Glenvar Heights",
  "Albert Lea",
  "Fort Mill",
  "Maitland",
  "Tallmadge",
  "Willimantic",
  "Imperial",
  "Zachary",
  "Anoka",
  "Sayre",
  "Five Forks",
  "Nederland",
  "Īlām",
  "Boulsa",
  "Colonia",
  "Karukh",
  "North Adams",
  "Lindenwold",
  "Portsmouth",
  "Amesbury Town",
  "Same",
  "Foxborough",
  "Veinticinco de Mayo",
  "Bon Air",
  "Martinsburg",
  "Buckhall",
  "Broken Hill",
  "Butner",
  "Eldorado",
  "Palos Hills",
  "Lynden",
  "Mesquite",
  "Crossville",
  "Redland",
  "Sylacauga",
  "North Canton",
  "Ashwaubenon",
  "Americus",
  "Shippensburg",
  "West Columbia",
  "Versailles",
  "Kangaba",
  "Wyckoff",
  "Knik-Fairview",
  "North Babylon",
  "Clive",
  "Killingly",
  "Gorham",
  "Pallasovka",
  "Bloemhof",
  "Wādī Ḩalfā’",
  "La Vista",
  "South Ogden",
  "Wolf Trap",
  "Dickson",
  "Lemont",
  "Brookfield",
  "Sunland Park",
  "Cedar Mill",
  "Streator",
  "Plast",
  "El Reno",
  "Bethpage",
  "Monte Cristi",
  "Nevel’sk",
  "Ambriz",
  "Wailuku",
  "Ihosy",
  "Piñas",
  "Fort Hunt",
  "McKinleyville",
  "North Decatur",
  "Phoenixville",
  "Bellingham",
  "Vero Beach",
  "Brenham",
  "Southbridge Town",
  "Lemay",
  "Batavia",
  "Albemarle",
  "Ngara",
  "Cohoes",
  "Goodlettsville",
  "Tavares",
  "Lock Haven",
  "El Segundo",
  "Bella Vista",
  "Norcross",
  "Neabsco",
  "Denville",
  "Tôlan̈aro",
  "Karratha",
  "Estelle",
  "Geddes",
  "Mikumi",
  "La Marque",
  "Delran",
  "Buffalo",
  "Mossel Bay",
  "Solvang",
  "Mercedes",
  "Bemidji",
  "Terrell",
  "Morombe",
  "Oconomowoc",
  "Seagoville",
  "Nipomo",
  "Groveton",
  "Wolcott",
  "Lake Placid",
  "Puerto López",
  "Poronaysk",
  "Valle Vista",
  "Clemson",
  "Holmdel",
  "Donna",
  "Lakeport",
  "Macetown",
  "Coldwater",
  "Fairview Heights",
  "Highland Village",
  "Bellinzona",
  "Country Club Hills",
  "Akureyri",
  "Kamenka",
  "Troutdale",
  "Stuart",
  "Cinnaminson",
  "Ham Lake",
  "Woodcrest",
  "Centralia",
  "Morganton",
  "Lindsay",
  "Ladson",
  "Yelm",
  "Opa-locka",
  "Lake Mary",
  "Westchester",
  "Spanish Springs",
  "Northbridge",
  "Port Jervis",
  "Wendell",
  "East Greenbush",
  "McComb",
  "Dajabón",
  "Collipulli",
  "Blytheville",
  "Sarasota Springs",
  "Karema",
  "Sabaneta",
  "Sikeston",
  "Streetsboro",
  "Burrillville",
  "Gawler",
  "Glen Allen",
  "Bridgeview",
  "Kupino",
  "Center Point",
  "Mount Clemens",
  "Sienna Plantation",
  "North Myrtle Beach",
  "Fort Leonard Wood",
  "Morton",
  "Republic",
  "Fairview Park",
  "Dixon",
  "Evans",
  "Abington",
  "San Martín",
  "Three Lakes",
  "Fort Thomas",
  "New Port Richey",
  "Ocean Acres",
  "Laguna Woods",
  "Tanque Verde",
  "Spárti",
  "Prospect Heights",
  "Burley",
  "Huarmey",
  "Horqueta",
  "Buda",
  "East Longmeadow",
  "Sayville",
  "Hope Mills",
  "Pearl River",
  "Highland Springs",
  "Upper Grand Lagoon",
  "Nyahanga",
  "Kaukauna",
  "Parole",
  "Frankfort",
  "Storrs",
  "Lake Wales",
  "San Gabriel",
  "Fonte Boa",
  "Boerne",
  "Easthampton Town",
  "Nikolayevsk",
  "Donaldsonville",
  "Ilave",
  "Fairhaven",
  "Picayune",
  "Conyers",
  "Colchester",
  "Gun Barrel City",
  "Welby",
  "Humble",
  "Camano",
  "Belgrade",
  "Chickasha",
  "Auburndale",
  "Grandville",
  "Readington",
  "Belvedere Park",
  "Ellington",
  "Kirksville",
  "Dyer",
  "Lake St. Louis",
  "Bodaybo",
  "Coalinga",
  "Fillmore",
  "Crescent City",
  "Longmeadow",
  "River Falls",
  "Tiverton",
  "Fallon",
  "Nikel’",
  "King City",
  "Knightdale",
  "Derzhavīnsk",
  "Cliza",
  "Houghton",
  "Camrose",
  "Shively",
  "Parma",
  "Yorito",
  "Groves",
  "Southchase",
  "West Lealman",
  "Clearlake",
  "Pecan Grove",
  "Greenwood Village",
  "Miami",
  "Hood River",
  "Los Osos",
  "Cutlerville",
  "Washougal",
  "Overland",
  "Suffield",
  "Kodinsk",
  "Gornyak",
  "Circleville",
  "Elkton",
  "Stallings",
  "Long Beach",
  "Hermitage",
  "Seven Oaks",
  "Clark",
  "Narragansett",
  "At-Bashy",
  "North Reading",
  "Lamont",
  "Sierra Vista Southeast",
  "Loncoche",
  "Great Falls",
  "Middleburg Heights",
  "Dunn",
  "Mastic",
  "Clarksdale",
  "Duxbury",
  "Pataskala",
  "Grosse Pointe Woods",
  "New Haven",
  "Puerto Pimentel",
  "Candelaria",
  "Ammon",
  "Monte Plata",
  "Satipo",
  "Discovery Bay",
  "Alamo",
  "Fairburn",
  "Great Bend",
  "Aarau",
  "Stony Point",
  "Pequannock",
  "Cloverly",
  "Canyon",
  "Hueytown",
  "Addison",
  "Río Bueno",
  "Griffith",
  "McMinnville",
  "Kuala Lipis",
  "Hereford",
  "Herisau",
  "Northview",
  "Kérouané",
  "Burrel",
  "Nyunzu",
  "Gantt",
  "Blackfoot",
  "Malta",
  "Nerchinsk",
  "Villa Rica",
  "Kulunda",
  "Bay Village",
  "Bradley",
  "Wilmington Island",
  "Junín",
  "Millbrook",
  "Lady Lake",
  "Fullerton",
  "Mitchell",
  "Gainesville",
  "East Highland Park",
  "Udachnyy",
  "Moreau",
  "Teseney",
  "Willow Grove",
  "Farmingville",
  "Niceville",
  "Vyazemskiy",
  "Ramsey",
  "Bennington",
  "Galion",
  "Rio Linda",
  "Rossville",
  "Depew",
  "Washington Court House",
  "Archer Lodge",
  "Yorkton",
  "Lakeway",
  "Sarandë",
  "Waxhaw",
  "Callaway",
  "Taft",
  "Plainfield",
  "Phelan",
  "Port Isabel",
  "Purcellville",
  "Murraysville",
  "Dalanzadgad",
  "Lake Geneva",
  "Aldine",
  "Longwood",
  "Powder Springs",
  "World Golf Village",
  "Fort Carson",
  "Red Hill",
  "La Grande",
  "Mountain House",
  "Gikongoro",
  "Salyan",
  "Rāmechhāp",
  "Bhimphedi̇̄",
  "Kök-Janggak",
  "Chaguarama",
  "Andrews",
  "Las Vegas",
  "Yazoo City",
  "Mebane",
  "Flowing Wells",
  "Berea",
  "College Park",
  "Tsumeb",
  "Seekonk",
  "Bredasdorp",
  "Dumas",
  "Ledyard",
  "Northborough",
  "Belchertown",
  "Swift Current",
  "Tonawanda",
  "Tenafly",
  "Mariestad",
  "New Freedom",
  "Kerman",
  "Chubbuck",
  "Buford",
  "Whitman",
  "Chegdomyn",
  "New River",
  "Tolland",
  "Brooklyn Park",
  "Bellair-Meadowbrook Terrace",
  "Hugo",
  "Uryzhar",
  "Sun Lakes",
  "Coto de Caza",
  "Nuuk",
  "Beech Grove",
  "Hershey",
  "Bradley Gardens",
  "Mastic Beach",
  "Boulder City",
  "Ijevan",
  "Sulphur Springs",
  "Thibodaux",
  "Prince Rupert",
  "Forest City",
  "Azalea Park",
  "Yankton",
  "Hayden",
  "Astoria",
  "Campbellsville",
  "Branchburg",
  "Abong Mbang",
  "Chaparral",
  "Connersville",
  "Gouré",
  "Pleasanton",
  "Oldsmar",
  "Fraser",
  "West Richland",
  "Fort Mohave",
  "Kings Park West",
  "Saraland",
  "Swampscott",
  "Mount Washington",
  "Wilbraham",
  "Bosobolo",
  "Franklin Park",
  "Ust’-Ordynskiy",
  "South Venice",
  "Jefferson Valley-Yorktown",
  "Manorville",
  "Johnson City",
  "Fostoria",
  "Tehachapi",
  "Gonzalez",
  "Holliston",
  "Pewaukee",
  "Lindenhurst",
  "Phillipsburg",
  "El Sobrante",
  "Toppenish",
  "Oak Park",
  "Hewitt",
  "Beekman",
  "Crowley",
  "Tamaqua",
  "Ascensión",
  "Miami Springs",
  "Nioro",
  "Gramercy",
  "Payson",
  "Wauchula",
  "Tórshavn",
  "Royal Kunia",
  "Whitewater",
  "Hartselle",
  "Abadla",
  "College",
  "Pomfret",
  "Mineral Wells",
  "Mill Valley",
  "Lívingston",
  "Metuchen",
  "Iona",
  "Windham",
  "Red Wing",
  "Cambridge",
  "Hobe Sound",
  "East Bridgewater",
  "Hollins",
  "Hopatcong",
  "Coronel José Félix Bogado",
  "Alausí",
  "Paradise Valley",
  "Oil City",
  "Beacon",
  "Port Hedland",
  "Robbinsville",
  "Elk Plain",
  "North Branford",
  "St. Simons",
  "Ballina",
  "Chota",
  "Antioch",
  "Deming",
  "Maridi",
  "Kouroussa",
  "Sweden",
  "Aḑ Ḑab‘ah",
  "Kaoma",
  "Dentsville",
  "Moorestown-Lenola",
  "Black Forest",
  "Williams Lake",
  "New Franklin",
  "Brooks",
  "Greendale",
  "Cahokia",
  "Ionia",
  "Boutilimit",
  "Añatuya",
  "Shilka",
  "Raymondville",
  "Grovetown",
  "Shawangunk",
  "Effingham",
  "New Fairfield",
  "Cayce",
  "Maqat",
  "Mashpee",
  "Mountain Home",
  "Çorovodë",
  "Chippewa Falls",
  "Middlesborough",
  "Southern Pines",
  "Ferndale",
  "Wangaratta",
  "Muscle Shoals",
  "Cromwell",
  "Cherry Hill Mall",
  "Jericho",
  "Centerton",
  "Port Orchard",
  "Grain Valley",
  "Brandermill",
  "Beach Park",
  "Bridgetown",
  "Koundara",
  "Hooksett",
  "East Wenatchee",
  "Lakeland South",
  "Calera",
  "Hendersonville",
  "Hickory Hills",
  "Big Rapids",
  "Hidalgo",
  "Fort Lewis",
  "Hot Springs Village",
  "Mount Dora",
  "Lewisville",
  "Gardendale",
  "Port Augusta",
  "Druid Hills",
  "Baraboo",
  "Glasgow",
  "Conneaut",
  "Allouez",
  "Rantoul",
  "Levelland",
  "Middlesex",
  "Broadlands",
  "Guapí",
  "Greenlawn",
  "Clewiston",
  "Raynham",
  "Hazel Crest",
  "Wixom",
  "Kem’",
  "Steamboat Springs",
  "Arcadia",
  "Fort Drum",
  "Uxbridge",
  "Glenpool",
  "Singleton",
  "Ipswich",
  "Ocean View",
  "Teays Valley",
  "Wood Dale",
  "Mwinilunga",
  "Sharonville",
  "Quesnel",
  "Maumee",
  "Hutchinson",
  "Destin",
  "Waunakee",
  "Bandarbeyla",
  "North Mankato",
  "Elizabethton",
  "Thonotosassa",
  "Moberly",
  "Wauconda",
  "Zarghūn Shahr",
  "Sauk Rapids",
  "West Freehold",
  "Wayland",
  "Soddy-Daisy",
  "Lamas",
  "Okmulgee",
  "Motupe",
  "Nesconset",
  "Swellendam",
  "Hockessin",
  "Twentynine Palms",
  "Palmetto",
  "Setauket-East Setauket",
  "Bongaree",
  "Waianae",
  "Brecksville",
  "Port Lincoln",
  "DeRidder",
  "East Islip",
  "Mar de Ajó",
  "Atlantic Beach",
  "Glenn Dale",
  "Brevard",
  "Fort Campbell North",
  "Alpena",
  "Frías",
  "Belle Chasse",
  "East Milton",
  "Havre de Grace",
  "Berkeley Heights",
  "Piney Green",
  "Rincon",
  "Taos",
  "Warrington",
  "Lone Tree",
  "Vadnais Heights",
  "Lyndhurst",
  "Brookside",
  "Palos Verdes Estates",
  "Chañaral",
  "Fruitville",
  "Baker",
  "St. James",
  "West Haven",
  "Millbury",
  "Swansea",
  "Richton Park",
  "Esil",
  "Chesterton",
  "Olivehurst",
  "Vicuña",
  "Cajabamba",
  "Bluffdale",
  "Spring Creek",
  "Western Springs",
  "Englewood",
  "Artesia",
  "Newark",
  "Oatfield",
  "Hampton Bays",
  "Vidalia",
  "Price",
  "Solana Beach",
  "Santa Fe",
  "Jensen Beach",
  "Mbulu",
  "Reidsville",
  "Youngsville",
  "Golyshmanovo",
  "South Park Township",
  "Rendon",
  "Timberlake",
  "Kent",
  "Moss Point",
  "Bollnäs",
  "Lockhart",
  "North Auburn",
  "Muisne",
  "Lake Forest Park",
  "Charlton",
  "Joaquín V. González",
  "Fortuna",
  "Newington",
  "Montgomeryville",
  "Van Buren",
  "Tokoroa",
  "Lakeland Village",
  "Dolbeau",
  "San Marino",
  "Pelham",
  "Pecos",
  "Middleburg",
  "Dardenne Prairie",
  "New Providence",
  "Stony Brook",
  "Kirensk",
  "Fruita",
  "Elfers",
  "Kettering",
  "Talladega",
  "Warrenville",
  "La Homa",
  "Sharon",
  "Warrensville Heights",
  "Dallas",
  "Lapeer",
  "Lahaina",
  "Paso de los Toros",
  "Broome",
  "Homosassa Springs",
  "Bensville",
  "Kittanning",
  "Beyla",
  "Globe",
  "Namanga",
  "Big Bear City",
  "Uyar",
  "Medway",
  "Schodack",
  "Mercerville",
  "Monfort Heights",
  "Taiynsha",
  "Bellefonte",
  "Fort Morgan",
  "Weigelstown",
  "McPherson",
  "Qulan",
  "Andover",
  "Mounds View",
  "Rocky Point",
  "Worthington",
  "Short Hills",
  "Lake Tapps",
  "Stoughton",
  "New Ulm",
  "Glenn Heights",
  "Bonita",
  "Dunmore",
  "Borger",
  "New Kingman-Butler",
  "North Palm Beach",
  "Dabola",
  "Port Clinton",
  "Bay St. Louis",
  "Macará",
  "Lakeside",
  "Bellefontaine",
  "Taylorville",
  "Wawarsing",
  "Mŭynoq",
  "Champasak",
  "Glen Carbon",
  "Rogers",
  "Muscoy",
  "Ridge",
  "Moody",
  "Forest Hill",
  "Superior",
  "Mahomet",
  "Uyuni",
  "Artondale",
  "South Daytona",
  "Fallsburg",
  "West Carrollton",
  "Tarboro",
  "South Sioux City",
  "Port Neches",
  "Oak Island",
  "East Hampton",
  "Tierra Amarilla",
  "Port Royal",
  "Chilca",
  "Herrin",
  "Hamilton Square",
  "Malibu",
  "Keokuk",
  "Camabatela",
  "Justice",
  "Horsham",
  "Endicott",
  "Suamico",
  "Los Lagos",
  "German Flatts",
  "Channahon",
  "Sun Village",
  "Bastrop",
  "Powell River",
  "Riverton",
  "Hammonton",
  "Plover",
  "Camp Pendleton South",
  "Loveland",
  "Dorado",
  "Panama City Beach",
  "Cedartown",
  "Grimes",
  "Pennsville",
  "Lewisboro",
  "Cloquet",
  "St. Ann",
  "Wickliffe",
  "Prairie Ridge",
  "Lynnfield",
  "Diébougou",
  "Forrest City",
  "Lilburn",
  "Librazhd",
  "Frederick",
  "Joppatowne",
  "Davidson",
  "Santaquin",
  "Spearfish",
  "Fergus Falls",
  "Uglegorsk",
  "West Monroe",
  "Charlotte",
  "Sunchales",
  "Mogocha",
  "Boone",
  "Cedar Grove",
  "Sault Ste. Marie",
  "Oskaloosa",
  "Vinings",
  "Goldenrod",
  "Richmond Hill",
  "Lakeland",
  "Lindale",
  "Jacksonville",
  "Medfield",
  "LaBelle",
  "Grand Terrace",
  "Barcelos",
  "Verde Village",
  "Derby",
  "San Anselmo",
  "Spring Lake",
  "Royse City",
  "Windsor Locks",
  "Minden",
  "Virginia",
  "Kingsbury",
  "Parker",
  "Markham",
  "Corinth",
  "Choctaw",
  "Port Pirie",
  "Cypress Lake",
  "Azle",
  "South Charleston",
  "Moss Bluff",
  "Cornwall",
  "Calbuco",
  "Lakeland North",
  "Woodhaven",
  "Lincolnwood",
  "Winnetka",
  "Platteville",
  "Cedar Lake",
  "New Kensington",
  "Coventry",
  "Ringwood",
  "Arkansas City",
  "Oregon",
  "North St. Paul",
  "Colonial Park",
  "Bayou Blue",
  "Wells Branch",
  "Carnot-Moon",
  "Fredericksburg",
  "Avenal",
  "Colesberg",
  "Cheney",
  "Truckee",
  "Kewanee",
  "Finneytown",
  "Kilindoni",
  "Palos Heights",
  "Larkspur",
  "Lumberton",
  "Eatontown",
  "Othello",
  "St. Peter",
  "Gulfport",
  "Northlake",
  "Sandpoint",
  "Magalia",
  "Lakeland Highlands",
  "Trophy Club",
  "Ulster",
  "Elkhorn",
  "Ellwood City",
  "North Castle",
  "North Smithfield",
  "Waconia",
  "Nazyvayevsk",
  "Newington Forest",
  "Palmer Town",
  "Pine Castle",
  "Shepherdsville",
  "Warwick",
  "Ishpeming",
  "Middle Valley",
  "Irmo",
  "Yulee",
  "Boloso",
  "Holiday City-Berkeley",
  "Beatrice",
  "Porvoo",
  "Tyngsborough",
  "Blythe",
  "Green River",
  "Holly Hill",
  "Gladstone",
  "Travilah",
  "Blue Ash",
  "Pontiac",
  "Woolwich",
  "Lake Wylie",
  "Portales",
  "Belomorsk",
  "Athens",
  "Florida City",
  "Neosho",
  "North Greenbush",
  "Babylon",
  "Harwich",
  "Speedway",
  "Cascades",
  "Kelso",
  "Cherlak",
  "Riverview",
  "Robertsville",
  "Mays Chapel",
  "Brandfort",
  "Lakewood Park",
  "Fate",
  "Jesup",
  "Ocean Pines",
  "James Island",
  "Alta",
  "Uacu Cungo",
  "Waimea",
  "Loganville",
  "Poquoson",
  "Pepperell",
  "Tucson Estates",
  "Glen Rock",
  "Angra do Heroísmo",
  "Broussard",
  "Gatesville",
  "Los Alamos",
  "Festus",
  "Leeds",
  "Aleksandrovsk-Sakhalinskiy",
  "Cottonwood",
  "Overlea",
  "Delavan",
  "Kingsburg",
  "Glenwood Springs",
  "Princetown",
  "North Valley",
  "Woodinville",
  "Wanaque",
  "Rehoboth",
  "Upper",
  "Dolinsk",
  "Mount Sinai",
  "Elgin",
  "Greensburg",
  "Kasaji",
  "Lake Station",
  "Brown Deer",
  "Tepelenë",
  "Pell City",
  "Cordele",
  "Lansing",
  "Scotts Valley",
  "Chuquicamata",
  "Macedonia",
  "Lexington Park",
  "East Bethel",
  "Grants",
  "Somerset",
  "Killarney",
  "Stafford",
  "Somersworth",
  "Robstown",
  "Silver City",
  "Carahue",
  "Nacozari Viejo",
  "El Campo",
  "Falmouth",
  "Qazmalar",
  "Otsego",
  "Bucyrus",
  "Prineville",
  "Shady Hills",
  "Malargüe",
  "Healdsburg",
  "Kempsey",
  "Sedro-Woolley",
  "Mango",
  "Lincoln City",
  "Lake Los Angeles",
  "Dolo Bay",
  "Gulf Shores",
  "Largo",
  "Stone Ridge",
  "Cadillac",
  "Griswold",
  "Jupiter Farms",
  "Cafayate",
  "Enumclaw",
  "Port Lavaca",
  "Nelson",
  "Negēlē",
  "Bogalusa",
  "Goulds",
  "Florham Park",
  "Port Washington",
  "Nanakuli",
  "Malvern",
  "Rurrenabaque",
  "Lovington",
  "Chowchilla",
  "Magdagachi",
  "Newton",
  "Elma",
  "Cocoa Beach",
  "East Grand Rapids",
  "Coolidge",
  "The Pinery",
  "Diamond Springs",
  "Miller Place",
  "Martinsville",
  "Tomball",
  "Putnam Valley",
  "Weatherford",
  "Upper Montclair",
  "Dranesville",
  "Arlington",
  "Athol",
  "Snyder",
  "Ozark",
  "Norfolk",
  "Chino Valley",
  "Seven Hills",
  "Collegedale",
  "Gympie",
  "Mamakating",
  "Bridgeton",
  "Brattleboro",
  "Mont-Laurier",
  "Schiller Park",
  "Jerome",
  "Dudley",
  "Lundazi",
  "Clute",
  "Nocatee",
  "Muswellbrook",
  "Delhi",
  "Carver",
  "Eunice",
  "D'Iberville",
  "White House",
  "Fairfax Station",
  "Los Alamitos",
  "Pichilemu",
  "Wrentham",
  "Isabela",
  "Sandy",
  "Plano",
  "Elizabethtown",
  "Smyrna",
  "Aguadilla",
  "Tarrytown",
  "Walker Mill",
  "Hawaiian Paradise Park",
  "Severnyy",
  "North Bellport",
  "Bainbridge",
  "Gramsh",
  "Claiborne",
  "Woodward",
  "Omaruru",
  "Accokeek",
  "Evanston",
  "Port Morant",
  "Minneola",
  "Bennettsville",
  "San Germán",
  "Archdale",
  "Zmeinogorsk",
  "Río Colorado",
  "Cerrillos",
  "Aginskoye",
  "Bellmawr",
  "Port St. John",
  "West Plains",
  "Portachuelo",
  "Westview",
  "Valencia West",
  "Cedarburg",
  "Ravenna",
  "Emmaus",
  "Maltby",
  "Bradford",
  "Grove City",
  "Valdez",
  "Guymon",
  "Haddonfield",
  "Samaná",
  "Leon Valley",
  "Doctor Phillips",
  "Scottsburg",
  "Endwell",
  "Excelsior Springs",
  "East Windsor",
  "Little Chute",
  "East Renton Highlands",
  "Spirit Lake",
  "South Yarmouth",
  "Hibbing",
  "Monte Quemado",
  "Kirs",
  "DuBois",
  "Storm Lake",
  "Humahuaca",
  "Caracaraí",
  "Woods Cross",
  "Belén",
  "Makoua",
  "North Vernon",
  "Mendota Heights",
  "Gloucester City",
  "Coshocton",
  "Webb City",
  "Mount Sterling",
  "Fulton",
  "Lamesa",
  "Mexico",
  "Wyandanch",
  "Delémont",
  "Mountain Top",
  "Minooka",
  "Waupun",
  "Campton Hills",
  "Haysville",
  "Leicester",
  "Hormigueros",
  "Princess Anne",
  "Santa Cruz",
  "Franklin Lakes",
  "Angola",
  "Beachwood",
  "Shiloh",
  "Lakeville",
  "Lighthouse Point",
  "Soquel",
  "East Hanover",
  "Otar",
  "Prieska",
  "Macclenny",
  "Gardere",
  "Lower Burrell",
  "Jefferson Hills",
  "Canyon Lake",
  "Kennebunk",
  "Mwingi",
  "Springdale",
  "Merriam",
  "Newport East",
  "Fitzgerald",
  "Chalco",
  "Greentree",
  "Niles",
  "Pompton Lakes",
  "Qaşr-e Shīrīn",
  "Lake Morton-Berrydale",
  "Camp Verde",
  "Koror",
  "Wahpeton",
  "Yorktown",
  "Kaneohe Station",
  "Summerfield",
  "Mack",
  "Echelon",
  "Maysville",
  "Walden",
  "Madison Heights",
  "Huntingdon",
  "Woodland Park",
  "Munhall",
  "Lake Grove",
  "Mayfield",
  "Tremonton",
  "Damascus",
  "Two Rivers",
  "Lunenburg",
  "Dobbs Ferry",
  "Folkston",
  "Parkes",
  "River Forest",
  "Lithgow",
  "Grosse Pointe Park",
  "Woodmere",
  "Kalaoa",
  "Gardnerville Ranchos",
  "Hawera",
  "Fairview Shores",
  "Lowes Island",
  "Brookings",
  "Pedernales",
  "Wantage",
  "Satellite Beach",
  "Halfway",
  "Sierra Madre",
  "Rifle",
  "Millington",
  "Arizona City",
  "Holbrook",
  "Urbana",
  "Dent",
  "Bairnsdale",
  "Mount Kisco",
  "Galena Park",
  "Elkins",
  "Bowen",
  "Fox Crossing",
  "Viera East",
  "Lindon",
  "Brock Hall",
  "Alamosa",
  "Braselton",
  "Stayton",
  "Moncks Corner",
  "West Caldwell",
  "Garden Acres",
  "Bacliff",
  "Mitchellville",
  "Big Lake",
  "Nantucket",
  "Destrehan",
  "Grand Haven",
  "Norwell",
  "Norwalk",
  "Chenango",
  "Winchester",
  "Ogdensburg",
  "Broomall",
  "Panthersville",
  "Kargat",
  "Hailey",
  "Vidor",
  "Tamalpais-Homestead Valley",
  "Linthicum",
  "Kenora",
  "Rochelle",
  "Crestwood",
  "Rosaryville",
  "Annapolis Neck",
  "Show Low",
  "Ngorongoro",
  "Greencastle",
  "Pozo Almonte",
  "Forestville",
  "Pike Creek Valley",
  "Cheval",
  "Sussex",
  "Burr Ridge",
  "Totowa",
  "Winnemucca",
  "Dawson Creek",
  "California City",
  "Kings Mountain",
  "Farmersville",
  "Atchison",
  "Weddington",
  "Yeppoon",
  "Spencer",
  "Browns Mills",
  "Port Salerno",
  "Silverton",
  "Winchendon",
  "Muskegon Heights",
  "Elk City",
  "Ironton",
  "Point Pleasant",
  "Union Park",
  "Pearsall",
  "Hudson",
  "Kennett",
  "Vermillion",
  "Essex Junction",
  "Ludington",
  "Whitehall",
  "Përmet",
  "Gold Canyon",
  "Massena",
  "Poulsbo",
  "Worth",
  "Three Rivers",
  "Forest",
  "Gulf Gate Estates",
  "Miami Shores",
  "Toccoa",
  "North Logan",
  "Ketchikan",
  "Morro Bay",
  "San Diego Country Estates",
  "Petal",
  "Bellefontaine Neighbors",
  "Khilok",
  "Lake Arrowhead",
  "Myrtle Grove",
  "Benito Juárez",
  "Wapakoneta",
  "Hillsdale",
  "Bedford Heights",
  "Songo",
  "Millsboro",
  "Kimpese",
  "Ballston",
  "Lloyd",
  "Fredonia",
  "Maynard",
  "Hanson",
  "Van Wert",
  "Batemans Bay",
  "Fox Lake",
  "Smithfield",
  "Timonium",
  "Hornell",
  "Oak Hills",
  "Doraville",
  "Pine Hill",
  "Saks",
  "Scituate",
  "Castle Pines",
  "Amos",
  "Tanaina",
  "Lanham",
  "Kapaa",
  "Roscoe",
  "Bellmead",
  "Puquio",
  "Somers Point",
  "Summerville",
  "Little Canada",
  "Martin",
  "Lincoln Park",
  "Southport",
  "Wyomissing",
  "Vermilion",
  "Sauk Village",
  "Norton",
  "Santiago",
  "Acushnet",
  "Springbok",
  "Baie-Comeau",
  "Lawrenceburg",
  "Arkadelphia",
  "Burtonsville",
  "Bothell East",
  "Bogué",
  "Manville",
  "Cape Canaveral",
  "Lake Arbor",
  "Avon Park",
  "Middle Island",
  "Fort Bragg",
  "Hull",
  "Marlboro Village",
  "Puerto Quijarro",
  "Malone",
  "Highland City",
  "Salanso",
  "Kendallville",
  "Forest Acres",
  "Kiama",
  "Clearfield",
  "Anderson",
  "Weston",
  "Bethanie",
  "Arden Hills",
  "Taksimo",
  "Cusseta",
  "Melvindale",
  "DeForest",
  "Tega Cay",
  "Magnolia",
  "Burkburnett",
  "Warr Acres",
  "Cresson",
  "Fort Madison",
  "Raymond",
  "Plattekill",
  "Raceland",
  "Camden",
  "East Greenwich",
  "Shamva",
  "Sweet Home",
  "Saddlebrooke",
  "Comstock Park",
  "Canandaigua",
  "Pleasant View",
  "Nanticoke",
  "Hinton",
  "Wood River",
  "Reading",
  "Caldera",
  "Struthers",
  "Lonquimay",
  "Marawī",
  "Alvorada",
  "Valley Cottage",
  "Alcoa",
  "Elon",
  "Sleepy Hollow",
  "Bolivar",
  "Scottdale",
  "Busselton",
  "Kinnelon",
  "Friendly",
  "Kairaki",
  "Shasta Lake",
  "Fort Bliss",
  "Troy",
  "Kirkland",
  "Old Saybrook",
  "Shawano",
  "Fort Salonga",
  "Fife",
  "Wasilla",
  "Havre",
  "Innisfail",
  "Katherine",
  "Waterloo",
  "Otuzco",
  "Mentone",
  "Forestdale",
  "Manvel",
  "Pleasant Grove",
  "Quartz Hill",
  "Nogliki",
  "Kutztown",
  "River Grove",
  "Sumner",
  "Waldwick",
  "Standish",
  "Snohomish",
  "Bluffton",
  "Hartsville",
  "Eumseong",
  "Key Largo",
  "Salaverry",
  "Rrëshen",
  "Bonham",
  "River Vale",
  "Laurens",
  "Wells",
  "Coffeyville",
  "Fulshear",
  "Gages Lake",
  "Bel Air",
  "Roboré",
  "Southborough",
  "Conway",
  "Fort Lupton",
  "Colts Neck",
  "Taltal",
  "Grenada",
  "Skovorodino",
  "Kuruman",
  "Moba",
  "Senanga",
  "Moranbah",
  "Gyangzê",
  "Hammerfest",
  "Choele Choel",
  "Gar",
  "Reserve",
  "Selkirk",
  "Vera",
  "Makale",
  "Tipp City",
  "Flat Rock",
  "Molalla",
  "Madera Acres",
  "Vernon",
  "East Rutherford",
  "Lake Barcroft",
  "Country Club",
  "Edgewater",
  "Blackhawk",
  "Liberty Lake",
  "Jewett City",
  "Northampton",
  "Holmen",
  "Philipsburg",
  "Wabash",
  "Croydon",
  "Corte Madera",
  "Helena-West Helena",
  "East Aurora",
  "Bogandé",
  "Dalby",
  "Chevy Chase",
  "Cross Lanes",
  "Denham Springs",
  "Fort Oglethorpe",
  "Sugar Grove",
  "Danville",
  "Cuero",
  "Lake Monticello",
  "Mapleton",
  "Bee Ridge",
  "Ellisville",
  "Carroll",
  "New Port Richey East",
  "Merrill",
  "Prospect",
  "Orland",
  "Woodfield",
  "Park Forest Village",
  "South Huntington",
  "Manor",
  "Steinbach",
  "Fuller Heights",
  "Waggaman",
  "Marysville",
  "Bull Mountain",
  "Hamburg",
  "Cody",
  "Silsbee",
  "George Mason",
  "Hope",
  "Miles City",
  "Blacklick Estates",
  "Gifford",
  "Eidson Road",
  "Harleysville",
  "Tell City",
  "Coweta",
  "White Marsh",
  "Winterville",
  "Boaz",
  "Kittery",
  "St. Pete Beach",
  "North Gates",
  "Mooresville",
  "Bernalillo",
  "Bishop",
  "Pella",
  "Rio del Mar",
  "Rayne",
  "Vega Alta",
  "San Justo",
  "Central City",
  "Kendall Park",
  "Box Elder",
  "Clay",
  "Rye Brook",
  "Roxborough Park",
  "Crestline",
  "Roxboro",
  "Emmett",
  "Charters Towers",
  "Parsons",
  "Potsdam",
  "Brownfield",
  "Hackettstown",
  "Willoughby Hills",
  "Bryan",
  "New Baltimore",
  "Cheat Lake",
  "Mount Holly",
  "Columbia City",
  "Amityville",
  "Howell",
  "Mound",
  "Conning Towers Nautilus Park",
  "Fort Valley",
  "Gig Harbor",
  "Jennings",
  "Rockcreek",
  "Lantana",
  "Bardmoor",
  "Beecher",
  "DuPont",
  "Wilmore",
  "Reedsburg",
  "Dunn Loring",
  "Baker City",
  "Augusta",
  "Francisville",
  "Linganore",
  "Craig",
  "Stansbury Park",
  "Platte City",
  "Harrisonville",
  "Clarion",
  "Siler City",
  "Lakes",
  "Manistee",
  "Fort Stockton",
  "Castelli",
  "Mount Airy",
  "Itasca",
  "Lititz",
  "Steger",
  "Little Falls",
  "Mission",
  "The Village",
  "Harahan",
  "Westwood",
  "Yorkshire",
  "Budd Lake",
  "Fayetteville",
  "Emerald",
  "Rice Lake",
  "Lambertville",
  "Stonegate",
  "Girard",
  "Marlton",
  "Le Mars",
  "Good Hope",
  "Redlands",
  "La Plata",
  "Cloverdale",
  "Gorē",
  "Orlu",
  "Amherst",
  "Medulla",
  "Louisville",
  "Flossmoor",
  "Bethalto",
  "Gunbarrel",
  "North College Hill",
  "Victoria",
  "Independent Hill",
  "Muncy",
  "Monmouth",
  "Fort Polk South",
  "Saline",
  "Gloucester Point",
  "Bohemia",
  "White Meadow Lake",
  "Dillon",
  "Lynwood",
  "Hartland",
  "Swan Hill",
  "Tomah",
  "Woodmoor",
  "Brandon",
  "College Place",
  "Ulladulla",
  "Kapuskasing",
  "Cape Elizabeth",
  "Northwest Harborcreek",
  "Chesapeake Ranch Estates",
  "Viera West",
  "Ecorse",
  "Jersey Shore",
  "Basalt",
  "Elwood",
  "Powdersville",
  "Red Bank",
  "Melissa",
  "Highland Park",
  "Little River",
  "Brownsville",
  "Frankfort Square",
  "Corozal",
  "Lake Mohawk",
  "Blackstone",
  "Lenoir City",
  "Sullivan",
  "Jeannette",
  "Indianola",
  "Fultondale",
  "Sans Souci",
  "Larkfield-Wikiup",
  "Cold Springs",
  "Pryor Creek",
  "Murphysboro",
  "San Elizario",
  "Helotes",
  "Harrisburg",
  "Tiburon",
  "Fair Oaks",
  "Grosse Pointe Farms",
  "Thief River Falls",
  "Kodiak",
  "Washington Terrace",
  "Boiling Springs",
  "Madeira",
  "Beverly Hills",
  "West Tawakoni",
  "Scaggsville",
  "Eagle Point",
  "Guthrie",
  "LaSalle",
  "Kings Grant",
  "Meadowbrook",
  "Cabo Rojo",
  "Ménaka",
  "St. Augustine Shores",
  "Waseca",
  "Fairmont",
  "Groton",
  "Fair Oaks Ranch",
  "Colac",
  "Fallston",
  "Ayr",
  "St. Anthony",
  "Dauphin",
  "Hillview",
  "Brunswick",
  "Madras",
  "Fort Irwin",
  "Wakefield-Peacedale",
  "Brewer",
  "Namsos",
  "Rapid Valley",
  "Garrison",
  "White City",
  "Dexter",
  "Oakbrook",
  "Tuba City",
  "Manteno",
  "Salisbury",
  "Waihee-Waiehu",
  "Mechanicsburg",
  "Walterboro",
  "Audubon",
  "Star",
  "Cypress Gardens",
  "Aliquippa",
  "St. Stephens",
  "Picnic Point",
  "Olney",
  "Merrydale",
  "Plainville",
  "Ville Platte",
  "Chanute",
  "Oakwood",
  "Chamical",
  "Rigby",
  "Montecito",
  "Rotonda",
  "Oak Hills Place",
  "Newmarket",
  "Sheffield Lake",
  "Carencro",
  "Triangle",
  "Succasunna",
  "Garden City",
  "Brigantine",
  "Shiprock",
  "Beckett Ridge",
  "Park Ridge",
  "Sodankylä",
  "Cresskill",
  "Hickam Housing",
  "Sheffield",
  "Navasota",
  "Memphis",
  "Abdurahmoni Jomí",
  "Oakville",
  "Olmsted Falls",
  "Rock Falls",
  "Bithlo",
  "Scotchtown",
  "Villas",
  "St. Albans",
  "Grinnell",
  "Sutherlin",
  "Golden Hills",
  "Berthoud",
  "Airmont",
  "Glenshaw",
  "Kemijärvi",
  "Matawan",
  "Rawlins",
  "Sellersburg",
  "Micco",
  "New Richmond",
  "Pitman",
  "Quakertown",
  "Wesley Chapel",
  "Waverly",
  "Canonsburg",
  "Seabrook",
  "Kearney",
  "Siteía",
  "Makaha",
  "Leesville",
  "Woodway",
  "Earlimart",
  "Graham",
  "Alderwood Manor",
  "Seaside",
  "Calimesa",
  "Port Townsend",
  "Sultan",
  "Edgewater Park",
  "Midland",
  "Perry Heights",
  "Robertsdale",
  "Susanville",
  "Shannon",
  "Jerseyville",
  "Woodburn",
  "Gresham Park",
  "Zimmerman",
  "Harrodsburg",
  "Cape St. Claire",
  "Richlands",
  "Scott",
  "Closter",
  "Long Hill",
  "Riverdale",
  "Murrells Inlet",
  "Palm Beach",
  "Pineville",
  "Old Orchard Beach",
  "Center Moriches",
  "Mahopac",
  "Pike Creek",
  "Rhinelander",
  "Livingston",
  "Pine Lake Park",
  "Edgemere",
  "Holualoa",
  "Westampton",
  "North Sarasota",
  "Ivins",
  "Montvale",
  "Edgewood",
  "Heath",
  "Lake Villa",
  "Clarendon Hills",
  "Claymont",
  "Kirby",
  "Orange Park",
  "West Frankfort",
  "Fountain Inn",
  "Rio Vista",
  "Stuttgart",
  "South Kensington",
  "Perryton",
  "Clayton",
  "Lindstrom",
  "Fairless Hills",
  "Perryville",
  "Mayo",
  "Bonadelle Ranchos-Madera Ranchos",
  "La Libertad",
  "Lake Park",
  "Elsmere",
  "Orrville",
  "Frostburg",
  "Rockland",
  "Birch Bay",
  "East Grand Forks",
  "Monroe",
  "San Sebastián",
  "Ladue",
  "Cortez",
  "Wagoner",
  "Temperance",
  "Laurel",
  "North Madison",
  "Dumbarton",
  "Los Altos Hills",
  "Morrisville",
  "Farmville",
  "Detroit Lakes",
  "Sanatoga",
  "Des Peres",
  "Kingaroy",
  "North Haledon",
  "Tallulah",
  "Indian Harbour Beach",
  "Monett",
  "Inverell",
  "Buckhannon",
  "Signal Mountain",
  "Chos Malal",
  "Perkasie",
  "Miramar Beach",
  "Westwego",
  "Corrales",
  "Celebration",
  "Port Wentworth",
  "Bay Minette",
  "Mount Vista",
  "Prairie du Sac",
  "Willowbrook",
  "Aspen",
  "Silver Springs Shores",
  "Floris",
  "White Horse",
  "Hazard",
  "Hoquiam",
  "Oranjemund",
  "Moundsville",
  "Sunset Hills",
  "San Antonio Oeste",
  "Glens Falls North",
  "Beaver Falls",
  "Pleasant Hill",
  "Corning",
  "Willow Street",
  "Fern Park",
  "Mount Pleasant",
  "Spotswood",
  "Goundam",
  "McFarland",
  "St. Johns",
  "Westlake Village",
  "Kensington",
  "University of Virginia",
  "Progress Village",
  "Gridley",
  "Upper Saddle River",
  "Kulpsville",
  "Alamo Heights",
  "Hawthorn Woods",
  "Mason",
  "Sturgeon Bay",
  "Paw Paw",
  "Tuscumbia",
  "East York",
  "Cohasset",
  "Gaylord",
  "Jessup",
  "Runnemede",
  "Congers",
  "Socorro",
  "New Prague",
  "Richmond Heights",
  "McRae-Helena",
  "Conover",
  "Wading River",
  "Boonton",
  "Jefferson City",
  "Murphy",
  "Pukalani",
  "Kennedale",
  "Oak Hill",
  "Aurora",
  "Ripley",
  "Thompsonville",
  "Holly",
  "Gering",
  "Whitehouse",
  "Doylestown",
  "Lansdowne",
  "Oradell",
  "Johnstown",
  "Kenilworth",
  "Pebble Creek",
  "Wetumpka",
  "Thomson",
  "Denison",
  "Lopatcong",
  "Fairmount",
  "Canal Winchester",
  "Petoskey",
  "Old Jefferson",
  "Absecon",
  "Middleport",
  "Napoleon",
  "Center Line",
  "Savoy",
  "Innsbrook",
  "Waynesburg",
  "Aransas Pass",
  "Croton-on-Hudson",
  "Tuskegee",
  "Gulf Hills",
  "Fort Mitchell",
  "Irondale",
  "Arab",
  "St. Martin",
  "Bloomingdale",
  "Seffner",
  "Pismo Beach",
  "Fletcher",
  "Kenton",
  "Morehead",
  "Rolling Hills Estates",
  "Mystic Island",
  "Mount Joy",
  "Aztec",
  "Moree",
  "Lake Carmel",
  "Hyrum",
  "Mahtomedi",
  "Decorah",
  "Bayport",
  "Fruitland Park",
  "Whitestown",
  "Laurel Hill",
  "Tillamook",
  "Country Club Estates",
  "Castle Shannon",
  "Applewood",
  "Bellevue",
  "Burton",
  "Nolensville",
  "Airway Heights",
  "Pingree Grove",
  "Jennings Lodge",
  "Sangaree",
  "Alliance",
  "Chestnut Ridge",
  "Lugoff",
  "Black Mountain",
  "Menominee",
  "Trinidad",
  "Niagara",
  "Brooksville",
  "Millis",
  "West Haven-Sylvan",
  "West Vero Corridor",
  "Roanoke",
  "Pleasant Hills",
  "Omak",
  "Village Green-Green Ridge",
  "St. Rose",
  "Dalhart",
  "Almirante",
  "Monona",
  "North Weeki Wachee",
  "Westphalia",
  "Ahuimanu",
  "Hollymead",
  "Brazil",
  "Oak Brook",
  "Montrose",
  "Rabaul",
  "Gilberts",
  "Puerto Aysén",
  "Fair Lakes",
  "Felida",
  "Vinton",
  "Waller",
  "Chardon",
  "Cumberland Hill",
  "Cedar Hills",
  "Richland Hills",
  "Hillside",
  "Sound Beach",
  "Fort Dix",
  "Firebaugh",
  "Willits",
  "Long Grove",
  "Deniliquin",
  "Fishersville",
  "Briarcliff Manor",
  "Crestwood Village",
  "Jefferson",
  "Ayer",
  "Raritan",
  "Long Branch",
  "Fort Belvoir",
  "Hastings-on-Hudson",
  "Ripon",
  "El Calafate",
  "Brentwood",
  "Orting",
  "La Junta",
  "Abingdon",
  "Shorewood",
  "Robinwood",
  "West Long Branch",
  "Green Cove Springs",
  "Albion",
  "Dry Run",
  "Lake Dallas",
  "Oberlin",
  "Southwest Ranches",
  "Latrobe",
  "Flushing",
  "Blair",
  "Camp Hill",
  "Old Forge",
  "Rathdrum",
  "Jersey Village",
  "South Boston",
  "Downingtown",
  "Sheboygan Falls",
  "Pahokee",
  "Cherry Valley",
  "Manhasset",
  "Columbiana",
  "Middletown",
  "Bel Aire",
  "Venice Gardens",
  "Maryville",
  "Carnegie",
  "Port Jefferson",
  "Mecca",
  "West Boylston",
  "North Springfield",
  "Campbell",
  "Ersekë",
  "Grand Blanc",
  "Esperance",
  "Mascoutah",
  "Baldwinsville",
  "South Cleveland",
  "Crookston",
  "Carl Junction",
  "Prien",
  "Cheraw",
  "Blandon",
  "Dryden",
  "Selah",
  "Terrace Heights",
  "Olivette",
  "Los Fresnos",
  "Covington",
  "Sallisaw",
  "Southwood Acres",
  "Dock Junction",
  "Bernardsville",
  "Duvall",
  "Colonie",
  "Fort Riley",
  "Grand Ledge",
  "San Juan del Sur",
  "Oceano",
  "Fruitvale",
  "Broadview",
  "Clover",
  "Joshua",
  "Manhattan",
  "Winters",
  "Butler",
  "Rockmart",
  "Ancient Oaks",
  "Delta",
  "Canóvanas",
  "Quarryville",
  "Orosi",
  "Victor Harbor",
  "Quincy",
  "Catalina",
  "Seaford",
  "Mansfield Center",
  "Emerson",
  "Citrus Hills",
  "Hampton",
  "Larose",
  "Seville",
  "Ione",
  "Park City",
  "York",
  "Big Stone Gap",
  "Clear Lake",
  "Norwich",
  "Inwood",
  "Antigo",
  "Scotia",
  "Round Lake Park",
  "River Oaks",
  "West Slope",
  "Half Moon",
  "Pittston",
  "Ormond-by-the-Sea",
  "Wapato",
  "Swainsboro",
  "Altoona",
  "South Beloit",
  "Revelstoke",
  "Thomaston",
  "Sebastopol",
  "Rolesville",
  "Estes Park",
  "South Lockport",
  "Southeast Arcadia",
  "Galliano",
  "West Concord",
  "Pomona",
  "Hales Corners",
  "Heyin",
  "Plaistow",
  "Gettysburg",
  "Eden Isle",
  "Grove",
  "Robinson",
  "Fort Scott",
  "Aberdeen",
  "New Roads",
  "Sudden Valley",
  "Escalon",
  "Brighton",
  "Wilmington Manor",
  "Ogden",
  "Devils Lake",
  "Fairfax",
  "Carbondale",
  "Fort Pierce North",
  "San Antonio",
  "Lamar",
  "Kasson",
  "Haddon Heights",
  "Corona de Tucson",
  "Ojai",
  "Marshall",
  "Marengo",
  "Happy Valley",
  "Silvis",
  "Pinehurst",
  "Baltimore Highlands",
  "Aledo",
  "Calipatria",
  "Evergreen",
  "Lake Shore",
  "Pulaski",
  "San Pablo Villa de Mitla",
  "Philadelphia",
  "Highlands",
  "Vandenberg Village",
  "Whitefish",
  "Southgate",
  "Mount Shasta",
  "Richfield",
  "Chehalis",
  "Zuni Pueblo",
  "Pinson",
  "Delafield",
  "Diamondhead",
  "Saranac Lake",
  "Waupaca",
  "Sappington",
  "Helena Valley Southeast",
  "Strathmore",
  "Lockwood",
  "Banff",
  "Young",
  "Yarmouth",
  "Hughson",
  "Webster City",
  "Hubbard",
  "Porters Neck",
  "River Rouge",
  "Winsted",
  "Waite Park",
  "Cotati",
  "Flowery Branch",
  "Beebe",
  "Morrow",
  "Rockton",
  "Homeland",
  "Fairlawn",
  "Woodlake",
  "Withamsville",
  "Middleborough Center",
  "Gaoual",
  "Gold River",
  "Green",
  "Salida",
  "Glastonbury Center",
  "Wellington",
  "Whiteville",
  "New London",
  "LaFayette",
  "Camp Pendleton North",
  "Middleton",
  "Villa Hills",
  "Yreka",
  "Payette",
  "Hempstead",
  "Charles City",
  "Summit View",
  "Northgate",
  "Marshfield",
  "New Bremen",
  "Santa Clara",
  "Providence",
  "Mount Horeb",
  "McCook",
  "Mentor-on-the-Lake",
  "Albertville",
  "Lake of the Woods",
  "Clarkston",
  "Osceola",
  "North Star",
  "Sandwich",
  "Midland Park",
  "Dos Palos",
  "Thurmont",
  "Herkimer",
  "Monument",
  "Florence",
  "Connellsville",
  "Northport",
  "La Feria",
  "Geneva",
  "Crystal City",
  "Torrington",
  "Edgerton",
  "Baywood",
  "Lowell",
  "Spring Valley Lake",
  "Hiawatha",
  "West Dundee",
  "Wynne",
  "Smithville",
  "Dunbar",
  "Lincolnshire",
  "Ralston",
  "Utuado",
  "Fair Plain",
  "Hudsonville",
  "Pleasantville",
  "New Cumberland",
  "Kenwood",
  "San Martin",
  "Napili-Honokowai",
  "South Weber",
  "Port Jefferson Station",
  "Delphos",
  "Inverness",
  "Soldotna",
  "Leeton",
  "St. Louis",
  "Tyrone",
  "Longboat Key",
  "Leola",
  "Lander",
  "Riverdale Park",
  "Neptune Beach",
  "Vandalia",
  "Ephrata",
  "Oakdale",
  "Corbin",
  "Freeland",
  "Pollock Pines",
  "Ponchatoula",
  "Scappoose",
  "Bonne Terre",
  "Canfield",
  "Strasburg",
  "Skiatook",
  "Gonzales",
  "Bermuda Dunes",
  "East Hills",
  "Winooski",
  "El Rio",
  "Marksville",
  "University of California-Davis",
  "Palmyra",
  "Bellbrook",
  "Austell",
  "Knoxville",
  "Green Brook",
  "Palmer",
  "West Glens Falls",
  "Mantua",
  "La Sarre",
  "Tolleson",
  "Springs",
  "Wauseon",
  "Willows",
  "Carhué",
  "Rockville",
  "Pacific",
  "Rockport",
  "North Amherst",
  "Flatwoods",
  "Bedford",
  "St. Helena",
  "Moab",
  "Milltown",
  "Stanwood",
  "Lawrenceville",
  "Walled Lake",
  "Laurium",
  "Sioux Center",
  "Crete",
  "Gunnedah",
  "Wick",
  "Yardville",
  "Sausalito",
  "Nappanee",
  "Elsa",
  "Keystone Heights",
  "Dade City",
  "Umatilla",
  "Lower Allen",
  "Sanger",
  "Providence Village",
  "Floresville",
  "Berrien Springs",
  "Hornsby Bend",
  "Lampasas",
  "Green Hill",
  "Ridgefield",
  "Northridge",
  "Paintsville",
  "St. Martinville",
  "Landen",
  "Highland Heights",
  "Hudson Falls",
  "Lewisburg",
  "Gibsonville",
  "Crossett",
  "Nebraska City",
  "Belen",
  "Keyport",
  "Narrabri",
  "Lake Lorraine",
  "Guánica",
  "Ladera Heights",
  "Fort Myers Beach",
  "Gunnison",
  "Meraux",
  "Syracuse",
  "Lihue",
  "Lakes of the Four Seasons",
  "Lucas Valley-Marinwood",
  "Stephenville",
  "Tappan",
  "Maryborough",
  "Kapoeta",
  "Live Oak",
  "Juana Díaz",
  "Union Grove",
  "New Paltz",
  "Milton-Freewater",
  "Catoosa",
  "Wytheville",
  "Nuevo",
  "Stratford",
  "Milliken",
  "University Park",
  "Murillo",
  "St. Augustine Beach",
  "Orange City",
  "Madison Park",
  "Belle Isle",
  "Farr West",
  "Leitchfield",
  "Stawell",
  "Emmitsburg",
  "Madison",
  "Mountainside",
  "Plumas Lake",
  "Kiel",
  "Sturtevant",
  "Sedona",
  "Commerce",
  "Adel",
  "Alta Sierra",
  "Atherton",
  "Bridge City",
  "Trumann",
  "Westmere",
  "Desert Palms",
  "Ashland",
  "Cottage Grove",
  "Treasure Island",
  "Mendota",
  "Black Jack",
  "Kentfield",
  "Huron",
  "Ben Lomond",
  "Portage Lakes",
  "Nibley",
  "Mira Monte",
  "Barnesville",
  "Sandston",
  "Allendale",
  "Whitinsville",
  "Thermalito",
  "Mechanicstown",
  "King",
  "Valley Park",
  "Chestertown",
  "Whitemarsh Island",
  "Welcome",
  "Columbia Falls",
  "Brier",
  "Whitmore Lake",
  "North Patchogue",
  "Rumson",
  "Sturgis",
  "Old Greenwich",
  "Mead",
  "Linwood",
  "Perry",
  "Lutherville",
  "Lovejoy",
  "Kaufman",
  "Seward",
  "Roaring Spring",
  "Greenbrier",
  "Cos Cob",
  "Atkinson",
  "Mexia",
  "McCordsville",
  "Beacon Square",
  "Makawao",
  "Cochituate",
  "Clover Creek",
  "Russellville",
  "North Bend",
  "Kingsland",
  "Bath",
  "Loomis",
  "Mount Ivy",
  "Roslyn Heights",
  "Village St. George",
  "Cortland",
  "Lake Mills",
  "Cowra",
  "Fircrest",
  "Hondo",
  "Kimberly",
  "East Shoreham",
  "Colusa",
  "Fort Meade",
  "Roeland Park",
  "Inniswold",
  "Bayville",
  "Taylor Mill",
  "Maple Glen",
  "Crozet",
  "Byron Bay",
  "Plain City",
  "Youngtown",
  "Homeacre-Lyndora",
  "Blairsville",
  "Thames",
  "Enoch",
  "Creswell",
  "Merrimac",
  "Byron Center",
  "Junction City",
  "Brook Highland",
  "Winthrop Harbor",
  "Washington",
  "North Syracuse",
  "Bee Cave",
  "Ephraim",
  "Osprey",
  "Red Hook",
  "Camp Swift",
  "North Caldwell",
  "Edinboro",
  "Huntertown",
  "Interlaken",
  "Bremen",
  "Ambridge",
  "Barberton",
  "Glendive",
  "Portage",
  "Plaquemine",
  "Myers Corner",
  "Locust Grove",
  "Nevada",
  "Gleneagle",
  "Groveland",
  "Stickney",
  "Aquia Harbour",
  "Stevensville",
  "Roosevelt",
  "South Hill",
  "Rinconada",
  "Gorom-Gorom",
  "Selma",
  "Ellettsville",
  "Columbus",
  "Church Hill",
  "Fox Point",
  "Laurence Harbor",
  "South Patrick Shores",
  "Silver Lake",
  "Canton",
  "Martins Ferry",
  "Taneytown",
  "Loudoun Valley Estates",
  "Normandy Park",
  "Cherry Hills Village",
  "Rupert",
  "Cushing",
  "Montevallo",
  "Spring Hill",
  "Beaver Dam",
  "Penn Estates",
  "Tangdong",
  "Green Knoll",
  "Mattydale",
  "Horta",
  "Clairton",
  "Lacy-Lakeview",
  "Amite City",
  "Lawrence",
  "Irvington",
  "Orange Lake",
  "Pembroke Park",
  "Lemoore Station",
  "Belle Plaine",
  "Catasauqua",
  "Howland Center",
  "Essex",
  "Indian River Estates",
  "East Rochester",
  "Ironwood",
  "Hapeville",
  "International Falls",
  "Cherryville",
  "Slippery Rock",
  "Waimanalo",
  "Savage",
  "Veneta",
  "Abilene",
  "Decatur",
  "Hillsboro",
  "Lexington",
  "Laurel Bay",
  "Wharton",
  "Keyser",
  "Prairie du Chien",
  "Jimaní",
  "Granite Falls",
  "North Riverside",
  "Blackwell",
  "Rittman",
  "Yorketown",
  "Nitro",
  "Parkwood",
  "Gypsum",
  "Valley City",
  "Lionville",
  "Broadway",
  "Plattsmouth",
  "Islamorada",
  "Eldridge",
  "Hurricane",
  "Avon",
  "Lackland AFB",
  "Harrisville",
  "Woodland",
  "Penn Yan",
  "Litchfield",
  "Gulf Breeze",
  "Tumut",
  "Rossford",
  "Watford City",
  "Tipton",
  "Cooma",
  "East Porterville",
  "Powell",
  "Camilla",
  "Covedale",
  "Richboro",
  "Galax",
  "Bellwood",
  "Spring Lake Park",
  "Kosciusko",
  "Oelwein",
  "Fort Myers Shores",
  "Topsfield",
  "Fowler",
  "Talent",
  "Marvin",
  "Woodfin",
  "Elkin",
  "Kathleen",
  "Cheverly",
  "Geneseo",
  "Rockaway",
  "Milford",
  "Pratt",
  "Gulf Park Estates",
  "Homestead Meadows South",
  "Horseheads",
  "Clarkston Heights-Vineland",
  "Ingeniero Guillermo N. Juárez",
  "Russells Point",
  "Genoa",
  "Oakmont",
  "Moraine",
  "Garden Home-Whitford",
  "Jeanerette",
  "Keene",
  "Silver Lakes",
  "Rancho Calaveras",
  "Willis",
  "Village of Oak Creek",
  "Dahlonega",
  "Rushville",
  "Devine",
  "South Run",
  "Eagle",
  "Gladewater",
  "Paloma Creek South",
  "Belpre",
  "East Foothills",
  "Lake Murray of Richland",
  "Groesbeck",
  "Flin Flon",
  "Carmel Hamlet",
  "Prairie View",
  "Cynthiana",
  "Otis Orchards-East Farms",
  "Orchard Mesa",
  "Allegan",
  "Cold Spring",
  "Littlestown",
  "Stone Mountain",
  "Merimbula",
  "Emporia",
  "Champion Heights",
  "St. John",
  "Garrett",
  "California",
  "Bensley",
  "Mulvane",
  "Bangor Base",
  "Branford Center",
  "Crystal Lake",
  "Heathcote",
  "Forest Hills",
  "Mila Doce",
  "Waveland",
  "Mount Vernon",
  "White Oak",
  "Sylvester",
  "Dalaba",
  "Everman",
  "Boonville",
  "Red Lion",
  "Solvay",
  "East Glenville",
  "Steilacoom",
  "Eudora",
  "Viola",
  "Enola",
  "Lincroft",
  "Huntington Woods",
  "Johnsburg",
  "Carlstadt",
  "Hamlet",
  "Corry",
  "Biloela",
  "Pimmit Hills",
  "Carthage",
  "Ashville",
  "Finderne",
  "University at Buffalo",
  "Wollochet",
  "Blue Bell",
  "East Pasadena",
  "El Granada",
  "Iowa Park",
  "Cumming",
  "Harlan",
  "Lanett",
  "Henryetta",
  "Snyderville",
  "Carolina Beach",
  "Walker",
  "Riverside",
  "Rockford",
  "Prosser",
  "Plymouth Meeting",
  "Red Chute",
  "Naples Park",
  "Pepper Pike",
  "Swarthmore",
  "Schuyler",
  "Cheshire Village",
  "Bartonville",
  "Carrizo Springs",
  "Blakely",
  "Amory",
  "Rogersville",
  "Nelsonville",
  "Keyes",
  "Montgomery",
  "Barnhart",
  "Bridgeport",
  "Ocean City",
  "East Farmingdale",
  "Eastman",
  "Fruit Heights",
  "South Monroe",
  "Wailua Homesteads",
  "Vineyard",
  "Punxsutawney",
  "North East",
  "De Queen",
  "Tanglewilde",
  "Fabens",
  "Clinton",
  "Valley Falls",
  "Tarrant",
  "Metropolis",
  "Chamberlayne",
  "Kenedy",
  "Kermit",
  "Dacula",
  "Combee Settlement",
  "Clifton Springs",
  "Wheelersburg",
  "Walkersville",
  "Heber Springs",
  "Edgemoor",
  "Barbourville",
  "Bondurant",
  "Alpine",
  "Elm Grove",
  "Laie",
  "Cresaptown",
  "Union Gap",
  "Morrilton",
  "Ramtown",
  "Satsuma",
  "Du Quoin",
  "Homeland Park",
  "Chillicothe",
  "South Williamsport",
  "Battlefield",
  "Montezuma",
  "Glenwood",
  "Heathrow",
  "Bowleys Quarters",
  "Garrettsville",
  "Ingham",
  "Shrewsbury",
  "Spring Ridge",
  "Gowanda",
  "Coco",
  "Leonardtown",
  "Sky Lake",
  "Ararat",
  "Dunlap",
  "Orlovista",
  "Northern Cambria",
  "Lewistown",
  "Beacon Falls",
  "East Alton",
  "Sheridan",
  "Air Force Academy",
  "Holly Springs",
  "Potomac Mills",
  "Tequesta",
  "Vine Grove",
  "New Whiteland",
  "Los Ranchos de Albuquerque",
  "Atlantic",
  "Margate City",
  "Hainesport",
  "Stewartville",
  "East Falmouth",
  "Chelan",
  "Simsbury Center",
  "Ontario",
  "Munford",
  "Rensselaer",
  "Twin Lakes",
  "Mount Healthy",
  "Bridgewater",
  "Dinguiraye",
  "Catskill",
  "River Park",
  "The Pas",
  "Karasburg",
  "Lakemoor",
  "South Gate Ridge",
  "Old Tappan",
  "Gardnerville",
  "Evansville",
  "Coxsackie",
  "Cochran",
  "Chepes",
  "Vail",
  "West Hattiesburg",
  "Woodcreek",
  "Finley",
  "Inverness Highlands South",
  "Litchfield Park",
  "Williamsburg",
  "Wescosville",
  "Rocky Mount",
  "Ashton-Sandy Spring",
  "Eastampton",
  "Tessalit",
  "Darlington",
  "Conley",
  "Northville",
  "Tabor City",
  "Wonthaggi",
  "Watchung",
  "Patterson",
  "Linglestown",
  "Upper Sandusky",
  "Crosspointe",
  "Henderson",
  "Countryside",
  "Shields",
  "Comstock Northwest",
  "Jordan",
  "Dowagiac",
  "Beardstown",
  "Fair Haven",
  "Forestbrook",
  "Staunton",
  "Steelton",
  "Lake Alfred",
  "Port Saint John’s",
  "Progreso",
  "Wesley Hills",
  "Tracyton",
  "Roseland",
  "Maquoketa",
  "Taylor",
  "Clyde",
  "South Hooksett",
  "Maintirano",
  "Selinsgrove",
  "Lake Mohegan",
  "Paulsboro",
  "Chester",
  "Posen",
  "Wyndmoor",
  "Deschutes River Woods",
  "Little Silver",
  "Sullivan City",
  "Crockett",
  "Manasquan",
  "Woodcliff Lake",
  "West Side Highway",
  "Marlow Heights",
  "Villa Park",
  "Norwood",
  "West Clarkston-Highland",
  "Union",
  "Baldwin",
  "Tellico Village",
  "Oreland",
  "Brookville",
  "Glendale",
  "Hartwell",
  "James City",
  "White Rock",
  "Pass Christian",
  "Pelican Bay",
  "New Martinsville",
  "Fort Shawnee",
  "Mount Arlington",
  "Luling",
  "Windcrest",
  "Shady Side",
  "Elburn",
  "Peoria Heights",
  "Kerikeri",
  "Breese",
  "Hilton",
  "Northam",
  "Siesta Key",
  "Hellertown",
  "Gustine",
  "Sinton",
  "Pevely",
  "Sansom Park",
  "Garnet",
  "Ramblewood",
  "Fountainhead-Orchard Hills",
  "Delano",
  "Carterville",
  "Attalla",
  "Grafton",
  "Goodrich",
  "Mount Zion",
  "North Windham",
  "Pauls Valley",
  "Vegreville",
  "North Hills",
  "Anadarko",
  "Gas City",
  "Granite Shoals",
  "Scenic Oaks",
  "Crafton",
  "Grand Turk",
  "Dickson City",
  "Alva",
  "Eucalyptus Hills",
  "Nephi",
  "Chickasaw",
  "Blauvelt",
  "Palmview",
  "Minot AFB",
  "Wellsville",
  "Slaton",
  "West Modesto",
  "Carnarvon",
  "Mathis",
  "Aptos",
  "Washingtonville",
  "Granville",
  "St. Robert",
  "Raton",
  "Belding",
  "Hollidaysburg",
  "Eastwood",
  "Stratmoor",
  "Yoakum",
  "Raleigh Hills",
  "Fairwood",
  "Burnet",
  "North Manchester",
  "Willow Oak",
  "Seminole",
  "Oceanport",
  "Pocahontas",
  "Westville",
  "Cullowhee",
  "Belton",
  "Wrightsville",
  "Benton",
  "Greenwood",
  "Moosic",
  "Mount Plymouth",
  "Alma",
  "Hidden Valley Lake",
  "Hillandale",
  "Hartford City",
  "Trooper",
  "Country Homes",
  "Fort Wright",
  "Anna",
  "Douglass Hills",
  "Spring Grove",
  "Isanti",
  "Pea Ridge",
  "Nazareth",
  "Brush",
  "Greenacres",
  "Paoli",
  "Violet",
  "Princeton",
  "Kununurra",
  "Berne",
  "Willard",
  "Kingstree",
  "Las Flores",
  "Anthony",
  "Pontoon Beach",
  "Lake Bluff",
  "Webster",
  "West Bountiful",
  "Buckner",
  "Pelham Manor",
  "Willow Springs",
  "Fort Washington",
  "Estherville",
  "Vinita",
  "Edna",
  "Linton",
  "Springhill",
  "Hutchins",
  "Old Bethpage",
  "Groveport",
  "Doffing",
  "Harris Hill",
  "Chelsea",
  "Eureka",
  "West Yarmouth",
  "Pana",
  "Islip Terrace",
  "Elverta",
  "Wailea",
  "Ada",
  "Taylorsville",
  "Starke",
  "Falcon Heights",
  "Orchard Homes",
  "New Carlisle",
  "Asbury",
  "Carlinville",
  "Charleston",
  "Morris Plains",
  "Milton",
  "Weldon Spring",
  "Baxley",
  "Oxford",
  "Waynesboro",
  "Marlin",
  "Madisonville",
  "Bowling Green",
  "Paxtonia",
  "Union Beach",
  "Huntingburg",
  "Zeeland",
  "Strawberry",
  "Littlefield",
  "Greenwich",
  "Ferriday",
  "Ocean Shores",
  "Duquesne",
  "Lakeview",
  "Boxborough",
  "Dacono",
  "Holdenville",
  "Lochbuie",
  "Honesdale",
  "Corydon",
  "Coraopolis",
  "Wellston",
  "South Apopka",
  "Honeoye Falls",
  "Old Fig Garden",
  "Monaca",
  "Sweetwater",
  "Apple Valley",
  "Palmview South",
  "Elberton",
  "Weiser",
  "Swartz Creek",
  "Stroudsburg",
  "Thiells",
  "Wappingers Falls",
  "Somerdale",
  "Fort Plain",
  "Valatie",
  "Rockdale",
  "Jamestown",
  "Germantown",
  "New Hempstead",
  "Breckenridge",
  "Roma",
  "Morganfield",
  "New Holland",
  "Waterville",
  "Pecan Plantation",
  "Black River Falls",
  "Waterford",
  "Nashville",
  "Robbins",
  "Firthcliffe",
  "Mali",
  "Boothwyn",
  "Breinigsville",
  "Bessemer City",
  "Brookhaven",
  "Cobleskill",
  "Bellows Falls",
  "Sparta",
  "Volo",
  "Salamanca",
  "Westernport",
  "Englewood Cliffs",
  "Winston",
  "Canal Fulton",
  "Muldrow",
  "Titusville",
  "Sherwood Manor",
  "Colby",
  "Rockwood",
  "Brent",
  "Trenton",
  "Cidra",
  "Slinger",
  "Wake Village",
  "Quantico Base",
  "Grayson",
  "Smiths Station",
  "Monticello",
  "Kalaheo",
  "Orwigsburg",
  "Mocksville",
  "Tea",
  "Gibson",
  "Pinckneyville",
  "South Amherst",
  "Williams",
  "Dove Valley",
  "Hilmar-Irwin",
  "Aubrey",
  "Ettrick",
  "La Habra Heights",
  "Manteo",
  "Jonesboro",
  "Terrell Hills",
  "Jonesborough",
  "McGuire AFB",
  "Northwood",
  "Wayne",
  "Highland Lakes",
  "St. Paul Park",
  "Mudgee",
  "Eaton Rapids",
  "Massanetta Springs",
  "La Grange",
  "Hanamaulu",
  "Hightstown",
  "Fairport",
  "Tonganoxie",
  "Goshen",
  "Centerport",
  "Los Chaves",
  "Paola",
  "Iola",
  "Highland",
  "North Dansville",
  "Caldwell",
  "Denver City",
  "Coal City",
  "Center",
  "Cameron",
  "Morganville",
  "Connell",
  "Anamosa",
  "Uhrichsville",
  "Summit",
  "Caruthersville",
  "West Hills",
  "New Castle",
  "Hidden Valley",
  "Leadville",
  "Ballston Spa",
  "Peace River",
  "Willow Park",
  "Maplewood",
  "Mena",
  "Big Pine Key",
  "Grandwood Park",
  "St. Clair",
  "Lely Resort",
  "East Port Orchard",
  "Delhi Hills",
  "Manchester-by-the-Sea",
  "Rothschild",
  "Grandyle Village",
  "Chadron",
  "Manitou Springs",
  "Lake Wildwood",
  "Blaine",
  "Ravena",
  "Salem",
  "Wind Lake",
  "Fox Chapel",
  "Palmerton",
  "Warm Mineral Springs",
  "Lehighton",
  "Centerville",
  "Lafayette",
  "Grayson Valley",
  "Sunbury",
  "Williamsville",
  "Elmsford",
  "Sunset",
  "Holdrege",
  "Cavalero",
  "Carmi",
  "Gilbertsville",
  "Batesburg-Leesville",
  "Lake Fenton",
  "Frankenmuth",
  "Red Oak",
  "Calistoga",
  "Kings Point",
  "Suncook",
  "St. Augustine South",
  "Jackson",
  "Hampshire",
  "Savannah",
  "Zebulon",
  "South Sarasota",
  "Mount Olive",
  "Big Bear Lake",
  "Gilmer",
  "Giddings",
  "Polýgyros",
  "Union City",
  "Eaton",
  "Wadesboro",
  "Cottleville",
  "Algona",
  "Cocoa West",
  "Grambling",
  "Spring Valley",
  "Arlington Heights",
  "West Wendover",
  "Fruitland",
  "Nolanville",
  "Dumfries",
  "Waynesville",
  "Bangor",
  "Addis",
  "Collinsville",
  "Avondale",
  "Ocean Bluff-Brant Rock",
  "Silver Hill",
  "Sidney",
  "Rio Hondo",
  "Zephyrhills South",
  "Timber Pines",
  "North Oaks",
  "Kaitaia",
  "Belle Fourche",
  "Creve Coeur",
  "Pontotoc",
  "Terryville",
  "Jan Phyl Village",
  "Grosse Pointe",
  "Mullins",
  "Sarmiento",
  "Mount Carmel",
  "Clarinda",
  "Sawmills",
  "Central",
  "Wenham",
  "East Moriches",
  "Hazardville",
  "Dickinson",
  "Polson",
  "Forsyth",
  "Ranson",
  "Turpin Hills",
  "Byron",
  "Jinzhong",
  "Punta Santiago",
  "Birdsboro",
  "Worland",
  "Eagleton Village",
  "Bayou Vista",
  "Mount Repose",
  "Bisbee",
  "Williamston",
  "South Bay",
  "Schuylkill Haven",
  "Ottawa",
  "Winona Lake",
  "Lake City",
  "Deale",
  "Myrtletown",
  "Ayden",
  "Pinardville",
  "Stewartstown",
  "Offutt AFB",
  "Westvale",
  "Schlusser",
  "Humboldt",
  "Scott City",
  "Buellton",
  "Morris",
  "Colona",
  "Brady",
  "Berkeley",
  "Collegeville",
  "Winterset",
  "McKenzie",
  "Walkertown",
  "Monee",
  "Peculiar",
  "Ward",
  "Rio Pinar",
  "Cape May Court House",
  "Travelers Rest",
  "East Harwich",
  "Sea Cliff",
  "Odessa",
  "Midway",
  "Poplar Grove",
  "Midfield",
  "Angier",
  "Montevideo",
  "Gillespie",
  "Brownfields",
  "Cold Spring Harbor",
  "Abbeville",
  "Wahneta",
  "Bay Hill",
  "Winnsboro",
  "Canutillo",
  "Dwight",
  "Owego",
  "North Fond du Lac",
  "Post",
  "Crystal Springs",
  "Flagler Beach",
  "South Haven",
  "Colville",
  "Munroe Falls",
  "Belvedere",
  "Gretna",
  "Milan",
  "Hutchinson Island South",
  "White Hall",
  "St. Clairsville",
  "Concordia",
  "Shepherdstown",
  "Olyphant",
  "Stafford Springs",
  "Pascoag",
  "Wheatley Heights",
  "Krum",
  "St. Joseph",
  "Elmwood",
  "Wanaka",
  "Attica",
  "Port Allen",
  "Demarest",
  "Muleshoe",
  "Carrollton",
  "La Crescent",
  "West Salem",
  "Kings Park",
  "Cleveland",
  "Bridgeville",
  "Brookshire",
  "Thatcher",
  "Shanor-Northvue",
  "Eagleville",
  "De Soto",
  "Basehor",
  "Alexandria",
  "Centreville",
  "Bowie",
  "North Fort Lewis",
  "River Ridge",
  "Town and Country",
  "Rutherford",
  "Forbes",
  "Creston",
  "Berri",
  "Cobram",
  "Scone",
  "Sioux Lookout",
  "Tonantins",
  "Abra Pampa",
  "Victorica",
  "Cochrane",
  "La Paz",
  "Liverpool",
  "Melville",
  "Comandante Fontana",
  "George Town",
  "Goondiwindi",
  "Manjimup",
  "Narrogin",
  "Pofadder",
  "Smithton",
  "Svolvær",
  "Manica",
  "San Antonio de los Cobres",
  "Vergara",
  "Proserpine",
  "Katanning",
  "Finnsnes",
  "Sapouy",
  "Perito Moreno",
  "Ulaan-Uul",
  "Seymour",
  "Gaspé",
  "Yomou",
  "Vanrhynsdorp",
  "Puerto Deseado",
  "Kirkenes",
  "Sebba",
  "Turangi",
  "La Paloma",
  "Fort Chipewyan",
  "Sinnamary",
  "Hokitika",
  "Clare",
  "Central Coast",
  "Port Douglas",
  "Lac La Biche",
  "General Conesa",
  "Renwick",
  "Pampa del Infierno",
  "Longreach",
  "Nautla",
  "Orocué",
  "Weipa",
  "Mangbwalu",
  "Kailu",
  "Wallaroo",
  "Saint-Georges",
  "Nuquí",
  "Tom Price",
  "Aiguá",
  "Baie-du-Poste",
  "Gimli",
  "Rørvik",
  "Bordertown",
  "Mkokotoni",
  "Chumbicha",
  "Athabasca",
  "Gobernador Gregores",
  "Baltasar Brum",
  "Donegal",
  "Nelson House",
  "Nagqu",
  "Puerto Williams",
  "Bourke",
  "Rankin Inlet",
  "Scottsdale",
  "Palopo",
  "Karungu",
  "Queenstown",
  "Juradó",
  "San Julián",
  "Boffa",
  "Maltahöhe",
  "Port Hardy",
  "Roura",
  "Puerto Villamil",
  "Wawa",
  "Kaikoura",
  "Katwe",
  "Charleville",
  "Arviat",
  "Te Anau",
  "Yamba",
  "Tumby Bay",
  "Mount Barker",
  "Vila Bela da Santíssima Trindade",
  "Trancas",
  "Wagin",
  "Baker Lake",
  "Alto Río Senguer",
  "Kalbarri",
  "Iracoubo",
  "Sierra Colorada",
  "Penola",
  "Alexander Bay",
  "San Vicente del Caguán",
  "Ouyen",
  "Jaqué",
  "Cape Dorset",
  "Tarutung",
  "Uummannaq",
  "Lake Louise",
  "Las Lajas",
  "Cloncurry",
  "Oatlands",
  "Ravensthorpe",
  "Susques",
  "Barcaldine",
  "Streaky Bay",
  "Repulse Bay",
  "Greytown",
  "Yélimané",
  "Príncipe da Beira",
  "Tarapoto",
  "Wyndham",
  "Rodeo",
  "Linxi",
  "Meekatharra",
  "Arctic Bay",
  "Fort Good Hope",
  "Tinogasta",
  "Quilpie",
  "Gastre",
  "Telsen",
  "Schefferville",
  "Hughenden",
  "Kimmirut",
  "Halfmoon Bay",
  "Lasserre",
  "Morawa",
  "Saint Anthony",
  "Kingston South East",
  "Kraulshavn",
  "Three Springs",
  "Sayḩūt",
  "Oxford House",
  "La Esmeralda",
  "Progress",
  "Hödrögö",
  "Al Qurayyāt",
  "Obluch’ye",
  "Olëkminsk",
  "Glocester",
  "Mazoe",
  "Vilyuysk",
  "Harrison",
  "Gamba",
  "Newcastle",
  "Būlaevo",
  "Scottsboro",
  "Ware",
  "Brewster",
  "Valley Center",
  "Qarabalyq",
  "Celina",
  "Pine Ridge",
  "Olmos",
  "Stuarts Draft",
  "Pleasant Valley",
  "Philipstown",
  "Pokrovsk",
  "Brandywine",
  "Franklin",
  "Southwick",
  "Aqadyr",
  "Mandritsara",
  "Bloomfield",
  "Woodbury",
  "Hartford",
  "Sémbé",
  "Vashon",
  "Grand Rapids",
  "Burlington",
  "Biryusinsk",
  "Dombarovskiy",
  "Rainbow City",
  "Hebron",
  "Igrim",
  "Hermantown",
  "Sturbridge",
  "Newfane",
  "Townsend",
  "Marcy",
  "Putnam",
  "Portland",
  "Shira",
  "Williston",
  "Claremont",
  "Pike Road",
  "Thompson",
  "Sutton",
  "Walworth",
  "Hastings",
  "Redding",
  "Ruidoso",
  "Crawford",
  "Alexander City",
  "Ertis",
  "San José de Chiquitos",
  "Edwards",
  "Economy",
  "Freetown",
  "Ust’-Nera",
  "Usakos",
  "Challapata",
  "Hamlin",
  "Fort Knox",
  "Shar",
  "Springfield",
  "Jumlā",
  "East Haddam",
  "St. Marys",
  "Flowood",
  "Citrus Springs",
  "Macedon",
  "Pittsgrove",
  "Aguelhok",
  "Mbamba Bay",
  "Lake Elmo",
  "Pasadena Hills",
  "Qashyr",
  "Weare",
  "Lenox",
  "Quibala",
  "Woodbridge",
  "Makhambet",
  "Zhangaözen",
  "Esopus",
  "Lisbon",
  "Barrington",
  "Seneca Falls",
  "Needles",
  "Monson",
  "Incline Village",
  "New Scotland",
  "Marrupa",
  "Topsham",
  "Asbury Lake",
  "Oak Grove",
  "Southside",
  "Eufaula",
  "Douglas",
  "Newstead",
  "Al Quway‘īyah",
  "Golden Valley",
  "Blanchard",
  "Hooper",
  "Picture Rocks",
  "Spanish Fort",
  "Suntar",
  "Helena Valley West Central",
  "Barton",
  "Kalifornsky",
  "Mansfield",
  "Park Hills",
  "Concord",
  "Aqsū",
  "Lacombe",
  "Plumsted",
  "Ellicott",
  "Montesano",
  "North Elba",
  "Sand Lake",
  "Sugarmill Woods",
  "Rutland",
  "Achacachi",
  "Guntersville",
  "Vallegrande",
  "Erwin",
  "Skowhegan",
  "Masindi Port",
  "Monahans",
  "Montague",
  "Haddam",
  "Freeport",
  "Schroeppel",
  "Somers",
  "Baxter",
  "Cortlandville",
  "Colorado City",
  "Grantsville",
  "Hernando",
  "Olovyannaya",
  "Bedminster",
  "Frostproof",
  "Skidaway Island",
  "Brooklyn",
  "Boxford",
  "Aiquile",
  "Fairfield Glade",
  "Zhänibek",
  "Adams",
  "Qarqaraly",
  "Buxton",
  "Orono",
  "Dal’negorsk",
  "Putina",
  "Templeton",
  "Poteau",
  "Byram",
  "Boston",
  "Lake Norman of Catawba",
  "Qusmuryn",
  "Kontcha",
  "Oldeani",
  "Lancaster",
  "Ellsworth",
  "Bajram Curri",
  "Hardyston",
  "Summit Park",
  "Keflavík",
  "Hitchcock",
  "Wyoming",
  "Dilolo",
  "Onalaska",
  "Deerpark",
  "Carneys Point",
  "Greenfield",
  "Ch’osan-ŭp",
  "Bonner Springs",
  "Hollis",
  "Upton",
  "Moengo",
  "Laughlin",
  "Piedmont",
  "Waterboro",
  "Big Flats",
  "Halifax",
  "Minnetrista",
  "Kronenwetter",
  "Kalabo",
  "Topanga",
  "Orange",
  "Cumberland",
  "Bow",
  "Eden",
  "Pikeville",
  "Caribou",
  "Las Lomitas",
  "New Hanover",
  "Meadow Lakes",
  "Clarksville",
  "Middlebury",
  "Bayghanīn",
  "North Hanover",
  "Williamstown",
  "Chesterfield",
  "Bansang",
  "Winslow",
  "Easton",
  "Mammoth Lakes",
  "Etowah",
  "Piñon Hills",
  "Shirley",
  "Steele Creek",
  "Westminster",
  "Royalton",
  "Urubamba",
  "Berwick",
  "West Livingston",
  "Upper Deerfield",
  "Purcell",
  "Kula",
  "Bryans Road",
  "Wedgefield",
  "Old Town",
  "Old Lyme",
  "Buena Vista",
  "Lucas",
  "Tranqueras",
  "Igarka",
  "Bargersville",
  "Dighton",
  "Coeymans",
  "Haiku-Pauwela",
  "Coracora",
  "Al ‘Alamayn",
  "Neiafu",
  "Reyes",
  "St. Johnsbury",
  "Stratham",
  "Susuman",
  "Sanibel",
  "South Berwick",
  "Thousand Palms",
  "Oroville East",
  "Luân Châu",
  "As Sallūm",
  "Pompey",
  "Osakarovka",
  "St. Gabriel",
  "Mills River",
  "Busti",
  "Wawayanda",
  "Bossembele",
  "Acton",
  "Belmont",
  "Truth or Consequences",
  "Marianna",
  "Fairview",
  "DeFuniak Springs",
  "New Albany",
  "Latimer",
  "Bestöbe",
  "East End",
  "Swanzey",
  "Tobyl",
  "Okondja",
  "Tuttle",
  "Wimauma",
  "Pembroke",
  "West Bridgewater",
  "Gilford",
  "Parry Sound",
  "Pedro Luro",
  "Batesville",
  "Zambezi",
  "Stow",
  "Fort Payne",
  "Quellón",
  "Melekeok",
  "Joshua Tree",
  "Clarkson",
  "Mount Hope",
  "Peru",
  "Newbury",
  "Maurice River",
  "Kieta",
  "Archbald",
  "Page",
  "Nicoadala",
  "Westbrook",
  "Tabernacle",
  "Oak Ridge",
  "Great Barrington",
  "Hanover",
  "Milo",
  "Upper Freehold",
  "Karibib",
  "Zholymbet",
  "Sitka",
  "Sandersville",
  "Epping",
  "Millersville",
  "Moapa Valley",
  "Farmington",
  "Hoosick",
  "Wildwood",
  "Kirtland",
  "Khandyga",
  "New Hartford",
  "Kishkeneköl",
  "Golfito",
  "Parkville",
  "Exeter",
  "Antigonish",
  "Noble",
  "Hobart",
  "Meridianville",
  "Lake Hallie",
  "Unionville",
  "Moores Mill",
  "Gateway",
  "Plymouth",
  "Scriba",
  "Lincoln",
  "Makarov",
  "North Branch",
  "Dalton",
  "Brewton",
  "Hamilton",
  "Berkley",
  "Trinity",
  "Ghadāmis",
  "Porter",
  "Fairfield",
  "Awjilah",
  "Tecumseh",
  "St. Francis",
  "Pendleton",
  "Collins",
  "Qazaly",
  "Duanesburg",
  "Marble Falls",
  "Walnut Ridge",
  "Outjo",
  "Harvard",
  "Darnestown",
  "West Point",
  "Quirihue",
  "Ridgeway",
  "Calverton",
  "Pukë",
  "Sunnyvale",
  "San Ramón",
  "Andalusia",
  "Fenton",
  "Cairo",
  "Shamong",
  "Snowflake",
  "Medina",
  "Killingworth",
  "Ellenville",
  "Marlborough",
  "Melut",
  "Lake Mathews",
  "Schriever",
  "Newport",
  "Lynchburg",
  "Eliot",
  "Atmore",
  "Liberty",
  "San Carlos",
  "San Matías",
  "Verkhnevilyuysk",
  "Meredith",
  "Granby",
  "Fort Nelson",
  "Yaphank",
  "Wilna",
  "Prestonsburg",
  "Dennis",
  "Senatobia",
  "Sandown",
  "Selfoss",
  "Verona",
  "Mattapoisett",
  "Smithers",
  "Pigeon Forge",
  "Wolfeboro",
  "Rowley",
  "Oakland",
  "Ashburnham",
  "Jasper",
  "Eldorado at Santa Fe",
  "Ndendé",
  "Hurley",
  "Lebanon",
  "Chena Ridge",
  "Rincon Valley",
  "Chatham",
  "Kingston",
  "Johnson Lane",
  "Umba",
  "West Greenwich",
  "Westmoreland",
  "Bathurst",
  "Lecanto",
  "Northfield",
  "Southampton",
  "Eagar",
  "Mullica",
  "North Hobbs",
  "Rib Mountain",
  "Rhome",
  "Anaconda",
  "Fishhook",
  "Orange Beach",
  "Booneville",
  "Waikoloa Village",
  "Winthrop",
  "Rindge",
  "Ranchettes",
  "Norway House",
  "Chapaev",
  "Sealy",
  "Mendon",
  "Mims",
  "Tazovskiy",
  "Hardeeville",
  "Logan",
  "Lago Vista",
  "Hillsborough",
  "Rosendale",
  "Mont Belvieu",
  "Newberry",
  "Dover",
  "Willington",
  "Corcoran",
  "Indian Hills",
  "Houlton",
  "Littleton",
  "Demopolis",
  "Roseburg North",
  "Jamul",
  "Tewksbury",
  "Clanton",
  "The Village of Indian Hill",
  "Mundybash",
  "Woodstock",
  "Lyndon",
  "Harvest",
  "Blairstown",
  "Idabel",
  "Fellsmere",
  "Orleans",
  "Woods Creek",
  "Claverack",
  "Mineola",
  "Durham",
  "Cambria",
  "Aiyomojok",
  "Lee",
  "Volney",
  "Dayton",
  "Hermon",
  "Lee Acres",
  "Pecan Acres",
  "Fdérik",
  "Bilibino",
  "Piggs Peak",
  "Turner",
  "Rumford",
  "Indiantown",
  "Ingeniero Jacobacci",
  "Pittstown",
  "Saratoga",
  "Palermo",
  "Tiksi",
  "Chinle",
  "Homer",
  "Presque Isle",
  "Gray",
  "Wilmington",
  "Winfield",
  "Kirkwood",
  "New Gloucester",
  "Gardiner",
  "Thompson's Station",
  "Greenville",
  "Chackbay",
  "Wrightsboro",
  "Richland",
  "Beni Ounif",
  "Lake Delton",
  "Riga",
  "Cave Creek",
  "Kasempa",
  "Mascotte",
  "Hopkinton",
  "Harrietstown",
  "Warrenton",
  "Vossevangen",
  "Okhotsk",
  "Woodside",
  "Marbletown",
  "Atlanta",
  "Independence",
  "Bethany",
  "Harwinton",
  "Doney Park",
  "Poland",
  "Pagosa Springs",
  "Hamptonburgh",
  "Lyons",
  "Cut Off",
  "New Boston",
  "Beekmantown",
  "Stettler",
  "Barre",
  "Samsula-Spruce Creek",
  "Salton City",
  "Lucerne Valley",
  "Rochester",
  "South Pittsburg",
  "Three Points",
  "Lakehills",
  "Avarua",
  "Vienna",
  "Tura",
  "Columbia",
  "Ouadda",
  "San Quintín",
  "Oneonta",
  "Rancho Murieta",
  "Jim Thorpe",
  "Stokesdale",
  "Indian Wells",
  "Morristown",
  "Loudon",
  "Rye",
  "Kenai",
  "Frankford",
  "Homestead Meadows North",
  "Marilla",
  "Bear Valley Springs",
  "Sterling",
  "Witu",
  "Bar Harbor",
  "Tichigan",
  "Qamīnis",
  "Bridgton",
  "Ridgeland",
  "Desaguadero",
  "Bright",
  "Jaffrey",
  "Newfield",
  "Hohenau",
  "Hawaiian Beaches",
  "Hadley",
  "Veintiocho de Noviembre",
  "Auburn",
  "Conklin",
  "Torghay",
  "Yosemite Lakes",
  "Hockinson",
  "Alton",
  "Maine",
  "Ghent",
  "North Stonington",
  "Nassau Village-Ratliff",
  "Pound Ridge",
  "Avra Valley",
  "East Granby",
  "Dandridge",
  "Fort-Shevchenko",
  "New Ipswich",
  "North Salem",
  "New Liskeard",
  "Holland",
  "Hampstead",
  "Warren",
  "Hampden",
  "Brookline",
  "Xiangkhoang",
  "Floral City",
  "Bulverde",
  "Hollywood",
  "Bolton",
  "Carlisle",
  "Shelby",
  "Glencoe",
  "Schuyler Falls",
  "Fort Rucker",
  "Paris",
  "Flanders",
  "Northumberland",
  "Newman",
  "Chernyshevskiy",
  "Colesville",
  "Combarbalá",
  "Villa del Rosario",
  "Daleville",
  "Chisago City",
  "Williamson",
  "Wilton",
  "Farmers Loop",
  "Rockingham",
  "Commercial",
  "Carlyss",
  "Canterbury",
  "Battlement Mesa",
  "Marion",
  "Paulden",
  "Ulysses",
  "Lone Grove",
  "Deerfield",
  "Hearst",
  "Mount Hood Village",
  "Rainsville",
  "Bristol",
  "Manchester",
  "McMinns Lagoon",
  "McGregor",
  "Lake Montezuma",
  "Clifton",
  "Sabattus",
  "Sugarcreek",
  "Charlestown",
  "Wakefield",
  "Qaşr al Farāfirah",
  "Puerto La Victoria",
  "Artëmovsk",
  "Ust’-Kamchatsk",
  "Betanzos",
  "Cubal",
  "Pevek",
  "Nata",
  "Turukhansk",
  "Uncia",
  "Camargo",
  "Bagdarin",
  "Sangar",
  "Marathon",
  "Brownsweg",
  "Otavi",
  "Kindersley",
  "Sokolo",
  "Saryshagan",
  "La Cruz",
  "Bekily",
  "El Maitén",
  "Batagay",
  "Kyaukpyu",
  "Channel-Port aux Basques",
  "San Javier",
  "Omsukchan",
  "Apolo",
  "Novyy Uoyan",
  "Santo Tomás",
  "Mitzic",
  "Bongandanga",
  "Brus Laguna",
  "Quime",
  "Araouane",
  "Mayumba",
  "Saint-Augustin",
  "Mbé",
  "Digby",
  "Karmah an Nuzul",
  "Shongzhy",
  "Westport",
  "Hay River",
  "Windsor",
  "Ciudad Cortés",
  "Vitim",
  "P’ungsan",
  "La Ronge",
  "Deer Lake",
  "Teeli",
  "Cherskiy",
  "Lavumisa",
  "Mezen’",
  "Palana",
  "Zyryanka",
  "Atikokan",
  "De-Kastri",
  "Dehiba",
  "Ligonha",
  "Santa Bárbara",
  "Srednekolymsk",
  "Magdalena",
  "Darregueira",
  "Bukachacha",
  "Ugol’nyye Kopi",
  "Lukulu",
  "Gander",
  "Krasnogorsk",
  "Mopipi",
  "Zhigansk",
  "Khatanga",
  "Caballococha",
  "Mékambo",
  "Shelburne",
  "Anna Regina",
  "Buur Gaabo",
  "Ust’-Maya",
  "Arroyos y Esteros",
  "I-n-Amguel",
  "Charagua",
  "Abaí",
  "Inuvik",
  "Taoudenni",
  "San Lorenzo",
  "Saranpaul’",
  "Samaipata",
  "Padilla",
  "Lillooet",
  "Villalonga",
  "Ygatimi",
  "Hlatikulu",
  "Entre Ríos",
  "Sauðárkrókur",
  "Merredin",
  "Chapleau",
  "Burns Lake",
  "Huasco",
  "Provideniya",
  "Ísafjörður",
  "Chokurdakh",
  "Nauta",
  "Marādah",
  "Mariscal Estigarribia",
  "José Batlle y Ordóñez",
  "Tarabuco",
  "Severo-Kuril’sk",
  "Baures",
  "Uspallata",
  "El Dorado",
  "Oficina María Elena",
  "Coroico",
  "Dondo",
  "Sohano",
  "Muconda",
  "Toltén",
  "Egilsstaðir",
  "Egvekinot",
  "Mwenga",
  "El Manteco",
  "Stanley",
  "Biggar",
  "Sorata",
  "Wiarton",
  "Hudson Bay",
  "Plumtree",
  "Pozo Colorado",
  "Mejillones",
  "Evensk",
  "Konza",
  "Dawwah",
  "Matagami",
  "Kaupanger",
  "Amapá",
  "Lehututu",
  "Abunã",
  "Saskylakh",
  "Tae-dong",
  "Corocoro",
  "Paamiut",
  "Beringovskiy",
  "Tasiilaq",
  "Río Mayo",
  "Sixaola",
  "Attawapiskat",
  "Novyy Port",
  "Borgarnes",
  "Puerto Villarroel",
  "Red Lake",
  "Nokaneng",
  "Al Jaghbūb",
  "Nasir",
  "Moosonee",
  "Al Qaşr",
  "Höfn",
  "Peterborough",
  "Labutta",
  "Omboué",
  "Tofino",
  "Igloolik",
  "Inukjuak",
  "Little Current",
  "Ceduna",
  "Pond Inlet",
  "Manja",
  "Kipili",
  "Ust’-Kuyga",
  "El’dikan",
  "Chibemba",
  "Meningie",
  "Tajarhī",
  "Cap-Chat",
  "Cambridge Bay",
  "Lokwabe",
  "Thessalon",
  "Gingin",
  "Chibia",
  "Tsau",
  "Bella Bella",
  "Verkhoyansk",
  "Cobalt",
  "Qasigiannguit",
  "Qardho",
  "Nyimba",
  "Pangnirtung",
  "Dawson",
  "Lubutu",
  "Fulacunda",
  "Cangamba",
  "Chumikan",
  "Kugluktuk",
  "Geraldton",
  "Ayan",
  "Gillam",
  "Kuujjuaq",
  "Villa Rumipal",
  "Ñacunday",
  "Lavrentiya",
  "Port Denison",
  "Regedor Quissico",
  "Halls Creek",
  "Çeleken",
  "Nipigon",
  "San Rafael",
  "Capitán Pablo Lagerenza",
  "Buluko",
  "Winton",
  "Santo António",
  "Nain",
  "Los Blancos",
  "Upernavik",
  "Puerto Acosta",
  "Mirbāţ",
  "Dikson",
  "Gjoa Haven",
  "Klyuchi",
  "Exmouth",
  "Fort McPherson",
  "Argentia",
  "Omolon",
  "Norman Wells",
  "Besalampy",
  "Sicasica",
  "Norseman",
  "Altata",
  "Churchill",
  "Cacolo",
  "General Eugenio A. Garay",
  "Cuevo",
  "Daraj",
  "Yulara",
  "Tuktoyaktuk",
  "Berens River",
  "Hoskins",
  "Shamattawa",
  "Luanza",
  "Baddeck",
  "Coral Harbour",
  "Georgetown",
  "La Scie",
  "Watson Lake",
  "Bávaro",
  "Calulo",
  "Uelen",
  "Taloyoak",
  "Muhembo",
  "Yerëma",
  "Comallo",
  "Natashquan",
  "Satadougou",
  "Serebryansk",
  "Zhaltyr",
  "Pannawonica",
  "Buchans",
  "Djanet",
  "Pine Creek",
  "Hall Beach",
  "Massangena",
  "Kimba",
  "Calatrava",
  "Qaanaaq",
  "Dibaya",
  "Panda",
  "Boulia",
  "Tunduru",
  "Ype Jhú",
  "Mingan",
  "Onslow",
  "Sabaya",
  "Mikhalkino",
  "Ubombo",
  "Chiramba",
  "Llica",
  "Kangirsuk",
  "Mereeg",
  "Sandspit",
  "Cowell",
  "Calenga",
  "Andamooka",
  "Punta Prieta",
  "Délįne",
  "Fort Smith",
  "Caluula",
  "Tournavista",
  "Cartwright",
  "Puerto Pinasco",
  "Chitado",
  "Yakossi",
  "Tmassah",
  "Holman",
  "Lynn Lake",
  "Scoresbysund",
  "Eidsvold",
  "Trout River",
  "Kullorsuaq",
  "Woomera",
  "Fort Resolution",
  "Forteau Bay",
  "Xangongo",
  "Wilcannia",
  "Hopedale",
  "Pukatawagan",
  "Mount Magnet",
  "Tsavo",
  "Sherlovaya Gora",
  "Comandante Luis Piedra Buena",
  "Nizhneyansk",
  "Korf",
  "Trepassey",
  "Espungabera",
  "Chonchi",
  "Toconao",
  "Chesterfield Inlet",
  "Balsadero Río Verde",
  "Eastmain",
  "Laverton",
  "Manyoni",
  "Dease Lake",
  "Umm al ‘Abīd",
  "Shoyna",
  "Bugrino",
  "Cazombo",
  "Put’ Lenina",
  "Enurmino",
  "Richmond",
  "Paulatuk",
  "Yaupi",
  "Ikela",
  "Kulusuk",
  "Fort Simpson",
  "Birdsville",
  "Amderma",
  "Brochet",
  "Cat Lake",
  "Gubkin",
  "Radisson",
  "Ivanhoe",
  "Port-Menier",
  "Villa O’Higgins",
  "Resolute",
  "Tasiusaq",
  "Theodore",
  "Timbedgha",
  "Nimule",
  "Adelaide River",
  "Kanyato",
  "Amau",
  "Leonora",
  "I-n-Amenas",
  "Zouar",
  "Thargomindah",
  "Mapai",
  "Burketown",
  "Kangersuatsiaq",
  "Upper Hay River",
  "Port Hope Simpson",
  "Charaña",
  "Kalima",
  "Southern Cross",
  "Camooweal",
  "Quipungo",
  "Bicheno",
  "Vilankulo",
  "Lusanga",
  "Hurdiyo",
  "Tsiigehtchic",
  "Androka",
  "Karumba",
  "Vikhorevka",
  "Windorah",
  "Al Qunfudhah",
  "Ivujivik",
  "Stony Rapids",
  "Luiana",
  "Bafwasende",
  "Cuito Cuanavale",
  "Narsarsuaq",
  "Bedourie",
  "Buton",
  "Bifoun",
  "Alert",
  "Fort Severn",
  "Rigolet",
  "Lansdowne House",
  "Sharbaqty",
  "Salluit",
  "Łutselk’e",
  "Nichicun",
  "Al ‘Uqaylah",
  "Savissivik",
  "Godhavn",
  "Villa Martín Colchak",
  "Gyda",
  "Güeppí",
  "Tiyerbes",
  "Bîr Mogreïn",
  "Yessey",
  "Manily",
  "Zhilinda",
  "Ust’-Olenëk",
  "Ağdam",
  "Logashkino",
  "Mucusso",
  "Būrylbaytal",
  "Ituni",
  "Vorontsovo",
  "Mukhomornoye",
  "Grytviken",
  "Uranium City",
  "Burwash Landing",
  "Piso Firme",
  "As Sidrah",
  "Kingoonya",
  "Rocafuerte",
  "Grise Fiord",
  "Kovda",
  "Cuya",
  "Numto",
  "Peregrebnoye",
  "Lar’yak",
  "Lagunas",
  "Andoas",
  "Puca Urco",
  "Zillah",
  "Ul’kan",
  "Barnīs",
  "Soldado Bartra",
  "Bol’sheretsk",
  "Karamken",
  "Strelka",
  "Puerto Heath",
  "Ust’-Nyukzha",
  "Djado",
  "Khorgo",
  "Varnek",
  "Indiga",
  "Podkamennaya Tunguska",
  "Komsa",
  "Omchak",
  "Trofimovsk",
  "Sagastyr",
  "Utkholok",
  "Siglan",
  "Pakhachi",
  "Tunguskhaya",
  "Chagda",
  "Il’pyrskiy",
  "Shalaurova",
  "Chegga",
  "Olenëk",
  "Matochkin Shar",
  "Zvëzdnyy",
  "Agapa",
  "Starorybnoye",
  "Menkerya",
  "Tukchi",
  "Khakhar’",
  "Zemlya Bunge",
  "Island Lake",
  "Big Beaverhouse",
  "Nord",
  "Timmiarmiut",
  "Cheremoshna",
  "Ambarchik",
  "Nordvik",
  "Ennadai",
  ];
  export default cities;