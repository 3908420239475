const splitTagline = initial => {
  const dividers = [": ", "; ", " - ", " – ", " | "];
  let splitIndex;
  let splitIndexLength;

  for (let i = 0; i < dividers.length; i += 1) {
    const index = initial.indexOf(dividers[i]);
    if (index !== -1 && (splitIndex ? index < splitIndex : true)) {
      splitIndex = index;
      splitIndexLength = dividers[i].length;
    }
  }

  if (splitIndex) {
    const title = initial.substring(0, splitIndex);
    const tagline = initial.substring(splitIndex + splitIndexLength);
    return {
      title: title.trim(),
      tagline:
        tagline
          .trim()
          .charAt(0)
          .toUpperCase() + tagline.slice(1),
    };
  }

  return {
    title: initial,
    tagline: "",
  };
};

const sortAlphabetically = (a, b) => {
  let nameA = "";
  let nameB = "";
  if (typeof a === "string" && typeof b === "string") {
    nameA = a.toLowerCase();
    nameB = b.toLowerCase();
  }
  if (nameA < nameB) {
    return -1;
  }
  return 1;
};

const removeDuplicates = list => {
  return list.filter((el, i) => list.indexOf(el) === i);
};

const generateFilterList = (prop, list) => {
  return removeDuplicates(list.map(el => el[prop]));
};

export {
  splitTagline,
  sortAlphabetically,
  removeDuplicates,
  generateFilterList,
};
