import React from "react";
import { isMobileOnly } from "react-device-detect";

import { Card, Col, Row } from "antd";
import { withFirebase } from "../../Firebase";
import { withAuthorization } from "../../Session";
import Participant from "./Participant";

import "./RoomMapCard.css";

class RoomMapCard_ extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      participants: [],
      prevEmails: [],
      loading: true,
    };

    this.showRoomDrawer = this.showRoomDrawer.bind(this);
  }

  async componentDidMount() {
    await this.fetchParticipants();
    this.setState({ loading: false });
  }

  async componentDidUpdate() {
    this.fetchParticipants();
  }

  async fetchParticipants() {
    const { firebase, room, roomChanged } = this.props;
    const { prevEmails } = this.state;
    const roomData = room.data();
    let different = false;

    const emails = roomData.participants;

    if (prevEmails.length !== emails.length) different = true;
    else
      emails.forEach((email, index) => {
        if (email !== prevEmails[index]) {
          different = true;
        }
      });

    if (different) {
      const users = await firebase.getActiveCenterUsers(emails);
      this.setState({
        participants: users,
        prevEmails: emails,
      });
      roomChanged(room, users);
    }

    return different;
  }

  determineColor() {
    const { authUser } = this.props;
    const { room } = this.props;

    let color = "#ffffff";
    if (room.data().locked) color = "#DBDBDB";
    if (room.data().participants.includes(authUser.email)) {
      color = "#e6f7ff";
    }
    return color;
  }

  showRoomDrawer() {
    const { participants } = this.state;
    const { roomSelected, room } = this.props;
    roomSelected(room, participants);
  }

  renderParticipants() {
    const { participants } = this.state;
    const { participantSelected } = this.props;
    return participants.map(p => (
      <Participant
        style={{ zIndex: 5 }}
        count={0}
        participant={p}
        size="medium"
        key={p.id}
        participantSelected={participantSelected}
      />
    ));
  }

  render() {
    const { room, windowWidth } = this.props;
    const { loading } = this.state;
    const roomData = room.data();

    const padding = 120;
    const shares = 29;

    const widthfactor = (windowWidth - padding) / shares;

    const minWidthfactor = 25;
    const maxWidthfactor = 80;

    const height = "calc((100vh - 170px + 42px)/5)";
    const rowMaxHeigth = "calc(((100vh - 450px)/5))";
    const minHeight = 110;

    if (!isMobileOnly) {
      if (roomData.room_type === "empty") {
        return (
          <Col>
            <Card
              className="empty-card"
              bordered={false}
              style={{
                height,
                minHeight,
                minWidth: minWidthfactor * roomData.map_info[2],
                width: roomData.map_info[2] * widthfactor,
                maxWidth: maxWidthfactor * roomData.map_info[2],
                background: "inherit",
              }}
            ></Card>
          </Col>
        );
      }
      if (roomData.room_type === "hallway") {
        return (
          <Col>
            <Card
              className="hallway-card"
              bordered={false}
              style={{
                height,
                minHeight,
                minWidth: minWidthfactor * roomData.map_info[2],
                width: roomData.map_info[2] * widthfactor,
                maxWidth: maxWidthfactor * roomData.map_info[2],
                background: "#A4A4A6",
              }}
            ></Card>
          </Col>
        );
      }

      return (
        <Col>
          <Card
            className="room-card"
            title={roomData.title}
            bordered
            loading={loading}
            hoverable
            onClick={() => this.showRoomDrawer()}
            style={{
              height,
              minHeight,
              minWidth: minWidthfactor * roomData.map_info[2],
              width: roomData.map_info[2] * widthfactor,
              maxWidth: maxWidthfactor * roomData.map_info[2],
              background: this.determineColor(),
              borderRadius: 10,
            }}
            extra={
              roomData.conversation ===
                "https://app.knitplaces.com/?space=CDTM" && (
                <img
                  src="static/icons/knit.jpeg"
                  alt="Knit"
                  style={{
                    height: 20,
                    width: 20,
                    marginBottom: 2,
                    marginRight: 7,
                  }}
                />
              )
            }
          >
            <div style={{ overflowX: "auto" }}>
              <Row style={{ maxHeight: rowMaxHeigth, minHeight: 44 }}>
                {this.renderParticipants()}
              </Row>
            </div>
          </Card>
        </Col>
      );
    }

    if (roomData.room_type !== "empty" && roomData.room_type !== "hallway") {
      return (
        <Col span={24} style={{ paddingBottom: 5 }}>
          <Card
            className="room-card"
            title={roomData.title}
            bordered
            loading={loading}
            hoverable
            onClick={() => this.showRoomDrawer()}
            style={{
              height: 140,
              width: "100%",
              background: this.determineColor(),
              borderRadius: 10,
            }}
            extra={
              roomData.conversation ===
                "https://app.knitplaces.com/?space=CDTM" && (
                <img
                  src="static/icons/knit.jpeg"
                  alt="Knit"
                  style={{
                    height: 20,
                    width: 20,
                    marginBottom: 2,
                    marginRight: 7,
                  }}
                />
              )
            }
          >
            <div style={{ overflowX: "auto" }}>
              <Row style={{ maxHeight: 70, minHeight: 44 }}>
                {this.renderParticipants()}
              </Row>
            </div>
          </Card>
        </Col>
      );
    }

    return null;
  }
}

const condition = authUser => authUser != null;
const RoomMapCard = withFirebase(withAuthorization(condition)(RoomMapCard_));
// eslint-disable-next-line import/prefer-default-export
export { RoomMapCard };
