import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import { message } from "antd";
import { withFirebase } from "../../Firebase";

const Reactivation = ({ firebase, isJob }) => {
  const { id } = useParams();

  const [error, setError] = useState(false);
  const [oppUpdated, setOppUpdated] = useState(false);

  useEffect(() => {
    (async () => {
      await firebase.db
        .collection(isJob ? "jobs" : "talents")
        .doc(id)
        .get()
        .then(async doc => {
          await doc.ref.update({
            confirmedRelevantOn: new Date(Date.now()),
            isRelevant: true,
          });
          setOppUpdated(true);
        })
        .catch(() => {
          setError(true);
        });
    })();
  }, [firebase, isJob, id]);

  return (
    <>
      {oppUpdated && message.success("Successfully reactivated your post")}
      {error && message.error("An error occurred reactivating your post")}
    </>
  );
};

export default withFirebase(Reactivation);
