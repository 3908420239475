import React from "react";
import {
  Drawer,
  Row,
  Divider,
  Button,
  Input,
  Col,
  Space,
  Checkbox,
  Card,
  Typography,
  Avatar,
} from "antd";
import {
  LoginOutlined,
  LogoutOutlined,
  SendOutlined,
  EditOutlined,
  LayoutOutlined,
  MoreOutlined,
} from "@ant-design/icons";

import { Swipeable } from "react-swipeable";
import { isMobileOnly } from "react-device-detect";

import { withFirebase } from "../../Firebase";
import { withAuthorization } from "../../Session";
import Participant from "./Participant";
import RoomPost from "./RoomPost";

const { Text } = Typography;
const { Meta } = Card;
const { TextArea } = Input;

class RoomPopup_ extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      childrenDrawer: false,
      input: "",
      title: "",
      description: "",
      editPost: false,
      submitPost: false,
      expandPost: false,
      editParticipants: 0,
    };

    this.hideRoomDrawer = this.hideRoomDrawer.bind(this);
    this.enterRoom = this.enterRoom.bind(this);
    this.setupZoom = this.setupZoom.bind(this);
    this.leaveRoom = this.leaveRoom.bind(this);
    this.onDescriptionChange = this.onDescriptionChange.bind(this);
    this.onURLChange = this.onURLChange.bind(this);
    this.submitPost = this.submitPost.bind(this);
    this.deletePost = this.deletePost.bind(this);
    this.changePost = this.changePost.bind(this);
    this.kickParticipant = this.kickParticipant.bind(this);
  }

  onChange = e => {
    switch (e.target.id) {
      case "zoom_link":
        this.setState({
          input: e.target.value,
        });
        break;
      case "title":
        this.setState({
          title: e.target.value,
        });
        break;
      case "description":
        this.setState({
          description: e.target.value,
        });
        break;
      default:
        this.setState({});
        break;
    }
  };

  onLockedChange(locked) {
    const { firebase, selectedRoom } = this.props;
    firebase.lockRoom(selectedRoom.id, locked);
  }

  onDescriptionChange(text) {
    const { firebase, selectedRoom } = this.props;
    firebase.changeSessionDescription(selectedRoom.id, text);
  }

  onURLChange(url) {
    const { firebase, selectedRoom } = this.props;
    firebase.setupConversationURL(selectedRoom.id, url);
  }

  onPermanentChange(permanent) {
    const { firebase, selectedRoom } = this.props;
    firebase.makePermanent(selectedRoom.id, permanent);
  }

  setupZoom() {
    const { firebase, selectedRoom } = this.props;
    const { input } = this.state;
    const url = input;
    firebase.setupConversationURL(selectedRoom.id, url);
    firebase.addParticipant(selectedRoom.id);
    this.onChildrenDrawerClose();
  }

  showChildrenDrawer = () => {
    this.setState({
      childrenDrawer: true,
    });
  };

  onChildrenDrawerClose = () => {
    this.setState({
      childrenDrawer: false,
    });
  };

  kickParticipant(participantId) {
    const { firebase, selectedRoom } = this.props;

    firebase.kickParticipant(selectedRoom.id, participantId);
  }

  hideRoomDrawer() {
    const { onClose } = this.props;
    onClose();
  }

  enterRoom() {
    const { firebase, selectedRoom, authUser } = this.props;

    if (selectedRoom.data().conversation === "") {
      this.showChildrenDrawer();
    } else if (selectedRoom.data().title === "Kitchen") {
      const userFirstName = authUser.firstName;
      const userLastName = authUser.lastName;
      const knitLink = `https://app.knitplaces.com/?space=CDTM&nickname=${userFirstName}%20${userLastName}`;
      window.open(knitLink, "_blank");
      firebase.addParticipant(selectedRoom.id);
    } else {
      window.open(selectedRoom.data().conversation, "_blank");
      firebase.addParticipant(selectedRoom.id);
    }
  }

  leaveRoom() {
    const { firebase, selectedRoom } = this.props;

    firebase.leaveRoom(selectedRoom.id);
    this.hideRoomDrawer();
  }

  submitPost() {
    const { firebase, selectedRoom } = this.props;
    const { title, description } = this.state;

    const post = {
      title,
      description,
    };
    firebase.submitRoomPost(selectedRoom.id, post);
    this.setState({
      title: "",
      description: "",
    });
  }

  deletePost(post) {
    const { firebase, selectedRoom } = this.props;
    firebase.deleteRoomPost(selectedRoom.id, post);
  }

  changePost(oldPost, newPost) {
    const { firebase, selectedRoom } = this.props;
    firebase.changeRoomPost(selectedRoom.id, oldPost, newPost);
  }

  checkForCompletness() {
    const { input } = this.state;
    let disabled = false;
    if (!input) {
      disabled = true;
    } else {
      if (input.length < 10 || input.length > 150) {
        disabled = true;
      }
      if (
        !input.includes("zoom.us") &&
        !input.includes("app.knit") &&
        !input.includes("youtube")
      ) {
        disabled = true;
      }
      if (!input.includes("https://")) {
        disabled = true;
      }
    }

    return disabled;
  }

  changeEditParticipants() {
    const { editParticipants } = this.state;
    let editParticipantsNew = 0;
    if (editParticipants === 0) editParticipantsNew = "x";
    this.setState({ editParticipants: editParticipantsNew });
  }

  renderPosts() {
    const { selectedRoom } = this.props;
    const { editPost } = this.state;
    if (selectedRoom.data().posts) {
      return selectedRoom
        .data()
        .posts.reverse()
        .map(post => {
          return (
            <RoomPost
              post={post}
              editable={editPost}
              deletePost={this.deletePost}
              changePost={this.changePost}
              key={post.title + post.description}
            />
          );
        });
    }
    return null;
  }

  renderParticipants() {
    const { participants, participantSelected } = this.props;
    const { editParticipants } = this.state;
    return participants.map(p => (
      <Participant
        style={{ zIndex: 5 }}
        count={editParticipants}
        participant={p}
        size="huge"
        key={p.id}
        participantSelected={participantSelected}
        kickParticipant={this.kickParticipant}
      />
    ));
  }

  render() {
    const { selectedRoom, participants, visible, authUser } = this.props;
    const {
      submitPost,
      expandPost,
      editPost,
      title,
      description,
      childrenDrawer,
    } = this.state;
    let maxPostHeight = "calc(100vh - 785px)";
    if (submitPost) maxPostHeight = "calc(100vh - 883px)";
    if (expandPost) maxPostHeight = "2000px";

    let maxHeaderHeight = "calc(100vh - 210px)";
    if (submitPost) maxHeaderHeight = "calc(100vh - 210px - 98px)";

    if (selectedRoom) {
      const selectedRoomData = selectedRoom.data();

      let editableUrl = true;
      if (
        selectedRoomData.conversation ===
        "https://app.knitplaces.com/?space=CDTM"
      )
        editableUrl = false;

      let inRoom = false;
      participants.forEach(p => {
        if (p.id === authUser.email) inRoom = true;
      });

      const { windowWidth } = this.props;

      return (
        <Drawer
          placement={isMobileOnly ? "bottom" : "right"}
          width={Math.min(windowWidth * 0.618, 650)}
          height="100%"
          visible={visible}
          onClose={this.hideRoomDrawer}
        >
          <Swipeable
            style={{ overflow: "hidden" }}
            onSwiped={event => this.swipe(event, isMobileOnly)}
          >
            <div
              style={{
                overflowY: "auto",
                height: inRoom ? maxHeaderHeight : "100%",
              }}
            >
              <div id="room-upper-header">
                <Row style={{ marginBottom: "4vh" }}>
                  {selectedRoomData.background && (
                    <Col
                      xs={9}
                      sm={9}
                      md={9}
                      lg={9}
                      xl={8}
                      xxl={8}
                      style={{ paddingRight: "4%" }}
                    >
                      <Avatar
                        src={selectedRoomData.background}
                        shape="square"
                        style={{
                            width: "100%",
                            height: "175px",
                        }}
                      />
                    </Col>
                  )}
                  <Col xs={15} sm={15} md={15} lg={15} xl={16} xxl={16}>
                    <h1 className="name">{selectedRoomData.title}</h1>
                    <div>
                      <Text type="secondary" className="header-label">
                        {selectedRoomData.description}
                      </Text>
                    </div>
                  </Col>
                </Row>
                {participants.length > 0 && (
                  <div>
                    <Divider style={{ marginTop: 24, marginBottom: 24 }} />
                    <Row>{this.renderParticipants()}</Row>
                  </div>
                )}
                {(selectedRoomData.currentSessionDescription !== "" ||
                  inRoom) && (
                  <div>
                    <Divider style={{ marginTop: 24, marginBottom: 24 }} />
                    <Text type="secondary" className="label">
                      Currently ongoing:
                    </Text>
                    <br />
                    <Text
                      style={{
                        color: "rgba(0, 0, 0, 0.85)",
                        fontWeight: 500,
                        fontSize: 16.38,
                      }}
                      editable={
                        inRoom && { onChange: this.onDescriptionChange }
                      }
                    >
                      {selectedRoomData.currentSessionDescription}
                    </Text>
                  </div>
                )}
                {!inRoom && selectedRoomData.conversation !== "" && (
                  <>
                    <Divider style={{ marginTop: 24, marginBottom: 24 }} />
                    <Button
                      type="primary"
                      shape="round"
                      icon={<LoginOutlined />}
                      disabled={selectedRoomData.locked}
                      onClick={this.enterRoom}
                    >
                      Join
                    </Button>
                  </>
                )}
                {!inRoom && selectedRoomData.conversation === "" && (
                  <>
                    <Divider style={{ marginTop: 24, marginBottom: 24 }} />
                    <Button
                      type="primary"
                      shape="round"
                      icon={<LoginOutlined />}
                      onClick={this.enterRoom}
                    >
                      Open room
                    </Button>
                  </>
                )}
                {inRoom && (
                  <div>
                    <Divider style={{ marginTop: 24, marginBottom: 24 }} />
                    <Text type="secondary" className="label">
                      Conversation URL
                    </Text>
                    <br />
                    <Row style={{ width: "100%" }}>
                      <Col style={{ width: "calc(100% - 200px)" }}>
                        <Text
                          ellipsis
                          copyable
                          style={{
                            color: "rgba(0, 0, 0, 0.85)",
                            fontWeight: 500,
                            fontSize: 16.38,
                            width: "100%",
                          }}
                          editable={
                            inRoom &&
                            editableUrl && { onChange: this.onURLChange }
                          }
                        >
                          {selectedRoomData.conversation}
                        </Text>
                      </Col>
                      <Col flex="200px" style={{ paddingLeft: 15 }}>
                        <Space>
                          <Checkbox
                            checked={selectedRoomData.permanent}
                            disabled={!editableUrl}
                            onChange={e =>
                              this.onPermanentChange(e.target.checked)
                            }
                          >
                            permanent
                          </Checkbox>
                          <Checkbox
                            checked={selectedRoomData.locked}
                            onChange={e =>
                              this.onLockedChange(e.target.checked)
                            }
                          >
                            locked
                          </Checkbox>
                        </Space>
                      </Col>
                    </Row>
                  </div>
                )}
              </div>
              {inRoom && (
                <div>
                  <Divider style={{ marginTop: 24, marginBottom: 24 }} />
                  <Text type="secondary" className="label">
                    Session Posts
                  </Text>
                  <div
                    style={{
                      maxHeight: maxPostHeight,
                      minHeight: 83,
                      overflowY: "auto",
                      overflowX: "hidden",
                    }}
                  >
                    <Row gutter={5}>{this.renderPosts()}</Row>
                  </div>
                </div>
              )}
            </div>
          </Swipeable>
          <div>
            {inRoom && (
              <div>
                {submitPost && (
                  <Card
                    className="room-post"
                    style={{ borderRadius: 10, padding: 0, marginTop: 10 }}
                  >
                    <Meta
                      title={
                        <div>
                          <Input
                            id="title"
                            value={title}
                            onChange={this.onChange}
                            size="small"
                            placeholder="A short title"
                            style={{
                              color: "rgba(0, 0, 0, 0.85)",
                              fontWeight: 500,
                              fontSize: 16.38,
                              width: "60%",
                              borderRadius: 5,
                              marginTop: 3,
                            }}
                          />
                          <Button
                            icon={<SendOutlined />}
                            style={{ float: "right", borderRadius: 10 }}
                            onClick={() => this.submitPost()}
                          >
                            Send
                          </Button>
                        </div>
                      }
                      style={{ paddingBottom: 10 }}
                    />
                    <TextArea
                      id="description"
                      value={description}
                      onChange={this.onChange}
                      autoSize
                      size="small"
                      placeholder="Your comment or a interesting link"
                      style={{ borderRadius: 5 }}
                    />
                  </Card>
                )}
                <Divider style={{ marginTop: 24, marginBottom: 24 }} />
                <Row gutter={5}>
                  {submitPost && (
                    <Col span={8}>
                      <Button
                        icon={<SendOutlined />}
                        onClick={() => this.setState({ submitPost: false })}
                        style={{ borderRadius: 10, width: "100%" }}
                      >
                        Hide
                      </Button>
                    </Col>
                  )}
                  {!submitPost && (
                    <Col span={8}>
                      <Button
                        icon={<SendOutlined />}
                        onClick={() => this.setState({ submitPost: true })}
                        style={{ borderRadius: 10, width: "100%" }}
                      >
                        Submit
                      </Button>
                    </Col>
                  )}
                  {editPost && (
                    <Col span={8}>
                      <Button
                        icon={<EditOutlined />}
                        onClick={() => this.setState({ editPost: false })}
                        style={{ borderRadius: 10, width: "100%" }}
                      >
                        Hide
                      </Button>
                    </Col>
                  )}
                  {!editPost && (
                    <Col span={8}>
                      <Button
                        icon={<EditOutlined />}
                        onClick={() => this.setState({ editPost: true })}
                        style={{ borderRadius: 10, width: "100%" }}
                      >
                        Edit
                      </Button>
                    </Col>
                  )}
                  {expandPost && (
                    <Col span={8}>
                      <Button
                        icon={<LayoutOutlined />}
                        onClick={() => this.setState({ expandPost: false })}
                        style={{ borderRadius: 10, width: "100%" }}
                      >
                        Fixate
                      </Button>
                    </Col>
                  )}
                  {!expandPost && (
                    <Col span={8}>
                      <Button
                        icon={<MoreOutlined />}
                        onClick={() => this.setState({ expandPost: true })}
                        style={{ borderRadius: 10, width: "100%" }}
                      >
                        Scroll
                      </Button>
                    </Col>
                  )}
                </Row>
              </div>
            )}
            {inRoom && (
              <div>
                <Divider style={{ marginTop: 24, marginBottom: 24 }} />
                <Button
                  type="primary"
                  shape="round"
                  icon={<LogoutOutlined />}
                  onClick={this.leaveRoom}
                >
                  Leave room
                </Button>
                <Button
                  shape="round"
                  icon={<EditOutlined />}
                  style={{ float: "right", borderColor: "transparent" }}
                  onClick={() => this.changeEditParticipants()}
                >
                  Edit Participants
                </Button>
              </div>
            )}
          </div>
          <Drawer
            placement={isMobileOnly ? "bottom" : "right"}
            width={Math.min(windowWidth * 0.5, 450)}
            height={270}
            closable={false}
            onClose={this.onChildrenDrawerClose}
            visible={childrenDrawer}
          >
            <h2>You are the first to join</h2>
            <div style={{ paddingBottom: 10 }}>
              <Text type="secondary">
                Because you are the first one to join, please set up a new zoom
                call quickly!
              </Text>
            </div>
            <Input
              id="zoom_link"
              addonBefore="URL:"
              placeholder="https://us02web.zoom.us/j/..."
              onChange={this.onChange}
            />
            <Divider />
            <Button
              type="primary"
              shape="round"
              icon={<LoginOutlined />}
              disabled={this.checkForCompletness()}
              onClick={this.setupZoom}
            >
              Enter room
            </Button>
          </Drawer>
        </Drawer>
      );
    }
    return null;
  }
}

const condition = authUser => authUser != null;
const RoomPopup = withFirebase(withAuthorization(condition)(RoomPopup_));
// eslint-disable-next-line import/prefer-default-export
export { RoomPopup };
