import React, { useEffect, useState } from "react";
import { Card, Divider, Row, Col, Button, Typography } from "antd";
import {
  MailOutlined,
  FormOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons";
import DownloadButton from "../../DownloadButton";

import { withFirebase } from "../../../../Firebase";

const { Title } = Typography;

const DetailCardDesktop = ({ jobDocument, firebase }) => {
  const [author, setAuthor] = useState({ email: "", name: "" });
  const [downloadURL, setDownloadURL] = useState("");

  const createdAt = new Date(
    jobDocument?.data?.createdAt?.seconds * 1000,
  ).toLocaleDateString();

  useEffect(() => {
    const getAuthorEmail = () => {
      const usersRef = firebase.db.collection("users");
      const query = usersRef.where("uid", "==", jobDocument.data.createdBy);
      query.get().then(snapshot => {
        snapshot.forEach(doc => {
          const { firstName, lastName, email } = doc.data();
          setAuthor({ ...{ name: `${firstName} ${lastName}`, email } });
        });
      });
    };
    if (!jobDocument.data) {
      return;
    }
    getAuthorEmail();
  }, [firebase, jobDocument]);

  useEffect(() => {
    const getDownloadURL = async () => {
      if (!jobDocument.data.pdfUid) {
        return;
      }
      const ref = firebase.storage
        .ref()
        .child(`jobs/${jobDocument.data.createdBy}/${jobDocument.data.pdfUid}`);
      ref
        .getDownloadURL()
        .then(url => {
          setDownloadURL(url);
        })
        .catch(
          // TODO
          /* err */ () => {
            // console.error(err);
          },
        );
    };
    getDownloadURL();
  }, [jobDocument, firebase.storage]);

  const hasFile = jobDocument?.data?.pdfUid;
  const hasLinkToJob = jobDocument.data?.linkToJob;

  const {
    logoURL,
    level,
    role,
    websiteURL,
    company,
    location,
    linkToJob,
    description,
  } = jobDocument.data;

  return (
    <Card style={{ maxHeight: "65vh", overflowY: "scroll" }}>
      {/* Header and Overview */}
      <Row>
        {/* Company Logo */}
        <Col span={8}>
          <img alt="Company Logo" style={{ width: "90%" }} src={`${logoURL}`} />
        </Col>
        {/* Job overview */}
        <Col span={16}>
          <Title level={2}>
            {level} {role}
          </Title>
          <Row>
            <Col>
              <a
                href={websiteURL || "#"}
                target="_blank"
                rel="noopener noreferrer"
              >
                {company}
              </a>
            </Col>
          </Row>
          <Row>
            <Col>
              <Col>
                {" "}
                <EnvironmentOutlined /> {location}
              </Col>
            </Col>
          </Row>
          <Row>
            <Col>
              Posted by <a href={`mailto:${author.email}`}>{author.name}</a> on{" "}
              {createdAt}
            </Col>
          </Row>
        </Col>
      </Row>

      <Divider />
      {/* Apply and contact */}
      <Row justify="space-between">
        <Col span={hasFile ? 7 : 11}>
          <Button
            style={{ width: "100%" }}
            onClick={() => window.open(`mailto:${author.email}`)}
          >
            <MailOutlined /> Get in touch
          </Button>
        </Col>
        <Col span={hasFile ? 7 : 11}>
          <Button
            style={{ width: "100%" }}
            onClick={() =>
              window.open(hasLinkToJob ? linkToJob : author.email, "_blank")
            }
          >
            <FormOutlined /> Apply directly
          </Button>
        </Col>
        {hasFile && (
          <Col span={7}>
            <DownloadButton href={downloadURL}>File</DownloadButton>
          </Col>
        )}
      </Row>
      <Divider />
      {/* About the job */}
      <Row>
        <Col span={24}>
          <Title level={3}>About the job</Title>
          <p>{description}</p>
        </Col>
      </Row>
      <Divider />
    </Card>
  );
};

export default withFirebase(DetailCardDesktop);
