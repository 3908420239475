import React from "react";
import { isMobileOnly } from "react-device-detect";

import { Layout, Row, PageHeader, Spin, Button, Dropdown } from "antd";
import { Loading3QuartersOutlined, FilterOutlined } from "@ant-design/icons";

import { RoomMapCard } from "./components/RoomMapCard";
import { RoomPopup } from "./components/RoomPopup";
import { ProfileDrawer } from "../Directory/ProfileDrawer";
import FilterMenu from "./components/FilterMenu";

import { withFirebase } from "../Firebase";
import { withAuthorization } from "../Session";

import "./MapPage.css";

const { Content } = Layout;

class VirtualCenter_ extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      rooms: [],

      showRoomDrawer: false,
      roomSelected: null,
      participants: [],
      showProfileDrawer: false,
      profileSelected: null,
      filterSelected: "",
    };

    this.roomSelected = this.roomSelected.bind(this);
    this.roomChanged = this.roomChanged.bind(this);
    this.onCloseRoomDrawer = this.onCloseRoomDrawer.bind(this);

    this.participantSelected = this.participantSelected.bind(this);
    this.onCloseProfileDrawer = this.onCloseProfileDrawer.bind(this);

    this.filterSelected = this.filterSelected.bind(this);
  }

  componentDidMount() {
    const { firebase } = this.props;

    firebase.syncRooms(rooms => {
      this.setState({
        rooms,
        loading: false,
      });
    });
  }

  onCloseProfileDrawer() {
    this.setState({
      profileSelected: null,
      showProfileDrawer: false,
    });
  }

  onCloseRoomDrawer() {
    this.setState({
      roomSelected: null,
      participants: [],
      showRoomDrawer: false,
    });
  }

  roomSelected(room, participants) {
    this.setState({
      roomSelected: room,
      participants,
      showRoomDrawer: true,
    });
  }

  roomChanged(room, participants) {
    const { roomSelected } = this.state;
    if (roomSelected) {
      if (roomSelected.id === room.id) {
        this.setState({
          roomSelected: room,
          participants,
        });
      }
    }
  }

  participantSelected(participant) {
    this.setState({
      showProfileDrawer: true,
      profileSelected: participant,
    });
  }

  filterSelected(filterSelected) {
    this.setState({ filterSelected });
  }

  inRoom() {
    const { rooms } = this.state;
    const { authUser } = this.props;

    if (
      rooms.filter(r => r.data().participants.includes(authUser.email))
        .length === 1
    )
      return true;
    return false;
  }

  renderRooms(i) {
    const { rooms } = this.state;
    const { windowWidth } = this.props;

    if (!rooms) {
      return "";
    }
    if (!rooms.length > 0) {
      return "";
    }
    return rooms
      .filter(r => r.data().map_info[0] === i)
      .sort((a, b) => {
        return a.data().map_info[1] - b.data().map_info[1];
      })
      .map(r => (
        <RoomMapCard
          windowWidth={windowWidth}
          room={r}
          key={r.id}
          roomSelected={this.roomSelected}
          roomChanged={this.roomChanged}
          participantSelected={this.participantSelected}
        />
      ));
  }

  renderRoomsOrdered() {
    const { rooms, filterSelected } = this.state;
    const { windowWidth, authUser } = this.props;

    if (!rooms) {
      return "";
    }
    if (!rooms.length > 0) {
      return "";
    }

    let roomsOrdered = [];
    switch (filterSelected) {
      case "active":
        roomsOrdered = rooms.filter(r => r.data().participants.length > 0);
        break;
      case "lecture":
        roomsOrdered = rooms
          .filter(r => r.data().room_type === "lecture")
          .sort((a, b) => {
            return a.data().title.split(" ")[1] - b.data().title.split(" ")[1];
          });
        break;
      case "main":
        roomsOrdered = rooms.filter(r => r.data().room_type === "main");
        break;
      case "group":
        roomsOrdered = rooms.filter(r => r.data().room_type === "group");
        break;
      case "ca":
        roomsOrdered = rooms
          .filter(r => r.data().room_type === "ca")
          .sort((a, b) => {
            if (a.data().title.split(" ")[1] === "Lounge") {
              return -1;
            }
            return a.data().title.split(" ")[1] - b.data().title.split(" ")[1];
          });
        break;
      default:
        roomsOrdered = rooms.filter(r => r.data().participants.length > 0);

        rooms
          .filter(r => r.data().participants.length === 0)
          .filter(r => r.data().room_type === "lecture")
          .sort((a, b) => {
            return a.data().title.split(" ")[1] - b.data().title.split(" ")[1];
          })
          .forEach(r => roomsOrdered.push(r));

        rooms
          .filter(r => r.data().participants.length === 0)
          .filter(r => r.data().room_type === "main")
          .forEach(r => roomsOrdered.push(r));

        rooms
          .filter(r => r.data().participants.length === 0)
          .filter(r => r.data().room_type === "group")
          .forEach(r => roomsOrdered.push(r));

        rooms
          .filter(r => r.data().participants.length === 0)
          .filter(r => r.data().room_type === "ca")
          .sort((a, b) => {
            if (a.data().title.split(" ")[1] === "Lounge") {
              return -1;
            }
            return a.data().title.split(" ")[1] - b.data().title.split(" ")[1];
          })
          .forEach(r => roomsOrdered.push(r));
    }

    return roomsOrdered.map(r => {
      if (!r.data().participants.includes(authUser.email)) {
        return (
          <RoomMapCard
            windowWidth={windowWidth}
            room={r}
            key={r.id}
            roomSelected={this.roomSelected}
            roomChanged={this.roomChanged}
            participantSelected={this.participantSelected}
          />
        );
      }
      return null;
    });
  }

  renderCurrentRoom() {
    const { rooms } = this.state;
    const { windowWidth, authUser } = this.props;

    const currentRooms = rooms.filter(r =>
      r.data().participants.includes(authUser.email),
    );
    if (currentRooms.length === 1) {
      return (
        <RoomMapCard
          windowWidth={windowWidth}
          room={currentRooms[0]}
          key={currentRooms[0].id}
          roomSelected={this.roomSelected}
          roomChanged={this.roomChanged}
          participantSelected={this.participantSelected}
        />
      );
    }
    return null;
  }

  render() {
    const { windowWidth } = this.props;
    const {
      roomSelected,
      rooms,
      loading,
      showRoomDrawer,
      participants,
      showProfileDrawer,
      profileSelected,
    } = this.state;
    let liveRoom = null;
    if (roomSelected) liveRoom = rooms.find(r => r.id === roomSelected.id);

    const inRoom = this.inRoom();

    return (
      <Layout style={{ overflow: "hidden" }} className="layout-vc">
        <Content
          style={{
            padding: "0",
            margin: isMobileOnly ? "0px 0px 0px 0px" : "64px 16px 0px 16px",
            overflow: "hidden",
            minHeight: "calc(100vh - 64px)",
          }}
        >
          <PageHeader
            className="site-page-header-vc"
            title="Virtual Center"
            subTitle={
              !isMobileOnly &&
              windowWidth >= 1300 &&
              "See whats going on in the center and join in by clicking on a room"
            }
            style={{ 
              zIndex: 5, 
              background: "rgba(255,255,255,0.8)", 
              width: isMobileOnly ? "100%" :"50%", 
              padding: isMobileOnly ? "7px 24px" : "10px", 
              borderRadius: 8, 
              marginTop: isMobileOnly ? 10 : 25, 
              marginLeft: isMobileOnly ? 0 : 45,
              marginBottom: isMobileOnly ? 0 : 0}}
            extra={
              isMobileOnly && (
                <Dropdown
                  overlay={<FilterMenu callback={this.filterSelected} />}
                >
                  <Button
                    icon={<FilterOutlined />}
                    onClick={e => e.preventDefault()}
                    shape="circle"
                  />
                </Dropdown>
              )
            }
          />
          <div
            style={{
              paddingLeft: 24,
              paddingRight: 24,
              minHeight: 380,
              marginTop: isMobileOnly ? 0 : -57,
            }}
          >
            
            {loading && (
              <div style={{ textAlign: "center" }}>
                <Spin
                  indicator={<Loading3QuartersOutlined spin />}
                  style={{ marginTop: "30px" }}
                />
              </div>
            )}
            {!loading && !isMobileOnly && (
              <div style={{ overflowX: "auto", paddingBottom: 20}}>
                <Row justify="center" style={{ width: "100%", minWidth: 725}}>
                  {this.renderRooms(1)}
                </Row>
                <Row justify="center" style={{ width: "100%", minWidth: 725 }}>
                  {this.renderRooms(2)}
                </Row>
                <Row justify="center" style={{ width: "100%", minWidth: 725 }}>
                  {this.renderRooms(3)}
                </Row>
                <Row justify="center" style={{ width: "100%", minWidth: 725 }}>
                  {this.renderRooms(4)}
                </Row>
                <Row justify="center" style={{ width: "100%", minWidth: 725 }}>
                  {this.renderRooms(5)}
                </Row>
              </div>
            )}
            {isMobileOnly && (
              <>
                <div
                  style={{
                    overflowY: "auto",
                    overflowX: "hidden",
                    height: inRoom
                      ? "calc(100vh - 292px)"
                      : "calc(100vh - 136px)",
                  }}
                >
                  <Row gutter={5}>{this.renderRoomsOrdered()}</Row>
                </div>
                {inRoom && (
                  <div style={{ paddingTop: 20 }}>
                    <Row gutter={5}>{this.renderCurrentRoom()}</Row>
                  </div>
                )}
              </>
            )}
          </div>
        </Content>
        <RoomPopup
          windowWidth={windowWidth}
          visible={showRoomDrawer}
          selectedRoom={liveRoom}
          participants={participants}
          onClose={this.onCloseRoomDrawer}
          participantSelected={this.participantSelected}
        />
        <ProfileDrawer
          visible={showProfileDrawer}
          selectedProfile={profileSelected ? profileSelected.data() : null}
          onClose={this.onCloseProfileDrawer}
        />
      </Layout>
    );
  }
}

const condition = authUser => authUser != null;
const VirtualCenter = withFirebase(
  withAuthorization(condition)(VirtualCenter_),
);
// eslint-disable-next-line import/prefer-default-export
export { VirtualCenter };
