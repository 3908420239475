import React from "react";
import { Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";

const DownloadButton = ({ href, children }) => {
  return (
    <Button
      style={{ width: "100%" }}
      href={href}
      icon={<DownloadOutlined />}
      target="_blank"
      download
    >
      {children}
    </Button>
  );
};

export default DownloadButton;
