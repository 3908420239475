import React, { useState } from "react";

import {
  Select,
  Button,
  Row,
  Col,
  Layout,
  PageHeader,
  Typography,
  Divider,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";

const { Content } = Layout;
const { Option } = Select;
const { Title } = Typography;

const FilterPage = ({
  sort,
  sortOrder,
  filter,
  filterLists,
  activeFilter,
  closeFilterPage,
  jobsShownHits,
}) => {
  const {
    companyFilterList,
    roleFilterList,
    locationFilterList,
    levelFilterList,
  } = filterLists;

  const handleSortChange = sortCriteria => {
    sort(sortCriteria);
  };

  const generateOptions = filterList => {
    const options = filterList.map(option => (
      <Option key={option}>{option}</Option>
    ));
    return options;
  };

  const handleFilterReset = event => {
    event.preventDefault();
    filter({ category: "reset" });
  };

  const [isInputFocussed, setIsInputFocussed] = useState(false);

  return (
    <>
      <PageHeader
        title="Filter Jobs"
        id="resources-header"
        style={{
          position: "fixed",
          backgroundColor: "white",
          width: "100%",
          zIndex: 99,
        }}
        extra={[
          <Button
            onClick={closeFilterPage}
            icon={<CloseOutlined />}
            shape="circle"
          />,
        ]}
      />
      <Content>
        <Row style={{ paddingTop: "100px" }}>
          <Col offset={1} span={6}>
            <Title level={4}>Sort</Title>
          </Col>
        </Row>
        {/* Sorting  */}
        <Row style={{ paddingBottom: "10px" }}>
          <Col className="sort-col" offset={1} span={22}>
            <Select
              placeholder="Sort"
              style={{ width: "100%" }}
              onChange={handleSortChange}
              value={sortOrder}
              size="large"
            >
              <Option value="latest">Latest first</Option>
              <Option value="earliest">Earliest first</Option>
            </Select>
          </Col>
        </Row>
        <Divider style={{ marginTop: "10px", marginBottom: "15px" }} />
        {/* Filtering */}
        <Row>
          <Col span={6} offset={1}>
            <Title level={4}>Filter</Title>
          </Col>
          <Col offset={10} span={6}>
            <Button
              style={{ width: "100%" }}
              type="link"
              onClick={handleFilterReset}
            >
              Reset Filter
            </Button>
          </Col>
        </Row>
        <Divider style={{ marginTop: "10px", marginBottom: "20px" }} />
        <Row>
          <Col offset={1} span={23}>
            Role
          </Col>
          <Col className="filter-col" offset={1} span={22}>
            <Select
              size="large"
              mode="multiple"
              style={{ width: "100%" }}
              placeholder="e.g. Software Engineer"
              onChange={value => filter({ category: "role", filter: value })}
              value={activeFilter.role}
              onFocus={() => setIsInputFocussed(true)} // hides "show results" button bc mobile keyboard would push it upwards
              onBlur={() => setIsInputFocussed(false)} // shows button again when leaving input field
            >
              {generateOptions(roleFilterList)}
            </Select>
          </Col>
        </Row>
        <Row>
          <Col offset={1} span={23}>
            Level
          </Col>
          <Col className="filter-col" offset={1} span={22}>
            <Select
              size="large"
              mode="multiple"
              style={{ width: "100%" }}
              placeholder="e.g. Working Student"
              value={activeFilter.level}
              onChange={value => filter({ category: "level", filter: value })}
              onFocus={() => setIsInputFocussed(true)}
              onBlur={() => setIsInputFocussed(false)}
            >
              {generateOptions(levelFilterList)}
            </Select>
          </Col>
        </Row>
        <Row>
          <Col offset={1} span={23}>
            Company
          </Col>
          <Col className="filter-col" offset={1} span={22}>
            <Select
              size="large"
              mode="multiple"
              placeholder="e.g. Google"
              style={{ width: "100%" }}
              value={activeFilter.company}
              onChange={value => filter({ category: "company", filter: value })}
              onFocus={() => setIsInputFocussed(true)}
              onBlur={() => setIsInputFocussed(false)}
            >
              {generateOptions(companyFilterList)}
            </Select>
          </Col>
        </Row>
        <Row style={{ paddingBottom: "90px" }}>
          <Col offset={1} span={23}>
            Location
          </Col>
          <Col className="filter-col" offset={1} span={22}>
            <Select
              size="large"
              placeholder="e.g. Munich"
              onChange={value =>
                filter({ category: "location", filter: value })
              }
              mode="multiple"
              value={activeFilter.location}
              style={{ width: "100%" }}
              onFocus={() => setIsInputFocussed(true)}
              onBlur={() => setIsInputFocussed(false)}
            >
              {generateOptions(locationFilterList)}
            </Select>
          </Col>
        </Row>
        {!isInputFocussed && (
          <Row
            style={{
              position: "fixed",
              width: "100%",
              bottom: "70px",
              height: "60px",
              padding: "0",
            }}
          >
            <Col span={24}>
              <Button
                style={{ width: "100%", height: "100%" }}
                type="primary"
                onClick={closeFilterPage}
              >
                <b>Show results ({jobsShownHits})</b>
              </Button>
            </Col>
          </Row>
        )}
      </Content>
    </>
  );
};

export default FilterPage;
