export default class Job {
  constructor({
    company = "",
    createdAt = { seconds: 0 },
    createdBy = "",
    description = "",
    level = "",
    location = "",
    logoURL = "",
    role = "",
  }) {
    this.company = company;
    this.createdAt = createdAt;
    this.createdBy = createdBy;
    this.description = description;
    this.level = level;
    this.location = location;
    this.logoURL = logoURL;
    this.role = role;
  }
}
