import React, { useState, useEffect } from "react";
import { Card, Row, Col, Button, message } from "antd";
import { DownloadOutlined, LaptopOutlined } from "@ant-design/icons";
import "./index.css";
import { withFirebase } from "../../../Firebase";

const JobCard = ({
  jobDetails,
  openJobDrawerWithData,
  firebase,
  ReloadPosts,
  authUser,
}) => {
  const { company, location, createdAt, role, level } = jobDetails;

  const [downloadURL, setDownloadURL] = useState("");

  // Get download link for job description
  useEffect(() => {
    const getDownloadURL = () => {
      if (!jobDetails.pdfUid) {
        return;
      }
      firebase.storage
        .ref(`jobs/${authUser.uid}/${jobDetails.pdfUid}`)
        .getDownloadURL()
        .then(url => {
          setDownloadURL(url);
        })
        .catch(() => {});
    };
    getDownloadURL();
  }, [jobDetails, firebase, authUser]);

  const date = new Date(createdAt.seconds * 1000).toLocaleDateString();

  return (
    <>
      {jobDetails && (
        <Card className="job-card">
          <Row justify="space-between">
            <Col xs={20} sm={20} md={20} lg={5}>
              <b>
                {level} - {role}
              </b>
            </Col>
            <Col xs={12} sm={24} md={12} lg={3}>
              {company}
            </Col>
            <Col xs={12} sm={24} md={12} lg={3}>
              {location}
            </Col>
            <Col xs={12} sm={12} md={12} lg={3}>
              created on {date}
            </Col>
            {jobDetails.pdfUid ? (
              <Col xs={24} sm={24} md={24} lg={2}>
                <Button
                  href={downloadURL}
                  icon={<DownloadOutlined />}
                  className="job-card-button-row-button"
                  target="_blank"
                  download
                >
                  File
                </Button>
              </Col>
            ) : (
              <Col xs={24} sm={24} md={24} lg={2}></Col>
            )}
            <Col xs={24} sm={24} md={24} lg={2}>
              <Button
                onClick={() => openJobDrawerWithData(jobDetails)}
                className="job-card-button-row-button"
              >
                EDIT
              </Button>
            </Col>
            <Col xs={24} sm={24} md={24} lg={2}>
              <Button
                onClick={() => {
                  firebase
                    .removeOpportunityDoc("job", jobDetails.id, authUser)
                    .then(() => {
                      ReloadPosts();
                      message.success("Job was deleted successfully.");
                    });
                }}
                className="job-card-button-row-button"
              >
                DELETE
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                <LaptopOutlined /> Job
              </p>
            </Col>
          </Row>
        </Card>
      )}
    </>
  );
};

export default withFirebase(JobCard);
