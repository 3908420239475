import React, { useState, useEffect } from "react";
import { isMobileOnly } from "react-device-detect";

import { Layout, PageHeader, Tabs, Button, message } from "antd";

import { FilterOutlined, PlusOutlined } from "@ant-design/icons";

import { withAuthorization } from "../Session";
import { withFirebase } from "../Firebase";
import JobDrawer from "./Job/JobDrawer";
import TalentDrawer from "./Talent/TalentDrawer";
import Jobs from "./Job";
import Talent from "./Talent";
import EditPost from "./EditPost";

import "./index.css";

const { Content } = Layout;
const { TabPane } = Tabs;

const Opportunities = props => {
  const [activeTab, setActiveTab] = useState("Jobs");
  // const [loading, setLoading] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [reloadPosts, setReloadPosts] = useState(false);
  const [isFilterPageOpen, setIsFilterPageOpen] = useState(false);

  const { windowWidth, authUser } = props;

  const onTabChange = newActiveTab => {
    if (newActiveTab === activeTab) return;

    setActiveTab(newActiveTab);
    // loadResources();
    // firebase.logEvent("resource_change_tab", { tab: newActiveTab });
  };

  const getDrawerOpenButtonTitle = () => {
    if (isMobileOnly) {
      return "";
    }
    if (activeTab === "Jobs") {
      return isMobileOnly ? "" : "Submit new Job";
    }
    if (activeTab === "Talents") {
      return "Submit new Talent";
    }
    return "";
  };

  const getDrawerOpenButton = () => {
    if (activeTab === "Manage Postings") {
      return <></>;
    }
    return (
      <Button
        key="Submit"
        icon={<PlusOutlined />}
        onClick={() => {
          setDrawerOpen("open");
        }}
        type="primary"
        shape={isMobileOnly && "circle"}
      >
        {getDrawerOpenButtonTitle()}
      </Button>
    );
  };

  useEffect(() => {
    setReloadPosts(false);
  }, [reloadPosts]);

  const closeFilterPage = () => {
    setIsFilterPageOpen(false);
  };

  const renderTab = activeKey => {
    switch (activeKey) {
      case "Jobs":
        return (
          <Jobs
            reloadPosts={reloadPosts}
            closeFilterPage={closeFilterPage}
            isFilterPageOpen={isFilterPageOpen}
          />
        );
      case "Talents":
        return <Talent reloadPosts={reloadPosts} />;
      case "Manage Postings":
        return (
          <EditPost
            authUser={authUser}
            windowWidth={windowWidth}
            reloadPosts={reloadPosts}
            setReloadPosts={setReloadPosts}
          />
        );
      default:
        message.error("something went wrong");
        return null;
    }
  };

  const Drawer = () => {
    if (activeTab === "Jobs") {
      return (
        <JobDrawer
          authUser={authUser}
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
          windowWidth={windowWidth}
          drawerType={activeTab}
          setReloadPosts={setReloadPosts}
        />
      );
    }
    if (activeTab === "Talents") {
      return (
        <TalentDrawer
          authUser={authUser}
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
          windowWidth={windowWidth}
          drawerType={activeTab}
          setReloadPosts={setReloadPosts}
        />
      );
    }
    return null;
  };

  const handleClick = () => {
    setIsFilterPageOpen(!isFilterPageOpen);
  };

  return (
    <Layout className="module">
      {!isFilterPageOpen && (
        <PageHeader
          title="Jobs & Talent"
          id="resources-header"
          subTitle={[isMobileOnly ? "" : "Offer jobs and find talent"]}
          style={{
            position: "fixed",
            backgroundColor: "white",
            width: "100%",
            zIndex: 99,
          }}
          extra={[
            getDrawerOpenButton(),
            isMobileOnly && (
              <Button
                onClick={handleClick}
                icon={<FilterOutlined />}
                shape="circle"
              />
            ),
          ]}
        />
      )}
      <Content>
        {!isFilterPageOpen && (
          <Tabs
            onChange={onTabChange}
            activeKey={activeTab}
            size="default"
            style={{
              position: "fixed",
              width: "100%",
              marginTop: isMobileOnly ? "70px" : "70px",
              zIndex: 99,
              paddingBottom: "0px",
            }}
            className={isMobileOnly ? "resources-tabs" : null}
          >
            <TabPane
              key="Jobs"
              tab={<span style={{ paddingLeft: "24px" }}>Jobs</span>}
            />
            <TabPane
              key="Talents"
              tab={<span style={{ paddingLeft: "8px" }}>Talent</span>}
            />
            <TabPane
              key="Manage Postings"
              tab={<span style={{ paddingLeft: "8px" }}>Manage Postings</span>}
            />
          </Tabs>
        )}

        <div
          style={{
            marginTop: isMobileOnly && !isFilterPageOpen ? "115px" : "0px",
          }}
        >
          {renderTab(activeTab)}
        </div>
        <Drawer />
      </Content>
    </Layout>
  );
};

const condition = authUser => authUser != null;

export default withFirebase(withAuthorization(condition)(Opportunities));
