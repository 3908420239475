import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";
import "firebase/performance";
import "firebase/analytics";
import { message } from "antd";
import { firebaseConfig } from "../../constants/firebaseAPI";
import * as ROUTES from "../../constants/routes";

const verifyURL = window.location.origin + ROUTES.VERIFY;
const actionCodeSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be whitelisted in the Firebase Console.
  url: verifyURL,
  // This must be true.
  handleCodeInApp: true,
};

// cache download links of images
const urlCache = {};

class Firebase {
  constructor() {
    this.app = firebase.initializeApp(firebaseConfig);
    this.auth = firebase.auth();
    this.db = firebase.firestore();
    this.auth.languageCode = "en";
    this.storage = firebase.storage();
    this.performanceMonitoring = firebase.performance();
    this.analytics = firebase.analytics();
    this.functions = this.app.functions("europe-west1");
    this.countPageViews = 0;
  }

  // Auth API
  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithEmailLink = email =>
    this.auth.sendSignInLinkToEmail(email, actionCodeSettings);

  doCompleteSignInEmailLink = async () => {
    if (this.auth.isSignInWithEmailLink(window.location.href)) {
      let email = window.localStorage.getItem("emailForSignIn");
      if (!email) {
        email = message.info("Please provide your email for confirmation");
      }

      await this.auth.signInWithEmailLink(email, window.location.href);

      window.localStorage.removeItem("emailForSignIn");
      this.logEvent("login", { method: "Email Link" });
    }
  };

  doSignOut = () => {
    this.logEvent("logout");
    this.auth.signOut();
  };

  doSignOutCallback = (success, failure) =>
    this.auth
      .signOut()
      .then(() => success())
      .catch(error => failure(error));

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);

  doDeleteUser = (success, requiresReauth) => {
    this.auth.currentUser
      .delete()
      .then(success)
      .catch(error => {
        if (error.code === "auth/requires-recent-login") requiresReauth();
      });
  };

  // *** Merge Auth and DB User API *** //
  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        next(authUser);
      } else {
        fallback();
      }
    });

  // Storage APIs
  uploadImageAndGetUrl = (
    onError,
    onProgress,
    onSuccessUrlHandler,
    fullPath,
    imgDataUrl,
  ) => {
    const metadata = {
      contentType: "image/jpeg",
      cacheControl: "public,max-age=2628000", // 1 month
    };

    const uploadRef = this.storage.ref().child(fullPath);
    const uploadTask = uploadRef.putString(imgDataUrl, "data_url", metadata);

    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
      snapshot => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        if (onProgress) onProgress(progress);
      },
      error => {
        // https://firebase.google.com/docs/storage/web/handle-errors
        onError(error);
      },
      () => {
        // Upload completed successfully, now we add the fullPath to the firestore
        uploadRef
          .getDownloadURL()
          .then(url => onSuccessUrlHandler(url))
          .catch(e => onError(e));
      },
    );
  };

  updateUserAvatar = (
    onError,
    onProgress,
    onSuccess,
    avatarDataUrl,
    authUser,
  ) => {
    const path = `avatars/${authUser.email}/avatar.jpg`;

    this.uploadImageAndGetUrl(
      onError,
      onProgress,
      url => {
        this.currentUser()
          .set({ avatar: url }, { merge: true })
          .then(() => onSuccess())
          .catch(e => onError(e));
      },
      path,
      avatarDataUrl,
    );
  };

  getDownloadUrl = fileRef => {
    if (!(fileRef in Object.keys(urlCache)))
      urlCache[fileRef] = this.storage
        .ref(fileRef)
        .getDownloadURL()
        .then(downloadURL => {
          return downloadURL;
        })
        .catch(() => {
          return null;
        });

    return urlCache[fileRef];
  };

  // Storage

  removeOpportunityPDF = (type, authUser, fileID) => {
    let fullPath;
    if (type === "job") {
      fullPath = `jobs/${authUser.uid}/${fileID}`;
    } else if (type === "talent") {
      fullPath = `talents/${authUser.uid}/${fileID}`;
    }

    return new Promise((resolve, reject) => {
      const storageRef = firebase.storage().ref(fullPath);
      try {
        storageRef.delete();
      } catch (e) {
        reject(e);
      }
      resolve();
    });
  };

  uploadOpportunityPDF = (
    type,
    onError,
    onSuccess,
    onProgress,
    file,
    authUser,
  ) => {
    const metadata = {
      contentType: "application/pdf",
    };

    let fullPath;
    if (type === "job") {
      fullPath = `jobs/${authUser.uid}/${file.uid}`;
    } else if (type === "talent") {
      fullPath = `talents/${authUser.uid}/${file.uid}`;
    }
    const storageRef = firebase.storage().ref(fullPath);

    let uploadTask;
    try {
      uploadTask = storageRef.put(file, metadata);
    } catch (e) {
      onError(e);
    }

    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
      snapshot => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        if (onProgress) onProgress({ event: { percent: progress } });
      },
      error => {
        // https://firebase.google.com/docs/storage/web/handle-errors
        onError(error);
      },
      onSuccess,
    );
  };

  updateOpportunityDoc = (type, id, opportunity) => {
    let opportunityRef;
    if (type === "job") {
      opportunityRef = this.db.collection("jobs").doc(id);
    } else if (type === "talent") {
      opportunityRef = this.db.collection("talents").doc(id);
    }
    const data = Object.assign({}, opportunity);
    if (data.pdfFile) {
      const { pdfFile } = data;
      data.pdfUid = pdfFile.file.uid;
    } else {
      data.pdfUid = null;
    }
    delete data.pdfFile;

    if (!data.logoURL) {
      delete data.logoURL;
    }
    return opportunityRef.update(data);
  };

  removeOpportunityDoc = async (type, id, authUser) => {
    let opportunityRef;
    if (type === "job") {
      opportunityRef = this.db.collection("jobs").doc(id);
    } else if (type === "talent") {
      opportunityRef = this.db.collection("talents").doc(id);
    }

    // delete pdf if necessary
    const doc = await opportunityRef.get();
    if (doc.exists) {
      const data = await doc.data();
      if (data.pdfUid) {
        this.removeOpportunityPDF(type, authUser, data.pdfUid);
      }
    }
    // delete doc in collection
    // removeOpportunityPDF = (type, authUser, fileID)
    return opportunityRef.delete();
  };

  // Firestore Opportunities

  doCreateJob = (job, authUser) => {
    const data = Object.assign({}, job);

    if (data.pdfFile) {
      const { pdfFile } = data;
      data.pdfUid = pdfFile.file.uid;
    }
    delete data.pdfFile;
    if (!data.logoURL) {
      delete data.logoURL;
    }

    data.isRelevant = true;
    data.createdBy = authUser.uid;
    data.createdAt = firebase.firestore.FieldValue.serverTimestamp();
    return this.db.collection("jobs").add(data);
  };

  doCreateTalent = (talent, authUser) => {
    const data = Object.assign({}, talent);
    if (data.pdfFile) {
      const { pdfFile } = data;
      data.pdfUid = pdfFile.file.uid;
    }
    delete data.pdfFile;
    if (!data.logoURL) {
      delete data.logoURL;
    }
    data.isRelevant = true;
    data.createdBy = authUser.uid;
    data.createdAt = firebase.firestore.FieldValue.serverTimestamp();

    return this.db.collection("talents").add(data);
  };

  // Firestore Resources

  resources = () => this.db.collection("resources");

  resource = id => this.resources().doc(id);

  resourceComments = id => this.resource(id).collection("comments");

  doCreateResource = (resource, authUser) => {
    const data = resource;
    data.author = authUser.email;
    if (authUser.firstName && authUser.lastName)
      data.authorName = `${authUser.firstName} ${authUser.lastName}`;
    data.upvotes = 0;
    data.upvoters = [];
    data.commentsCount = 0;
    data.createdAt = firebase.firestore.FieldValue.serverTimestamp();

    return this.db.collection("resources").add(data);
  };

  doAddResourceComment = (id, authUser, content) => {
    const data = { content };
    data.author = authUser.email;
    if (authUser.firstName && authUser.lastName)
      data.authorName = `${authUser.firstName} ${authUser.lastName}`;
    if (authUser.avatar) data.avatar = authUser.avatar;

    data.createdAt = firebase.firestore.FieldValue.serverTimestamp();

    return this.resourceComments(id).add(data);
  };

  relevanceRankedResources = async (
    activeTab,
    filter,
    alreadyFetched,
    queryLimit,
  ) => {
    const endpoint = this.functions.httpsCallable("relevanceRankedResources");
    return endpoint({ activeTab, filter, alreadyFetched, queryLimit }).then(
      result => {
        /* Read result of the Cloud Function.
        Cloud functions passes different (bit messed up) format than direct firebase call
        Have to update the ref and the timestamp to match format of direct firebase call
        If this goes out of hand, consider to rewrite the cloud function to return an array of ids 
        and then fetch them directly form the frontend */

        const newResources = result.data;
        newResources.forEach(resource => {
          const newResource = resource;
          const milli =
            resource.createdAt._seconds * 1000 +
            resource.createdAt._nanoseconds / 1000000;
          newResource.createdAt = firebase.firestore.Timestamp.fromMillis(
            milli,
          );
          newResource.ref = this.db.collection("resources").doc(resource.id);
          return newResource;
        });
        return newResources;
      },
    );
  };

  // Firestore Virtual Center Rooms
  syncRooms = callback => {
    this.db
      .collection("rooms")
      .onSnapshot({ includeMetadataChanges: true }, querySnapshot => {
        const result = [];
        querySnapshot.forEach(doc => result.push(doc));
        callback(result);
      });
  };

  addParticipant = roomId => {
    const user = this.currentUser();
    this.db
      .collection("rooms")
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          if (doc.id === roomId) {
            /* doc.ref.update({
              participants: firebase.firestore.FieldValue.arrayUnion(user.id)
            }) */
          } else if (doc.data().participants.includes(user.id)) {
            doc.ref.update({
              participants: firebase.firestore.FieldValue.arrayRemove(user.id),
            });
          }
        });
      });
    this.db
      .collection("rooms")
      .doc(roomId)
      .update({
        participants: firebase.firestore.FieldValue.arrayUnion(user.id),
      });
  };

  setupConversationURL = (roomId, url) => {
    let disabled = false;
    if (url.length < 10 || url.length > 150) {
      disabled = true;
    }
    if (
      !url.includes("zoom.us") &&
      !url.includes("app.knit") &&
      !url.includes("youtube")
    ) {
      disabled = true;
    }
    if (!url.includes("https://")) {
      disabled = true;
    }
    if (!disabled)
      this.db
        .collection("rooms")
        .doc(roomId)
        .update({ conversation: url, locked: false, permanent: false });
  };

  leaveRoom = roomId => {
    const user = this.currentUser();
    this.db
      .collection("rooms")
      .doc(roomId)
      .get()
      .then(doc => {
        if (doc.data().participants.includes(user.id)) {
          if (doc.data().participants.length <= 1) {
            const { permanent } = doc.data();
            let { conversation } = doc.data();
            if (!permanent) conversation = "";

            doc.ref.update({
              participants: [],
              posts: [],
              currentSessionDescription: "",
              locked: false,
              conversation,
            });
          } else {
            doc.ref.update({
              participants: firebase.firestore.FieldValue.arrayRemove(user.id),
            });
          }
        }
      });
  };

  kickParticipant = (roomId, participantId) => {
    this.db
      .collection("rooms")
      .doc(roomId)
      .get()
      .then(doc => {
        if (doc.data().participants.includes(participantId)) {
          if (doc.data().participants.length <= 1) {
            let { conversation, locked } = doc.data().conversation;

            if (!doc.data().permanent) {
              conversation = "";
              locked = false;
            }

            doc.ref.update({
              participants: [],
              conversation,
              locked,
              posts: [],
              currentSessionDescription: "",
            });
          } else {
            doc.ref.update({
              participants: firebase.firestore.FieldValue.arrayRemove(
                participantId,
              ),
            });
          }
        }
      });
  };

  changeSessionDescription = (roomId, description) => {
    this.db
      .collection("rooms")
      .doc(roomId)
      .update({ currentSessionDescription: description });
  };

  lockRoom = (roomId, locked) => {
    this.db
      .collection("rooms")
      .doc(roomId)
      .update({ locked });
  };

  makePermanent = (roomId, permanent) => {
    this.db
      .collection("rooms")
      .doc(roomId)
      .update({ permanent });
  };

  submitRoomPost = (roomId, post) => {
    this.db
      .collection("rooms")
      .doc(roomId)
      .update({ posts: firebase.firestore.FieldValue.arrayUnion(post) });
  };

  deleteRoomPost = (roomId, post) => {
    this.db
      .collection("rooms")
      .doc(roomId)
      .update({ posts: firebase.firestore.FieldValue.arrayRemove(post) });
  };

  changeRoomPost = (roomId, oldPost, newPost) => {
    this.db
      .collection("rooms")
      .doc(roomId)
      .update({ posts: firebase.firestore.FieldValue.arrayRemove(oldPost) });
    this.db
      .collection("rooms")
      .doc(roomId)
      .update({ posts: firebase.firestore.FieldValue.arrayUnion(newPost) });
  };

  // Firestore User API
  user = email => this.db.collection("users").doc(email);

  currentUser = () =>
    this.db.collection("users").doc(this.auth.currentUser.email);

  users = () => this.db.collection("users");

  getActiveCenterUsers = async emails => {
    const pArray = emails.map(async email => {
      const doc = await this.db
        .collection("users")
        .doc(email)
        .get();

      if (doc.exists) {
        return doc;
      }
      return null;
    });

    const users = await Promise.all(pArray);
    return users;
  };

  getAllUsers = callback => {
    this.db
      .collection("users")
      .onSnapshot({ includeMetadataChanges: true }, querySnapshot => {
        const result = [];
        querySnapshot.forEach(doc => {
          result.push(doc);
        });
        callback(result);
      });
  };

  // Update User API (both Auth and Firestore)
  doUpdateUserProfile = (email, data, success) => {
    const cleanProfile = data;
    // remove undefined
    Object.keys(cleanProfile).forEach(
      key => cleanProfile[key] === undefined && delete cleanProfile[key],
    );

    cleanProfile.updatedAt = firebase.firestore.FieldValue.serverTimestamp();

    // update doc
    const docUpdate = this.user(email).set(
      { ...cleanProfile },
      { merge: true },
    );

    // if displayName needs to be updated in auth
    if (
      cleanProfile &&
      cleanProfile.firstName &&
      cleanProfile.lastName &&
      `${cleanProfile.firstName} ${cleanProfile.lastName}` !==
        this.auth.currentUser.displayName
    ) {
      const authUpdate = this.auth.currentUser.updateProfile({
        displayName: `${cleanProfile.firstName} ${cleanProfile.lastName}`,
      });
      // wait for both Promises to resolve
      Promise.all([docUpdate, authUpdate]).then(() => success());
      // .catch(error => console.error(error));
    } else {
      // only wait for docUpdate to resolve
      docUpdate.then(() => success());
      // .catch(error => console.error(error));
    }
  };

  // Firebase Analytics

  // Log standard events, need to match the events mentioned in the documentation of GA
  // https://support.google.com/firebase/answer/6317498?hl=en&ref_topic=6317484

  logEvent = (eventName, eventParams) => {
    this.analytics.logEvent(eventName, {
      ...eventParams,
      debug_mode: process.env.NODE_ENV !== "production",
    });
  };

  setAnalyticsUID = uid => {
    this.analytics.setUserId(uid);
  };

  setAnalyticsUserProperties = props => {
    this.analytics.setUserProperties(props);
  };

  /*
   * It is default behavior of GA to track page_views only works for first loading; disabling this is complicated.
   * As this automatically tracked page_view does not include the debug_mode flag, page_view_initial is added
   * to inspect in the GA debug view. Reports should just use page_view events.
   */
  trackPageView = () => {
    this.analytics.logEvent(
      this.countPageViews > 0 ? "page_view" : "page_view_initial",
      { debug_mode: process.env.NODE_ENV !== "production" },
    );
    this.countPageViews += 1;
  };
}

export default Firebase;
