import React, { Component } from "react";
import { withRouter, Route, Switch } from "react-router-dom";

import { Layout } from "antd";

import "./App.css";

import { withAuthentication } from "./components/Session";
import { withFirebase } from "./components/Firebase";

import * as ROUTES from "./constants/routes";

import Navigation from "./components/Navigation";
import Landing from "./components/Landing";
import Login from "./components/Login";
import Account from "./components/Account";
import Admin from "./components/Admin";
import SignInLinkVerify from "./components/Verify";
import Setup from "./components/Setup";
import Directory from "./components/Directory";
import Resources from "./components/Resources";
import Meetups from "./components/Meetups";
import { VirtualCenter } from "./components/VirtualCenter";
import Opportunities from "./components/Opportunities";
import JobDetailView from "./components/Opportunities/Job/Detail/MobileView";
import Reactivation from "./components/Opportunities/Reactivation";
import EmailPreferences from "./components/EmailPreferences";

const { Content } = Layout;
class App extends Component {
  constructor(props) {
    super(props);
    window.addEventListener("resize", this.handleWindowSizeChange);
    this.state = {
      windowWidth: window.innerWidth,
    };
  }

  componentDidMount() {
    const { history, firebase } = this.props;
    // Track page views
    const track = pathname => {
      // Change the document title to leverage page_view events
      if (pathname !== "/") {
        let pageTitle = pathname.slice(1);
        pageTitle = pageTitle.charAt(0).toUpperCase() + pageTitle.slice(1);
        document.title = `${pageTitle} - CDTM Community`;
      } else {
        document.title = "CDTM Community";
      }

      firebase.trackPageView();
    };

    track(window.location.pathname);
    this.unlisten = history.listen(location => {
      track(location.pathname);
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
    this.unlisten();
  }

  handleWindowSizeChange = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  render() {
    const { windowWidth } = this.state;
    return (
      <>
        <Navigation />
        <Layout
          style={{
            background: "#fcfcff",
            minHeight: "100vh",
          }}
        >
          <Content>
            <Switch>
              <Route exact path={ROUTES.LANDING} component={Landing} />
              <Route path={ROUTES.LOGIN} component={Login} />
              <Route path={ROUTES.VERIFY} component={SignInLinkVerify} />
              <Route
                path={ROUTES.ACCOUNT}
                render={props => (
                  <Account {...props} windowWidth={windowWidth} />
                )}
              />
              <Route
                path={ROUTES.EMAIL_PREFERENCES}
                component={EmailPreferences}
              />
              <Route path={ROUTES.ADMIN} component={Admin} />
              <Route
                path={ROUTES.SETUP}
                render={props => <Setup {...props} windowWidth={windowWidth} />}
              />
              <Route
                path={ROUTES.DIRECTORY}
                render={props => (
                  <Directory {...props} windowWidth={windowWidth} />
                )}
              />
              <Route
                path={ROUTES.VIRTUAL_CENTER}
                render={props => (
                  <VirtualCenter {...props} windowWidth={windowWidth} />
                )}
              />
              <Route
                path={ROUTES.RESOURCES}
                render={props => (
                  <Resources {...props} windowWidth={windowWidth} />
                )}
              />
              <Route
                path={`${ROUTES.OPPORTUNITIES}/jobs/reactivate/:id`}
                render={() => <Reactivation isJob />}
              />
              <Route
                path={`${ROUTES.OPPORTUNITIES}/talents/reactivate/:id`}
                render={() => <Reactivation isJob={false} />}
              />
              <Route
                path={`${ROUTES.OPPORTUNITIES}/jobs/:id`}
                render={props => (
                  <JobDetailView {...props} windowWidth={windowWidth} />
                )}
              />
              <Route
                path={ROUTES.OPPORTUNITIES}
                render={props => (
                  <Opportunities {...props} windowWidth={windowWidth} />
                )}
              />
              <Route path={ROUTES.MEETUPS} render={() => <Meetups />} />
              <Route component={Landing} />
            </Switch>
          </Content>
        </Layout>
      </>
    );
  }
}

export default withRouter(withAuthentication(withFirebase(App)));
