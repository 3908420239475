import React, { useEffect, useState } from "react";
import { Modal, Button, Row, Col, Divider } from "antd";
import { withFirebase } from "../../../Firebase";

const DetailsModal = ({ data, setIsVisible, isVisible, firebase }) => {
  const { createdAt, createdBy, description, role, level, name } = data;

  const [authorEmail, setAuthorEmail] = useState("");

  const handleClose = () => {
    setAuthorEmail("");
    setIsVisible(false);
  };

  useEffect(() => {
    const getAuthor = id => {
      firebase
        .users()
        .get()
        .then(snapshot => {
          const users = snapshot.docs.map(doc => doc.data());
          const author = users.find(user => user.uid === id);
          if (author) {
            setAuthorEmail(`${author.email}`);
            return;
          }
          setAuthorEmail("unknown");
        });
    };
    getAuthor(createdBy);
  }, [createdBy, firebase]);

  return (
    <Modal
      visible={isVisible}
      title={name}
      onOk={handleClose}
      onCancel={handleClose}
      footer={[
        <Button key="back" onClick={handleClose}>
          Close
        </Button>,
      ]}
    >
      <Row>
        <Col>
          Open for positions as a <b>{level}</b> in:
        </Col>
      </Row>
      <Row>
        <Col>
          <ul>
            {role?.map(r => (
              <li>{r}</li>
            ))}
          </ul>
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col span={24}>
          <p>{description}</p>
        </Col>
      </Row>
      <Divider />
      <Row className="row-author" justify="space-between">
        <Col>created by {authorEmail}</Col>
        {createdAt && (
          <Col>
            created at {new Date(createdAt.seconds * 1000).toLocaleDateString()}
          </Col>
        )}
      </Row>
    </Modal>
  );
};

export default withFirebase(DetailsModal);
