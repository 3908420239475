import React, { useState, useEffect } from "react";
import { Layout, Row, Col, Divider, message } from "antd";
import JobCard from "./JobCard";
import TalentCard from "./TalentCard";
import "./index.css";
import { withFirebase } from "../../Firebase";
import JobDrawer from "./JobDrawer";
import TalentDrawer from "./TalentDrawer";
// Util functions

const { Content } = Layout;

const EditPost = ({
  firebase,
  authUser,
  windowWidth,
  reloadPosts,
  setReloadPosts,
}) => {
  const [allJobs, setAllJobs] = useState([]);
  const [allTalents, setAllTalents] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerType, setDrawerType] = useState(false);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    const fetchPosts = () => {
      firebase.db
        .collection("jobs")
        .where("createdBy", "==", authUser.uid)
        .get()
        .then(snapshot => {
          const data = snapshot.docs.map(doc => {
            const post = doc.data();
            post.id = doc.id;
            post.type = "job";
            return post;
          });
          setAllJobs(data);
        })
        .catch(() => message.error("Failed to fetch opportunities"));

      firebase.db
        .collection("talents")
        .where("createdBy", "==", authUser.uid)
        .get()
        .then(snapshot => {
          const data = snapshot.docs.map(doc => {
            const post = doc.data();
            post.id = doc.id;
            post.type = "talent";
            return post;
          });
          setAllTalents(data);
        })
        .catch(() => message.error("Failed to fetch opportunities"));
    };
    fetchPosts();
  }, [reloadPosts, firebase, authUser]);

  const openJobDrawerWithData = data => {
    setFormData(data);
    setDrawerType("Job");
    setDrawerOpen(true);
  };

  const openTalentDrawerWithData = data => {
    setFormData(data);
    setDrawerType("Talent");
    setDrawerOpen(true);
  };

  const Drawer = () => {
    if (drawerType === "Job") {
      return (
        <JobDrawer
          authUser={authUser}
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
          windowWidth={windowWidth}
          drawerType={drawerType}
          formData={formData}
          setReloadPosts={setReloadPosts}
        />
      );
    }
    if (drawerType === "Talent") {
      return (
        <TalentDrawer
          authUser={authUser}
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
          windowWidth={windowWidth}
          drawerType={drawerType}
          formData={formData}
          setReloadPosts={setReloadPosts}
        />
      );
    }
    return null;
  };

  return (
    <Layout className="layout-opportunities">
      <Content className="content-opportunities">
        <Row className="row-opportunities">
          <Col span={20} offset={2}>
            <Divider />
            {allJobs &&
              allJobs.map(job => {
                return (
                  <Row className="job-card-row">
                    <Col span={24} className="job-card-col">
                      <JobCard
                        jobDetails={job}
                        openJobDrawerWithData={openJobDrawerWithData}
                        ReloadPosts={() => setReloadPosts(true)}
                        authUser={authUser}
                      />
                    </Col>
                  </Row>
                );
              })}
            {allTalents &&
              allTalents.map(talent => {
                return (
                  <Row className="job-card-row">
                    <Col span={24} className="job-card-col">
                      <TalentCard
                        talentDetails={talent}
                        openTalentDrawerWithData={openTalentDrawerWithData}
                        ReloadPosts={() => setReloadPosts(true)}
                        authUser={authUser}
                      />
                    </Col>
                  </Row>
                );
              })}
          </Col>
        </Row>
      </Content>
      <Drawer />
    </Layout>
  );
};

export default withFirebase(EditPost);
