import React, { useState, useEffect } from "react";
import { Card, Row, Col, Button } from "antd";
import "./index.css";
import { withFirebase } from "../../../Firebase";
import DownloadButton from "../../Job/DownloadButton";

const TalentCard = ({ talentDetails, openModalWithData, firebase }) => {
  const { name, location, createdAt, role, level, createdBy } = talentDetails;

  const [downloadURL, setDownloadURL] = useState("");

  useEffect(() => {
    const getDownloadURL = async () => {
      if (!talentDetails.pdfUid) {
        return;
      }
      const reference = await firebase.storage.ref(
        `talents/${createdBy}/${talentDetails.pdfUid}`,
      );

      reference
        .getDownloadURL()
        .then(url => {
          setDownloadURL(url);
        })
        .catch(() => {});
    };
    getDownloadURL();
  }, [talentDetails, createdBy, firebase]);

  const date = new Date(createdAt.seconds * 1000).toLocaleDateString();

  return (
    <>
      {talentDetails && (
        <Card className="job-card">
          <Row justify="space-between">
            <Col xs={24} sm={24} md={12} lg={3}>
              <b>{name}</b>
            </Col>
            <Col xs={12} sm={24} md={12} lg={3}>
              <b>{level}</b>
            </Col>
            <Col xs={12} sm={24} md={12} lg={3}>
              {role.map(r => (
                <span>{`${r}\n`}</span>
              ))}
            </Col>
            <Col xs={12} sm={24} md={12} lg={3}>
              {location}
            </Col>
            <Col xs={12} sm={12} md={12} lg={3}>
              created on {date}
            </Col>
            {talentDetails.pdfUid ? (
              <Col xs={24} sm={24} md={24} lg={2}>
                <DownloadButton href={downloadURL && downloadURL}>
                  CV
                </DownloadButton>
              </Col>
            ) : (
              <Col xs={24} sm={24} md={24} lg={2}></Col>
            )}
            <Col xs={24} sm={24} md={24} lg={2}>
              <Button
                onClick={() => openModalWithData(talentDetails)}
                className="job-card-button-row-button"
              >
                More
              </Button>
            </Col>
          </Row>
        </Card>
      )}
    </>
  );
};

export default withFirebase(TalentCard);
