import React, { useState, useEffect } from "react";
import { Card, Row, Col, Button, message } from "antd";
import { DownloadOutlined, UserOutlined } from "@ant-design/icons";
import "./index.css";
import { withFirebase } from "../../../Firebase";

const TalentCard = ({
  talentDetails,
  openTalentDrawerWithData,
  firebase,
  ReloadPosts,
  authUser,
}) => {
  const { name, location, createdAt, role, level } = talentDetails;

  const [downloadURL, setDownloadURL] = useState("");

  useEffect(() => {
    (() => {
      if (!talentDetails.pdfUid) {
        return;
      }
      firebase.storage
        .ref(`talents/${authUser.uid}/${talentDetails.pdfUid}`)
        .getDownloadURL()
        .then(url => {
          setDownloadURL(url);
        })
        .catch(() => {});
    })();
  }, [talentDetails, firebase.storage, authUser]);

  const date = new Date(createdAt.seconds * 1000).toLocaleDateString();

  return (
    <>
      {talentDetails && (
        <Card className="job-card">
          <Row justify="space-between">
            <Col xs={24} sm={24} md={12} lg={4}>
              <b>{name}</b>
            </Col>
            <Col xs={12} sm={24} md={12} lg={3}>
              <b>{level}</b>
            </Col>
            <Col xs={12} sm={24} md={12} lg={3}>
              {role.map(r => (
                <span>{`${r}\n`}</span>
              ))}
            </Col>
            <Col xs={12} sm={24} md={12} lg={3}>
              {location}
            </Col>
            <Col xs={12} sm={12} md={12} lg={3}>
              created on {date}
            </Col>
            {talentDetails.pdfUid ? (
              <Col xs={24} sm={24} md={24} lg={2}>
                <Button
                  href={downloadURL}
                  icon={<DownloadOutlined />}
                  target="_blank"
                  download
                >
                  CV
                </Button>
              </Col>
            ) : (
              <Col xs={24} sm={24} md={24} lg={2}></Col>
            )}
            <Col xs={24} sm={24} md={24} lg={2}>
              <Button
                onClick={() => openTalentDrawerWithData(talentDetails)}
                className="job-card-button-row-button"
              >
                EDIT
              </Button>
            </Col>
            <Col xs={24} sm={24} md={24} lg={2}>
              <Button
                onClick={() => {
                  firebase
                    .removeOpportunityDoc("talent", talentDetails.id, authUser)
                    .then(() => {
                      ReloadPosts();
                      message.success("Talent was deleted successfully.");
                    });
                }}
                className="job-card-button-row-button"
              >
                DELETE
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                <UserOutlined />
                Talent
              </p>
            </Col>
          </Row>
        </Card>
      )}
    </>
  );
};

export default withFirebase(TalentCard);
