export default class Newsletter {
  constructor(ID = "", title = "", description = "", subscribers = []) {
    this.ID = ID;
    this.title = title;
    this.description = description;
    this.subscribers = subscribers;
  }

  async subscribe(userEmail, collection) {
    if (!this.subscribers.includes(userEmail)) {
      this.subscribers.push(userEmail);
      await collection.doc(this.ID).set(
        {
          subscribers: this.subscribers,
        },
        { merge: true },
      );
    }
  }

  async unsubscribe(userEmail, collection) {
    if (this.subscribers.includes(userEmail)) {
      this.subscribers.splice(this.subscribers.indexOf(userEmail), 1);
      await collection
        .doc(this.ID)
        .set({ subscribers: this.subscribers }, { merge: true });
    }
  }
}
