import React, { useState, useEffect } from "react";
import {
  Layout,
  Row,
  Col,
  Typography,
  Card,
  Button,
  Avatar,
  message,
} from "antd";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import { isMobileOnly } from "react-device-detect";
import { withFirebase } from "../Firebase";
import Newsletter from "./Newsletter";
import { withAuthorization } from "../Session";

const { Content } = Layout;
const { Title } = Typography;

const EmailPreferences = ({ firebase, authUser }) => {
  const [newsletters, setNewsletters] = useState([]);

  useEffect(() => {
    firebase.db
      .collection("newsletter")
      .get()
      .then(snapshot => {
        const newsletter = snapshot.docs.map(doc => {
          const { title, description, subscribers } = doc.data();
          return new Newsletter(doc.id, title, description, subscribers);
        });
        setNewsletters(newsletter);
      });
  }, [firebase]);

  const activeUser = authUser.email;

  const collectionRef = firebase.db.collection("newsletter");

  const handleSubscribe = (i, email) => {
    const { ID, title, description } = newsletters[i];
    try {
      newsletters[i].subscribe(email, collectionRef);
      const newNewsletter = new Newsletter(
        ID,
        title,
        description,
        newsletters[i].subscribers,
      );
      const newArray = newsletters.map((nl, index) => {
        return i === index ? null : nl;
      });
      newArray[i] = newNewsletter;
      setNewsletters(newArray);
    } catch (err) {
      message.error("Could not subscribe you to that newsletter");
    }
  };

  const handleUnsubscribe = async (i, email) => {
    const { ID, title, description } = newsletters[i];
    try {
      await newsletters[i].unsubscribe(email, collectionRef);
      const newNewsletter = new Newsletter(
        ID,
        title,
        description,
        newsletters[i].subscribers,
      );
      const newArray = newsletters.map((nl, index) => {
        return i === index ? null : nl;
      });
      newArray[i] = newNewsletter;
      setNewsletters(newArray);
    } catch (err) {
      message.error("Could not unsubscribe you from that newsletter");
    }
  };

  return (
    <Layout className="module">
      <Content>
        <Row style={{ marginTop: "3%" }}>
          <Col span={isMobileOnly ? 18 : 12} offset={isMobileOnly ? 3 : 6}>
            <Row>
              <Col>
                <Title level={2}>Manage your email preferences</Title>
                <p>
                  These newsletters are currently available. Change your
                  subsciptions to get more or less updates:
                </p>
              </Col>
            </Row>
            {newsletters.map((nl, index) => {
              const isSubscriber = nl.subscribers.includes(activeUser);

              return (
                <Row style={{ marginBottom: "3%" }}>
                  <Col span={24} style={{ padding: "0" }}>
                    <Card
                      style={{ width: "100%" }}
                      actions={[
                        <Button
                          onClick={
                            isSubscriber
                              ? () => handleUnsubscribe(index, activeUser)
                              : () => handleSubscribe(index, activeUser)
                          }
                        >
                          {isSubscriber ? "Unsubscribe" : "Subscribe"}
                        </Button>,
                      ]}
                    >
                      <Row>
                        <Col>
                          <Avatar
                            style={{ backgroundColor: "#fff" }}
                            icon={
                              isSubscriber ? (
                                <CheckCircleTwoTone twoToneColor="green" />
                              ) : (
                                <CloseCircleTwoTone twoToneColor="red" />
                              )
                            }
                          />
                        </Col>
                        <Col>
                          <Title level={4}>{nl.title}</Title>
                        </Col>
                      </Row>
                      <Row>
                        <Col>{nl.description}</Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              );
            })}
          </Col>
        </Row>

        <Row>
          <Col></Col>
        </Row>
      </Content>
    </Layout>
  );
};

const condition = authUser => authUser != null;

export default withFirebase(withAuthorization(condition)(EmailPreferences));
