import React from "react";
import { Layout, Row, Col, Card, PageHeader } from "antd";
import { withFirebase } from "../Firebase";
import { withAuthorization } from "../Session";

import "./index.css";

const Resources = () => {
  return (
    <Layout className="module">

      <PageHeader
        title="Resources"
        subTitle="Discover and share"
        className="page-header"
        ghost={false}
      />

      <Row>
        <Col span={20} offset={2}>
          <Row className="resources-card-row">
            <Col span={24} className="resources-card-col">
              <Card className="resources-card">
                <Row>
                  <Col xs={20} sm={20} md={20} lg={3}>
                    <b>&#128712; Info</b>
                  </Col>
                  <Col>
                    Resources have moved to our CDTM knowledge base at
                    &nbsp; <a href="https://app.nuclino.com/CDTM/">
                    app.nuclino.com/CDTM
                  </a>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  );
};

const condition = authUser => authUser != null;

export default withFirebase(withAuthorization(condition)(Resources));
