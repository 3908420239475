import React from "react";
import { Card, Typography, Col, Row } from "antd";
import { CloseOutlined } from "@ant-design/icons";

import "./RoomPost.css";

const { Text, Paragraph } = Typography;

class RoomPost extends React.Component {

    onTitleChange(text){

        const {changePost, post} = this.props
        const newPost = {
            title: text,
            description: post.description,
        }
        changePost(post, newPost)
    }

    onDescriptionChange(text){
        const {changePost, post} = this.props
        const newPost = {
            title: post.title,
            description: text,
        }
       changePost(post, newPost)
    }
    
    render () {
    const {post, editable, deletePost} = this.props

    return (
        <Col span={24} style={{paddingBottom: 5}}>
        <Card className="room-post" style={{borderRadius: 10, padding: 0}}>
            <Row>
                <Col style={{width: "calc(100% - 50px)"}}><Text style={{color: "rgba(0, 0, 0, 0.85)", fontWeight: 500, fontSize: 16.38, width: "100%"}} editable={editable && {onChange: (text) => this.onTitleChange(text)}} ellipsis>{post.title}</Text></Col>
                {editable &&
                <Col flex="50px"><CloseOutlined onClick={() => deletePost(post)} style={{float:"right", marginTop: 7, paddingLeft: 5}}/></Col>}
            </Row>
            <div style={{maxHeight: 100, overflowY: "auto"}}>
                <Paragraph copyable={!editable} ellipsis={{ rows: 2, expandable: !editable, symbol: "more" }} editable={editable && {onChange: (text) => this.onDescriptionChange(text)}}>{post.description}</Paragraph>
            </div>
        </Card>
        </Col>)
    }
}

export default RoomPost