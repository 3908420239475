import React from "react";
import { Form, Input } from "antd";
import { MailOutlined } from "@ant-design/icons";

export const UserFormBasicGetInitialValues = authUser => {
  return {
    email: authUser.email,
    firstName: authUser.firstName,
    lastName: authUser.lastName,
  };
};

export const UserFormBasic = ({ formItemLayout }) => (
  <React.Fragment>
    <Form.Item {...formItemLayout} name="email" label="E-Mail">
      <Input
        disabled
        prefix={<MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
        inputMode="email"
      />
    </Form.Item>
    <Form.Item
      {...formItemLayout}
      name="firstName"
      label="First Name"
      rules={[
        {
          required: true,
          message: "Please enter your first name",
          whitespace: true,
        },
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      {...formItemLayout}
      name="lastName"
      label="Last Name"
      rules={[
        {
          required: true,
          message: "Please enter your last name",
          whitespace: true,
        },
      ]}
    >
      <Input />
    </Form.Item>{" "}
  </React.Fragment>
);
