import React, { useState, useEffect } from "react";
import { Layout, Row, Col, message, Affix } from "antd";
import { isMobileOnly } from "react-device-detect";

import JobCard from "./JobCard";
import FilterBar from "./FilterBar";
import "./index.css";
import { withFirebase } from "../../Firebase";
// Util functions
import { generateFilterList } from "../../utils";
import { sortJobList as sortJobs } from "../utils";

import DetailDesktopView from "./Detail/DesktopView";
import Job from "../models/Job";
import JobFbDocument from "../models/JobFbDocument";
import FilterPage from "./FilterPage";

const { Content } = Layout;

const Jobs = ({ firebase, reloadPosts, closeFilterPage, isFilterPageOpen }) => {
  const [allJobs, setAllJobs] = useState([]);
  const [jobsShown, setJobsShown] = useState([]);
  const [sortOrder, setSortOrder] = useState("latest");
  const [selectedJob, setSelectedJob] = useState(0);
  const [selectedJobData, setSelectedJobData] = useState(
    new JobFbDocument("", new Job({})),
  );

  const initialFilter = {
    location: [],
    level: [],
    role: [],
    company: [],
  };
  const [activeFilter, setActiveFilter] = useState(initialFilter);

  useEffect(() => {
    const fetchJobs = () => {
      firebase.db
        .collection("jobs")
        .where("isRelevant", "==", true)
        .get()
        .then(snapshot => {
          const data = snapshot.docs.map(
            doc => new JobFbDocument(doc.id, doc.data()),
          );

          setAllJobs(data);
          setJobsShown(data);
        })
        .catch(() => message.error("Failed to fetch jobs"));
    };
    fetchJobs();
  }, [reloadPosts, firebase]);

  const companyFilterList = generateFilterList(
    "company",
    allJobs.map(job => job.data),
  );
  const roleFilterList = generateFilterList(
    "role",
    allJobs.map(job => job.data),
  );
  const locationFilterList = generateFilterList(
    "location",
    allJobs.map(job => job.data),
  );
  const levelFilterList = generateFilterList(
    "level",
    allJobs.map(job => job.data),
  );

  useEffect(() => {
    const sorted = (() => sortJobs(jobsShown, sortOrder))();
    setJobsShown(sorted);
    // eslint-disable-next-line
  }, [sortOrder]);

  const updateActiveFilter = newFilter => {
    if (newFilter.category === "reset") {
      setActiveFilter(initialFilter);
    } else {
      setActiveFilter({
        ...activeFilter,
        [newFilter.category]: newFilter.filter,
      });
    }
  };

  useEffect(() => {
    const filterJobs = jobList => {
      const filtered = jobList.filter(job => {
        const { company, level, location, role } = job.data;
        return (
          (activeFilter.company.includes(company) ||
            activeFilter.company.length === 0) &&
          (activeFilter.level.includes(level) ||
            activeFilter.level.length === 0) &&
          (activeFilter.location.includes(location) ||
            activeFilter.location.length === 0) &&
          (activeFilter.role.includes(role) || activeFilter.role.length === 0)
        );
      });
      return filtered;
    };
    const filtered = filterJobs(allJobs);
    const sortedAndFiltered = sortJobs(filtered, sortOrder);
    setJobsShown(sortedAndFiltered);
  }, [activeFilter, allJobs, sortOrder]);

  useEffect(() => {
    if (!jobsShown || jobsShown.length <= 0) {
      return;
    }
    setSelectedJobData(jobsShown[selectedJob]);
  }, [selectedJob, jobsShown]);

  return !isFilterPageOpen ? (
    <Layout className="layout-opportunities">
      <Content className="content-opportunities">
        {!isMobileOnly && (
          <Affix offsetTop={50}>
            <Row
              style={{
                backgroundColor: "white",
                paddingTop: "145px",
                paddingBottom: "0px",
                borderBottom: "1px solid #f0f0f0",
              }}
            >
              <Col span={22} offset={1}>
                <FilterBar
                  sort={setSortOrder}
                  sortOrder={sortOrder}
                  filterLists={{
                    companyFilterList,
                    roleFilterList,
                    locationFilterList,
                    levelFilterList,
                  }}
                  filter={updateActiveFilter}
                  activeFilter={activeFilter}
                />
              </Col>
            </Row>
          </Affix>
        )}
        <Row className="row-opportunities">
          <Col span={22} offset={1}>
            <Row justify="space-between">
              <Col span={isMobileOnly ? 24 : 12}>
                {jobsShown.map((job, i) => {
                  const isHighlighted = i === selectedJob;

                  return (
                    <Row className="job-card-row">
                      <Col span={24} className="job-card-col">
                        <JobCard
                          isHighlighted={isHighlighted}
                          jobDetails={job}
                          index={i}
                          selectCard={setSelectedJob}
                        />
                      </Col>
                    </Row>
                  );
                })}
              </Col>
              {!isMobileOnly && (
                <Col span={11} className="detail-card-column">
                  <Affix offsetTop={240}>
                    <DetailDesktopView jobDocument={selectedJobData} />
                  </Affix>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Content>
    </Layout>
  ) : (
    <FilterPage
      sort={setSortOrder}
      sortOrder={sortOrder}
      filterLists={{
        companyFilterList,
        roleFilterList,
        locationFilterList,
        levelFilterList,
      }}
      filter={updateActiveFilter}
      activeFilter={activeFilter}
      closeFilterPage={closeFilterPage}
      jobsShownHits={jobsShown ? jobsShown.length : 0}
    />
  );
};

export default withFirebase(Jobs);
