import React from "react";
import { Card } from "antd";
import { isMobileOnly } from "react-device-detect";
import "./index.css";
import { withFirebase } from "../../../Firebase";

import DesktopView from "./DesktopView";
import MobileView from "./MobileView";

const JobCard = ({ jobDetails, isHighlighted, selectCard, index }) => {
  const handleClick = () => {
    selectCard(index);
  };

  return (
    <>
      {jobDetails && (
        <Card
          className="job-card"
          style={{
            backgroundColor: isHighlighted && "rgba(255, 255, 255, 0.5)",
          }}
          onClick={handleClick}
        >
          {isMobileOnly ? (
            <MobileView data={jobDetails} />
          ) : (
            <DesktopView data={jobDetails} />
          )}
        </Card>
      )}
    </>
  );
};

export default withFirebase(JobCard);
