import React from "react";
import { Select, Row, Col, Button } from "antd";
import "./index.css";

const { Option } = Select;

const FilterAndSortBar = ({
  sort,
  sortOrder,
  filter,
  filterLists,
  activeFilter,
}) => {
  const { roleFilterList, locationFilterList, levelFilterList } = filterLists;

  const handleSortChange = sortCriteria => {
    sort(sortCriteria);
  };

  const generateOptions = filterList => {
    const options = filterList.map(option => (
      <Option className="filter-option" key={option}>
        {option}
      </Option>
    ));
    return options;
  };

  const handleFilterReset = () => {
    filter({ category: "reset" });
  };

  return (
    <Row>
      <Col className="sort-col">
        <Select
          placeholder="Sort"
          style={{ width: 120 }}
          onChange={handleSortChange}
          value={sortOrder}
        >
          <Option value="latest">Latest first</Option>
          <Option value="earliest">Earliest first</Option>
        </Select>
      </Col>
      <Col className="filter-col">
        <Select
          mode="multiple"
          style={{ width: 170 }}
          placeholder="Role"
          onChange={value => filter({ category: "role", filter: value })}
          value={activeFilter.role}
        >
          {generateOptions(roleFilterList)}
        </Select>
      </Col>
      <Col className="filter-col">
        <Select
          mode="multiple"
          style={{ width: 120 }}
          placeholder="Level"
          value={activeFilter.level}
          onChange={value => filter({ category: "level", filter: value })}
        >
          {generateOptions(levelFilterList)}
        </Select>
      </Col>
      <Col className="filter-col">
        <Select
          placeholder="Location"
          onChange={value => filter({ category: "location", filter: value })}
          mode="multiple"
          value={activeFilter.location}
          style={{ width: 120 }}
        >
          {generateOptions(locationFilterList)}
        </Select>
      </Col>
      <Col>
        <Button onClick={handleFilterReset}>Reset filters</Button>
      </Col>
    </Row>
  );
};

export default FilterAndSortBar;
