import React from "react";
import { Select, Row, Col, Button } from "antd";
import "./index.css";

const { Option } = Select;

const FilterBar = ({ sort, sortOrder, filter, filterLists, activeFilter }) => {
  const {
    companyFilterList,
    roleFilterList,
    locationFilterList,
    levelFilterList,
  } = filterLists;

  const handleSortChange = sortCriteria => {
    sort(sortCriteria);
  };

  const generateOptions = filterList => {
    const options = filterList.map(option => (
      <Option key={option}>{option}</Option>
    ));
    return options;
  };

  const handleFilterReset = () => {
    filter({ category: "reset" });
  };

  return (
    <Row>
      <Col className="sort-col" xs={24} sm={24} md={6} lg={3}>
        <Select
          placeholder="Sort"
          style={{ minWidth: 120, width: "100%" }}
          onChange={handleSortChange}
          value={sortOrder}
        >
          <Option value="latest">Latest first</Option>
          <Option value="earliest">Earliest first</Option>
        </Select>
      </Col>
      <Col className="filter-col" xs={24} sm={24} md={6} lg={3}>
        <Select
          mode="multiple"
          style={{ minWidth: 120, width: "100%" }}
          placeholder="Role"
          onChange={value => filter({ category: "role", filter: value })}
          value={activeFilter.role}
        >
          {generateOptions(roleFilterList)}
        </Select>
      </Col>
      <Col className="filter-col" xs={24} sm={24} md={6} lg={3}>
        <Select
          mode="multiple"
          style={{ minWidth: 120, width: "100%" }}
          placeholder="Level"
          value={activeFilter.level}
          onChange={value => filter({ category: "level", filter: value })}
        >
          {generateOptions(levelFilterList)}
        </Select>
      </Col>
      <Col className="filter-col" xs={24} sm={24} md={6} lg={3}>
        <Select
          mode="multiple"
          placeholder="Company"
          style={{ minWidth: 120, width: "100%" }}
          value={activeFilter.company}
          onChange={value => filter({ category: "company", filter: value })}
        >
          {generateOptions(companyFilterList)}
        </Select>
      </Col>
      <Col className="filter-col" xs={24} sm={24} md={6} lg={3}>
        <Select
          placeholder="Location"
          onChange={value => filter({ category: "location", filter: value })}
          mode="multiple"
          value={activeFilter.location}
          style={{ minWidth: 120, width: "100%" }}
        >
          {generateOptions(locationFilterList)}
        </Select>
      </Col>
      <Col>
        <Button onClick={handleFilterReset}>Reset filters</Button>
      </Col>
    </Row>
  );
};

export default FilterBar;
