import React, { useState, useEffect } from "react";
import { Layout, Row, Col, Divider, message } from "antd";
import DetailsModal from "./DetailsModal";
import TalentCard from "./TalentCard";
import FilterAndSortBar from "./FilterAndSort";
import "./index.css";
import { withFirebase } from "../../Firebase";
// Util funcitons
import { generateFilterList } from "../../utils";
import { sortTalentList as sortTalents } from "../utils";

const { Content } = Layout;

const Talent = ({ firebase, reloadPosts }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [allTalents, setAllTalents] = useState([]);
  const [talentsShown, setTalentShown] = useState([]);
  const [sortOrder, setSortOrder] = useState("latest");

  const initialFilter = {
    location: [],
    level: [],
    role: [],
    company: [],
  };
  const [activeFilter, setActiveFilter] = useState(initialFilter);

  useEffect(() => {
    const fetchTalents = async () => {
      firebase.db
        .collection("talents")
        .where("isRelevant", "==", true)
        .get()
        .then(snapshot => {
          const data = snapshot.docs.map(doc => doc.data());
          setAllTalents(data);
          setTalentShown(data);
        })
        .catch(() => message.error("Failed to fetch talents"));
    };
    fetchTalents();
  }, [reloadPosts, firebase]);

  const openModalWithData = data => {
    setModalData(data);
    setIsModalOpen(true);
  };

  const companyFilterList = generateFilterList("company", allTalents);
  const locationFilterList = generateFilterList("location", allTalents);
  const levelFilterList = generateFilterList("level", allTalents);

  const roles = allTalents
    .map(t => t.role)
    .reduce((prev, curr) => prev.concat(curr), []);
  const roleFilterList = roles.filter((el, i) => roles.indexOf(el) === i);

  useEffect(() => {
    const sorted = (() => sortTalents(talentsShown, sortOrder))();
    setTalentShown(sorted);
    // eslint-disable-next-line
  }, [sortOrder]);

  const updateActiveFilter = newFilter => {
    if (newFilter.category === "reset") {
      setActiveFilter(initialFilter);
    } else {
      setActiveFilter({
        ...activeFilter,
        [newFilter.category]: newFilter.filter,
      });
    }
  };

  const filter = (talent, filters) => {
    return (
      (filters.company.includes(talent.company) ||
        filters.company.length === 0) &&
      (filters.level.includes(talent.level) || filters.level.length === 0) &&
      (filters.location.includes(talent.location) ||
        filters.location.length === 0) &&
      (talent.role.reduce(
        (prev, curr) => prev || filters.role.includes(curr),
        false,
      ) ||
        filters.role.length === 0)
    );
  };

  // resorts and filters list of shown jobs
  useEffect(() => {
    const filterTalent = talentList => {
      const filtered = talentList.filter(talent =>
        filter(talent, activeFilter),
      );
      return filtered;
    };
    const filtered = filterTalent(allTalents);
    const sortedAndFiltered = sortTalents(filtered, sortOrder);
    setTalentShown(sortedAndFiltered);
  }, [activeFilter, allTalents, sortOrder]);

  return (
    <Layout className="layout-opportunities">
      <Content className="content-opportunities">
        <Row className="row-opportunities">
          <Col span={20} offset={2}>
            <Row justify="start">
              <Col span={24}>
                <FilterAndSortBar
                  sort={setSortOrder}
                  sortOrder={sortOrder}
                  filterLists={{
                    companyFilterList,
                    roleFilterList,
                    locationFilterList,
                    levelFilterList,
                  }}
                  filter={updateActiveFilter}
                  activeFilter={activeFilter}
                />
              </Col>
            </Row>
            <Divider />
            {talentsShown.map(talent => {
              return (
                <Row>
                  <Col span={24} className="job-card-col">
                    <TalentCard
                      talentDetails={talent}
                      openModalWithData={openModalWithData}
                    />
                  </Col>
                </Row>
              );
            })}
            <DetailsModal
              data={modalData}
              isVisible={isModalOpen}
              setIsVisible={setIsModalOpen}
            />
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default withFirebase(Talent);
