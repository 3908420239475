import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Layout,
  PageHeader,
  Row,
  Col,
  Card,
  Button,
  Typography,
  Divider,
} from "antd";
import {
  EnvironmentOutlined,
  FormOutlined,
  MailOutlined,
} from "@ant-design/icons";

import { withFirebase } from "../../../../Firebase";
import Job from "../../../models/Job";

const { Content } = Layout;
const { Title } = Typography;

const MobileView = ({ firebase }) => {
  const { id } = useParams();

  const [job, setJob] = useState(new Job({}));
  const [author, setAuthor] = useState({ email: "", name: "" });

  useEffect(() => {
    const fetchJob = async jobID => {
      const data = await firebase.db
        .collection("jobs")
        .doc(jobID)
        .get()
        .then(snapshot => snapshot.data());
      if (!data) {
        return [true, null];
      }
      return [false, data];
    };
    fetchJob(id).then(([hasError, result]) => {
      if (!hasError) {
        setJob(result);
      }
    });
  }, [firebase, id]);

  useEffect(() => {
    const getAuthorEmail = () => {
      const usersRef = firebase.db.collection("users");
      const query = usersRef.where("uid", "==", job.createdBy);
      query.get().then(snapshot => {
        snapshot.forEach(doc => {
          const { firstName, lastName, email } = doc.data();
          setAuthor({ ...{ name: `${firstName} ${lastName}`, email } });
        });
      });
    };
    if (!job) {
      return;
    }
    getAuthorEmail();
  }, [firebase, job]);

  const {
    logoURL,
    company,
    location,
    level,
    role,
    websiteURL,
    description,
    createdAt,
    linkToJob,
  } = job;

  const date = new Date(createdAt.seconds * 1000).toLocaleDateString();

  return (
    <Layout className="module">
      <PageHeader
        title="Jobs & Talent"
        id="resources-header"
        subTitle="Details"
        className="page-header mobile-page-header"
        style={{ borderBottom: "1px solid #f0f0f0" }}
      />
      <Content style={{ paddingTop: "80px", paddingBottom: "30px" }}>
        <Row style={{ height: "100%" }}>
          <Col span={22} offset={1}>
            <Card>
              <Row justify="center">
                <Col>
                  <img src={logoURL} alt="Company logo" height="120px" />
                </Col>
              </Row>
              <Divider />
              <Row style={{ marginTop: "5%" }}>
                <Col span={24}>
                  <Title level={3}>
                    {level} {role}
                  </Title>
                </Col>
              </Row>

              <Row>
                <Col>
                  <a href={websiteURL}> {company}</a>
                </Col>
              </Row>
              <Row>
                <Col>
                  <EnvironmentOutlined /> {location}
                </Col>
              </Row>
              <Divider />
              <Row justify="space-around">
                <Col span={linkToJob ? 11 : 24}>
                  <Button
                    style={{ width: "100%" }}
                    onClick={() => window.open(`mailto:${author.email}`)}
                  >
                    <MailOutlined /> Get in touch
                  </Button>
                </Col>
                {linkToJob && (
                  <Col span={12}>
                    <Button
                      onClick={() => window.open(linkToJob, "_blank")}
                      style={{ width: "100%" }}
                    >
                      <FormOutlined />
                      Apply directly
                    </Button>
                  </Col>
                )}
              </Row>
              <Divider />
              <Row>
                <Col>{description}</Col>
              </Row>
              <Divider />
              <Row>
                <Col>
                  created on the {date} by {author.name}
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default withFirebase(MobileView);
