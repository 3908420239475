import React from "react";
// Ant Design
import { Row, Col, Divider } from "antd";
import ProfileBodySection from "./ProfileBodySection";
// Options an utils
import FIELDS from "../../../constants/userFormFields";

const ProfileBody = ({ userData }) => {
  const fieldsExist = fields => {
    let exist = false;
    fields.forEach(field => {
      if (userData[field.value]) exist = true;
    });
    return exist;
  };

  return (
    <>
      {FIELDS.map((section, index) => (
        <Row key={section.heading}>
          {index !== 0 && fieldsExist(section.fields) && (
            <Divider style={{ marginTop: "1.5rem", marginBottom: "2rem" }} />
          )}
          <Col span={24}>
            <ProfileBodySection fields={section.fields} userData={userData} />
          </Col>
        </Row>
      ))}
    </>
  );
};

export default ProfileBody;
