import React from "react";

import { Drawer } from "antd";

import { isMobileOnly } from "react-device-detect";
import { Swipeable } from "react-swipeable";
import { withAuthorization } from "../Session";

import DirectoryProfile from "./Profile"

class ProfileDrawer_ extends React.Component {

    constructor(props) {
        super(props);
        window.addEventListener("resize", this.handleWindowSizeChange);
        this.state = {
          windowWidth: window.innerWidth,
        };
      }

    handleWindowSizeChange = () => {
    this.setState({ windowWidth: window.innerWidth });
    };

    render () {
        
        const { selectedProfile, visible, onClose, authUser } = this.props
        const { windowWidth } = this.state;

        if(selectedProfile) {
            return (
                <Drawer
                    placement={isMobileOnly ? "bottom" : "right"}
                    visible={visible}
                    onClose={() => onClose()}
                    closable
                    destroyOnClose
                    width={Math.min(windowWidth * 0.618, 650)}
                    height="85%"
                    >
                    <Swipeable onSwiped={event => this.swipe(event, isMobileOnly)}>
                        <DirectoryProfile
                        userData={selectedProfile}
                        authUserEmail={authUser.email}
                        />
                    </Swipeable>
                </Drawer>
              )    
        }
        return null
        
    }
    
}


const condition = authUser => authUser != null;

const ProfileDrawer = withAuthorization(condition)(ProfileDrawer_ );
// eslint-disable-next-line import/prefer-default-export
export { ProfileDrawer }; 